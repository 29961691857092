import { FC, memo } from 'react'
import { Button, Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { SeatTypesMagicTable } from 'modules/Seats/SeatMetrics/SeatTypesMagicTable'
import { Outlet, useNavigate } from 'react-router-dom'
import { SeatTypeProvider } from 'modules/Seats/SeatMetric'
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'

const SeatTypes: FC = memo(() => {
  const navigate = useNavigate()

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Metrics"
          actionElement={
            <ActionButton onClick={() => navigate('/seats/new')} />
          }
        >
          <SeatTypesMagicTable />
        </Page>
      </Flex>
      <SeatTypeProvider>
        <Outlet />
      </SeatTypeProvider>
    </Flex>
  )
})

const ActionButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="component-library-primary"
      leftIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
      iconSpacing="4px"
      onClick={onClick}
    >
      New seat metric
    </Button>
  )
}

export default SeatTypes
