import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { SeatBalancesMagicTable } from 'modules/Seats/SeatBalances/SeatBalancesMagicTable'
import { FC, memo } from 'react'

import { SeatBalanceProvider } from 'modules/Seats/SeatBalances/hooks/useSeatBalanceContext'
import { useSeatBalances } from 'modules/Seats/SeatBalances/hooks/useSeatBalances'

const SeatBalances: FC = memo(() => {
  const seatBalanceContext = useSeatBalances()

  return (
    <>
      <SeatBalanceProvider value={seatBalanceContext}>
        <Flex flexDirection="column" flex={1}>
          <Flex position="sticky" top={0} height="100vh">
            <Page title="Balances" paddingBottom={0}>
              <SeatBalancesMagicTable />
            </Page>
          </Flex>
        </Flex>
      </SeatBalanceProvider>
    </>
  )
})

export default SeatBalances
