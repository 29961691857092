import { FormFields, useForm } from '@sequencehq/utils'
import { greaterThan, required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  BillingFrequency,
  BillingType,
  PricingEditorReducerState
} from 'common/drawers/PricingEditor/domain'
import {
  defaultAvailableFrequenciesOptions,
  PRICING_MODEL_OPTIONS
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type StandardPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['STANDARD']

type StandardPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> &
  FormFields<StandardPriceFields> & {
    currency: FormFields<CommonFields>['currency'] & {
      hidden: boolean
    }
  }

type UseStandardPriceForm = () => {
  fieldsConfig: StandardPriceFormFieldConfig
  priceDetails: { label: string; value: string }[]
}

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

/**
 * The standard price has a bonus option, as the standard price pulls
 * double duty as the 'FIXED' and 'ONE_TIME' pricing model form. ONE_TIME
 * as a frequency will map to ONE_TIME as the model when going through the
 * external adapters.
 */
const availableFrequencies = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

export const useStandardPriceForm: UseStandardPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const commonFields = useCommonFieldConfig<
    StandardPriceFields & CommonFields
  >()

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.STANDARD
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'price',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'billingFrequency',
        validation: [required],
        options: availableFrequencies
      },
      {
        property: 'billingType',
        validation: [required],
        options: availableBillingTypeOptions
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name: newData.name,
          currency: newData.currency
        },
        STANDARD: {
          price: newData.price,
          billingFrequency: newData.billingFrequency,
          billingType: newData.billingType
        }
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      }
    }
  }, [fields, pricingEditorContext.queries.rawData.configuration.currency])

  const priceDetails = useMemo(() => {
    return commonPriceDetails({
      taxCategoryName:
        pricingEditorContext.queries.rawData.data.product.taxCategoryName,
      pricingModel: 'STANDARD',
      billingFrequency: queries.formData.billingFrequency,
      billingType: queries.formData.billingType
    })
  }, [
    queries.formData.billingFrequency,
    queries.formData.billingType,
    pricingEditorContext.queries.rawData.data.product.taxCategoryName
  ])

  return {
    fieldsConfig: enhancedFields,
    priceDetails
  }
}
