import { createContext, useContext } from 'react'
import { type LineItemEditorLineItem } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor.ts'
import {
  type IntegrationLedgerAccount,
  type PriceModel,
  type PricingType
} from '@sequencehq/core-models'
import type { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  AvalaraTaxValidationModalState,
  SequenceTaxValidationModalState
} from 'InvoiceEditor/components/LineItems/hooks/useTaxModals'

export type LineItemEditorInterface = {
  data: {
    currency: Currency
    title: string
    lineItem: LineItemEditorLineItem
    prices: Record<
      PriceModel['id'],
      { id: PriceModel['id']; pricingType: PricingType }
    >
    ledgerIntegrations: Record<
      string,
      {
        id: string
        defaultLedgerAccount?: string
        options: IntegrationLedgerAccount[]
      }
    >
  }
  editor: {
    canSave: boolean
    showValidationErrors: boolean
    canUseTaxCategories: boolean
    mode: 'CREATE' | 'EDIT'
  }
  functions: {
    onConfirmSubmit: (saveFunction: () => void) => () => Promise<void>
    onCreate: () => void
    onDelete: () => void
    onUpdate: () => void
    onValidationChange: (newValidity: boolean) => void
    updateLineItem: (newData: Partial<LineItemEditorLineItem>) => void
  }
  modals?: {
    avalara: AvalaraTaxValidationModalState
    sequence: SequenceTaxValidationModalState
  }
}

const LineItemEditorContext = createContext<LineItemEditorInterface>({
  data: {
    currency: 'GBP',
    title: 'Add new',
    lineItem: {
      id: '',
      title: '',
      description: '',
      quantity: '1',
      rate: '0.00',
      rateType: 'FIXED',
      itemType: 'PRODUCT',
      taxRate: '0',
      externalIds: {}
    },
    prices: {},
    ledgerIntegrations: {}
  },
  editor: {
    canSave: false,
    showValidationErrors: false,
    canUseTaxCategories: false,
    mode: 'CREATE'
  },
  functions: {
    onConfirmSubmit: () => () => Promise.resolve(),
    onCreate: () => {},
    onDelete: () => {},
    onUpdate: () => {},
    onValidationChange: () => {},
    updateLineItem: () => {}
  }
})

export const useLineItemEditorContext = () => useContext(LineItemEditorContext)
export const LineItemEditorProvider = LineItemEditorContext.Provider
