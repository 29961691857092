import { usePricingEditorConnector } from './usePricingEditor.connector'
import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'

/**
 * Route components are expected to be rendered as part of the routing definitions,
 * such as routes.tsx.
 *
 * If a route component needs access to data from the billing schedule context, it
 * should use a connector hook.
 * @returns
 */
export const PricingEditorCubeRouteWrapper = () => {
  const connector = usePricingEditorConnector()

  return (
    <PricingEditorRoot
      variant="variant"
      existingPrice={connector.existingData.price}
      disableDefaultPricePorts={connector.disableDefaultPricePorts}
      initialMode={connector.mode}
      currency={connector.scheduleCurrency}
      variantPriceId={connector.existingData?.price?.id}
      onSave={connector.onSave}
      onCancel={connector.onCancel}
    />
  )
}
