import { Text } from '@chakra-ui/react'
import { GreyGrey60, Lato13Bold } from '@sequencehq/design-tokens'
import { FC, PropsWithChildren } from 'react'

export const NavSection: FC<PropsWithChildren> = ({ children }) => (
  <Text
    {...Lato13Bold}
    fontSize="11px"
    textTransform="uppercase"
    color={GreyGrey60}
    px={2}
    py={1}
  >
    {children}
  </Text>
)
