import { currencyToName } from '@sequencehq/core-models'
import { FormFieldConfiguration } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'
import { useMemo } from 'react'

export type CommonFields = Pick<
  PricingEditorReducerState['data']['pricingEditorData']['common'],
  'currency' | 'name'
>

export const useCommonFieldConfig = <DataModel extends CommonFields>(): {
  fields: FormFieldConfiguration<DataModel>[]
} => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const availableCurrencies = useMemo(() => {
    return pricingEditorContext.queries.rawData.configuration.availableCurrencies.map(
      currency => ({
        label: currency,
        value: currency
      })
    )
  }, [pricingEditorContext.queries.rawData.configuration.availableCurrencies])

  const fields = useMemo(() => {
    return [
      {
        property: 'name',
        validation: [required]
      },
      {
        property: 'currency',
        validation: [required],
        options: availableCurrencies
      }
    ] as FormFieldConfiguration<DataModel>[]
  }, [availableCurrencies])

  return { fields }
}
