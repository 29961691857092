import { useLineItemEditorContext } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditorContext.ts'
import { useMemo } from 'react'
import { ValidationError, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'

export type FieldConfig<F, T extends keyof F = keyof F> = {
  disabled: boolean
  validationErrors: ValidationError[]
  value: F[T]
  onChange: (value: F[T]) => void
}

type UseExternalLedgerForm = () => {
  fieldsConfig: {
    integrations: (FieldConfig<Record<string, string>> & {
      id: string
      label: string
      options: { value: string; label: string }[]
    })[]
  }
}

export const defaultLedgerAccountValues = (
  existingValues: Record<string, string>,
  ledgerIntegrations: Record<
    string,
    { id: string; defaultLedgerAccount?: string }
  >
) => {
  return Object.values(ledgerIntegrations).reduce((acc, integration) => {
    if (existingValues[integration.id]) {
      return {
        ...acc,
        [integration.id]: existingValues[integration.id]
      }
    }

    return {
      ...acc,
      [integration.id]: integration.defaultLedgerAccount
    }
  }, {})
}

export const useExternalLedgerForm: UseExternalLedgerForm = () => {
  const lineItemEditorContext = useLineItemEditorContext()
  const presetValues = lineItemEditorContext.data.lineItem.externalIds
  const valuesWithDefaults = defaultLedgerAccountValues(
    presetValues,
    lineItemEditorContext.data.ledgerIntegrations
  )

  const { fields } = useForm<Record<string, string>>({
    value: valuesWithDefaults,
    showValidationErrors: lineItemEditorContext.editor.showValidationErrors,
    fieldConfiguration: Object.values(
      lineItemEditorContext.data.ledgerIntegrations
    ).map(integration => ({
      property: integration.id,
      validation: [required],
      options: integration.options.map(option => ({
        value: option.code,
        label: `${option.name} (${option.code})`
      }))
    })),
    onChange: newData => {
      lineItemEditorContext.functions.updateLineItem({
        externalIds: newData
      })
    }
  })

  const enhancedFieldsConfig = useMemo(() => {
    return Object.entries(fields).map(([integrationId, fieldConfig]) => ({
      ...fieldConfig,
      id: integrationId,
      label: `${integrationId} ledger account`
    }))
  }, [fields])

  return {
    fieldsConfig: {
      integrations: enhancedFieldsConfig
    }
  }
}
