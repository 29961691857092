import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useVariantPriceLayout } from './useVariantPriceLayout'
import { StandardPriceVariantForm } from '../../editors/StandardPrice/StandardPriceVariantForm'
import {
  SimpleAsyncModalUI,
  SimpleModalUI,
  Skeleton
} from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { GraduatedPriceForm } from '../../editors/GraduatedPrice/GraduatedPriceForm'
import { GraduatedPriceVariantForm } from '../../editors/GraduatedPrice/GraduatedPriceVariantForm'
import { LinearPriceVariantForm } from '../../editors/LinearPrice/LinearPriceVariantForm'
import { VolumePriceVariantForm } from '../../editors/VolumePrice/VolumePriceVariantForm'
import { PackagedPriceVariantForm } from '../../editors/PackagedPrice/PackagedPriceVariantForm'
import { SeatBasedPriceVariantForm } from '../../editors/SeatBasedPrice/SeatBasedPriceVariantForm'
import { StandardPriceForm } from '../../editors/StandardPrice/StandardPriceForm'
import { LinearPriceForm } from '../../editors/LinearPrice/LinearPriceForm'
import { VolumePriceForm } from '../../editors/VolumePrice/VolumePriceForm'
import { PackagedPriceForm } from '../../editors/PackagedPrice/PackagedPriceForm'
import { SeatBasedPriceForm } from '../../editors/SeatBasedPrice/SeatBasedPriceForm'

export const VariantPriceLayout = (props: {
  loading: boolean
  variantPriceId?: string
  onCancel?: () => void
}) => {
  const {
    formValid,
    isListPriceBasedVariant,
    pricingModel,
    onCancel,
    submit,
    submitting,
    title
  } = useVariantPriceLayout({
    variantPriceId: props.variantPriceId,
    onCancel: props.onCancel
  })

  return (
    <SimpleAsyncModalUI
      variant="drawer"
      title={title}
      cancelButtonText="Cancel"
      submitButtonText={submit.text}
      submittingButtonText={submit.submittingText}
      onSubmit={submit.action}
      closeOnSubmit={formValid}
      onClose={onCancel}
      contentProps={{
        padding: 0
      }}
      submitDisabled={submitting || submit.disabled}
      onCancel={onCancel}
      data-testid="priceEditor.variant"
    >
      <Grid
        templateAreas={`"content"`}
        templateRows={'1fr'}
        width="100%"
        height="100%"
      >
        <GridItem gridArea="content">
          <Box
            width="100%"
            minWidth="400px"
            data-testid="price-form"
            padding="24px"
          >
            {props.loading ? (
              <Flex direction="column" gap="20px" width="100%">
                <Skeleton height="40px" width="100%" />
                <Skeleton height="200px" width="100%" />
                <Skeleton height="40px" width="100%" />
                <Skeleton height="40px" width="100%" />
                <Skeleton height="40px" width="100%" />
              </Flex>
            ) : (
              match(pricingModel)
                .with('STANDARD', () =>
                  isListPriceBasedVariant ? (
                    <StandardPriceVariantForm />
                  ) : (
                    <StandardPriceForm />
                  )
                )
                .with('GRADUATED', () =>
                  isListPriceBasedVariant ? (
                    <GraduatedPriceVariantForm />
                  ) : (
                    <GraduatedPriceForm />
                  )
                )
                .with('LINEAR', () =>
                  isListPriceBasedVariant ? (
                    <LinearPriceVariantForm />
                  ) : (
                    <LinearPriceForm />
                  )
                )
                .with('VOLUME', () =>
                  isListPriceBasedVariant ? (
                    <VolumePriceVariantForm />
                  ) : (
                    <VolumePriceForm />
                  )
                )
                .with('PACKAGED', () =>
                  isListPriceBasedVariant ? (
                    <PackagedPriceVariantForm />
                  ) : (
                    <PackagedPriceForm />
                  )
                )
                .with('SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR', () =>
                  isListPriceBasedVariant ? (
                    <SeatBasedPriceVariantForm />
                  ) : (
                    <SeatBasedPriceForm />
                  )
                )
                .exhaustive()
            )}
          </Box>
        </GridItem>
      </Grid>
    </SimpleAsyncModalUI>
  )
}
