import { Button, Link, Text } from '@chakra-ui/react'
import { Lato14ExtendedRegular, Lato16Bold } from '@sequencehq/design-tokens'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { Wordmark } from '@sequencehq/core-components'

export const PkceExpectedCodeVerifierPage = () => {
  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Text {...Lato16Bold} color="gray.90">
          Looks like you're on a different browser
        </Text>
        <Text {...Lato14ExtendedRegular} color="gray.80">
          To keep your account secure, the magic link needs to be opened in the
          same browser or device where you started. Please try again.
        </Text>
        <Button
          as={Link}
          href="/auth/login"
          mt="2"
          variant="primary2"
          width="100%"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Try again
        </Button>
      </Section>
    </PageContainer>
  )
}

export default PkceExpectedCodeVerifierPage
