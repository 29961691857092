import {
  dashboardv99990101Client,
  type Dashboardv99990101Api
} from '@sequencehq/api/dashboard/v99990101'
import type { TaxCategory, TaxRate } from 'Settings/domain/taxRates.types'
import { useMutation, useQuery } from '@sequencehq/api/utils'
import { useNotifications } from 'lib/hooks/useNotifications'
import type { CountriesAlpha2, State } from '@sequencehq/api/commonEnums'
import { SUPPORTED_COUNTRIES_WITH_STATES } from 'Settings/view/taxRatesSettings/constants.ts'

type CreateNewTaxRateVersionBody =
  Dashboardv99990101Api.PostTaxRate.CreateNewTaxRateVersionBody
type CreateTaxRateBody = Dashboardv99990101Api.PostTaxRate.CreateTaxRateBody

export type UpdateTaxRateInput = {
  country: CountriesAlpha2 | undefined
  taxCategories: Record<TaxCategory['id'], TaxRate['amount']>
}

export function useUpdateTaxRate() {
  const notifications = useNotifications()

  const { data } = useQuery(dashboardv99990101Client.getTaxCategories)
  const taxCategories = data?.items ?? []

  const {
    mutateAsync: postTaxRate,
    isError: isPostTaxRateError,
    reset: resetPostTaxRate
  } = useMutation(dashboardv99990101Client.postTaxRate)
  const {
    mutateAsync: postNewTaxRateVersion,
    isError: isPostNewTaxRateVersionError,
    reset: resetPostNewTaxRateVersion
  } = useMutation(dashboardv99990101Client.postNewTaxRateVersion)

  return async (
    updateTaxRateInput: UpdateTaxRateInput,
    taxRatesForCountry?: TaxRate[],
    state?: State
  ) => {
    const country = updateTaxRateInput.country

    if (!country) {
      return
    }

    const taxRatesToCreate: {
      body: CreateTaxRateBody
    }[] = []
    const taxRatesToUpdate: {
      id: string
      body: CreateNewTaxRateVersionBody
    }[] = []

    Object.entries(updateTaxRateInput.taxCategories).map(
      ([categoryId, amount]) => {
        const existingTaxRate = taxRatesForCountry?.find(
          rate => rate.taxCategoryId === categoryId
        )

        // If a tax rate already existis for the country for the category,
        //    we need to update its version to persist the new amount.
        // Else we need to create a new tax rate for the category and country.
        if (existingTaxRate) {
          taxRatesToUpdate.push({
            id: existingTaxRate.id,
            body: {
              name: existingTaxRate.name,
              invoiceName: existingTaxRate.invoiceName,
              invoiceShortName: existingTaxRate.invoiceShortName,
              isPublished: true,
              taxCategoryId: existingTaxRate.taxCategoryId,
              amount,
              country,
              region: existingTaxRate.region
            }
          })
        } else {
          const taxCategoryName = taxCategories.find(
            taxCategory => taxCategory.id === categoryId
          )?.name

          const selectedState = SUPPORTED_COUNTRIES_WITH_STATES.includes(
            country
          )
            ? state
            : undefined

          const generatedTaxRateName = [country, selectedState, taxCategoryName]
            .filter(Boolean)
            .join(' ')

          taxRatesToCreate.push({
            body: {
              name: generatedTaxRateName,
              invoiceName: generatedTaxRateName,
              invoiceShortName: generatedTaxRateName,
              isPublished: true,
              taxCategoryId: categoryId,
              amount,
              country,
              region: selectedState
            }
          })
        }
      }
    )

    await Promise.all([
      ...taxRatesToCreate.map(taxRateToCreate => postTaxRate(taxRateToCreate)),
      ...taxRatesToUpdate.map(taxRateToUpdate =>
        postNewTaxRateVersion(taxRateToUpdate)
      )
    ])

    if (isPostTaxRateError || isPostNewTaxRateVersionError) {
      notifications.displayNotification('Failed to update tax rates', {
        type: 'error'
      })

      return
    }

    notifications.displayNotification('Tax rates updated', {
      type: 'success'
    })

    resetPostNewTaxRateVersion()
    resetPostTaxRate()
  }
}
