import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback } from 'react'

export type v1ApiNewBillingScheduleSettings =
  DashboardApi20240730.PostBillingScheduleSettings.BillingScheduleSettingsBody
export type v1ApiUpdateBillingScheduleSettings =
  DashboardApi20240730.PutBillingScheduleSettings.BillingScheduleSettingsBody
type ApiBillingScheduleSettings =
  DashboardApi20240730.PostBillingScheduleSettings.Response

type UseSaveBillingScheduleSettings = () => {
  updateBillingScheduleSettings: (
    data: v1ApiNewBillingScheduleSettings | v1ApiUpdateBillingScheduleSettings
  ) => Promise<ApiBillingScheduleSettings['data']>
}

export const useSaveBillingScheduleSettings: UseSaveBillingScheduleSettings =
  () => {
    const updateBillingScheduleSettings = useCallback(
      async ({
        ...data
      }: v1ApiUpdateBillingScheduleSettings): Promise<
        ApiBillingScheduleSettings['data']
      > => {
        const existingSettings = (
          await dashboard20240730Client.getBillingScheduleSettings({
            billingScheduleId: data.billingScheduleId
          })
        )?.data

        if (!existingSettings?.id) {
          const response =
            await dashboard20240730Client.postBillingScheduleSettings({
              body: {
                billingScheduleId: data.billingScheduleId,
                paymentProvider: data.paymentProvider,
                autoCharge: data.autoCharge
              }
            })

          return response.data
        }

        const response =
          await dashboard20240730Client.putBillingScheduleSettings({
            id: existingSettings.id,
            body: {
              billingScheduleId: data.billingScheduleId,
              paymentProvider: data.paymentProvider,
              autoCharge: data.autoCharge
            }
          })

        return response.data
      },
      []
    )

    return {
      updateBillingScheduleSettings
    }
  }
