import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { useLoadAllItemsQuery } from '@sequencehq/api/dist/utils'
import { useMemo } from 'react'

/**
 * Grabs the usage metrics from the API - will load all pages
 * and append a basic label to the metric. We'll be moving
 * more toward `useQuery` again now that we have a cache,
 * and usageMetrics are a prime candidate to benefit from that!
 * @returns
 */
export const useUsageMetrics = () => {
  const getUsageMetricsQuery = useLoadAllItemsQuery(
    dashboardv20240509Client.getUsageMetrics
  )

  const usageMetrics = useMemo(() => {
    if (getUsageMetricsQuery.data) {
      return getUsageMetricsQuery.data.pages
        .flatMap(page => page.items)
        .map(metric => ({
          ...metric,
          label: `${metric.name} (${metric.aggregationType})`
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    return []
  }, [getUsageMetricsQuery.data])

  return {
    usageMetrics,
    loading: getUsageMetricsQuery.isPending,
    error: getUsageMetricsQuery.isError
  }
}
