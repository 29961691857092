import { Flex, Text } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyGrey60,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import ChevronRightIcon from '@heroicons/react/16/solid/ChevronRightIcon'
import { Fragment } from 'react'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'

export const EditorBreadcrumb = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const steps = pricingEditorContext.queries.breadcrumbSteps
  const activeStepIndex = steps.findIndex(step => step.active)

  return (
    <Flex
      alignItems="center"
      padding="20px 16px"
      borderBottom={`1px solid ${GreyGrey30}`}
      backgroundColor={GreyGrey0}
      data-testid="editorBreadcrumb"
    >
      {steps.map((step, index) => (
        <Fragment key={step.name}>
          <Text
            {...Lato13Bold}
            color={step.active ? IndigoIndigo50 : GreyGrey60}
            cursor={index < activeStepIndex ? 'pointer' : 'default'}
            userSelect="none"
            data-testid={`editorBreadcrumb.step.${index}`}
            onClick={() => {
              if (index < activeStepIndex) {
                pricingEditorContext.mutators.common.updateConfiguration({
                  mode: step.mode
                })
              }
            }}
          >
            {step.name}
          </Text>
          {index < steps.length - 1 && (
            <ChevronRightIcon height="16px" width="16px" color={GreyGrey60} />
          )}
        </Fragment>
      ))}
    </Flex>
  )
}
