import {
  PhaseBlockConfig,
  PhaseBlock,
  QuoteBlock
} from '@sequencehq/quote-content'
import { PresentationV2 } from 'Cube/domain/cube.domain.types'
import { dequal } from 'dequal'
import { omit } from 'lodash'

export function hasPresentationChanged(
  prev: PresentationV2,
  current: PresentationV2
): boolean {
  return !dequal(toComparable(prev), toComparable(current))
}

export function toComparable(presentation: PresentationV2) {
  return {
    ...presentation,
    blocks: presentation.blocks.map(block => {
      if (isPhaseBlock(block)) {
        return { ...block, config: stripEphemeralData(block.config) }
      }
      return block
    })
  }
}

export function stripEphemeralData(config: PhaseBlockConfig) {
  return {
    ...config,
    phases: config.phases.map(phase => {
      const { id, prices, discounts, minimums, ...basePhase } = phase
      return {
        ...basePhase,
        prices: prices.map(price =>
          omit(price, ['id', 'createdAt', 'updatedAt'])
        ),
        discounts: discounts.map(discount =>
          omit(discount, ['id', 'priceIds'])
        ),
        minimums: minimums.map(minimum =>
          omit(minimum, ['id', 'scope.priceIds'])
        ),
        globalDiscount: phase.globalDiscount ?? undefined
      }
    })
  }
}

export function isPhaseBlock(block: {
  blockType: QuoteBlock['blockType']
}): block is PhaseBlock {
  return block.blockType === 'phases'
}

const NON_BLOCKNOTE_BLOCKTYPES = ['coverImage']
export const mergeBlockNotePresentationIntoFullPresentation = (
  blockNotePresentation: QuoteBlock[],
  fullPresentation: PresentationV2
) => {
  // Right now coverImage (when it exists) is the first block so we can just concat
  // In the future we might need to handle this differently to account for ordering
  return {
    ...fullPresentation,
    blocks: fullPresentation.blocks
      .filter(block => NON_BLOCKNOTE_BLOCKTYPES.includes(block.blockType))
      .concat(blockNotePresentation)
  }
}
