import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  IconButton,
  MenuButton
} from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey30,
  GreyGrey60,
  Lato16Bold
} from '@sequencehq/design-tokens'
import { ChevronRightIcon } from '@sequencehq/core-components'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { Link, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import { KebabMenu, MenuItemBuilder } from '@sequencehq/core-components'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import { archiveProductBuilder } from 'Products/Products.tsx'
import { Product } from 'modules/Products/types'
import { NEW_PRICE_PREFIX } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

export const ProductHeader = () => {
  const {
    data: { product }
  } = useProductContext()
  const navigate = useNavigate()
  const flags = useFlags()
  return (
    <Flex
      padding="16px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid"
      borderColor={GreyGrey30}
      h={47}
    >
      <Breadcrumb
        spacing="4px"
        separator={
          <Box
            as="span"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mx={2}
          >
            <ChevronRightIcon color={GreyGrey60} />
          </Box>
        }
      >
        <BreadcrumbItem {...Lato16Bold} color={GreyGrey60}>
          <BreadcrumbLink as={Link} to="/products">
            Products
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage {...Lato16Bold}>
          <BreadcrumbLink href="#">{product.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex gap="6px">
        <Button
          variant="component-library-secondary"
          aria-label="Edit product"
          onClick={() => navigate(`/products/${product.id}/edit`)}
        >
          Edit product
        </Button>

        <Button
          variant="component-library-primary"
          aria-label="Create new list price"
          onClick={() =>
            navigate(
              `/products/${
                product.id
              }/list-price/${NEW_PRICE_PREFIX}${crypto.randomUUID()}`
            )
          }
          leftIcon={<PlusIcon height="16px" width="16px" color="inherit" />}
          iconSpacing="4px"
          data-testid="product.newListPrice"
        >
          New list price
        </Button>
        <KebabMenu
          isLazy
          menuItems={[archiveProductBuilder].map(
            (builder: MenuItemBuilder<Product>) =>
              builder(product, { onSuccess: () => navigate(`/products`) })
          )}
          buttonComponent={
            <MenuButton
              as={IconButton}
              variant="component-library-ghost"
              size="sm"
              icon={
                <EllipsisHorizontalIcon
                  style={{ height: '24px', width: '24px', color: GreyGrey60 }}
                />
              }
              onClick={e => {
                e.stopPropagation()
              }}
              minWidth="initial"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="6px"
              _hover={{
                backgroundColor: GreyGrey20
              }}
            />
          }
        />
      </Flex>
    </Flex>
  )
}
