import { SelectFieldWithActions } from '@sequencehq/core-components'
import { useNavigate } from 'react-router-dom'
import { useUsageMetrics } from '../../../../communication/external/useUsageMetrics'
import { useMemo } from 'react'

export const UsageMetricField = (props: {
  value: string
  onChange: (value: string) => void
  validationErrors: { message: string }[]
  isDisabled: boolean
  'data-testid': string
}) => {
  const navigate = useNavigate()

  const { usageMetrics, error, loading } = useUsageMetrics()
  const options = useMemo(() => {
    return usageMetrics.map(metric => ({
      label: metric.label,
      value: metric.id
    }))
  }, [usageMetrics])

  return (
    <SelectFieldWithActions
      label="Usage metric"
      data-testid={props['data-testid']}
      placeholder="Select usage metric"
      value={props.value}
      options={options}
      onChange={props.onChange}
      validationErrors={props.validationErrors}
      isDisabled={props.isDisabled || loading || error}
      onAddNew={() => navigate('./metrics/new')}
    />
  )
}
