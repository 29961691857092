import { createSlice } from '@reduxjs/toolkit'
import integratedApiWithTags, { TagTypes } from 'features/api'
import { match, P } from 'ts-pattern'

const monitoredTagTypes = [
  'BillingSchedules',
  'CreditGrants',
  'CreditNotes',
  'Customers',
  'Invoices',
  'NotificationPolicies',
  'OAuthClients',
  'Plans',
  'Prices',
  'Products',
  'Quotes',
  'SeatSnapshots',
  'TaxRates',
  'SeatSnapshots',
  'SeatTotals',
  'SeatTypes',
  'SeatBalances',
  'SingleSignOnDomains',
  'UsageEvents',
  'UsageMetrics',
  'Users'
] as const

export type MonitoredTagTypes =
  | Extract<TagTypes, (typeof monitoredTagTypes)[number]>
  | '__IGNORE'

const getInitialState = () => {
  const tags = monitoredTagTypes.reduce<Record<MonitoredTagTypes, number>>(
    (acc, monitoredTagType) => ({
      ...acc,
      [monitoredTagType]: 0
    }),
    {} as Record<MonitoredTagTypes, number>
  )

  return { tags }
}

const lookupTagFromCacheKey = (
  queryCacheKey: string
): MonitoredTagTypes | undefined => {
  return match(queryCacheKey)
    .with(P.string.startsWith('getCustomers'), () => 'Customers' as const)
    .with(P.string.startsWith('getInvoices'), () => 'Invoices' as const)
    .with(P.string.startsWith('getApiPlans'), () => 'Plans' as const)
    .with(P.string.startsWith('getPrices'), () => 'Prices' as const)
    .with(
      P.string.startsWith('getApiUsageEvents'),
      () => 'UsageEvents' as const
    )
    .with(P.string.startsWith('getCreditNotes'), () => 'CreditNotes' as const)
    .with(P.string.startsWith('getUsageMetrics'), () => 'UsageMetrics' as const)
    .with(P.string.startsWith('getSeatTypes'), () => 'SeatTypes' as const)
    .with(P.string.startsWith('getSeatBalances'), () => 'SeatBalances' as const)
    .with(
      P.string.startsWith('getSeatSnapshots'),
      () => 'SeatSnapshots' as const
    )
    .with(
      P.string.startsWith('getLatestSeatTotals'),
      () => 'SeatTotals' as const
    )
    .with(P.string.startsWith('getUsers'), () => 'Users' as const)
    .with(
      P.string.startsWith('getApiNotificationsPolicies'),
      () => 'NotificationPolicies' as const
    )
    .with(P.string.startsWith('getTaxRates'), () => 'TaxRates' as const)
    .with(P.string.startsWith('getOauthClients'), () => 'OAuthClients' as const)
    .with(P.string.startsWith('getBillingProducts'), () => 'Products' as const)
    .with(
      P.string.startsWith('getCreditsFiltered'),
      () => 'CreditGrants' as const
    )
    .with(P.string.startsWith('getApiQuotes'), () => 'Quotes' as const)
    .otherwise(() => undefined)
}

const { reducer } = createSlice({
  name: 'apiCaching',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      integratedApiWithTags.internalActions.removeQueryResult,
      (state, action) => {
        const tag = lookupTagFromCacheKey(action.payload.queryCacheKey)
        if (tag) {
          state.tags[tag] = state.tags[tag] + 1
        }
      }
    )
  }
})

export default reducer
