import { Currency, toPriceSummary } from '@sequencehq/core-models'
import { greaterThan, required } from '@sequencehq/validation'
import {
  Minimum,
  MinimumEditorMode,
  MinimumEditorPrice,
  MinimumScopeTarget
} from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'
import { FormFields, toBillingFrequencyLabel, useForm } from '@sequencehq/utils'
import deepmerge from 'deepmerge'
import { dequal } from 'dequal'
import { useCallback, useMemo, useState } from 'react'

type MinimumEditorFields = Omit<FormFields<Minimum>, 'scope.priceIds'> & {
  'scope.priceIds': Omit<FormFields<Minimum>['scope.priceIds'], 'options'> & {
    options: {
      value: MinimumEditorPrice['id']
      label: string
      description?: string
      disabled?: boolean
    }[]
    hidden: boolean
  }
}

export type MinimumDomainInterface = {
  queries: {
    editor: {
      mode: MinimumEditorMode
      currency: Currency
      changesMade: boolean
      canSave: boolean
      availablePrices: MinimumEditorPrice[]
      applyToAllAvailable: boolean
    }
    form: {
      fields: MinimumEditorFields
    }
  }
  mutators: {
    save: () => void
    close: () => void
  }
}

type UseMinimumEditorDomain = (props: {
  data: {
    initialMinimumData: Minimum
  }
  configuration: {
    currency: Currency
    mode: MinimumEditorMode
    availablePrices: MinimumEditorPrice[]
    applyToAllAvailable: boolean
  }
  handlers: {
    onSave: (updatedMinimum: Minimum) => void
    onClose: () => void
  }
}) => MinimumDomainInterface

/**
 * Since minimums is a rather simple drawer (effectively one form), the domain will be primarily be a wrapper
 * over the `useForm` hook. We will define field configuration at the domain level, and expect
 * the form component to make use of these configurations.
 *
 * This prevents us needing to sync between two sources of data, as well as manage the syncronisation of form
 * validation state (to control functionality such as save).
 *
 * @returns
 */
export const useMinimumEditorDomain: UseMinimumEditorDomain = props => {
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const scopeTargetOptions: {
    value: MinimumScopeTarget
    label: string
    disabled?: boolean
    disabledReason?: string
  }[] = useMemo(
    () => [
      {
        value: 'allUsage',
        label: 'All usage based products',
        disabled: !props.configuration.applyToAllAvailable,
        disabledReason: !props.configuration.applyToAllAvailable
          ? 'A minimum  can only be applied to prices with the same frequency'
          : ''
      },
      {
        value: 'specific',
        label: 'Specific products'
      }
    ],
    [props.configuration.applyToAllAvailable]
  )

  const form = useForm<Minimum>({
    value: props.data.initialMinimumData,
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'value',
        validation: [
          required,
          greaterThan(0, 'Please enter an amount greater than 0')
        ],
        disabled: () => props.configuration.mode === MinimumEditorMode.VIEW
      },
      {
        property: 'name',
        disabled: () => props.configuration.mode === MinimumEditorMode.VIEW
      },
      {
        property: 'scope.target',
        options: scopeTargetOptions,
        disabled: () => props.configuration.mode === MinimumEditorMode.VIEW
      },
      {
        property: 'scope.priceIds',
        disabled: ctx =>
          ctx.formData.scope.target !== 'specific' ||
          props.configuration.mode === MinimumEditorMode.VIEW,
        validation: [required]
      }
    ]
  })

  const selectedFrequency = useMemo(() => {
    const allSelectedPrices = form.queries.formData.scope.priceIds
      .map(priceId =>
        props.configuration.availablePrices.find(price => price.id === priceId)
      )
      .filter((price): price is MinimumEditorPrice => Boolean(price))

    return (
      allSelectedPrices.find(price => price.billingFrequency !== 'ONE_TIME')
        ?.billingFrequency ?? null
    )
  }, [form.queries.formData, props.configuration.availablePrices])

  const enhancedFieldConfig = useMemo(() => {
    return deepmerge(form.fields, {
      ['scope.priceIds']: {
        options: props.configuration.availablePrices.map(price => ({
          value: price.id,
          label: price.name,
          description: `${toPriceSummary(
            price
          )} / ${toBillingFrequencyLabel(price.billingFrequency)}`,
          disabled: Boolean(
            selectedFrequency && price.billingFrequency !== selectedFrequency
          )
        })),
        hidden: form.queries.formData.scope.target !== 'specific'
      }
    })
  }, [
    form.fields,
    props.configuration.availablePrices,
    form.queries.formData.scope.target,
    selectedFrequency
  ])

  const handleSave = useCallback(() => {
    if (!form.queries.isValid) {
      setShowValidationErrors(true)
      return
    }

    props.handlers.onSave(form.queries.formData)
  }, [form.queries.isValid, form.queries.formData, props.handlers])

  const handleClose = useCallback(() => {
    props.handlers.onClose()
  }, [props.handlers])

  const changesMade = useMemo(() => {
    return !dequal(form.queries.formData, props.data.initialMinimumData)
  }, [props.data.initialMinimumData, form.queries.formData])

  const canSave = useMemo(() => {
    return form.queries.isValid && changesMade
  }, [form.queries.isValid, changesMade])

  if (localStorage.getItem('enable-minimum-drawer-logs')) {
    //eslint-disable-next-line no-console
    console.info(
      `%c[Minimum editor] minimum editor domain output`,
      'color: green;',
      {
        queries: {
          editor: {
            currency: props.configuration.currency,
            mode: props.configuration.mode,
            changesMade,
            canSave
          },
          form: {
            fields: enhancedFieldConfig
          }
        }
      }
    )
  }

  return {
    queries: {
      editor: {
        availablePrices: props.configuration.availablePrices,
        currency: props.configuration.currency,
        mode: props.configuration.mode,
        changesMade,
        canSave,
        applyToAllAvailable: props.configuration.applyToAllAvailable
      },
      form: {
        fields: enhancedFieldConfig
      }
    },
    mutators: {
      save: handleSave,
      close: handleClose
    }
  }
}
