import { Box, Flex, Grid, Icon, Center } from '@chakra-ui/react'
import {
  GreyGrey20,
  Lato12Regular,
  GreyGrey70,
  RedRed60,
  GreyGrey60,
  Lato14Bold,
  YellowYellow10,
  YellowYellow80
} from '@sequencehq/design-tokens'
import { SequenceMenu, Button } from '@sequencehq/core-components'
import EllipsisHorizontalIcon from '@heroicons/react/16/solid/EllipsisHorizontalIcon'
import CheckCircleIcon from '@heroicons/react/16/solid/CheckCircleIcon'
import { FC, useState } from 'react'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/16/solid'
import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon'
import ChevronRightIcon from '@heroicons/react/16/solid/ChevronRightIcon'
import { usePhaseDetails } from './usePhaseDetails'
import { usePhaseActions } from './usePhaseActions'
import { PhaseTitle } from './PhaseTitle'
import { usePhaseName } from './usePhaseName'

export const PhaseHeader: FC<{
  phaseId: string
  onExpand: (newState: boolean) => void
  expanded: boolean
  showAlignDatesWarning: boolean
}> = props => {
  const phaseDetails = usePhaseDetails({ phaseId: props.phaseId })
  const phaseActions = usePhaseActions({ phaseId: props.phaseId })
  const phaseName = usePhaseName({ phaseId: props.phaseId })
  const [isEditingName, setIsEditingName] = useState(false)

  return (
    <Grid
      height="56px"
      templateColumns={'1fr auto'}
      columnGap="8px"
      padding="0 8px 0 0"
      alignItems="center"
      {...(phaseDetails.isOnlyPhase
        ? {}
        : {
            cursor: 'pointer',
            onClick: () => props.onExpand(!props.expanded)
          })}
      {...Lato14Bold}
      userSelect="none"
      data-testid="phase.header"
    >
      <Flex alignItems="center" gap="8px">
        <Flex
          alignItems="center"
          justifyContent="center"
          height="24px"
          width="24px"
        >
          <Icon
            as={props.expanded ? ChevronDownIcon : ChevronRightIcon}
            height="20px"
            width="20px"
          />
        </Flex>
        <PhaseTitle
          phaseId={props.phaseId}
          isEditing={isEditingName}
          onEditStart={() => setIsEditingName(true)}
          onEditComplete={() => setIsEditingName(false)}
        />
        {props.showAlignDatesWarning && (
          <Flex
            backgroundColor={YellowYellow10}
            borderRadius="6px"
            height="24px"
            width="24px"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              as={ExclamationTriangleIcon}
              height="16px"
              width="16px"
              color={YellowYellow80}
            />
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Box>
          {phaseDetails.phaseComplete && (
            <Flex
              backgroundColor={GreyGrey20}
              borderRadius="6px"
              alignItems="center"
              padding="0 6px"
              height="24px"
              color={GreyGrey70}
              gap="4px"
              {...Lato12Regular}
            >
              <Icon
                as={CheckCircleIcon}
                height="16px"
                width="16px"
                color={GreyGrey70}
              />
              Completed
            </Flex>
          )}
        </Box>
        <Box onClick={e => e.stopPropagation()}>
          <SequenceMenu
            alignment="bottom-right"
            menuButton={
              <Button variant="ghost" size="24px" data-testid="phase.options">
                <Center flexShrink="0">
                  <EllipsisHorizontalIcon
                    width="16px"
                    height="16px"
                    color={GreyGrey60}
                  />
                </Center>
              </Button>
            }
            items={[
              {
                label: phaseName.name ? 'Edit name' : 'Add name',
                onClick: () => setIsEditingName(true),
                isDisabled: !phaseName.available || isEditingName
              },
              ...(phaseName.name
                ? [
                    {
                      label: 'Delete name',
                      onClick: () => {
                        setIsEditingName(false)
                        phaseName.updateName('')
                      },
                      style: { color: RedRed60 },
                      isDisabled: !phaseName.available
                    }
                  ]
                : []),
              {
                label: 'Delete phase',
                onClick: phaseActions.deletePhase.handler,
                style: { color: RedRed60 },
                isDisabled: !phaseActions.deletePhase.available
              }
            ]}
          />
        </Box>
      </Flex>
    </Grid>
  )
}
