import { taxStatusMap } from '@sequencehq/core-models'
import {
  type USStates,
  type CountriesAlpha2,
  useQuery,
  useLoadAllItemsQuery
} from '@sequencehq/api/utils'
import {
  statesForCountry,
  getStateOptions
} from '@sequencehq/api/utils/commonEnums'
import { useForm } from '@sequencehq/utils'
import { isEmail, required } from '@sequencehq/validation'
import type {
  Address,
  CreateCustomer,
  TaxStatus,
  Customer
} from 'components/Customers/types'
import { dequal } from 'dequal'
import { useCallback, useMemo, useState } from 'react'
import type { CustomerContactBillingPreference } from 'CustomerContacts/domain/CustomerContacts.domain.types'
import { countriesOptions } from 'components/Customers/data'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CreateCustomerError } from 'components/Customers/hooks/useCreateCustomer'

export const useCreateCustomerForm = ({
  onSubmit
}: {
  onSubmit: (
    customer: CreateCustomer
  ) => Promise<{ customer: Customer | null; error: CreateCustomerError | null }>
}) => {
  const { data: merchant } = useQuery(dashboard20240730Client.getMerchant)
  const { data: customersData } = useLoadAllItemsQuery(
    dashboardv99990101Client.getCustomers
  )
  const customers = customersData?.pages?.flatMap(page => page.items) ?? []

  const [isValid, setIsValid] = useState<boolean>(true)
  const [customer, setCustomer] = useState<CreateCustomer>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false)

  const initialState = useMemo(
    () => ({
      legalName: '',
      email: '',
      taxStatus: 'TAXED' as TaxStatus,
      label: '',
      parentId: '',
      customerAliases: [],
      address: {
        line1: '',
        town: '',
        postcode: '',
        country: merchant?.address.country ?? 'GB',
        state: undefined
      },
      addTaxRegistration: false,
      taxCountry: 'GB' as CountriesAlpha2,
      contacts: [],
      contactName: ''
    }),
    [merchant]
  )

  const stateOptions: { label: string; value: string }[] = useMemo(() => {
    return getStateOptions(customer?.address?.country)
  }, [customer])

  const taxStateOptions: { label: string; value: string }[] = useMemo(() => {
    return getStateOptions(customer?.taxCountry)
  }, [customer])

  const parentCustomerOptions = useMemo(() => {
    if (!customers) {
      return [{ value: '', label: 'Choose a parent account' }]
    }

    const options = customers.filter(item => {
      return !item.organizations.some(org =>
        org.members.some(member => member.id === item.id)
      )
    })

    const formattedOptions = options.map(({ id, legalName }) => ({
      value: id,
      label: legalName
    }))

    return [
      { value: '', label: 'Choose a parent account' },
      ...formattedOptions
    ]
  }, [customers])

  const { fields, mutators } = useForm<
    CreateCustomer & { contactName: string; email: string }
  >({
    value: initialState,
    showValidationErrors,
    fieldConfiguration: [
      {
        property: 'legalName',
        validation: [required]
      },
      {
        property: 'email',
        validation: [required, isEmail]
      },
      {
        property: 'label'
      },
      {
        property: 'address.line1',
        validation: [required]
      },
      {
        property: 'address.line2'
      },
      {
        property: 'address.town',
        validation: [required]
      },
      {
        property: 'address.state',
        options: stateOptions,
        validation: stateOptions.length > 0 ? [required] : []
      },
      {
        property: 'address.country',
        options: countriesOptions,
        validation: [required]
      },
      {
        property: 'address.postcode',
        validation: [required]
      },
      {
        property: 'taxStatus',
        options: Object.entries(taxStatusMap).map(([value, label]) => ({
          value,
          label
        })),
        validation: [required]
      },
      {
        property: 'customerAliases'
      },
      {
        property: 'addTaxRegistration'
      },
      {
        property: 'taxCountry',
        options: countriesOptions
      },
      {
        property: 'taxState',
        options: taxStateOptions,
        validation: taxStateOptions.length > 0 ? [required] : []
      },
      {
        property: 'taxIdentifier'
      },
      {
        property: 'parentId',
        options: parentCustomerOptions
      },
      {
        property: 'contactName',
        validation: [required]
      }
    ],
    onChange: newData => {
      setCustomer({
        ...newData,
        contacts: [contact(newData.contactName, newData.email)]
      })
    },
    onValidationStateChange: setIsValid
  })

  const processedFields = useMemo(() => {
    return {
      ...fields,
      'address.country': {
        ...fields['address.country'],
        showAddressFields: fields['address.country'].value
      },
      'address.state': {
        ...fields['address.state'],
        visible: statesForCountry(fields['address.country'].value).length > 0
      },
      taxState: {
        ...fields['taxState'],
        visible: statesForCountry(fields['taxCountry'].value).length > 0
      },
      customerAliases: {
        ...fields['customerAliases'],
        onAddNew: () =>
          fields.customerAliases.onChange([
            ...fields.customerAliases.value,
            ''
          ]),
        onRemove: (index: number) => {
          const aliasesBefore =
            index !== 0 ? fields.customerAliases.value.slice(0, index) : []
          const aliasesAfter =
            fields.customerAliases.value.slice(index + 1) ?? []
          fields.customerAliases.onChange([...aliasesBefore, ...aliasesAfter])
        }
      },
      addTaxRegistration: {
        ...fields.addTaxRegistration,
        visible: true,
        showTaxRegistrationFields: fields.addTaxRegistration.value !== false
      }
    }
  }, [fields])

  const canCreate = useMemo(() => {
    return customer !== undefined && !dequal(customer, initialState)
  }, [customer, initialState, isValid])

  const handleSubmit = useCallback(async () => {
    setIsSaving(true)
    if (!canCreate || !customer) {
      return
    }

    if (!isValid) {
      setShowValidationErrors(true)
      setIsSaving(false)
      return
    }

    const countryStates = statesForCountry(customer.address.country)

    if (
      countryStates.length > 0 &&
      fields['address.state'].value &&
      !countryStates.includes(fields['address.state'].value)
    ) {
      fields['address.state'].onChange(undefined as never)
      setShowValidationErrors(true)
      setIsSaving(false)
      return
    }

    const customerPayload = {
      ...customer,
      address: {
        ...customer.address,
        state:
          fields['address.state'].value &&
          countryStates.includes(fields['address.state'].value)
            ? fields['address.state'].value
            : undefined
      }
    }

    const submitResult = await onSubmit(customerPayload)

    setIsSaving(false)

    return submitResult
  }, [canCreate, customer, isValid, onSubmit, setShowValidationErrors])

  return {
    handleSubmit,
    fieldsConfig: processedFields,
    canCreate,
    isSaving
  }
}

function contact(name: string, email: string) {
  return {
    name,
    email,
    billingPreference: 'PRIMARY' as CustomerContactBillingPreference
  }
}
