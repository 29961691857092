import { Flex, Icon } from '@chakra-ui/react'
import PlusIcon from '@heroicons/react/24/outline/PlusIcon'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import { Currency } from '@sequencehq/core-models'
import { GreyGrey50, GreyGrey70 } from '@sequencehq/design-tokens'
import {
  PercentageInput,
  PriceInput,
  SimpleTableColumn,
  SimpleTableInputProps,
  SimpleTableInvalidRow,
  SimpleTableRowActionsProps
} from '@sequencehq/core-components'
import { FC, useCallback, useMemo } from 'react'
import { enforceMinimumPrecision, ValidationError } from '@sequencehq/utils'
import { PriceEditorPercentageTier } from 'common/drawers/PricingEditor/domain'
import { sanitizePercentageTiers } from 'common/drawers/PricingEditor/utils/sanitizePercentageTiers'

const tableInputStyle = {
  border: 'none',
  boxShadow: 'none',
  borderRadius: 'none'
}

const tableColumnsConfig: SimpleTableColumn<
  PriceEditorPercentageTier,
  keyof PriceEditorPercentageTier,
  {
    currency: Currency
  }
>[] = [
  {
    property: 'firstUnit',
    label: 'First Unit',
    bodyComponent: ({ value, config, onChange, rowIndex }) => (
      <PriceInput
        isDisabled
        currency={config.currency}
        value={value ?? '0.00'}
        onChange={onChange}
        variant="table"
        data-testid={`priceEditor.percentageTiers.${rowIndex}.firstUnit`}
        {...tableInputStyle}
      />
    )
  },
  {
    property: 'lastUnit',
    label: 'Last Unit',
    bodyComponent: ({
      value,
      onChange,
      onBlur,
      rowIndex,
      rows,
      disabled,
      config
    }) => {
      return (
        <PriceInput
          isDisabled={disabled || rowIndex === rows.length - 1}
          currency={config.currency}
          value={rowIndex === rows.length - 1 ? '∞' : value ?? ''}
          onChange={newValue => {
            if (rowIndex === rows.length - 1) {
              return
            }
            return onChange(newValue)
          }}
          variant="table"
          onBlur={onBlur}
          placeholder="0.00"
          onFocus={() => {
            if (value === '0.00') {
              onChange('')
            }
          }}
          data-testid={`priceEditor.percentageTiers.${rowIndex}.lastUnit`}
          {...tableInputStyle}
        />
      )
    }
  },
  {
    property: 'unitPercentage',
    label: 'Unit Price',
    bodyComponent: ({ value, onChange, onBlur, disabled, rowIndex }) => (
      <PercentageInput
        isDisabled={disabled}
        onChange={onChange}
        value={value ?? ''}
        onBlur={onBlur}
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        {...tableInputStyle}
        data-testid={`priceEditor.percentageTiers.${rowIndex}.unitPercentage`}
        variant="table"
      />
    )
  },
  {
    property: 'flatFee',
    label: 'Flat Fee',
    bodyComponent: ({
      value,
      onChange,
      onBlur,
      disabled,
      config,
      rowIndex
    }) => (
      <PriceInput
        isDisabled={disabled}
        currency={config.currency}
        onChange={onChange}
        value={value ?? ''}
        variant="table"
        onBlur={onBlur}
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        data-testid={`priceEditor.percentageTiers.${rowIndex}.flatFee`}
        {...tableInputStyle}
      />
    )
  }
]

type UsePercentageTierTableInput = (props: {
  onChange: (value: PriceEditorPercentageTier[]) => void
  value: PriceEditorPercentageTier[]
  isDisabled: boolean
  currency: Currency
  validationErrors: ValidationError[]
}) => {
  addNewTier: (rowIndex?: number) => void
  fieldConfig: SimpleTableInputProps<
    PriceEditorPercentageTier,
    {
      currency: Currency
    }
  > & {
    invalidRows: SimpleTableInvalidRow<PriceEditorPercentageTier>[]
    onBlur: () => void
  }
  test: {
    onDelete: (id: PriceEditorPercentageTier['id']) => void
    rowActionsAvailable: {
      delete: boolean
      addNewTier: boolean
    }
  }
}

export const usePercentageTierTableInput: UsePercentageTierTableInput =
  props => {
    const rowActionsAvailable = useMemo(() => {
      return {
        delete: props.value.length > 1 && !props.isDisabled,
        addNewTier: !props.isDisabled
      }
    }, [props.isDisabled, props.value.length])

    const onDelete = useCallback(
      (id: PriceEditorPercentageTier['id']) => {
        const newTiers = props.value.filter(tier => tier.id !== id)

        props.onChange(sanitizePercentageTiers(newTiers))
      },
      [props.value, props.onChange]
    )

    const addNewTier = useCallback(
      (rowIndex: number = props.value.length - 1) => {
        const previousTier = props.value[rowIndex]

        const newTiers = sanitizePercentageTiers([
          ...props.value.slice(0, rowIndex + 1),
          {
            id: crypto.randomUUID(),
            /** These will be calculated and set by the prepare tiers functionality */
            firstUnit: '',
            lastUnit: '',
            unitPercentage: enforceMinimumPrecision(2)(
              previousTier?.unitPercentage
            ),
            flatFee: enforceMinimumPrecision(2)(previousTier?.flatFee),
            minPrice: '',
            maxPrice: ''
          },
          ...props.value.slice(rowIndex + 1)
        ])
        props.onChange(newTiers)
      },
      [props.onChange, props.value]
    )

    const rowActionsComponent: FC<
      SimpleTableRowActionsProps<PriceEditorPercentageTier>
    > = useMemo(() => {
      return ({ rowData, rowIndex }) => {
        return (
          <Flex alignItems="center" height="100%" padding="0 8px" gap="4px">
            <Icon
              data-test="fixed-delete-tier"
              as={TrashIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.delete
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => rowActionsAvailable.delete && onDelete(rowData.id)}
            />
            <Icon
              data-test="fixed-add-new-tier"
              as={PlusIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.addNewTier
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              cursor="pointer"
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => {
                rowActionsAvailable.addNewTier && addNewTier(rowIndex)
              }}
            />
          </Flex>
        )
      }
    }, [onDelete, addNewTier, rowActionsAvailable])

    const rowValidationErrors: SimpleTableInvalidRow<PriceEditorPercentageTier>[] =
      useMemo(() => {
        return Object.values(
          props.validationErrors.reduce(
            (acc, error) => {
              const tierId = error.metadata?.tierId as string
              if (!tierId) {
                return acc
              }

              if (!acc[tierId]) {
                return {
                  ...acc,
                  [tierId]: {
                    id: tierId,
                    invalidProperties: [
                      error.metadata
                        ?.property as keyof PriceEditorPercentageTier
                    ]
                  }
                }
              }

              return {
                ...acc,
                [tierId]: {
                  ...acc[tierId],
                  invalidProperties: [
                    ...(acc[tierId]?.invalidProperties ?? []),
                    error.metadata?.property as keyof PriceEditorPercentageTier
                  ]
                }
              }
            },
            {} as Record<
              string,
              SimpleTableInvalidRow<PriceEditorPercentageTier>
            >
          )
        )
      }, [props.validationErrors])

    const onChange = useCallback(
      (newValue: PriceEditorPercentageTier[]) => {
        props.onChange(newValue)
      },
      [props.onChange]
    )

    const onBlur = useCallback(() => {
      props.onChange(sanitizePercentageTiers(props.value))
    }, [props.onChange, props.value])

    return {
      addNewTier,
      fieldConfig: {
        columns: tableColumnsConfig,
        rowActions: {
          component: rowActionsComponent
        },
        invalidRows: rowValidationErrors,
        onChange,
        onBlur,
        rows: props.value,
        isDisabled: props.isDisabled,
        config: {
          currency: props.currency
        }
      },
      test: {
        onDelete,
        rowActionsAvailable
      }
    }
  }
