import { Flex } from '@chakra-ui/react'
import { Button, TextInput } from '@sequencehq/core-components'
import { FC, memo, useState } from 'react'
import { InvoicesMagicTable } from 'components/Invoices/InvoicesMagicTable/InvoicesMagicTable'
import Page from 'components/Page'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { InvoicesGrid } from 'components/Invoices/InvoicesGrid'
import { InvoicesExportToCsv } from 'components/Invoices/InvoicesExportToCsv'
import { addSavedView } from 'modules/SavedViews'
import type { ActiveFilter } from '@sequencehq/tables'
import { savedViewPath } from 'SavedViews/savedViews.router'
import { useFlags } from 'launchdarkly-react-client-sdk'

const Invoices: FC<{
  showSegmentedFilters?: boolean
  title?: string
  forcedActiveFilters?: ActiveFilter[]
}> = memo(
  ({
    showSegmentedFilters = true,
    title = 'Invoices',
    forcedActiveFilters = []
  }) => {
    const flags = useFlags()
    const [searchParams] = useSearchParams()
    const view = searchParams.get('view')
    const [savingFilters, setSavingFilters] = useState<false | ActiveFilter[]>(
      false
    )

    return (
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title={savingFilters ? undefined : title}
            richTitle={
              savingFilters && flags.useSavedViews ? (
                <SavedViewEditor activeFilters={savingFilters} />
              ) : undefined
            }
            addNewConfig={{
              buttonText: 'New invoice',
              overlayPayload: {
                content: 'createInvoiceModal',
                data: {
                  customer: undefined
                }
              }
            }}
            // TODO: update export to not use search params
            menuButtons={<InvoicesExportToCsv />}
            paddingBottom={0}
          >
            {match(view)
              .with('grid', () => <InvoicesGrid />)
              .otherwise(() => (
                <InvoicesMagicTable
                  onSaveFilters={
                    flags.useSavedViews ? setSavingFilters : undefined
                  }
                  showSegmentedFilters={showSegmentedFilters}
                  forcedActiveFilters={forcedActiveFilters}
                />
              ))}
          </Page>
        </Flex>

        <Outlet />
      </Flex>
    )
  }
)

const SavedViewEditor: FC<{ activeFilters: ActiveFilter[] }> = ({
  activeFilters
}) => {
  const navigate = useNavigate()
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    const name = data.get('name')?.toString()
    if (!name) return
    console.log('SAVING', activeFilters)
    const id = addSavedView(name, activeFilters)
    navigate(savedViewPath(id))
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex>
        <TextInput name="name" />
        <Button variant="secondary">Cancel</Button>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </Flex>
    </form>
  )
}

export default Invoices
