import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoiceStatusBadgeProps,
  toInvoicePaymentStatusBadgeProps,
  invoiceStatus as invoiceStatusType,
  invoicePaymentStatus as invoicePaymentStatusType
} from '@sequencehq/core-models'

import {
  formatISODate,
  futureRelativeToDate,
  pastRelativeToDate
} from '@sequencehq/formatters'
import { startOfDay } from '@sequencehq/utils/dates'

const invoiceStatusValues = Object.keys(
  invoiceStatusType.keys
) as InvoiceStatus[]

const invoicePaymentStatusValues = Object.keys(
  invoicePaymentStatusType.keys
) as InvoicePaymentStatus[]

export const statusFilterOptions = () => {
  return invoiceStatusValues.map(status => ({
    label: toInvoiceStatusBadgeProps({ status }).children,
    value: status
  }))
}

export const paymentStatusFilterOptions = () => {
  return invoicePaymentStatusValues.map(status => ({
    label: toInvoicePaymentStatusBadgeProps({ status }).children,
    value: status
  }))
}

export const sentFilterOptions = [
  {
    label: '1 week ago',
    value: pastRelativeToDate(new Date(), { weeks: 1 })
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

export const dueBeforeFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month from now',
    value: futureRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months from now',
    value: futureRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months from now',
    value: futureRelativeToDate(new Date(), { months: 6 })
  }
]

export const dueAfterFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

export const invoiceBeforeFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month from now',
    value: futureRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months from now',
    value: futureRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months from now',
    value: futureRelativeToDate(new Date(), { months: 6 })
  }
]

export const invoiceAfterFilterOptions = [
  {
    label: 'Today',
    value: formatISODate(startOfDay(new Date()))
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]
