import { ActiveFilter } from '@sequencehq/tables'
import { id } from 'fp-ts/lib/Refinement'
import { v4 as uuid } from 'uuid'

export type SavedView = {
  id: string
  name: string
  activeFilters: ActiveFilter[]
}

export const loadSavedViews = (): SavedView[] => {
  const savedViewsJSON = localStorage.getItem('savedViews')
  if (!savedViewsJSON) {
    return []
  }
  const savedViews = JSON.parse(savedViewsJSON)
  return savedViews.filter((view: SavedView) => {
    return view.id && view.name && view.activeFilters
  })
}

export const loadSavedView = (id: string): SavedView | undefined => {
  const savedViews = loadSavedViews()
  return savedViews.find(view => view.id === id)
}

const storeSavedViews = (savedViews: SavedView[]) => {
  localStorage.setItem('savedViews', JSON.stringify(savedViews))
}

export const addSavedView = (name: string, activeFilters: ActiveFilter[]) => {
  const id = uuid()

  const savedViews = loadSavedViews()
  const newSavedViews = [...savedViews, { id, name, activeFilters }]
  storeSavedViews(newSavedViews)

  return id
}
