import { countriesAlpha2 } from '@sequencehq/api/commonEnums'

export const taxStatusOptions = {
  TAXED: 'Taxed',
  TAX_EXEMPT: 'Tax exempt',
  REVERSE_CHARGED: 'Reverse charged'
}

export const countriesOptions = Object.entries(countriesAlpha2).map(
  ([value, label]) => ({
    value,
    label
  })
)
