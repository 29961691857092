import { Box, Flex, Icon } from '@chakra-ui/react'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import PlusIcon from '@heroicons/react/24/outline/PlusIcon'
import { Currency } from '@sequencehq/core-models'
import {
  GreyGrey50,
  GreyGrey70,
  Lato14Regular
} from '@sequencehq/design-tokens'
import {
  NumberInput,
  PriceInput,
  SimpleTableColumn,
  SimpleTableInputProps,
  SimpleTableInvalidRow,
  SimpleTableRowActionsProps
} from '@sequencehq/core-components'
import { FC, useCallback, useMemo } from 'react'
import { enforceMinimumPrecision, ValidationError } from '@sequencehq/utils'
import { PriceEditorTier } from 'common/drawers/PricingEditor/domain'
import { sanitizeTiers } from 'common/drawers/PricingEditor/utils/sanitizeTiers'

const tableInputStyle = {
  border: 'none',
  boxShadow: 'none',
  borderRadius: 'none'
}

const tableColumnsConfig: SimpleTableColumn<
  PriceEditorTier,
  keyof PriceEditorTier,
  {
    currency: Currency
  }
>[] = [
  {
    property: 'firstUnit',
    label: 'First Unit',
    bodyComponent: ({ value, rowIndex }) => (
      <Box
        paddingLeft="12px"
        color={GreyGrey50}
        {...Lato14Regular}
        data-testid={`priceEditor.tiers.${rowIndex}.firstunit`}
      >
        {value}
      </Box>
    )
  },
  {
    property: 'lastUnit',
    label: 'Last Unit',
    bodyComponent: ({ value, onChange, onBlur, rowIndex, rows, disabled }) => {
      return (
        <NumberInput
          isDisabled={disabled || rowIndex === rows.length - 1}
          precision={0}
          onChange={newValue => {
            if (rowIndex === rows.length - 1) {
              return
            }
            return onChange(newValue)
          }}
          value={rowIndex === rows.length - 1 ? '∞' : value ?? ''}
          {...tableInputStyle}
          paddingLeft="12px"
          onBlur={onBlur}
          placeholder="0"
          data-testid={`priceEditor.tiers.${rowIndex}.lastUnit`}
          onFocus={() => {
            if (value === '0') {
              onChange('')
            }
          }}
        />
      )
    }
  },
  {
    property: 'unitPrice',
    label: 'Unit Price',
    bodyComponent: ({
      value,
      onBlur,
      onChange,
      disabled,
      config,
      rowIndex
    }) => (
      <PriceInput
        isDisabled={disabled}
        currency={config.currency}
        onChange={onChange}
        onBlur={onBlur}
        value={value ?? ''}
        variant="table"
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        data-testid={`priceEditor.tiers.${rowIndex}.unitPrice`}
        {...tableInputStyle}
      />
    )
  },
  {
    property: 'flatFee',
    label: 'Flat Fee',
    bodyComponent: ({
      value,
      onBlur,
      onChange,
      disabled,
      config,
      rowIndex
    }) => (
      <PriceInput
        isDisabled={disabled}
        currency={config.currency}
        onChange={onChange}
        value={value ?? ''}
        onBlur={onBlur}
        variant="table"
        placeholder="0.00"
        onFocus={() => {
          if (value === '0.00') {
            onChange('')
          }
        }}
        data-testid={`priceEditor.tiers.${rowIndex}.flatFee`}
        {...tableInputStyle}
      />
    )
  }
]

type UseFixedTierTableInput = (props: {
  onChange: (value: PriceEditorTier[]) => void
  value: PriceEditorTier[]
  isDisabled: boolean
  currency: Currency
  validationErrors: ValidationError[]
}) => {
  fieldConfig: SimpleTableInputProps<
    PriceEditorTier,
    {
      currency: Currency
    }
  > & {
    invalidRows: SimpleTableInvalidRow<PriceEditorTier>[]
    onBlur: () => void
  }
  addNewTier: (rowIndex?: number) => void
  test: {
    onDelete: (id: PriceEditorTier['id']) => void
    rowActionsAvailable: {
      delete: boolean
      addNewTier: boolean
    }
  }
}

export const useFixedTierTableInput: UseFixedTierTableInput = props => {
  const rowActionsAvailable = useMemo(() => {
    return {
      delete: props.value && props.value.length > 1 && !props.isDisabled,
      addNewTier: !props.isDisabled
    }
  }, [props])

  const onDelete = useCallback(
    (id: PriceEditorTier['id']) => {
      const newTiers = props.value.filter(tier => tier.id !== id)

      props.onChange(sanitizeTiers(newTiers))
    },
    [props]
  )

  const addNewTier = useCallback(
    (rowIndex: number = props.value.length - 1) => {
      const previousTier = props.value[rowIndex]

      const newTiers = sanitizeTiers([
        ...props.value.slice(0, rowIndex + 1),
        {
          id: crypto.randomUUID(),
          /** These will be calculated and set by the prepare tiers functionality */
          firstUnit: '',
          lastUnit: '',
          unitPrice: enforceMinimumPrecision(2)(previousTier?.unitPrice),
          flatFee: enforceMinimumPrecision(2)(previousTier?.flatFee)
        },
        ...props.value.slice(rowIndex + 1)
      ])
      props.onChange(newTiers)
    },
    [props]
  )

  const rowActionsComponent: FC<SimpleTableRowActionsProps<PriceEditorTier>> =
    useMemo(() => {
      return ({ rowData, rowIndex }) => {
        return (
          <Flex alignItems="center" height="100%" padding="0 8px" gap="4px">
            <Icon
              data-test="fixed-delete-tier"
              as={TrashIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.delete
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => rowActionsAvailable.delete && onDelete(rowData.id)}
            />
            <Icon
              data-test="fixed-add-new-tier"
              as={PlusIcon}
              width="20px"
              height="20px"
              {...(rowActionsAvailable.addNewTier
                ? {
                    color: GreyGrey70,
                    cursor: 'pointer'
                  }
                : {
                    color: GreyGrey50
                  })}
              cursor="pointer"
              _hover={{
                color: GreyGrey50
              }}
              onClick={() => {
                rowActionsAvailable.addNewTier && addNewTier(rowIndex)
              }}
            />
          </Flex>
        )
      }
    }, [onDelete, addNewTier, rowActionsAvailable])

  const rowValidationErrors: SimpleTableInvalidRow<PriceEditorTier>[] =
    useMemo(() => {
      return Object.values(
        props.validationErrors.reduce(
          (acc, error) => {
            const tierId = error.metadata?.tierId as string
            if (!tierId) {
              return acc
            }

            if (!acc[tierId]) {
              return {
                ...acc,
                [tierId]: {
                  id: tierId,
                  invalidProperties: [
                    error.metadata?.property as keyof PriceEditorTier
                  ]
                }
              }
            }

            return {
              ...acc,
              [tierId]: {
                ...acc[tierId],
                invalidProperties: [
                  ...(acc[tierId]?.invalidProperties ?? []),
                  error.metadata?.property as keyof PriceEditorTier
                ]
              }
            }
          },
          {} as Record<string, SimpleTableInvalidRow<PriceEditorTier>>
        )
      )
    }, [props.validationErrors])

  const onChange = useCallback(
    (newValue: PriceEditorTier[]) => {
      props.onChange(newValue)
    },
    [props]
  )

  const onBlur = useCallback(() => {
    props.onChange(sanitizeTiers(props.value))
  }, [props])

  return {
    addNewTier,
    fieldConfig: {
      columns: tableColumnsConfig,
      rowActions: {
        component: rowActionsComponent
      },
      invalidRows: rowValidationErrors,
      onChange,
      onBlur,
      rows: props.value,
      isDisabled: props.isDisabled,
      config: {
        currency: props.currency
      }
    },
    test: {
      onDelete,
      rowActionsAvailable
    }
  }
}
