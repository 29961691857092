import { Flex, IconButton, Text } from '@chakra-ui/react'
import { useInvoiceEditorSummary } from './useInvoiceEditorSummary'
import {
  GreyGrey70,
  Lato14Regular,
  LineHeights20
} from '@sequencehq/design-tokens'
import { DateRangePicker } from '@sequencehq/core-components'
import { PencilSquareIcon } from '@heroicons/react/16/solid'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { format, formatDateRange } from '@sequencehq/utils/dates'

export const InvoiceEditorSummaryBillingPeriod = () => {
  const { data, editBillingPeriod } = useInvoiceEditorSummary()
  const { data: contextData, functions } = useInvoiceEditorContext()

  const invoice = contextData.invoice

  const defaultDates =
    invoice.billingPeriodStart && invoice.billingPeriodEndInclusive
      ? [
          new Date(invoice.billingPeriodStart),
          new Date(invoice.billingPeriodEndInclusive)
        ]
      : undefined

  return (
    <>
      <Text {...Lato14Regular} lineHeight={LineHeights20} color={GreyGrey70}>
        Billing period
      </Text>
      <Flex alignItems="center" gap={1} role="group">
        <Text
          {...Lato14Regular}
          lineHeight={LineHeights20}
          color={data.billingPeriod ? 'gray.90' : 'gray.60'}
          flexShrink={0}
        >
          {invoice.billingPeriod || '—'}
        </Text>

        {editBillingPeriod.available && (
          <DateRangePicker
            defaultDates={defaultDates}
            onSubmit={selectedDates => {
              const formattedDates =
                selectedDates.filter(Boolean).length === 2
                  ? selectedDates.map(date => format(date, 'yyyy-MM-dd'))
                  : undefined

              if (
                !formattedDates ||
                formattedDates?.filter(Boolean).length === 0
              ) {
                return
              }

              functions.updateData({
                ...contextData,
                invoice: {
                  ...contextData.invoice,
                  billingPeriod: formatDateRange({
                    from: selectedDates[0],
                    to: selectedDates[1]
                  }),
                  billingPeriodStart: formattedDates[0],
                  billingPeriodEndInclusive: formattedDates[1]
                }
              })

              editBillingPeriod.onChange({
                start: formattedDates[0],
                endInclusive: formattedDates[1]
              })
            }}
          >
            <IconButton
              aria-label="Edit billing period"
              minWidth="16px"
              height="16px"
              width="16px"
              icon={<PencilSquareIcon height="16px" width="16px" />}
              variant="ghost2"
              visibility="hidden"
              _groupHover={{
                visibility: 'visible'
              }}
            />
          </DateRangePicker>
        )}
      </Flex>
    </>
  )
}
