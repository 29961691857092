import { match } from 'ts-pattern'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import { GraduatedPrice, UsageCalculationFrequency } from '../domain'

type UsageCalculationMode =
  GraduatedPrice['modelSpecific']['usageCalculationMode']
type UsageCalculationPeriod =
  GraduatedPrice['modelSpecific']['usageCalculationPeriod']
type GraduatedPriceBillingFrequency =
  GraduatedPrice['modelSpecific']['billingFrequency']

const cumulativeUsageCalculationMode = (): {
  usageCalculationMode: UsageCalculationMode
  usageCalculationPeriod: undefined
} => ({
  usageCalculationMode: 'CUMULATIVE',
  usageCalculationPeriod: undefined
})

// Extracted because for now, all `usageCalculationPeriod.interval` is 1 by default
// and for this type of pricing, `usageCalculationMode` is `PERIODIC`.
const periodicCalculationMode =
  (usageCalculationFrequency: UsageCalculationFrequency) =>
  (): {
    usageCalculationMode: UsageCalculationMode
    usageCalculationPeriod: UsageCalculationPeriod
  } => {
    return {
      usageCalculationMode: 'PERIODIC',
      usageCalculationPeriod: {
        frequency: usageCalculationFrequency,
        interval: 1
      }
    }
  }

// Converts the combination of `Usage calculation period` dropdown value from the graduated price form
// and `Billing frequency` to the corresponding price API types
function fromUsageCalculationPeriodDropdownToApiPriceProperties(
  usageCalculationPeriod: UsageCalculationPeriodDropdownValue,
  billingFrequency: GraduatedPriceBillingFrequency
): {
  usageCalculationMode: UsageCalculationMode
  usageCalculationPeriod: UsageCalculationPeriod
} {
  return (
    match({ usageCalculationPeriod, billingFrequency })
      .with(
        {
          billingFrequency: 'MONTHLY',
          usageCalculationPeriod: 'CUMULATIVE_USAGE'
        },
        {
          billingFrequency: 'QUARTERLY',
          usageCalculationPeriod: 'CUMULATIVE_USAGE'
        },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'CUMULATIVE_USAGE'
        },
        {
          billingFrequency: 'YEARLY',
          usageCalculationPeriod: 'CUMULATIVE_USAGE'
        },
        cumulativeUsageCalculationMode
      )
      .with(
        {
          billingFrequency: 'MONTHLY',
          usageCalculationPeriod: 'BILLING_PERIOD'
        },
        {
          usageCalculationPeriod: 'MONTHLY'
        },
        periodicCalculationMode('MONTHLY')
      )
      .with(
        { billingFrequency: 'MONTHLY', usageCalculationPeriod: 'QUARTERLY' },
        {
          billingFrequency: 'QUARTERLY',
          usageCalculationPeriod: 'BILLING_PERIOD'
        },
        { billingFrequency: 'QUARTERLY', usageCalculationPeriod: 'QUARTERLY' },
        {
          billingFrequency: 'ONE_TIME'
        },
        periodicCalculationMode('QUARTERLY')
      )
      .with(
        { billingFrequency: 'MONTHLY', usageCalculationPeriod: 'HALF_YEARLY' },
        {
          billingFrequency: 'QUARTERLY',
          usageCalculationPeriod: 'HALF_YEARLY'
        },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'HALF_YEARLY'
        },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'BILLING_PERIOD'
        },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'QUARTERLY'
        },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'MONTHLY'
        },
        periodicCalculationMode('HALF_YEARLY')
      )
      .with(
        { billingFrequency: 'MONTHLY', usageCalculationPeriod: 'ANNUAL' },
        { billingFrequency: 'QUARTERLY', usageCalculationPeriod: 'ANNUAL' },
        {
          billingFrequency: 'HALF_YEARLY',
          usageCalculationPeriod: 'ANNUAL'
        },
        {
          billingFrequency: 'YEARLY',
          usageCalculationPeriod: 'ANNUAL'
        },
        {
          billingFrequency: 'YEARLY',
          usageCalculationPeriod: 'HALF_YEARLY'
        },
        {
          billingFrequency: 'YEARLY',
          usageCalculationPeriod: 'BILLING_PERIOD'
        },
        { billingFrequency: 'YEARLY', usageCalculationPeriod: 'QUARTERLY' },
        periodicCalculationMode('YEARLY')
      )
      /**
       * When we transition to the ON_DEMAND billing frequency, we drop the 'billing period' option,
       * and default to the CUMULATIVE_USAGE mode. If a specific period has already been selected,
       * then we will retain that.
       */
      .with(
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'CUMULATIVE_USAGE'
        },

        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'BILLING_PERIOD'
        },
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: undefined
        },
        cumulativeUsageCalculationMode
      )
      .with(
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'ANNUAL'
        },
        periodicCalculationMode('YEARLY')
      )
      .with(
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'QUARTERLY'
        },
        periodicCalculationMode('QUARTERLY')
      )
      .with(
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'HALF_YEARLY'
        },
        periodicCalculationMode('HALF_YEARLY')
      )
      .with(
        {
          billingFrequency: 'ON_DEMAND',
          usageCalculationPeriod: 'MONTHLY'
        },
        periodicCalculationMode('MONTHLY')
      )
      .exhaustive()
  )
}

// Converts the price `usageCalculationMode`, `usageCalculationPeriod`, and `billingFrequency`
// into value suitable for the `Usage calculation period` dropdown in the graduated price form
function fromApiPricePropertiesToUsageCalculationPeriodDropdown(
  usageCalculationStructure: {
    usageCalculationMode: UsageCalculationMode
    usageCalculationPeriod: UsageCalculationPeriod
  },
  billingFrequency: GraduatedPriceBillingFrequency
): UsageCalculationPeriodDropdownValue {
  const { usageCalculationMode, usageCalculationPeriod } =
    usageCalculationStructure

  return match({
    usageCalculationMode,
    usageCalculationPeriod,
    billingFrequency
  })
    .with(
      {
        usageCalculationMode: 'BILLING_PERIOD'
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'MONTHLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: { frequency: 'QUARTERLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: { frequency: 'MONTHLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: { frequency: 'MONTHLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'YEARLY',
        usageCalculationPeriod: { frequency: 'QUARTERLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'HALF_YEARLY',
        usageCalculationPeriod: { frequency: 'HALF_YEARLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'BILLING_PERIOD'
    )
    .with(
      {
        usageCalculationMode: 'CUMULATIVE'
      },
      (): UsageCalculationPeriodDropdownValue => 'CUMULATIVE_USAGE'
    )
    .with(
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'QUARTERLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'QUARTERLY'
    )
    .with(
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'ON_DEMAND',
        usageCalculationPeriod: { frequency: 'MONTHLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'MONTHLY'
    )
    .with(
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'MONTHLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      {
        usageCalculationMode: 'PERIODIC',
        billingFrequency: 'QUARTERLY',
        usageCalculationPeriod: { frequency: 'YEARLY' }
      },
      (): UsageCalculationPeriodDropdownValue => 'ANNUAL'
    )
    .otherwise((match): UsageCalculationPeriodDropdownValue => {
      if (match.usageCalculationPeriod?.frequency === 'YEARLY') {
        return 'ANNUAL'
      }

      /**
       * Cumulative usage is the only value that is always present in all circumstances,
       * so makes for the best 'default of last resort'.
       */
      return match.usageCalculationPeriod?.frequency ?? 'CUMULATIVE_USAGE'
    })
}

export default {
  fromUsageCalculationPeriodDropdownToApiPriceProperties,
  fromApiPricePropertiesToUsageCalculationPeriodDropdown
}
