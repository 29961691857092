import { Button } from '@sequencehq/core-components'
import { Link } from '@chakra-ui/react'
import { ArrowUpRightIcon } from '@heroicons/react/16/solid'
import { getPortalDomain } from '@sequencehq/utils'
import { getEnvironment } from 'lib/environment/environment.ts'

type Props = {
  path: string
  isDisabled?: boolean
}

export const ViewCustomerPortalButton = ({
  path,
  isDisabled = false
}: Props) => {
  const portalDomain = getPortalDomain(getEnvironment())
  const customerPortalHref = !isDisabled ? `${portalDomain}${path}` : '#'

  return (
    <Link
      href={customerPortalHref}
      target="_blank"
      aria-label="View customer portal"
    >
      <Button
        variant="secondary"
        leadingIcon={<ArrowUpRightIcon width="16px" height="16px" />}
        disabled={isDisabled}
      >
        View customer portal
      </Button>
    </Link>
  )
}
