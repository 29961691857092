import { FC } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'

type ConfirmMergeInvoicesModalProps = {
  count: number
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmMergeInvoicesModal: FC<ConfirmMergeInvoicesModalProps> = ({
  count,
  onConfirm,
  onClose
}) => {
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Merge {count} invoices
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            You're about to merge {count} invoices. This action can't be undone. The original invoices will be voided.
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={onConfirm}
              height="32px"
              width="100%"
              data-testid="merge-invoices.confirm"
            >
              Merge
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
