import Invoices from 'components/Invoices'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { loadSavedView } from 'SavedViews/localStorage'

export const SavedViewPage: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const flags = useFlags()

  const savedView = !!id && loadSavedView(id)

  const shouldRedirect = !savedView || !flags.useSavedViews

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/')
    }
  }, [shouldRedirect, navigate])

  if (shouldRedirect) {
    return null
  }

  return (
    <Invoices
      key={id}
      showSegmentedFilters={false}
      title={savedView.name}
      forcedActiveFilters={savedView.activeFilters}
    />
  )
}
