import { InputSelectControlLegacyStyleBridge } from '@sequencehq/core-components'
import { InfoPopoverProps } from '@sequencehq/forms'
import { useSeatType } from 'modules/Seats/SeatMetric'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSeatMetrics } from 'Seats/SeatMetric/useSeatMetrics'

type SeatMetricInputProps = {
  fieldName: string
  infoPopover?: InfoPopoverProps
  disabled?: boolean
  height?: string
}

export const SeatMetricInput: FC<SeatMetricInputProps> = ({ disabled }) => {
  const navigate = useNavigate()
  const { dispatch } = useSeatType()

  const seatMetricsResult = useSeatMetrics()

  const seatMetrics = seatMetricsResult.metrics ?? []

  return (
    <InputSelectControlLegacyStyleBridge
      triggerStyle={{ paddingLeft: 16, userSelect: 'none' }}
      onChange={value => {
        dispatch({ type: 'setSeatTypeId', seatTypeId: value })
      }}
      options={seatMetrics.map(item => ({
        value: item.id,
        label: item.label
      }))}
      disabled={
        disabled || !!seatMetricsResult.error || seatMetricsResult.loading
      }
      onCreate={() => navigate('seats/new')}
      placeholder="Select seat type"
      data-testid="seatMetricInput"
      createLabel="Create new"
    />
  )
}
