import { INITIAL_PRICING_EDITOR_STATE } from '../pricingEditor.constants'
import {
  ActionHandler,
  UpdateListPriceAction
} from '../pricingEditor.domain.types'
import deepmerge from 'deepmerge'

export const updateListPrice: ActionHandler<UpdateListPriceAction> =
  prevState => action => {
    const newPricingEditorData = prevState.data.listPrices[action.payload]

    if (!newPricingEditorData) {
      return prevState
    }

    return deepmerge(
      prevState,
      {
        data: {
          pricingEditorData: {
            ...INITIAL_PRICING_EDITOR_STATE.data.pricingEditorData,
            common: {
              ...newPricingEditorData.common,
              listPriceId: action.payload
            },
            [newPricingEditorData.common.pricingModel]:
              newPricingEditorData.modelSpecific
          }
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }
