import { Cog6ToothIcon } from '@heroicons/react/16/solid'
import { Inspector, SwitchLineField } from '@sequencehq/core-components'
import { GreyGrey70, GreyGrey90, Lato13Bold } from '@sequencehq/design-tokens'
import { SidebarSection } from '../../QuoteEditorSidebar/widgets/SidebarSection'
import { useMemo } from 'react'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { Text } from '@chakra-ui/react'

const useQuoteSettings = () => {
  const cubeContext = useCubeContext()

  const groupPricesByPhase = useMemo(
    () =>
      cubeContext.queries.rawData.data.presentation.settings.groupPricesByPhase,
    [cubeContext.queries.rawData.data.presentation.settings.groupPricesByPhase]
  )

  return {
    groupPricesByPhase: {
      value: groupPricesByPhase,
      onChange: (value: boolean) => {
        cubeContext.mutators.updateData({
          presentation: {
            settings: {
              groupPricesByPhase: value
            }
          }
        })
      }
    }
  }
}

const QuoteSettings = () => {
  const quoteSettings = useQuoteSettings()

  return (
    <Inspector.Content>
      <SidebarSection title="Settings" showBorder>
        <SwitchLineField
          data-testid="quote-inspector-settings-group-prices-by-phase"
          value={quoteSettings.groupPricesByPhase.value}
          onChange={quoteSettings.groupPricesByPhase.onChange}
          text={
            <Text {...Lato13Bold} color={GreyGrey90}>
              Group prices by phase (published quote)
            </Text>
          }
        />
      </SidebarSection>
    </Inspector.Content>
  )
}

export const QuoteSettingsInspectorItem = () => {
  return (
    <Inspector.IconItem
      data-testid="quote-inspector-settings"
      tooltip="Settings"
      content={<QuoteSettings />}
      icon={<Cog6ToothIcon height="16px" width="16px" color={GreyGrey70} />}
    />
  )
}
