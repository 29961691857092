import { Badge } from '@sequencehq/core-components'
import { CREDIT_NOTE_STATUS_LABELS } from 'CreditNotes/constants'
import { CreditNoteStatus } from 'CreditNotes/types'
import { BadgeSentiment } from '@sequencehq/core-theme'

const CREDIT_NOTE_STATUS_SENTIMENTS: Record<CreditNoteStatus, BadgeSentiment> =
  {
    IN_PROGRESS: 'info',
    DRAFT: 'neutral',
    FINAL: 'success',
    SENT: 'success',
    VOIDED: 'neutral'
  }

interface Props {
  status: CreditNoteStatus
}

export const CreditNoteStatusBadge = ({ status }: Props) => {
  return (
    <Badge sentiment={CREDIT_NOTE_STATUS_SENTIMENTS[status]}>
      {CREDIT_NOTE_STATUS_LABELS[status]}
    </Badge>
  )
}
