import { getMonth, isAfter } from '@sequencehq/utils/dates'
import { BillingFrequency } from 'Cube/domain/cube.domain.types'

type PartialResolvedPhase = {
  id: string
  prices: Array<{
    productId: string
    billingFrequency: BillingFrequency
    structure: {
      pricingType: string
    }
  }>
  dates: {
    absolute: {
      start?: Date
    }
  }
  recurrencePreference: 'CONTINUE_FROM_PREVIOUS_PHASE' | 'RESET'
}

/**
 * Sort an array of the resolvedPhaseSubset by the start date of the phase
 * @param a
 * @param b
 * @returns
 */
const sortByLatestFirst = (
  a: PartialResolvedPhase,
  b: PartialResolvedPhase
) => {
  if (!a.dates.absolute.start || !b.dates.absolute.start) {
    return 0
  }
  return isAfter(a.dates.absolute.start, b.dates.absolute.start) ? -1 : 1
}

/**
 * Finds the billing month in which annual billing frequencies will recur
 * for the provided phase
 * @param args
 * @returns
 */
export const getMonthBillingStartedForAnnualFreq = (args: {
  phaseId: string
  phases: Array<PartialResolvedPhase>
}): number | undefined => {
  const phasesOrderedByLatestFirst = [...args.phases].sort(sortByLatestFirst)
  const phaseIndex = phasesOrderedByLatestFirst.findIndex(
    p => p.id === args.phaseId
  )
  const phase = phasesOrderedByLatestFirst[phaseIndex]
  if (!phase) {
    return
  }

  /**
   * Iterate through the previous phases, and find the most recent phase which
   * has the recurrence preference set to RESET.
   */
  const previousPhases = phasesOrderedByLatestFirst.slice(phaseIndex)
  const phaseWithOriginOfBillingPeriods = previousPhases.find(
    p => p.recurrencePreference === 'RESET'
  )

  if (phaseWithOriginOfBillingPeriods) {
    return (
      phaseWithOriginOfBillingPeriods.dates.absolute.start &&
      getMonth(phaseWithOriginOfBillingPeriods.dates.absolute.start)
    )
  }

  /**
   * If we didn't find any phase with the recurrence preference set to RESET,
   * return the month of the start of the first phase.
   */
  const startDateOfFirstPhase =
    phasesOrderedByLatestFirst[phasesOrderedByLatestFirst.length - 1].dates
      .absolute.start
  return startDateOfFirstPhase && getMonth(startDateOfFirstPhase)
}
