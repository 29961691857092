import { FC, ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import {
  Button,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'

export const LinkEntity: FC<{
  serviceName: string
  externalEntityLabel: string
  sequenceEntityLabel: string
  linkModal: ReactNode
  canLink?: boolean
  text?: string
  showLinkButton?: boolean
}> = ({ showLinkButton = true, ...props }) => {
  return (
    <Flex
      padding="12px"
      flexDirection="column"
      gap="8px"
      backgroundColor={GreyGrey10}
      borderRadius={BorderRadius6}
      alignItems="center"
    >
      <Box {...Lato13Bold} color={GreyGrey80}>
        No {props.externalEntityLabel} to show
      </Box>
      <Box {...Lato13Regular} color={GreyGrey70} textAlign="center">
        {props.text ??
          `This ${props.sequenceEntityLabel} is not linked to a ${props.serviceName} ${props.externalEntityLabel} yet.`}
      </Box>
      {showLinkButton && (
        <Box width="100%">
          <ModalContextProvider>
            <Modal.Trigger>
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                disabled={props.canLink === false}
              >
                Link {props.externalEntityLabel}
              </Button>
              {props.linkModal}
            </Modal.Trigger>
          </ModalContextProvider>
        </Box>
      )}
    </Flex>
  )
}
