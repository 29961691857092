import { LinkedBillingScheduleWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkedBillingScheduleWidget/LinkedBillingScheduleWidget'
import { FC } from 'react'

export const LinkSalesforceBillingScheduleWidget: FC<{
  billingScheduleId: string
}> = props => (
  <LinkedBillingScheduleWidget
    billingScheduleId={props.billingScheduleId}
    service="Salesforce"
  />
)
