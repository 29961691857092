import { pick } from 'lodash/fp'
import { v1ApiNewPrice } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entitySaving/useSavePrices'
import { v1ApiPrice } from 'Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadPrices'
import { objectsAreEqualIgnoreUndefined } from 'Cube/utils/objectsAreEqualIgnoreUndefined'

export type DedupedPrice = {
  price: v1ApiNewPrice
  matchingNewPrices: v1ApiNewPrice[]
}

export const dedupeSchedulePrices = (
  newPrices: (v1ApiPrice | v1ApiNewPrice)[]
): DedupedPrice[] => {
  return newPrices.reduce((acc: DedupedPrice[], currentPrice) => {
    const pickCheckableProperties = pick([
      'listPriceId',
      'structure',
      'currency',
      'productId',
      'name',
      'integrationIds',
      'billingFrequency',
      'billingType'
    ])

    const match = acc.find(dedupedPrice =>
      objectsAreEqualIgnoreUndefined(
        pickCheckableProperties(dedupedPrice.price),
        pickCheckableProperties(currentPrice)
      )
    )

    if (!match) {
      return [
        ...acc,
        {
          price: currentPrice,
          matchingNewPrices: [currentPrice]
        }
      ]
    }

    return acc.map(dedupedConfig => {
      if (dedupedConfig.price.id === match.price.id) {
        return {
          ...dedupedConfig,
          matchingNewPrices: [...dedupedConfig.matchingNewPrices, currentPrice]
        }
      }

      return dedupedConfig
    })
  }, [])
}
