import { FC, useMemo } from 'react'
import { Box, Flex, Text, Link, useToast } from '@chakra-ui/react'
import { Field, Form } from 'react-final-form'
import { SelectField, TextareaField } from 'components/FormFields'
import { TextInputField, Label } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { handleFormResponse } from 'lib/formValidation'
import { AggregationType, UsageMetricModel } from '@sequencehq/core-models'
import arrayMutators from 'final-form-arrays'
import { DrawerForm } from 'components/Modal'
import createDecorator from 'final-form-calculate'
import { Decorator } from 'final-form'
import { Blocker } from 'components/Form'
import { IndigoIndigo50, Lato16Bold } from '@sequencehq/design-tokens'
import { match } from 'ts-pattern'
import { UsageEventTypeInput } from 'components/CreateUsageMetric/UsageEventTypeInput'
import { UsageEventPropertyInput } from 'components/CreateUsageMetric/UsageEventPropertyInput'
import { EventPropertyList } from 'components/FormComponents'
import { useUpdateUsageMetric } from 'components/CreateUsageMetric/hooks/useUpdateUsageMetric.tsx'
import { Toast } from '@sequencehq/core-components'
import { useCreateUsageMetrics } from 'components/CreateUsageMetric/hooks/useCreateUsageMetric.tsx'

type NameValue = {
  name: string
  value: string
  comparisonType?: 'is' | 'isNot'
}

type FormValues = Omit<UsageMetricModel, 'propertyFilters'> & {
  propertyFilters: NameValue[]
}

type CreateOrEditUsageMetricFormProps = {
  formContainer: typeof DrawerForm
  onSuccess: (usageMetric: UsageMetricModel) => void
  handleCancel: () => void
  existingMetric?: UsageMetricModel
}

const CreateOrEditUsageMetricForm: FC<CreateOrEditUsageMetricFormProps> = ({
  formContainer: FormContainer,
  onSuccess,
  handleCancel,
  existingMetric
}) => {
  const _toast = useToast()

  const decorator = useMemo(
    () =>
      createDecorator({
        field: 'groupingProperty',
        updates: value => ({ metricType: value ? 'GROUPED' : 'SIMPLE' })
      }) as unknown as Decorator<FormValues>,
    []
  )

  const updateUsageMetric = useUpdateUsageMetric()
  const createUsageMetric = useCreateUsageMetrics()

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      destroyOnUnregister
      initialValues={{
        ...existingMetric,
        propertyFilters: existingMetric
          ? Object.entries(existingMetric.propertyFilters).map(
              ([name, value]) => ({
                name,
                value: value.join(','),
                comparisonType: (
                  existingMetric.propertiesToNegate || []
                ).includes(name)
                  ? 'isNot'
                  : 'is'
              })
            )
          : [{ name: '', value: '', comparisonType: 'is' }],
        metricType: existingMetric?.metricType || 'SIMPLE'
      }}
      decorators={[decorator]}
      mutators={{
        ...arrayMutators
      }}
      onSubmit={async (values, form) => {
        const propertyFilters = values.propertyFilters.reduce(
          (acc, { name, value }) => {
            if (!name && !value) {
              return acc
            }

            return { ...acc, [name]: value.split(',').map(v => v.trim()) }
          },
          {} as Record<string, unknown>
        )

        const propertiesToNegate = values.propertyFilters
          .filter(({ comparisonType }) => comparisonType === 'isNot')
          .map(({ name }) => name)

        const usageMetric = existingMetric
          ? await updateUsageMetric(existingMetric.id, {
              ...values,
              propertyFilters,
              propertiesToNegate
            })
          : await createUsageMetric({
              ...values,
              propertyFilters,
              propertiesToNegate
            })

        if (usageMetric) {
          onSuccess(usageMetric)
        }

        return handleFormResponse(usageMetric, form.getRegisteredFields())
      }}
      render={formProps => (
        <>
          <Blocker
            name="usageMetric"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel usage metric editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
          />
          <FormContainer
            {...formProps}
            title={existingMetric ? 'Edit usage metric' : 'Add usage metric'}
            submitLabel={
              existingMetric ? 'Edit usage metric' : 'Add usage metric'
            }
            handleCancel={handleCancel}
          >
            <Text textStyle="formHelperText">
              {existingMetric ? 'Use' : 'Create'} a metric to aggregate usage
              events and automate usage-based billing. Read more in the guide{' '}
              <Link
                color={IndigoIndigo50}
                href="https://docs.sequencehq.com/metrics-events/usage-metrics"
              >
                here
              </Link>
              .
            </Text>
            <Box height={4} />

            <Text {...Lato16Bold}>Basic information</Text>

            <Box height={4} />

            <TextInputField
              data-testid="usageMetric.name"
              fieldName="name"
              fieldLabel="Name"
              placeholder="API Calls"
              validate={required}
              infoPopover={{
                title: 'Metric name',
                body: 'Give your metric a name that makes it easy to identify.'
              }}
            />
            <Box height={4} />

            <TextareaField
              data-testid="usageMetric.description"
              fieldName="description"
              fieldLabel="Description"
              placeholder="Metric used to calculate API Calls event"
            />
            <Box height={4} />

            <Text {...Lato16Bold}>Usage</Text>

            <Box height={4} />

            <UsageEventTypeInput fieldName="eventType" />

            <Box height={4} />

            <SelectField
              fieldName="aggregationType"
              fieldLabel="Aggregation type"
              validate={required}
              data-testid="usageMetric.aggregationType"
              placeholder="Select how to combine events"
              options={[
                { label: `Count of usage values`, value: 'COUNT' },
                { label: 'Sum of usage values ', value: 'SUM' },
                { label: 'Unique usage values', value: 'UNIQUE' }
              ]}
              infoPopover={{
                title: 'Aggregation type',
                body: 'Select how you want to aggregate events. Sequence supports a simple count of events (e.g. number of API calls), a sum of an event property (e.g. transaction volume) or a count of unique events (e.g. unique user ids).'
              }}
            />

            <Box height={4} />

            <Field<AggregationType>
              name="aggregationType"
              subscription={{ value: true }}
            >
              {({ input: { value: aggregationType } }) => {
                return ['SUM', 'UNIQUE'].includes(aggregationType) ? (
                  <>
                    <Box height={4} />
                    <Label
                      infoPopover={{
                        title: aggregationType,
                        body: match(aggregationType)
                          .with(
                            'SUM',
                            () => 'Enter the event property to sum up.'
                          )
                          .with(
                            'UNIQUE',
                            () =>
                              'Enter the event property to count unique events by.'
                          )
                          .otherwise(() => '')
                      }}
                    >
                      Over event property
                    </Label>
                    <Box height={2} />
                    <UsageEventPropertyInput
                      fieldName="aggregationProperty"
                      validate={required}
                    />
                  </>
                ) : null
              }}
            </Field>
            <Box height={4} />
            <Text {...Lato16Bold}>Filter</Text>
            <Box height={4} />
            <EventPropertyList
              fieldName="propertyFilters"
              addPropertyLabel="Add filter"
              useMatcher={true}
              infoPopover={{
                title: 'Filter',
                body: (
                  <Flex direction="column">
                    <Flex>Use filters to narrow the scope of events.</Flex>
                    <Box height={2} />
                    <Flex>
                      To filter for multiple values, enter comma-separated
                      items.
                    </Flex>
                    <Box height={2} />
                    <Flex>
                      Only events matching all filters will be included in the
                      metric calculation. Enter comma-separated values to filter
                      for multiple values in a single filter. Each value is
                      treated as an 'OR' condition. Each additional filter acts
                      as an 'AND'.
                    </Flex>
                  </Flex>
                )
              }}
            />
          </FormContainer>
        </>
      )}
    />
  )
}

export default CreateOrEditUsageMetricForm
