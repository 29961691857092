import React, { FC, useState } from 'react'
import {
  InfiniteScroll,
  BulkActionsPositioner,
  BulkActionsCompleteAction,
  BulkActions,
  ScrollFade
} from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { BulkAction, InvoiceLite } from './types'
import { useEnrichedBulkActions } from './hooks/useEnrichedBulkActions'
import { allIds } from './utils'
import { isEmpty } from 'lodash'

type Props = {
  fallback: React.ReactNode
}

export const BulkActionsInFlight: FC<Props> = ({ fallback }) => {
  // TODO: maintain this through mounts — server dismiss ideally
  const [dismissedIds, setDismissedIds] = useState<string[]>([])
  const [reviewingId, setReviewingId] = useState<string | null>(null)

  const dismiss = (id: string) => {
    setDismissedIds([...dismissedIds, id])
    if (reviewingId === id) setReviewingId(null)
  }

  const { bulkActions, invoices, hasNextPage, isLoading, fetchNextPage } =
    useEnrichedBulkActions()

  const bulkActionsToRender = bulkActions.filter(
    bulkAction => !dismissedIds.includes(bulkAction.id)
  )

  if (!bulkActionsToRender.length || isEmpty(invoices)) return fallback

  return (
    <BulkActionsPositioner>
      <InfiniteScroll
        hasMore={hasNextPage}
        isLoading={isLoading}
        next={fetchNextPage}
      >
        <ScrollFade maxHeight={400}>
          {bulkActionsToRender.map(bulkAction => (
            <BulkActionInFlight
              key={bulkAction.id}
              bulkAction={bulkAction}
              onDismiss={() => dismiss(bulkAction.id)}
              invoices={invoices}
              isReviewing={reviewingId === bulkAction.id}
              onChangeReviewing={isReviewing =>
                setReviewingId(isReviewing ? bulkAction.id : null)
              }
            />
          ))}
        </ScrollFade>
      </InfiniteScroll>
    </BulkActionsPositioner>
  )
}

const BulkActionInFlight: FC<{
  bulkAction: BulkAction
  onDismiss: () => void
  invoices: Record<string, InvoiceLite>
  isReviewing: boolean
  onChangeReviewing: (isReviewing: boolean) => void
}> = ({ bulkAction, onDismiss, invoices, isReviewing, onChangeReviewing }) => {
  const actions = actionList(bulkAction, invoices)

  return (
    <BulkActions
      title={titleFor(bulkAction)}
      actions={actions}
      listTitle="Invoices"
      onDismiss={onDismiss}
      isReviewing={isReviewing}
      onChangeReviewing={onChangeReviewing}
    />
  )
}

function actionList(
  bulkAction: BulkAction,
  invoices: Record<string, InvoiceLite>
) {
  const ids = allIds(bulkAction)

  return ids.map(id => {
    const invoice = invoices[id]
    const isComplete = bulkAction.completedIds.includes(id)
    return toAction(invoice, isComplete)
  })
}

function toAction(
  invoice: InvoiceLite,
  isComplete: boolean
): BulkActionsCompleteAction {
  return {
    title: invoice.data.invoiceNumber,
    status: isComplete ? 'success' : 'failure'
  }
}

function titleFor(bulkAction: Pick<BulkAction, 'type' | 'status'>) {
  return match(bulkAction)
    .with({ type: 'UPDATE_INVOICES_STATUS' }, () => 'Update invoices status')
    .with(
      { type: 'VOID_INVOICES', status: 'PROCESSING' },
      () => 'Voiding invoices'
    )
    .with({ type: 'VOID_INVOICES' }, () => 'Voiding invoices complete')
    .with({ type: 'RECALCULATE_INVOICES' }, () => 'Recalculate invoices')
    .with({ type: 'DOWNLOAD_INVOICES' }, () => 'Download invoices')
    .with({ type: 'FINALISE_INVOICES' }, () => 'Finalise invoices')
    .with({ type: 'SEND_INVOICES' }, () => 'Send invoices')
    .exhaustive()
}
