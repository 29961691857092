import { Flex } from '@chakra-ui/react'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { useMagicTableToolbarContext } from '@sequencehq/tables'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'

type ActiveCreditNoteFilter = 'ALL' | 'DRAFT' | 'ISSUED'

export const CreditNotesSegmentedFilters = () => {
  const [activeFilter, setActiveFilter] =
    useState<ActiveCreditNoteFilter>('ALL')

  const [searchParams] = useSearchParams()

  const ctx = useMagicTableToolbarContext()

  useEffect(() => {
    const billingScheduleStatus = searchParams.get('creditNoteStatus')

    if (billingScheduleStatus === 'SENT') {
      setActiveFilter('ISSUED')
    } else if (billingScheduleStatus === 'DRAFT') {
      setActiveFilter('DRAFT')
    } else {
      setActiveFilter('ALL')
    }
  }, [searchParams])

  const disabled = useMemo(
    () => !ctx.queries.canChangeFilters(),
    [ctx.queries.canChangeFilters]
  )

  const handleFilterChange = useCallback(
    (value: string) => {
      match(value as ActiveCreditNoteFilter)
        .with('ALL', () => {
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'creditNoteStatus',
            values: []
          })
        })
        .with('DRAFT', () => {
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'creditNoteStatus',
            values: ['DRAFT']
          })
        })
        .with('ISSUED', () => {
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'creditNoteStatus',
            values: ['SENT']
          })
        })
        .exhaustive()
    },
    [ctx.functions.bulkSetMultiSelectOptions]
  )

  return (
    <Flex alignItems="start">
      <RadioButtonGroup
        onChange={handleFilterChange}
        initialValue={activeFilter}
        value={activeFilter}
        options={[
          {
            value: 'ALL',
            label: 'All',
            disabled
          },
          {
            value: 'DRAFT',
            label: 'Draft',
            disabled
          },
          {
            value: 'ISSUED',
            label: 'Issued',
            disabled
          }
        ]}
      />
    </Flex>
  )
}
