import { Center, Flex, Spinner, Text } from '@chakra-ui/react'
import {
  CalendarIcon,
  CodeBracketSquareIcon,
  CommandLineIcon,
  HashtagIcon,
  IdentificationIcon
} from '@heroicons/react/16/solid'
import { Badge, KebabMenu, MenuItemBuilder } from '@sequencehq/core-components'
import {
  customerAliasOptions,
  Option,
  UsageEventModel
} from '@sequencehq/core-models'
import { RedRed20, RedRed50 } from '@sequencehq/design-tokens'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableFilterConfig,
  useLinkMagicTableWithSearchParams,
  useMagicTableInfiniteQuery,
  usePersistMagicTableQueryParams
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { timestampFilterOptions } from 'components/UsageEvents/UsageEvents'
import {
  UsageEventFilters,
  UsageEventsView
} from 'components/UsageEvents/types'
import { useCustomerNamesByAlias } from 'components/UsageEvents/useCustomerNamesByAlias'
import { useLoadDistinctCustomerAliasCount } from 'components/UsageEvents/useLoadDistinctCustomerAliasCount'
import {
  useGetCustomersByAliasByAliasQuery,
  useGetUniqueUsageEventPropertiesQuery,
  useGetUniqueUsageEventTypesQuery
} from 'features/api'
import { openOverlay } from 'features/overlay'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ExtractQueryParams } from 'lib/types'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UsageEventsSegmentedFilters } from 'components/UsageEvents/UsageEventsSegmentedFilters'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { isoTimestampIgnoringTime } from '@sequencehq/utils/src/dates'
import { CommandLineIcon as CommandLineIcon24 } from '@heroicons/react/24/solid'

const customerAliasBadge = (value: string) => <Badge>{value}</Badge>

const linkToCustomerBuilder: MenuItemBuilder<UsageEventModel> = (
  event: UsageEventModel
) => {
  return {
    status: 'LIVE',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'linkAliasToCustomerModal',
          data: {
            alias: event.customerAlias
          }
        })
      ),
    label: 'Link to an existing customer'
  }
}

const createCustomerBuilder: MenuItemBuilder<UsageEventModel> = (
  event: UsageEventModel
) => {
  return {
    status: 'LIVE',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'createCustomerModal',
          data: {
            alias: event.customerAlias
          }
        })
      ),
    label: 'Create new customer'
  }
}

const menuItemBuilders = (isLinked: boolean) => {
  if (isLinked) {
    return []
  } else {
    const builders = [linkToCustomerBuilder, createCustomerBuilder]
    return builders
  }
}

export const UNMAPPED_ALIAS_OPTION_VALUE: string = '__SEQUENCE_UNMAPPED_ALIAS__'

export const UsageEventsMagicTable = () => {
  const { persistMagicTableQueryParams } = usePersistMagicTableQueryParams()

  const flags = useFlags()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [eventPropertiesQuery, setEventPropertiesQuery] = useState<string>('')
  const { data: eventPropertiesQueryData } =
    useGetUniqueUsageEventPropertiesQuery({
      search: eventPropertiesQuery
    })
  const eventProperties =
    eventPropertiesQueryData?.value()?.items.map(v => ({ id: v, value: v })) ??
    []

  const { data: allEventPropertiesQueryData } =
    useGetUniqueUsageEventPropertiesQuery({})
  const allEventProperties = allEventPropertiesQueryData?.value()?.items

  const { data: allEventTypesData } = useGetUniqueUsageEventTypesQuery({
    search: ''
  })
  const allEventTypes = allEventTypesData?.value()?.items

  const distinctAliasCount = useLoadDistinctCustomerAliasCount({
    customerAlias: searchParams.get('customerAlias'),
    unmappedAliasesOnly: searchParams.get('unmappedAliasesOnly'),
    eventType: searchParams.get('eventType'),
    eventId: searchParams.get('eventId'),
    eventTimestampBefore: searchParams.get('eventTimestampBefore'),
    eventTimestampAfter: searchParams.get('eventTimestampAfter')
  })

  const {
    isLoading: isLoadingAliases,
    aliases,
    customersWithAliases
  } = useCustomerNamesByAlias()

  const filters: MagicTableFilterConfig<UsageEventFilters>[] = [
    {
      type: 'text',
      paramName: 'eventId',
      label: 'Event ID',
      icon: CodeBracketSquareIcon
    },
    {
      type: 'multiSelect',
      paramName: 'eventType',
      options: (allEventTypes ?? []).map(eventType => ({
        label: eventType,
        value: eventType
      })),
      format: value => <Text>{value}</Text>,
      label: 'Event type',
      icon: CommandLineIcon
    },
    {
      type: 'multiSelectAliased',
      paramName: 'customerName',
      paramNameAliasOf: 'customerAlias',
      options: (customersWithAliases ?? []).map(customer => ({
        label: customer.customerName,
        value: customer.aliases.join(',')
      })),
      format: value => (
        <Text>
          {
            (customersWithAliases ?? []).find(
              customer => customer.aliases.join(',') === value
            )?.customerName
          }
        </Text>
      ),
      label: 'Customer',
      icon: IdentificationIcon
    },
    {
      type: 'multiSelect',
      paramName: 'customerAlias',
      options: [
        {
          label: 'Not mapped',
          value: UNMAPPED_ALIAS_OPTION_VALUE
        },
        ...customerAliasOptions(aliases ?? [])
      ],
      format: value => {
        const alias = (aliases ?? []).find(
          ({ value: customerAlias }) => customerAlias === value
        )
        const aliasValue = alias?.value ?? '-'
        return customerAliasBadge(aliasValue)
      },
      label: 'Customer alias',
      icon: HashtagIcon,
      optionsSortFn: (a: Option, b: Option) => {
        /**
         * Make sure the 'unmapped alias' option always appears at the top of the list
         */
        if (a.value === UNMAPPED_ALIAS_OPTION_VALUE) {
          return -1
        }

        if (b.value === UNMAPPED_ALIAS_OPTION_VALUE) {
          return 1
        }

        return a.label.localeCompare(b.label)
      },
      optionDisabledFn: (activeFilters, option) => {
        /**
         * Prevent the 'unmapped alias' option from being selected if a real alias option is selected,
         * and vice versa
         */
        const customerAliasFilter = activeFilters.find(
          activeFilter =>
            activeFilter.type === 'multiSelect' &&
            activeFilter.paramName === 'customerAlias'
        )

        if (
          !customerAliasFilter ||
          customerAliasFilter.type !== 'multiSelect'
        ) {
          return false
        }

        if (option.value === UNMAPPED_ALIAS_OPTION_VALUE) {
          return (
            customerAliasFilter.values.length > 0 &&
            !customerAliasFilter.values.includes(UNMAPPED_ALIAS_OPTION_VALUE)
          )
        }

        return customerAliasFilter.values.includes(UNMAPPED_ALIAS_OPTION_VALUE)
      },
      setQueryParamsFn: (thisFilter, activeFilter, updatedParams) => {
        if (activeFilter.type !== 'multiSelect') {
          return
        }

        if (activeFilter.values.includes(UNMAPPED_ALIAS_OPTION_VALUE)) {
          updatedParams.set('unmappedAliasesOnly', 'true')
          return
        }

        updatedParams.set(thisFilter.paramName, activeFilter.values.join(','))
      }
    },
    {
      type: 'date',
      paramName: 'eventTimestamp',
      paramNameBefore: 'eventTimestampBefore',
      paramNameAfter: 'eventTimestampAfter',
      optionsBefore: timestampFilterOptions,
      optionsAfter: timestampFilterOptions,
      format: (_, label) => label,
      label: 'Timestamp',
      stringifyDate: (date: Date) => {
        return isoTimestampIgnoringTime(date, 'start')
      },
      stringifyDateBefore: (date: Date) =>
        isoTimestampIgnoringTime(date, 'end'),
      stringifyDateAfter: (date: Date) =>
        isoTimestampIgnoringTime(date, 'start'),
      icon: CalendarIcon
    },
    {
      type: 'toggle',
      paramName: 'unmappedAliasesOnly',
      label: 'unmappedAliasesOnly',
      /**
       * NOTE @ihutc
       * To prevent a filter query param from being removed by MagicTable, we need to add it as a filter here.
       * Because we want to use this filter param in another way, and not through the default `toggle`
       * behaviour which renders in the 'View settings' menu, the `hidden` prop is set to true to hide it.
       * More info in this PR: https://github.com/SequenceHQ/sequence-web/pull/4185
       */
      hidden: true,
      restoreActiveFilterFn: (dispatch, thisFilter, queryParamValue) => {
        if (thisFilter.paramName === 'unmappedAliasesOnly') {
          dispatch({
            type: 'bulkSetMultiSelect',
            paramName: 'customerAlias',
            values: [UNMAPPED_ALIAS_OPTION_VALUE]
          })

          return
        }

        dispatch({
          type: 'switchToggleOption',
          paramName: thisFilter.paramName,
          value: queryParamValue === 'true'
        })
      }
    }
  ]

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getApiUsageEvents,
    filters,
    activeFilters,
    sortBy
  )

  if (
    !allEventProperties ||
    !allEventTypes ||
    typeof aliases === 'undefined' ||
    typeof customersWithAliases === 'undefined' ||
    isLoadingAliases
  ) {
    return (
      <Center width="100%" height="100%">
        <Spinner />
      </Center>
    )
  }

  const additionalToolbarComponentsV3 = {
    additionalToolbarComponentsV3: {
      start: <UsageEventsSegmentedFilters />
    }
  }

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<UsageEventsView>
          activeFilters={activeFilters}
          onChangeActiveFilters={onChangeActiveFilters}
          sortBy={sortBy}
          onChangeSortBy={onChangeSortBy}
          entityNamePlural="usage events"
          entityIcon={CommandLineIcon24}
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          additionalStatusBarComponents={
            distinctAliasCount
              ? `${distinctAliasCount} distinct customer ${
                  distinctAliasCount === '1' ? 'alias' : 'aliases'
                }`
              : undefined
          }
          rowPath={({ id }) =>
            persistMagicTableQueryParams(`/usage-events/${id}`)
          }
          objectPropertyColumns={{
            allEventProperties,
            getObject: (model: UsageEventModel) => model.eventProperties,
            searchFunc: (query: string) => {
              setEventPropertiesQuery(query)

              return eventProperties.map(({ value }) => ({
                label: value,
                value
              }))
            }
          }}
          columns={[
            {
              id: 'id',
              accessorKey: 'customerEventId',
              header: 'Event ID',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<UsageEventModel['customerEventId']>()}
                  showCopyButton={true}
                />
              )
            },
            {
              id: 'eventType',
              accessorKey: 'eventType',
              header: 'Event Type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Badge sentiment="monospaced" textTransform="none">
                    {value.getValue<UsageEventModel['eventType']>()}
                  </Badge>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'customer',
              accessorKey: 'customerAlias',
              header: 'Customer',
              cell: value => (
                <MagicTableAutoLoader<
                  ExtractQueryParams<typeof useGetCustomersByAliasByAliasQuery>,
                  { customerId?: string; customerName?: string }
                >
                  queryParams={{
                    alias: value.getValue<UsageEventModel['customerAlias']>()
                  }}
                  useQuery={useGetCustomersByAliasByAliasQuery}
                  extract={customer => ({
                    customerId: customer?.id
                  })}
                >
                  {({ customerId }) => {
                    if (!customerId) {
                      return (
                        <MagicTableCell
                          text="Alias not mapped to Customer"
                          borderColor={RedRed20}
                          textColor={RedRed50}
                        />
                      )
                    }

                    return (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        height="40px"
                        pl="6px"
                      >
                        <CustomerPreviewCardPill
                          customerId={customerId}
                          variant="ghost"
                        />
                      </Flex>
                    )
                  }}
                </MagicTableAutoLoader>
              )
            },
            {
              id: 'eventTimestamp',
              accessorKey: 'eventTimestamp',
              header: 'Timestamp',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<UsageEventModel['eventTimestamp']>()}
                />
              ),
              enableSorting: true
            },
            {
              id: 'customerAlias',
              accessorKey: 'customerAlias',
              header: 'Customer Alias',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<UsageEventModel['customerAlias']>()}
                />
              ),
              enableSorting: false,
              hiddenByDefault: true
            }
          ]}
          filters={filters}
          emptyState={{
            imageSrc: '/img/empty/gauge.png',
            learnMoreText:
              'Usage events are the units in which product usage is measured',
            learnMoreHref:
              'https://docs.sequencehq.com/metrics-events/usage-events',
            buttonNew: {
              buttonText: 'New usage event',
              onClick: () =>
                navigate(persistMagicTableQueryParams('/usage-events/new'))
            }
          }}
          kebabMenu={(model: UsageEventModel, props) => (
            <KebabMenu
              menuItems={menuItemBuilders(
                !!customersWithAliases.find(customer =>
                  customer.aliases.includes(model.customerAlias)
                )
              ).map(builder => builder(model))}
              flags={flags}
              {...props}
            />
          )}
          {...additionalToolbarComponentsV3}
        />
      )}
    </CurrentUserId>
  )
}
