import { Inspector } from '@sequencehq/core-components'
import { useBillingScheduleInspector } from './useBillingScheduleInspector'
import {
  LinkSalesforceAccountWidget,
  SalesforceInspectorItem,
  StripeInspectorItem,
  StripePaymentWidget
} from 'Integrations/index'
import { AttachmentsInspectorItem } from './items/AttachmentsInspector/AttachmentsInspectorItem'
import { StripeAutoChargeWidget } from 'Integrations/integrationsConfig/stripe/InspectorItem/widgets/StripeAutoChargeWidget.tsx'
import { LinkSalesforceBillingScheduleWidget } from 'Integrations/integrationsConfig/salesforce/InspectorItem/widgets/LinkSalesforceBillingScheduleWidget.tsx'

export const BillingScheduleInspector = () => {
  const hook = useBillingScheduleInspector()

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget customerId={hook.customerId} />
          <LinkSalesforceBillingScheduleWidget
            billingScheduleId={hook.billingScheduleId}
          />
        </SalesforceInspectorItem>
        <StripeInspectorItem alwaysShow>
          <StripePaymentWidget
            fieldData={hook.stripe.data}
            onChange={hook.stripe.onChange}
            disabled={!hook.stripe.available}
          />
          {hook.stripe.data.acceptPaymentsViaStripe?.value ? (
            <StripeAutoChargeWidget
              fieldData={hook.stripe.data}
              onChange={hook.stripe.onChange}
              disabled={!hook.stripe.available}
            />
          ) : null}
        </StripeInspectorItem>
        <AttachmentsInspectorItem />
      </Inspector.ItemGroup>
    </Inspector>
  )
}
