import {
  CreateUsageMetricDrawer,
  UsageMetricProvider
} from 'components/CreateUsageMetric'
import { usePricingEditorDomainContext } from '../../communication'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

const useConnector = () => {
  const ctx = usePricingEditorDomainContext()
  const navigate = useNavigate()

  const onSuccess = useCallback(
    (newMetric: { id: string }) => {
      ctx.mutators.common.updatePricingEditorData({
        [ctx.queries.rawData.data.pricingEditorData.common.pricingModel]: {
          usageMetricId: newMetric.id
        }
      })
      navigate('..')
    },
    [navigate, ctx]
  )

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess,
    onCancel
  }
}

export const PricingEditorUsageMetricRouteComponent = () => {
  const usageMetricDrawerProps = useConnector()

  return (
    <UsageMetricProvider>
      <CreateUsageMetricDrawer {...usageMetricDrawerProps} />
    </UsageMetricProvider>
  )
}
