import { ReactNode } from 'react'

import { Flex, Box, Text } from '@chakra-ui/react'

import { GreyGrey60, Lato13Regular } from '@sequencehq/design-tokens'

export const SummaryRow = ({
  label,
  value
}: { label: string; value: ReactNode }) => (
  <Flex height="28px" alignItems="center" alignContent="center">
    <Box width="140px">
      <Text {...Lato13Regular} color={GreyGrey60}>
        {label}
      </Text>
    </Box>
    {value}
  </Flex>
)
