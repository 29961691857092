import { type FC, useEffect, useState } from 'react'
import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  Text
} from '@chakra-ui/react'
import { GreyGrey30, Lato14Bold, Lato16Bold } from '@sequencehq/design-tokens'
import { Label } from '@sequencehq/forms'
import {
  TextInputField,
  InputSelectControlField,
  Button
} from '@sequencehq/core-components'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { useQuery } from '@sequencehq/api/utils'
import type { InvoiceEditorLineItemGroup } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import type { LineItemViewLineItemGroup } from 'InvoiceEditor/components/LineItems/hooks/useLineItems'

interface Props {
  isOpen: boolean
  onClose: () => void
  updateLineItemGroupFields: (
    updatedFields: Partial<InvoiceEditorLineItemGroup>
  ) => void
  lineItemGroup?: LineItemViewLineItemGroup | null
  isEditing: boolean
  onEdit: () => Promise<void>
  onCreate: () => Promise<void>
  onCancel: () => void
}

const LineItemGroupEditorDrawer: FC<Props> = ({
  isOpen,
  onClose,
  updateLineItemGroupFields,
  lineItemGroup,
  isEditing,
  onEdit,
  onCreate,
  onCancel
}) => {
  const { data } = useQuery(dashboardv99990101Client.getTaxCategories)
  const taxCategories = data?.items ?? []

  const defaultTaxCategory = taxCategories.find(
    category => category.isDefault
  )?.id

  const initialTaxCategory = lineItemGroup?.taxCategoryId || defaultTaxCategory

  useEffect(() => {
    if (initialTaxCategory !== lineItemGroup?.taxCategoryId) {
      updateLineItemGroupFields({ taxCategoryId: initialTaxCategory })
    }
  }, [
    initialTaxCategory,
    lineItemGroup?.taxCategoryId,
    updateLineItemGroupFields
  ])

  const [name, setName] = useState(lineItemGroup?.description || '')

  const handleClose = () => {
    setName('')
    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerOverlay
        display="flex"
        alignContent="center"
        justifyContent="center"
      />
      <DrawerContent
        data-testid="lineItemEditorDrawer"
        margin="8px 12px"
        borderRadius="8px"
        style={{
          width: 'fit-content',
          maxWidth: '100%',
          minWidth: '550px',
          overflow: 'hidden'
        }}
      >
        <Grid
          templateAreas={`
            "header"
            "content"
            "footer"
          `}
          templateRows="56px 1fr"
          width="100%"
          height="100%"
        >
          <Flex
            gridArea="header"
            borderBottom={`1px solid ${GreyGrey30}`}
            {...Lato14Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
          >
            <Text>{isEditing ? 'Edit group' : 'Add group'}</Text>
            <DrawerCloseButton style={{ position: 'inherit' }} />
          </Flex>
          <Flex flexDirection="column" p="20px">
            <Box>
              <Label>Name</Label>
              <TextInputField
                data-testid="lineItemGroupEditor.name"
                value={name}
                validationErrors={[]}
                onChange={event => {
                  const newName = event?.target?.value
                  setName(newName)
                  updateLineItemGroupFields({ description: newName })
                }}
                placeholder="Line item group name"
              />
            </Box>
            <InputSelectControlField
              data-testid="lineItemGroupEditor.taxCategory"
              label="Tax category"
              key={initialTaxCategory}
              onChange={category => {
                updateLineItemGroupFields({ taxCategoryId: category })
              }}
              options={taxCategories.map(category => ({
                label: category.name,
                value: category.id
              }))}
              validationErrors={[]}
              initialValue={initialTaxCategory}
              placeholder="Select a tax category"
            />
          </Flex>
          <Flex
            gridArea="footer"
            borderTop={`1px solid ${GreyGrey30}`}
            {...Lato16Bold}
            height="56px"
            px="24px"
            alignItems="center"
            justify="space-between"
            gap={2}
          >
            <Box flex={1}></Box>
            <Button
              variant="secondary"
              onClick={() => {
                onCancel()
                handleClose()
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="lineItemGroupEditor.save"
              variant="primary"
              onClick={() => {
                if (isEditing) {
                  onEdit().then(() => {
                    handleClose()
                  })
                } else {
                  onCreate().then(() => {
                    handleClose()
                  })
                }
              }}
            >
              {isEditing ? 'Update' : 'Add'}
            </Button>
          </Flex>
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}

export default LineItemGroupEditorDrawer
