import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import invariant from 'tiny-invariant'

export const usePhaseName = (props: {
  phaseId: string
}) => {
  const cubeCtx = useCubeContext()

  const resolvedPhase = cubeCtx.queries.resolvedPhases[props.phaseId]

  invariant(resolvedPhase, `Phase with id ${props.phaseId} not found`)

  return {
    available:
      cubeCtx.queries.availableFeatures.phases[resolvedPhase.id].phase.editName
        .available.enabled,
    name: resolvedPhase.name,
    updateName: (newValue: string) => {
      cubeCtx.mutators.updateData({
        phases: {
          [props.phaseId]: {
            name: newValue
          }
        }
      })
    }
  }
}
