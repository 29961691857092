import { useEffect, useState } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type BillingScheduleList =
  DashboardApi20240730.GetBillingSchedules.BillingScheduleList
export type BillingSchedule =
  DashboardApi20240730.GetBillingSchedule.BillingSchedule

export const useFetchBillingSchedules = ({
  customerId
}: {
  customerId: string
}) => {
  const [billingSchedules, setBillingSchedules] = useState<
    BillingScheduleList | undefined
  >()

  useEffect(() => {
    const fetchData = async () => {
      const result = await dashboard20240730Client.getBillingSchedules({
        customerId: customerId,
        limit: 12
      })

      if (result.data) {
        setBillingSchedules(result.data)
      }
    }

    void fetchData()
  }, [customerId])

  return {
    billingSchedules
  }
}
