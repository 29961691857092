import { developerLog } from '@sequencehq/utils'
import {
  ApiListPrice,
  EditorMode,
  PricingEditorDomainInput,
  PricingEditorDomainOutput,
  PricingEditorPortErrors,
  PricingEditorPortImplementation
} from 'common/drawers/PricingEditor/domain'
import { useCallback, useMemo } from 'react'
import { loadCommonData } from '../common.api.v1/loadCommonData'
import { loadListPrices, loadVariantPrice } from './entityLoaders'
import * as adapters from '../../adapters'
import {
  BASE_INITIAL_VARIANT_PRICE,
  INITIAL_PRICING_EDITOR_STATE,
  NEW_PRICE_PATTERN,
  NEW_PRICE_PREFIX
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import * as mutators from './entitySaving'
import deepmerge from 'deepmerge'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { VariantPrice } from '../loadData'
import { v4 as uuid } from 'uuid'

type UseVariantPricePorts = (props: {
  initialMode: EditorMode
  productId: string
  variantPriceId?: string
  listPriceId?: string
  existingPrice?: VariantPrice
  currency?: Currency
  disableDefaultPricePorts: boolean
}) => PricingEditorPortImplementation

/**
 * Load the price for the pricing editor. If the module is configured
 * to not use the default loading port for the price, then we
 * should use the existing price.
 *
 * If the price is new, we should use the list price as the base and
 * create a new price!
 *
 * Otherwise, we should load the price from the API.
 * @param args
 * @returns
 */
const getPrice = async (args: {
  existingPrice?: VariantPrice
  variantPriceId?: string
  listPrice?: ApiListPrice
  productId: string
  defaultCurrency?: Currency
  enabledCurrencies: Currency[]
  disableDefaultPricePorts?: boolean
}): Promise<VariantPrice | { error: PricingEditorPortErrors }> => {
  /**
   * If we want to load in an existing price, and an existing price is provided,
   * we will not default to a new price!
   *
   * (The future TODO here is to better define a 'create' mode, rather than infer
   * it from the variantPriceId, which is rather tricksy)
   */
  const loadExistingPrice = args.disableDefaultPricePorts && args.existingPrice
  const isANewPrice =
    args.variantPriceId?.match(NEW_PRICE_PATTERN) && !loadExistingPrice

  /**
   * When the price is new, we should be using the list price as
   * a basis. If no list price is provided, we will fall back to the
   * most basic starting point.
   */
  if (isANewPrice || !args.variantPriceId) {
    return {
      ...BASE_INITIAL_VARIANT_PRICE,
      ...args.listPrice,
      productId: args.productId,
      listPriceId: args.listPrice?.id,
      currency:
        args.defaultCurrency ??
        args.listPrice?.currency ??
        args.enabledCurrencies[0]
    }
  }

  /**
   * If the module is configured
   * to not use the default loading port for the price, then we
   * should use the existing price, if it exists. Since we have
   * disabled the price ports, we should also complain if the
   * existing price doesn't exist!
   */
  if (args.disableDefaultPricePorts) {
    if (!args.existingPrice) {
      return {
        error: PricingEditorPortErrors.NotFound
      }
    }

    return {
      ...args.existingPrice,
      listPriceId: args.listPrice?.id
    }
  }

  /**
   * Otherwise, just a nice simple load from the API.
   */
  const priceResponse = await loadVariantPrice(args.variantPriceId)
  if (priceResponse.error) {
    return {
      error: priceResponse.error
    }
  }

  return priceResponse.data
}

export const loadAllData = async (args: {
  configuration: Partial<PricingEditorDomainInput['configuration']>
  initialData: Partial<PricingEditorDomainInput['initialData']>
  variantPriceId?: string
  productId: string
  listPriceId?: string
  existingPrice?: VariantPrice
  disableDefaultPricePorts: boolean
}): Promise<{
  data: PricingEditorDomainInput | null
  error: PricingEditorPortErrors | null
}> => {
  developerLog(`%c[PricingEditor] Loading variant price`, 'color: pink;', {
    props: { id: args.variantPriceId }
  })
  const { accountSettings, xeroIntegration, product } = await loadCommonData({
    productId: args.productId
  })

  const errorResponse = [accountSettings, xeroIntegration, product].some(
    response => Boolean(response.error)
  )

  if (
    errorResponse ||
    !accountSettings.data ||
    !xeroIntegration.data ||
    !product.data
  ) {
    return {
      data: null,
      error: PricingEditorPortErrors.Other
    }
  }

  const listPrices = await loadListPrices({ productId: args.productId })
  const listPrice = listPrices.find(({ id }) => id === args.listPriceId)

  const price = await getPrice({
    defaultCurrency: args.configuration.currency,
    enabledCurrencies: accountSettings.data.enabledCurrencies,
    existingPrice: args.existingPrice,
    variantPriceId: args.variantPriceId,
    listPrice,
    productId: args.productId,
    disableDefaultPricePorts: args.disableDefaultPricePorts
  })

  if ('error' in price) {
    return {
      data: null,
      error: price.error
    }
  }

  const res = {
    xeroIntegration: xeroIntegration.data,
    listPrice,
    listPrices,
    product: product.data,
    price
  }

  const domainInput = adapters.dataAdapter.in.variant(res)

  return {
    data: {
      data: domainInput.domainInputData,
      configuration: deepmerge(args.configuration, {
        availableCurrencies: accountSettings.data.enabledCurrencies
      }),
      initialData: deepmerge(args.initialData, domainInput.initialData, {
        arrayMerge: (_, source: unknown[]) => source
      })
    },
    error: null
  }
}

export const useVariantPricePorts: UseVariantPricePorts = props => {
  const loadCore = useCallback(() => {
    return loadAllData({
      configuration: {
        ...INITIAL_PRICING_EDITOR_STATE['configuration'],
        currency: props.currency,
        mode: props.initialMode,
        priceType: 'variant'
      },
      initialData: {
        mode: props.initialMode
      },
      listPriceId: props.listPriceId,
      variantPriceId: props.variantPriceId,
      existingPrice: props.existingPrice,
      productId: props.productId,
      disableDefaultPricePorts: props.disableDefaultPricePorts
    })
  }, [
    props.currency,
    props.existingPrice,
    props.initialMode,
    props.listPriceId,
    props.productId,
    props.variantPriceId,
    props.disableDefaultPricePorts
  ])

  const saveVariant = useCallback(
    async (data: PricingEditorDomainOutput) => {
      developerLog(`%c[PricingEditor] Saving variant price`, 'color: pink;', {
        data
      })
      const saveData = adapters.dataAdapter.out.variant(data)

      if (props.disableDefaultPricePorts) {
        return {
          success: true,
          error: null,
          data: {
            price: {
              ...saveData,
              createdAt: new Date().toISOString(),
              /**
               * When we are creating a new variant without persisting, always generate a new id as
               * if we'd be creating a new price. However, we add a human readable prefix to give
               * some indication that something about this price is different and needs to be saved.
               */
              id: `${NEW_PRICE_PREFIX}${uuid()}`
            }
          }
        }
      }

      const result = await mutators.createVariantPrice(saveData)

      return {
        success: result.success,
        error: result.error,
        data: { price: result.data }
      }
    },
    [props.disableDefaultPricePorts, props.existingPrice]
  )

  const portImplementation: PricingEditorPortImplementation = useMemo(() => {
    return {
      in: {
        load: loadCore
      },
      out: {
        save: saveVariant
      }
    }
  }, [loadCore, saveVariant])

  return portImplementation
}
