import {
  Phase,
  Minimum,
  Price,
  DisabledReasonType,
  ValidationResult
} from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import deepmerge from 'deepmerge'

export type PhaseHookInterface = {
  showProductSearch: boolean
  setShowAddProductSearch: (show: boolean) => void
  features: {
    addDiscount: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    addMinimum: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    addProduct: {
      handler: () => void
      disabled: boolean
      reason: string
    }
    alignDates: {
      handler: () => void
      visible: boolean
    }
  }
  expanded: boolean
  updateExpanded: (newState: boolean) => void
  validationErrors: ValidationResult[]
  phaseDates: {
    start?: Date | undefined
    end?: Date | undefined
  }
  refs: {
    durationInput: React.RefObject<HTMLDivElement>
  }
}

type UsePhase = (props: {
  phaseId: Phase['id']
  expanded: boolean
  focusDurationOnLoad?: boolean
  onExpand: (expanded: boolean) => void
}) => PhaseHookInterface

export const getPriceGroups =
  (activeVersionMinimums: Minimum[]) =>
  (
    activeVersionPrices: Price[]
  ): {
    minimum?: Minimum
    prices: Price[]
  }[] =>
    Object.values(
      activeVersionPrices.reduce((acc, price) => {
        if (!('usageMetricId' in price.structure)) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        const matchingMinimum = activeVersionMinimums.find(
          minimum =>
            minimum.scope.target === 'allUsage' ||
            minimum.scope.priceIds.includes(price.id)
        )

        if (!matchingMinimum) {
          return deepmerge(acc, {
            'no-minimum': {
              prices: [price]
            }
          })
        }

        return deepmerge(acc, {
          [matchingMinimum.id]: {
            minimum: matchingMinimum,
            prices: [price]
          }
        })
      }, {})
    )

const ADD_DISCOUNT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.GlobalDiscountExists]:
    'Cannot add another discount when a global discount already exists',
  [DisabledReasonType.PhaseStartTransition]:
    'Change the start and/or end date of this phase to be able to add discounts',
  [DisabledReasonType.PhaseEndTransition]:
    'Change the start and/or end date of this phase to be able to add discounts',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a discount to a completed schedule',
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a discount to a completed phase'
}

const ADD_PRODUCT_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a product to a completed phase',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a product to a completed schedule'
}

const ADD_MINIMUM_DISABLED_REASONS: Partial<
  Record<DisabledReasonType, string>
> = {
  [DisabledReasonType.MaxMinimums]:
    'Cannot add another minimum when a minimum already exists for this phase',
  [DisabledReasonType.NoValidPrices]:
    'Add a usage based price in order to add a minimum',
  [DisabledReasonType.CompletedSchedule]:
    'Cannot add a minimum to a completed schedule',
  [DisabledReasonType.CompletedPhase]:
    'Cannot add a minimum to a completed phase'
}

export const usePhase: UsePhase = props => {
  const [showAddProductSearch, setShowAddProductSearch] = useState(false)
  const cubeContext = useCubeContext()
  const navigate = useNavigate()

  const durationInputRef = useRef<HTMLInputElement>(null)

  const resolvedPhase = useMemo(() => {
    return cubeContext.queries.resolvedPhases[props.phaseId]
  }, [props.phaseId, cubeContext.queries.resolvedPhases])

  const addProductDisabled = useMemo(() => {
    return !cubeContext.queries.availableFeatures.phases[resolvedPhase.id].phase
      .product.add.available
  }, [cubeContext, resolvedPhase.id])

  const showProductSearch = useMemo(() => {
    return !addProductDisabled && showAddProductSearch
  }, [addProductDisabled, showAddProductSearch])

  const availableFeaturesForVersion = useMemo(() => {
    return cubeContext.queries.availableFeatures.phases[resolvedPhase.id]
  }, [cubeContext.queries.availableFeatures, resolvedPhase.id])

  const features = useMemo(() => {
    return {
      addProduct: {
        handler: () => {
          setShowAddProductSearch(true)
        },
        disabled:
          !availableFeaturesForVersion.phase.product.add.available.enabled,
        reason:
          ADD_PRODUCT_DISABLED_REASONS[
            availableFeaturesForVersion.phase.product.add.reasons[0]?.reasonType
          ] ?? ''
      },
      addDiscount: {
        handler: () => {
          navigate(`./discount-editor/${props.phaseId}`)
        },
        disabled:
          !availableFeaturesForVersion.phase.discount.add.available.enabled,
        reason:
          ADD_DISCOUNT_DISABLED_REASONS[
            availableFeaturesForVersion.phase.discount.add.reasons[0]
              ?.reasonType
          ] ?? ''
      },
      addMinimum: {
        handler: () => {
          navigate(`./minimum-editor/${props.phaseId}`)
        },
        disabled:
          !availableFeaturesForVersion.phase.minimum.add.available.enabled,
        reason:
          ADD_MINIMUM_DISABLED_REASONS[
            availableFeaturesForVersion.phase.minimum.add.reasons[0]?.reasonType
          ] ?? ''
      },
      alignDates: {
        handler: () => {
          cubeContext.mutators.alignPhaseDuration(props.phaseId)
        },
        visible:
          availableFeaturesForVersion.phase.dates.alignDates.available.visible
      }
    }
  }, [navigate, props.phaseId, availableFeaturesForVersion, cubeContext])

  const updateExpanded = useCallback(
    (newState: boolean) => {
      props.onExpand(newState)
    },
    [props.onExpand]
  )

  useLayoutEffect(() => {
    if (props.focusDurationOnLoad) {
      durationInputRef.current?.focus()
    }
  }, [props.focusDurationOnLoad])

  const validationErrors = useMemo(() => {
    return [
      ...(cubeContext.queries.validation.activeValidationResults?.common
        .phases?.[props.phaseId]?.prices ?? [])
    ]
  }, [cubeContext.queries.validation.activeValidationResults, props.phaseId])

  return {
    showProductSearch,
    setShowAddProductSearch,
    validationErrors,
    expanded: props.expanded,
    updateExpanded,
    features,
    phaseDates: resolvedPhase.dates.absolute,
    refs: {
      durationInput: durationInputRef
    }
  }
}
