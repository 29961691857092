import { Flex } from '@chakra-ui/react'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { useMagicTableToolbarContext } from '@sequencehq/tables'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ActiveBillingSchedulesFilter = 'all' | 'active' | 'completed'
export const BillingSchedulesSegmentedFilters = () => {
  const [activeFilter, setActiveFilter] =
    useState<ActiveBillingSchedulesFilter>('all')
  const [searchParams] = useSearchParams()

  const ctx = useMagicTableToolbarContext()

  useEffect(() => {
    const billingScheduleStatus = searchParams.get('billingScheduleStatus')
    if (!billingScheduleStatus) {
      setActiveFilter('all')
    } else if (billingScheduleStatus === 'ACTIVE') {
      setActiveFilter('active')
    } else if (billingScheduleStatus === 'COMPLETED') {
      setActiveFilter('completed')
    }
  }, [searchParams])

  const handleFilterChange = useMemo(() => {
    return (value: string) => {
      switch (value) {
        case 'all':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'billingScheduleStatus',
            values: []
          })
          break
        case 'draft':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'billingScheduleStatus',
            values: ['DRAFT']
          })
          break
        case 'active':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'billingScheduleStatus',
            values: ['ACTIVE']
          })
          break
        case 'completed':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'billingScheduleStatus',
            values: ['COMPLETED']
          })
          break
        default:
          break
      }
    }
  }, [])

  const disabled = useMemo(
    () => !ctx.queries.canChangeFilters(),
    [ctx.queries.canChangeFilters]
  )

  return (
    <Flex alignItems="start">
      <RadioButtonGroup
        onChange={handleFilterChange}
        initialValue={activeFilter}
        value={activeFilter}
        options={[
          {
            value: 'all',
            label: 'All',
            disabled
          },
          {
            value: 'draft',
            label: 'Draft',
            disabled
          },
          {
            value: 'active',
            label: 'Active',
            disabled
          },
          {
            value: 'completed',
            label: 'Completed',
            disabled
          }
        ]}
      />
    </Flex>
  )
}
