import { InvoiceModel } from '@sequencehq/core-models'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { RedRed60 } from '@sequencehq/design-tokens'
import {
  KebabMenuItem,
  MenuItem,
  Modal,
  ModalContextProvider
} from '@sequencehq/core-components'
import { getEnvironment } from 'lib/environment/environment'
import { getPortalDomain } from '@sequencehq/utils'
import { customLocales, formatDistanceToNow } from '@sequencehq/utils/dates'
import { Flex, Text } from '@chakra-ui/react'
import { ArchiveInvoiceModal } from 'components/Invoices/modals/ArchiveInvoice.tsx'
import { useNavigate } from 'react-router-dom'

const SECONDS = 1000
const HOURS = 60 * 60 * SECONDS

type InvoiceStatusAction = {
  available: boolean
  disabled: boolean
  onClick: () => void
}

type UseInvoiceEditorHeader = () => {
  data: {
    title: string
    total: string
    status: InvoiceModel['status']
    paymentStatus: InvoiceModel['paymentStatus']
    isInvoiceReady: boolean
    calculatedAt: string | undefined
  }
  send: InvoiceStatusAction
  sendAndFinalise: InvoiceStatusAction
  sendPaymentReminder: InvoiceStatusAction
  finalise: InvoiceStatusAction
  updatePaymentStatus: {
    available: boolean
    disabled: boolean
    onChange: (newStatus: InvoiceModel['paymentStatus']) => void
  }
  menuItems: MenuItem[]
  preview: InvoiceStatusAction
  draft: InvoiceStatusAction
}

export const useHeader: UseInvoiceEditorHeader = () => {
  const { data, functions, derived } = useInvoiceEditorContext()
  const navigate = useNavigate()

  const portalDomain = getPortalDomain(getEnvironment())

  const onSendInvoice = useCallback(() => {
    functions.sendInvoice()
  }, [functions])
  const onFinaliseInvoice = useCallback(() => {
    functions.finaliseInvoice()
  }, [functions])
  const onRecalculateInvoice = useCallback(() => {
    functions.recalculateInvoice()
  }, [functions])
  const onVoidInvoice = useCallback(() => {
    functions.voidInvoice()
  }, [functions])
  const onFinaliseAndSendInvoice = useCallback(() => {
    functions.finaliseAndSendInvoice()
  }, [functions])
  const onSendPaymentReminder = useCallback(() => {
    functions.sendPaymentReminder()
  }, [functions])
  const onUpdatePaymentStatus = useCallback(
    (newStatus: InvoiceModel['paymentStatus']) => {
      functions.updatePaymentStatus(newStatus)
    },
    [functions]
  )
  const onConvertInvoiceToDraft = useCallback(() => {
    functions.convertInvoiceToDraft()
  }, [functions])
  const onViewPaymentDetails = useCallback(() => {
    functions.showPaymentDetailsDrawer()
  }, [])
  const onViewInvoicePdfPreview = useCallback(() => {
    functions.showInvoicePdfPreviewDrawer()
  }, [])
  const onCreateCreditNote = useCallback(() => {
    functions.createCreditNote()
  }, [functions])

  const onSendTestInvoice = useCallback(() => {
    functions.sendTestInvoice()
  }, [functions])

  const recalculateMenuItem = {
    label: 'Recalculate invoice',
    action: onRecalculateInvoice,
    status: 'LIVE' as const
  }

  const sendTestInvoiceMenuItem = {
    label: 'Send test invoice',
    action: onSendTestInvoice,
    status: 'LIVE' as const
  }

  const viewInPortalMenuItem = {
    label: 'View in customer portal',
    action: () => {
      window.open(
        `${portalDomain}/customers/${data.recipient.customerId}/invoices/${data.invoice.id}`
      )
    },
    status: 'LIVE' as const
  }

  const createCreditNoteMenuItem = {
    label: 'Create credit note',
    action: onCreateCreditNote,
    status: 'LIVE' as const,
    testId: 'kebab-menu-create-credit-note-button'
  }

  const voidInvoiceMenuItem = {
    label: 'Void invoice',
    action: onVoidInvoice,
    status: 'LIVE' as const,
    styles: {
      color: RedRed60
    }
  }

  const archiveInvoiceMenuItem: MenuItem = {
    status: derived.queries.availableFeatures.canArchiveInvoice
      ? 'LIVE'
      : 'DISABLED',
    action: () => {},
    label: (
      <ModalContextProvider>
        <Modal.Trigger>
          {triggerModal => {
            return (
              <KebabMenuItem>
                <Flex
                  flexDirection="row"
                  align="center"
                  gap={2}
                  width="100%"
                  onClick={triggerModal}
                  data-testid="kebab-menu-delete-invoice-button"
                >
                  <Text color={RedRed60}>Delete invoice</Text>
                </Flex>
              </KebabMenuItem>
            )
          }}
        </Modal.Trigger>
        <ArchiveInvoiceModal
          invoiceId={data.invoice.id}
          onSuccess={() => navigate('/invoices')}
        />
      </ModalContextProvider>
    )
  }

  const menuItems = useMemo(() => {
    const items: MenuItem[] = []

    const {
      canRecalculateInvoice,
      canViewInCustomerPortal,
      canCreateCreditNote
    } = derived.queries.availableFeatures

    if (canRecalculateInvoice) {
      items.push(recalculateMenuItem)
    }

    if (canViewInCustomerPortal) {
      items.push(viewInPortalMenuItem)
    }

    if (derived.queries.availableFeatures.canSendTestInvoice) {
      items.push(sendTestInvoiceMenuItem)
    }

    if (canCreateCreditNote) {
      items.push(createCreditNoteMenuItem)
    }

    items.push({
      label: 'View payment details',
      action: onViewPaymentDetails,
      status: 'LIVE' as const
    })

    if (derived.queries.availableFeatures.canVoidInvoice) {
      items.push(voidInvoiceMenuItem)
    }

    if (derived.queries.availableFeatures.canArchiveInvoice) {
      items.push(archiveInvoiceMenuItem)
    }

    return items
  }, [derived.queries.availableFeatures])

  const invoiceTitle = useMemo(() => {
    if (data.invoice.invoiceNumber) {
      return data.invoice.invoiceNumber
    }

    if (data.invoice.status === 'DRAFT') {
      return 'Draft invoice'
    } else if (data.invoice.status === 'IN_PROGRESS') {
      return 'Upcoming invoice'
    }

    return 'Invoice'
  }, [data.invoice.invoiceNumber, data.invoice.status])

  /**
   * Calculated at
   */
  const [calculatedAt, setCalculatedAt] = useState<string | undefined>()
  const refCalculatedAtTimer = useRef<NodeJS.Timeout>()

  const shouldSkipRefreshInterval = useCallback(() => {
    if (!data.invoice.calculatedAt) {
      return true
    }

    const lastRecalculatedDiffMs: number =
      new Date().getTime() - new Date(data.invoice.calculatedAt).getTime()

    return lastRecalculatedDiffMs > 1 * HOURS
  }, [data.invoice.calculatedAt])

  const formatCalculatedAt = useCallback((props: { calculatedAt: string }) => {
    const relativeString = formatDistanceToNow(new Date(props.calculatedAt), {
      addSuffix: true,
      locale: customLocales.enGb
    })

    return `Last calculated ${relativeString}`
  }, [])

  useEffect(() => {
    // cancel any active intervals (when `calculatedAt` or `canRecalculateInvoice` changes)
    if (refCalculatedAtTimer.current) {
      clearInterval(refCalculatedAtTimer.current)
      refCalculatedAtTimer.current = undefined
    }

    // stop if `calculatedAt` isn't shown
    if (
      !data.invoice.calculatedAt ||
      !derived.queries.availableFeatures.canRecalculateInvoice
    ) {
      setCalculatedAt(undefined)
      return
    }

    // initial update
    setCalculatedAt(
      formatCalculatedAt({ calculatedAt: data.invoice.calculatedAt })
    )

    // don't auto update if last recalculated more than 1 hour ago
    if (shouldSkipRefreshInterval()) {
      return
    }

    // set interval and update calculated at string periodically
    refCalculatedAtTimer.current = setInterval(() => {
      if (!data.invoice.calculatedAt || shouldSkipRefreshInterval()) {
        if (refCalculatedAtTimer.current) {
          clearInterval(refCalculatedAtTimer.current)
          refCalculatedAtTimer.current = undefined
        }

        return
      }

      setCalculatedAt(
        formatCalculatedAt({ calculatedAt: data.invoice.calculatedAt })
      )
    }, 30 * SECONDS)
  }, [
    data.invoice.calculatedAt,
    derived.queries.availableFeatures.canRecalculateInvoice,
    formatCalculatedAt,
    shouldSkipRefreshInterval
  ])

  // cancel any running timers when leaving the page
  useEffect(() => {
    return () => {
      if (refCalculatedAtTimer.current) {
        clearInterval(refCalculatedAtTimer.current)
        refCalculatedAtTimer.current = undefined
      }
    }
  }, [])

  return {
    menuItems,
    data: {
      title: invoiceTitle,
      status: data.invoice.status,
      paymentStatus: data.invoice.paymentStatus,
      total: data.totals.grossTotal,
      isInvoiceReady: data.invoice.isReady,
      calculatedAt
    },
    send: {
      available: derived.queries.availableFeatures.canSendInvoice,
      disabled: false,
      onClick: onSendInvoice
    },
    finalise: {
      available: derived.queries.availableFeatures.canFinaliseInvoice,
      disabled: derived.queries.availableFeatures.finaliseInvoiceDisabled,
      onClick: onFinaliseInvoice
    },
    sendAndFinalise: {
      available: derived.queries.availableFeatures.canFinaliseAndSendInvoice,
      disabled: derived.queries.availableFeatures.finaliseInvoiceDisabled,
      onClick: onFinaliseAndSendInvoice
    },
    sendPaymentReminder: {
      available: derived.queries.availableFeatures.canSendPaymentReminder,
      disabled: false,
      onClick: onSendPaymentReminder
    },
    updatePaymentStatus: {
      available: derived.queries.availableFeatures.canUpdatePaymentStatus,
      disabled: false,
      onChange: onUpdatePaymentStatus
    },
    preview: {
      available: derived.queries.availableFeatures.canPreviewInvoice,
      disabled: false,
      onClick: onViewInvoicePdfPreview
    },
    draft: {
      available: derived.queries.availableFeatures.canDraftInvoice,
      disabled: false,
      onClick: onConvertInvoiceToDraft
    }
  }
}
