import { Inspector } from '@sequencehq/core-components'
import { QuoteInformationInspectorItem } from './items/QuoteInformationInspectorItem'
import {
  LinkSalesforceAccountWidget,
  LinkSalesforceOpportunityWidget,
  SalesforceInspectorItem
} from 'Integrations/index'
import { useQuoteEditorInspector } from './useQuoteEditorInspector'
import { AttachmentsInspectorItem } from './items/AttachmentsInspector'
import { QuoteSettingsInspectorItem } from './items/QuoteSettingsInspectorItem'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const QuoteEditorInspector: React.FC = () => {
  const { useQuoteSettings } = useFlags()
  const hook = useQuoteEditorInspector()

  return (
    <Inspector showContentOnMount>
      <Inspector.ItemGroup>
        <QuoteInformationInspectorItem />
        <SalesforceInspectorItem>
          <LinkSalesforceAccountWidget {...hook.customerLinking} />
          <LinkSalesforceOpportunityWidget
            sequenceEntityLabel="quote"
            {...hook.opportunityLinking}
          />
        </SalesforceInspectorItem>
        <AttachmentsInspectorItem />
        {useQuoteSettings && <QuoteSettingsInspectorItem />}
      </Inspector.ItemGroup>
    </Inspector>
  )
}
