import { Box } from '@chakra-ui/react'
import { DatePickerField, SimpleModal } from '@sequencehq/core-components'
import { useState } from 'react'

export const CompleteMilestonePhaseModal = (props: {
  children: React.ReactNode
  minDate: Date
  onComplete: (date: Date) => void
}) => {
  const [completionDate, setCompletionDate] = useState(new Date())
  const reset = () => {
    setCompletionDate(new Date())
  }

  return (
    <SimpleModal
      trigger={props.children}
      cancelButtonText="Cancel"
      submitButtonText="Mark as complete"
      title="Mark phase as complete"
      onSubmit={() => {
        props.onComplete(completionDate)
        reset()
      }}
      contentProps={{
        minHeight: 'fit-content'
      }}
      data-testid="completeMilestone.modal"
    >
      <Box>
        <DatePickerField
          data-testid="completeMilestone.completionDate"
          label="Date of completion"
          placeholder="Select a date"
          minDate={props.minDate}
          width="100%"
          value={completionDate}
          onChange={date => date && setCompletionDate(date)}
        />
      </Box>
    </SimpleModal>
  )
}
