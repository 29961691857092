import { pastRelativeToDate } from '@sequencehq/formatters'
import { FC, memo } from 'react'
import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { UsageEventsMagicTable } from 'components/UsageEvents/UsageEventsMagicTable'
import { Outlet } from 'react-router-dom'
import { usePersistMagicTableQueryParams } from '@sequencehq/tables'

export const timestampFilterOptions = [
  {
    label: '1 week ago',
    value: pastRelativeToDate(new Date(), { weeks: 1 })
  },
  {
    label: '1 month ago',
    value: pastRelativeToDate(new Date(), { months: 1 })
  },
  {
    label: '3 months ago',
    value: pastRelativeToDate(new Date(), { months: 3 })
  },
  {
    label: '6 months ago',
    value: pastRelativeToDate(new Date(), { months: 6 })
  }
]

const UsageEvents: FC = memo(() => {
  const { persistMagicTableQueryParams } = usePersistMagicTableQueryParams()

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Events"
          addNewConfig={{
            buttonText: 'New usage event',
            href: persistMagicTableQueryParams('/usage-events/new')
          }}
          paddingBottom={0}
        >
          <UsageEventsMagicTable />
        </Page>
      </Flex>
      <Outlet />
    </Flex>
  )
})

export default UsageEvents
