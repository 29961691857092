import { PriceModel } from '@sequencehq/core-models'
import Spinner from 'components/Loading/Spinner'
import { useGetPricesByIdQuery } from 'features/api'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import * as entityLoaders from 'modules/Products/hooks/entityLoaders'
import * as entitySaving from 'modules/Products/hooks/entitySaving'
import invariant from 'tiny-invariant'
import * as Sentry from '@sentry/react'
import { ListPrice, Product, VariantPrice } from 'modules/Products/types'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ListPriceMutationInterface } from 'modules/Products/hooks/entitySaving/useSaveListPrice'
import { NEW_PRODUCT_PATTERN } from 'Cube/domain/cube.constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

type ProductContextType = {
  data: {
    product: Product
    listPrices: ListPrice[]
    variantPrices: VariantPrice[]
    price?: PriceModel
    taxCategories: DashboardApi20240730.GetTaxCategories.TaxCategory[]
  }
  functions: {
    archiveListPrice: (id: string) => Promise<void>
    createListPrice: (price: ListPriceMutationInterface) => Promise<void>
    updateListPrice: (
      id: string
    ) => (price: ListPriceMutationInterface) => Promise<void>
    updateListPriceState: (newOrUpdatedPrice: ListPrice) => void
    updateProductState: (updatedProduct: Product) => void
  }
  features: {
    newPriceEditor: boolean
    taxCategories: boolean
  }
}

const ProductContext = createContext<ProductContextType | null>(null)

enum LoadingStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  RELOADING = 'RELOADING',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

const loadTaxCategories = async () => {
  const result = await dashboard20240730Client.getTaxCategories()

  if (result.error || !result.data) {
    return {
      error: true,
      data: []
    }
  }

  return { error: false, data: result.data.items }
}

export const ProductContextProvider = ({
  productId,
  priceId,
  children
}: PropsWithChildren & { productId: string; priceId?: string }) => {
  const flags = useFlags()
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.UNINITIALIZED
  )
  const [data, setData] = useState<ProductContextType>({
    data: {
      product: {
        id: 'uninitialized',
        name: '',
        createdAt: '',
        updatedAt: ''
      },
      listPrices: [],
      variantPrices: [],
      taxCategories: []
    },
    functions: {
      archiveListPrice: () => Promise.resolve(),
      createListPrice: () => Promise.resolve(),
      updateListPrice: () => () => Promise.resolve(),
      updateListPriceState: () => {},
      updateProductState: () => {}
    },
    features: {
      newPriceEditor: false,
      taxCategories: false
    }
  })
  const notifications = useNotifications()

  const { data: price, isFetching } = useGetPricesByIdQuery(
    { id: priceId! },
    { skip: !priceId }
  )

  const productLoader = entityLoaders.useLoadProduct()
  const listPricesLoader = entityLoaders.useLoadListPrices()
  const variantPricesLoader = entityLoaders.useLoadVariantPrices()

  const saveListPrice = entitySaving.useSaveListPrice()

  const load: () => Promise<{
    success: boolean
    data: ProductContextType['data'] | null
  }> = useCallback(async () => {
    if (productId?.match(NEW_PRODUCT_PATTERN)) {
      return {
        success: false,
        data: null
      }
    }

    try {
      const product = await productLoader(productId)

      invariant(product, 'Product not found')

      const [listPricesData, variantPricesData, taxCategoriesData] =
        await Promise.all([
          listPricesLoader({ productId }),
          variantPricesLoader({ productId }),
          flags.showNewTaxRatesSettings
            ? loadTaxCategories()
            : Promise.resolve({ data: [] })
        ])

      return {
        success: true,
        data: {
          product,
          listPrices: listPricesData,
          variantPrices: variantPricesData,
          taxCategories: taxCategoriesData.data ?? []
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      return {
        success: false,
        data: null
      }
    }
  }, [
    productId,
    productLoader,
    listPricesLoader,
    variantPricesLoader,
    flags.showNewTaxRatesSettings
  ])

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADING ||
      loadingStatus === LoadingStatus.ERROR ||
      data.data.product.id !== 'uninitialized'
    ) {
      return
    }

    // TODO: what about reloading, e.g. after adding prices
    setLoadingStatus(
      loadingStatus === LoadingStatus.READY
        ? LoadingStatus.RELOADING
        : LoadingStatus.LOADING
    )
    void load().then(loadingResult => {
      const { success, data: loadedData } = loadingResult
      setLoadingStatus(success ? LoadingStatus.READY : LoadingStatus.ERROR)
      if (loadedData) {
        setData(prev => ({
          ...prev,
          data: loadedData
        }))
      }
    })
  }, [data.data.product.id, load, loadingStatus])

  const archiveListPrice = useCallback(
    async (id: string) => {
      notifications.displayNotification('Archiving list price...')
      const archiveResult = await saveListPrice.archive(id)

      if (archiveResult === null) {
        notifications.displayNotification('Failed to archive list price', {
          type: 'error'
        })
        return
      }

      notifications.displayNotification('List price archived', {
        type: 'success'
      })

      setData(prev => ({
        ...prev,
        data: {
          ...prev.data,
          listPrices: prev.data.listPrices.filter(
            listPrice => listPrice.id !== id
          )
        }
      }))
    },
    [notifications, saveListPrice]
  )

  const createListPrice = useCallback(
    async (newPrice: ListPriceMutationInterface) => {
      notifications.displayNotification('Creating list price...')
      const createResult = await saveListPrice.create(newPrice)

      if (createResult === null) {
        notifications.displayNotification('Failed to create list price', {
          type: 'error'
        })
        return
      }

      notifications.displayNotification('List price created', {
        type: 'success'
      })

      setData(prev => ({
        ...prev,
        data: {
          ...prev.data,
          listPrices: [createResult, ...prev.data.listPrices]
        }
      }))
    },
    [notifications, saveListPrice]
  )

  const updateListPriceState = useCallback(
    (newOrUpdatedPrice: ListPrice) => {
      const existingListPrice = data.data.listPrices.some(
        listPrice => listPrice.id === newOrUpdatedPrice.id
      )

      if (existingListPrice) {
        setData(prev => ({
          ...prev,
          data: {
            ...prev.data,
            listPrices: prev.data.listPrices.map(listPrice =>
              listPrice.id === newOrUpdatedPrice.id
                ? newOrUpdatedPrice
                : listPrice
            )
          }
        }))
      } else {
        setData(prev => ({
          ...prev,
          data: {
            ...prev.data,
            listPrices: [newOrUpdatedPrice, ...prev.data.listPrices]
          }
        }))
      }
    },
    [data.data.listPrices]
  )

  const updateListPrice = useCallback(
    (id: string) => async (updatedPrice: ListPriceMutationInterface) => {
      notifications.displayNotification('Updating list price...')
      const updateResult = await saveListPrice.update(id)(updatedPrice)

      if (updateResult === null) {
        notifications.displayNotification('Failed to update list price', {
          type: 'error'
        })
        return
      }

      notifications.displayNotification('List price updated', {
        type: 'success'
      })

      setData(prev => ({
        ...prev,
        data: {
          ...prev.data,
          listPrices: prev.data.listPrices.map(listPrice =>
            listPrice.id === id ? updateResult : listPrice
          )
        }
      }))
    },
    [notifications, saveListPrice]
  )

  const updateProductState = useCallback(
    (updatedProduct: Product) =>
      setData(prev => ({
        ...prev,
        data: {
          ...prev.data,
          product: updatedProduct
        }
      })),
    []
  )

  const context = useMemo(
    () => ({
      data: {
        ...data.data,
        price: !isFetching ? (price?.value() as PriceModel) : undefined
      },
      functions: {
        archiveListPrice,
        createListPrice,
        updateListPrice,
        updateListPriceState,
        updateProductState
      },
      features: {
        newPriceEditor: true,
        taxCategories: flags.showNewTaxRatesSettings
      }
    }),
    [
      data.data,
      isFetching,
      price,
      archiveListPrice,
      createListPrice,
      updateListPrice,
      updateListPriceState,
      updateProductState,
      flags.showNewTaxRatesSettings
    ]
  )

  if (
    loadingStatus === LoadingStatus.UNINITIALIZED ||
    loadingStatus === LoadingStatus.LOADING
  ) {
    return <Spinner />
  }

  return (
    <ProductContext.Provider value={context}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProductContext = () => {
  const context = useContext(ProductContext)

  if (!context) {
    throw new Error(
      'useProductContext must be used within a ProductContextProvider'
    )
  }

  return context
}
