import { PhaseBlockConfig } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'
import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'
import { priceModelAdapter } from '@sequencehq/utils'
import { isObject } from 'lodash/fp'

export const phasesAdapter: BlockAdapter<'phases'> = {
  in: () => {
    return [
      {
        type: 'phases'
      }
    ]
  },
  out: ctx => () => {
    const resolvedPhaseData = ctx.cubeQueries.orderedPhases
    return {
      blockType: 'phases',
      config: {
        phases: resolvedPhaseData.map(phase => ({
          id: phase.id,
          minimums: phase.minimums,
          name: phase.name,
          discounts: phase.discounts,
          globalDiscount: phase.globalDiscount,
          prices: phase.prices.map(priceModelAdapter.in),
          dates: {
            duration: isObject(phase.dates.duration)
              ? {
                  ...phase.dates.duration,
                  days:
                    phase.dates.duration?.days === -1
                      ? 0
                      : phase.dates.duration?.days
                }
              : phase.dates.duration,
            absolute: {
              start: apiDatesAdapters.toApi(phase.dates.absolute.start),
              end: apiDatesAdapters.toApi(phase.dates.absolute.end)
            }
          }
        }))
      } as PhaseBlockConfig,
      version: 1
    }
  }
}
