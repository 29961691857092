import type {
  IntegrationServices,
  IntegrationServiceTypes
} from '@sequencehq/api/utils/commonEnums'
import { Dashboardv99990101Api } from '@sequencehq/api/dist/clients/dashboard/v99990101'

export type IntegrationState =
  | 'UNAVAILABLE'
  | 'AVAILABLE'
  | 'PENDING'
  | 'CONNECTED'
  | 'COMING_SOON'

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P]
}

/**
 * If we have an integration that is only on the front end because it is
 * documentation only, or similar, then it needs to be added here.
 */
export type FrontEndOnlyServices =
  | 'Attio'
  | 'Anrok'
  | 'Go_Cardless'
  | 'Dropbox_Sign'
  | 'Equals'
  | 'Zapier'

export type FullIntegrationServices = IntegrationServices

export type FrontEndOnlyServiceTypes =
  | 'E-signatures'
  | 'Tax'
  | 'Reporting'
  | 'Workflows'

export type AvailableServices = FullIntegrationServices | FrontEndOnlyServices
export type AvailableServiceTypes =
  | IntegrationServiceTypes
  | FrontEndOnlyServiceTypes

export enum IntegrationFeatureDisabledReason {
  FeatureNotAvailable = 'NOT_AVAILABLE_FOR_INTEGRATION',
  IntegrationNotActive = 'INTEGRATION_NOT_ACTIVE',
  OperationInProgress = 'OPERATION_IN_PROGRESS',
  IntegrationActive = 'INTEGRATION_ACTIVE'
}

export type IntegrationFeatureAvailable = {
  available: {
    visible: boolean
    enabled: boolean
  }
  reasons: Array<IntegrationFeatureDisabledReason>
}

export type Integration =
  | {
      id: Exclude<AvailableServices, 'HubSpot' | 'Xero' | 'Avalara'>
      type: AvailableServiceTypes
      state: IntegrationState
      configuration: Record<string, unknown>
      enabled: boolean
      authFailure: boolean
    }
  | {
      id: 'Xero'
      type: AvailableServiceTypes
      state: IntegrationState
      configuration: Record<string, unknown>
      enabled: boolean
      authFailure: boolean
      defaultLedgerAccount: string | undefined
      creditDefaultLedgerAccount: string | undefined
    }
  | {
      id: 'HubSpot'
      type: AvailableServiceTypes
      state: IntegrationState
      configuration: Record<string, unknown>
      enabled: boolean
      authFailure: boolean
      enabledPipelineStages: string[]
    }
  | {
      id: 'Avalara'
      type: AvailableServiceTypes
      state: IntegrationState
      configuration: Record<string, unknown>
      enabled: boolean
      authFailure: boolean
    }

export enum ConnectionMethod {
  IntegrationApp = 'INTEGRATION_APP',
  OAuth = 'OAUTH'
}

export type IntegrationStatusLabel = 'BETA' | 'COMING_SOON' | null

export interface IntegrationConfig {
  logoUrl: string
  smallLogoUrl: string
  documentationUrl: string
  title: string
  description: string
  statusLabel: IntegrationStatusLabel
  features: {
    canDeleteLinks: boolean
    canUninstall: boolean
    isDocumentationOnly: boolean
    disableSubmitUntilValid?: boolean
  }
  components: {
    detail: React.ComponentType<any>
    management: React.ComponentType<ConfigurationManagementProps>
    inspector?: React.ComponentType<ConfigurationManagementProps>
  }
  communication?: {
    connecting?: {
      /**
       * Defaults to integration app
       */
      method?: ConnectionMethod
      integrationAppParameters?: Record<string, unknown>
    }
    configuration?: {
      loader: () => Promise<{
        data: Record<string, unknown> | null
        error: IntegrationsPortErrors | null
      }>
      mutator: (
        /**
         * Purposefully any since we don't perform any type checking 'domain side' of
         * the configuration value that is passed around from the configuration communication
         */
        data: any
      ) => Promise<{ success: boolean; error: IntegrationsPortErrors | null }>
    }
  }
}

export type ResolvedIntegration = Integration &
  IntegrationConfig & {
    isComingSoon: boolean
  }

export type IntegrationGroup = {
  title: string
  integrations: Array<ResolvedIntegration>
}

export type ResolvedIntegrations = Partial<
  Record<Integration['id'], ResolvedIntegration>
>

/**
 * Internal types for domain implementation
 */
type IntegrationsReducerData = {
  integrations: Partial<Record<Integration['id'], Integration>>
  featuredIntegrations: Array<Integration['id']>
}

export type IntegrationsReducerState = {
  data: IntegrationsReducerData
  configuration: {
    enableFeature: boolean
  }
  editor: {
    loaded: boolean
    loadingError: boolean
    connecting: boolean
    removing: boolean
    updating: boolean
    configuration: {
      valid: boolean
      showValidationErrors: boolean
      dataToSave?: Record<string, unknown>
    }
  }
  queries: {
    resolvedIntegrations: ResolvedIntegrations
    integrationGroups: Partial<
      Record<IntegrationServiceTypes, IntegrationGroup>
    >
    activeIntegrations: Array<ResolvedIntegration>
    featuredIntegrations: Array<ResolvedIntegration>
    availableFeatures: {
      integrations: Partial<
        Record<
          AvailableServices,
          {
            canRemoveLinks: IntegrationFeatureAvailable
            canUninstall: IntegrationFeatureAvailable
            canConnect: IntegrationFeatureAvailable
            canManage: IntegrationFeatureAvailable
          }
        >
      >
    }
  }
  initialData: IntegrationsReducerData
}

type Action<T extends string, P> = {
  type: T
  payload: P
}

export type LoadAction = Action<
  'load',
  Pick<IntegrationsReducerState, 'data' | 'configuration'>
>

export type UpdateEditorAction = Action<
  'updateEditor',
  RecursivePartial<IntegrationsReducerState['editor']>
>

export type UpdateDataAction = Action<
  'updateData',
  RecursivePartial<IntegrationsReducerState['data']>
>

export type ReloadDataAction = Action<
  'reloadData',
  RecursivePartial<IntegrationsReducerState['data']>
>

export type IntegrationsActions =
  | LoadAction
  | UpdateEditorAction
  | UpdateDataAction
  | ReloadDataAction

export type ActionHandler<A extends IntegrationsActions> = (
  prevState: IntegrationsReducerState
) => (action: A) => IntegrationsReducerState

export type PostActionContext = {
  preActionState: IntegrationsReducerState
  action: IntegrationsActions
}
export type PostActionStage = (
  ctx: PostActionContext
) => (prevState: IntegrationsReducerState) => IntegrationsReducerState

/**
 * Domain interface
 */
export interface IntegrationsDomainInterface {
  queries: IntegrationsReducerState['queries'] & {
    rawData: Pick<
      IntegrationsReducerState,
      'data' | 'configuration' | 'editor' | 'initialData'
    >
  }
  mutators: {
    updateEditor: (
      newState: RecursivePartial<IntegrationsReducerState['editor']>
    ) => void
    updateData: (
      newData: RecursivePartial<IntegrationsReducerState['data']>
    ) => void
    external: {
      in: {
        load: () => Promise<{
          data: Pick<IntegrationsReducerState, 'data' | 'configuration'> | null
          error: IntegrationsPortErrors | null
        }>
      }
      out: {
        updateIntegrationConfiguration: (
          service: AvailableServices
        ) => Promise<{ success: boolean }>
        connect: (service: AvailableServices) => Promise<{ success: boolean }>
        removeIntegration: (
          service: AvailableServices,
          config: { removeLinks: boolean }
        ) => Promise<{ success: boolean }>
      }
    }
  }
}

/**
 * Port implementation type
 */
export type IntegrationsDomainOutput = IntegrationsDomainInterface['queries']
export type IntegrationsDomainInput = Pick<
  IntegrationsReducerState,
  'data' | 'configuration'
>

export enum IntegrationsPortErrors {
  NotFound = 'notFound',
  IncompleteIntegration = 'incompleteIntegration',
  Other = 'other'
}

export type IntegrationsPortImplementation = {
  in: {
    load: () => Promise<{
      data: IntegrationsDomainInput | null
      error: IntegrationsPortErrors | null
    }>
  }
  out: {
    updateIntegrationConfiguration: (args: {
      service: FullIntegrationServices
      configuration: Record<string, unknown>
    }) => Promise<{
      success: boolean
      error: IntegrationsPortErrors | null
    }>
    connect: (args: {
      service: FullIntegrationServices
      params?: Record<string, unknown>
    }) => Promise<{
      success: boolean
      error: IntegrationsPortErrors | null
    }>
    removeIntegration: (
      service: FullIntegrationServices,
      config: { removeLinks: boolean }
    ) => Promise<{
      success: boolean
      error: IntegrationsPortErrors | null
    }>
  }
}

export interface ConfigurationManagementProps {
  integration: ResolvedIntegration
  showValidationErrors: boolean
  updateConfiguration: (newConfiguration: Record<string, any>) => void
  updateValidationState: (isValid: boolean) => void
}

export type AvalaraCompanies =
  Dashboardv99990101Api.GetAllAvalaraCompanies.AvalaraCompaniesResponse['items']
