import { baseApi as api, ParsedResponse } from 'features/api/baseApi'

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceMerchantDetails: build.query<
      ParsedResponse<GetInvoiceMerchantDetailsApiResponse>,
      GetInvoiceMerchantDetailsApiArg
    >({
      query: queryArg => ({
        url: `/invoice-merchant-details`,
        params: {
          currency: queryArg.currency,
          country: queryArg.country,
          state: queryArg.state
        }
      })
    }),
    getMerchantForSequenceAccount: build.query<
      ParsedResponse<GetMerchantForSequenceAccountApiResponse>,
      GetMerchantForSequenceAccountApiArg
    >({
      query: queryArg => ({ url: `/merchant-for-sequence-account` })
    }),
    postInvoicesTotals: build.mutation<
      ParsedResponse<PostInvoicesTotalsApiResponse>,
      PostInvoicesTotalsApiArg
    >({
      query: queryArg => ({
        url: `/invoices-totals`,
        method: 'POST',
        body: queryArg.calculateInvoiceTotalsEndpointCalculateInvoiceTotalsRequest
      })
    }),
    getInvoices: build.query<
      ParsedResponse<GetInvoicesApiResponse>,
      GetInvoicesApiArg
    >({
      query: queryArg => ({
        url: `/invoices`,
        params: {
          before: queryArg.before,
          after: queryArg.after,
          limit: queryArg.limit,
          sortOrder: queryArg.sortOrder,
          sortBy: queryArg.sortBy,
          invoiceStatus: queryArg.invoiceStatus,
          invoicePaymentStatus: queryArg.invoicePaymentStatus,
          customerId: queryArg.customerId,
          billingScheduleId: queryArg.billingScheduleId,
          dueBefore: queryArg.dueBefore,
          dueAfter: queryArg.dueAfter,
          sentBefore: queryArg.sentBefore,
          sentAfter: queryArg.sentAfter,
          invoiceBefore: queryArg.invoiceBefore,
          invoiceAfter: queryArg.invoiceAfter,
          excludeZeroQuantity: queryArg.excludeZeroQuantity,
          invoiceCurrency: queryArg.invoiceCurrency
        }
      })
    }),
    postInvoices: build.mutation<
      ParsedResponse<PostInvoicesApiResponse>,
      PostInvoicesApiArg
    >({
      query: queryArg => ({
        url: `/invoices`,
        method: 'POST',
        body: queryArg.createInvoiceEndpointCreateInvoiceRequestModel
      })
    }),
    postInvoicesByInvoiceLineItems: build.mutation<
      ParsedResponse<PostInvoicesByInvoiceLineItemsApiResponse>,
      PostInvoicesByInvoiceLineItemsApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-items`,
        method: 'POST',
        body: queryArg.createOrUpdateLineItemRequestModel
      })
    }),
    postInvoicesByInvoiceLineItemGroups: build.mutation<
      ParsedResponse<PostInvoicesByInvoiceLineItemGroupsApiResponse>,
      PostInvoicesByInvoiceLineItemGroupsApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-item-groups`,
        method: 'POST',
        body: queryArg.createLineItemGroupEndpointCreateLineItemGroupRequestModel
      })
    }),
    getInvoicesById: build.query<
      ParsedResponse<GetInvoicesByIdApiResponse>,
      GetInvoicesByIdApiArg
    >({
      query: queryArg => ({ url: `/invoices/${queryArg.id}` })
    }),
    putInvoicesById: build.mutation<
      ParsedResponse<PutInvoicesByIdApiResponse>,
      PutInvoicesByIdApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateInvoiceEndpointUpdateInvoiceRequestModel
      })
    }),
    putInvoicesByInvoiceLineItemsById: build.mutation<
      ParsedResponse<PutInvoicesByInvoiceLineItemsByIdApiResponse>,
      PutInvoicesByInvoiceLineItemsByIdApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-items/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.createOrUpdateLineItemRequestModel
      })
    }),
    deleteInvoicesByInvoiceLineItemsById: build.mutation<
      ParsedResponse<DeleteInvoicesByInvoiceLineItemsByIdApiResponse>,
      DeleteInvoicesByInvoiceLineItemsByIdApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-items/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    putInvoicesByInvoiceLineItemGroupsById: build.mutation<
      ParsedResponse<PutInvoicesByInvoiceLineItemGroupsByIdApiResponse>,
      PutInvoicesByInvoiceLineItemGroupsByIdApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-item-groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateLineItemGroupEndpointUpdateLineItemGroupRequestModel
      })
    }),
    deleteInvoicesByInvoiceLineItemGroupsById: build.mutation<
      ParsedResponse<DeleteInvoicesByInvoiceLineItemGroupsByIdApiResponse>,
      DeleteInvoicesByInvoiceLineItemGroupsByIdApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoice}/line-item-groups/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    postInvoicesByIdFinalizeAndSend: build.mutation<
      ParsedResponse<PostInvoicesByIdFinalizeAndSendApiResponse>,
      PostInvoicesByIdFinalizeAndSendApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/finalize-and-send`,
        method: 'POST'
      })
    }),
    postInvoicesFinalizeAndSend: build.mutation<
      ParsedResponse<PostInvoicesFinalizeAndSendApiResponse>,
      PostInvoicesFinalizeAndSendApiArg
    >({
      query: queryArg => ({
        url: `/invoices/finalize-and-send`,
        method: 'POST',
        body: queryArg.finaliseAndSendMultipleInvoicesEndpointRequestModel
      })
    }),
    postInvoicesByIdFinalize: build.mutation<
      ParsedResponse<PostInvoicesByIdFinalizeApiResponse>,
      PostInvoicesByIdFinalizeApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/finalize`,
        method: 'POST'
      })
    }),
    postInvoicesFinalize: build.mutation<
      ParsedResponse<PostInvoicesFinalizeApiResponse>,
      PostInvoicesFinalizeApiArg
    >({
      query: queryArg => ({
        url: `/invoices/finalize`,
        method: 'POST',
        body: queryArg.finaliseMultipleInvoicesEndpointRequestModel
      })
    }),
    getInvoicesInvoicesGrid: build.query<
      ParsedResponse<GetInvoicesInvoicesGridApiResponse>,
      GetInvoicesInvoicesGridApiArg
    >({
      query: queryArg => ({
        url: `/invoices/invoices-grid`,
        params: {
          invoiceCurrency: queryArg.invoiceCurrency,
          customerIds: queryArg.customerIds,
          invoiceStatuses: queryArg.invoiceStatuses,
          paymentStatuses: queryArg.paymentStatuses
        }
      })
    }),
    getInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageData: build.query<
      ParsedResponse<GetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataApiResponse>,
      GetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoiceId}/line-item-groups/${queryArg.lineItemGroupId}/usage-data`
      })
    }),
    getInvoicesByIdLineItemGroupsAll: build.query<
      ParsedResponse<GetInvoicesByIdLineItemGroupsAllApiResponse>,
      GetInvoicesByIdLineItemGroupsAllApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/line-item-groups/all`
      })
    }),
    getInvoicesByIdLineItemsAll: build.query<
      ParsedResponse<GetInvoicesByIdLineItemsAllApiResponse>,
      GetInvoicesByIdLineItemsAllApiArg
    >({
      query: queryArg => ({ url: `/invoices/${queryArg.id}/line-items/all` })
    }),
    getInvoicesByInvoiceIdUsageItemGroups: build.query<
      ParsedResponse<GetInvoicesByInvoiceIdUsageItemGroupsApiResponse>,
      GetInvoicesByInvoiceIdUsageItemGroupsApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.invoiceId}/usage-item-groups`
      })
    }),
    postInvoicesByIdRecalculate: build.mutation<
      ParsedResponse<PostInvoicesByIdRecalculateApiResponse>,
      PostInvoicesByIdRecalculateApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/recalculate`,
        method: 'POST'
      })
    }),
    postInvoicesRecalculate: build.mutation<
      ParsedResponse<PostInvoicesRecalculateApiResponse>,
      PostInvoicesRecalculateApiArg
    >({
      query: queryArg => ({
        url: `/invoices/recalculate`,
        method: 'POST',
        body: queryArg.recalculateMultipleInvoicesEndpointRequestModel
      })
    }),
    postInvoicesByIdSend: build.mutation<
      ParsedResponse<PostInvoicesByIdSendApiResponse>,
      PostInvoicesByIdSendApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/send`,
        method: 'POST'
      })
    }),
    postInvoicesByIdSendPaymentReminder: build.mutation<
      ParsedResponse<PostInvoicesByIdSendPaymentReminderApiResponse>,
      PostInvoicesByIdSendPaymentReminderApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/send-payment-reminder`,
        method: 'POST'
      })
    }),
    postInvoicesSend: build.mutation<
      ParsedResponse<PostInvoicesSendApiResponse>,
      PostInvoicesSendApiArg
    >({
      query: queryArg => ({
        url: `/invoices/send`,
        method: 'POST',
        body: queryArg.sendMultipleInvoicesEndpointRequestModel
      })
    }),
    postInvoicesByIdSendByTestEmail: build.mutation<
      ParsedResponse<PostInvoicesByIdSendByTestEmailApiResponse>,
      PostInvoicesByIdSendByTestEmailApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/send/${queryArg.testEmail}`,
        method: 'POST'
      })
    }),
    putInvoicesByIdPaymentStatus: build.mutation<
      ParsedResponse<PutInvoicesByIdPaymentStatusApiResponse>,
      PutInvoicesByIdPaymentStatusApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/payment-status`,
        method: 'PUT',
        body: queryArg.updateInvoicePaymentStatusEndpointRequestModel
      })
    }),
    putInvoicesPaymentStatus: build.mutation<
      ParsedResponse<PutInvoicesPaymentStatusApiResponse>,
      PutInvoicesPaymentStatusApiArg
    >({
      query: queryArg => ({
        url: `/invoices/payment-status`,
        method: 'PUT',
        body: queryArg.updateMultipleInvoicesPaymentStatusEndpointRequestModel
      })
    }),
    postInvoicesByIdVoid: build.mutation<
      ParsedResponse<PostInvoicesByIdVoidApiResponse>,
      PostInvoicesByIdVoidApiArg
    >({
      query: queryArg => ({
        url: `/invoices/${queryArg.id}/void`,
        method: 'POST'
      })
    }),
    postCreditNotesByIdLineItemsFromInvoice: build.mutation<
      ParsedResponse<PostCreditNotesByIdLineItemsFromInvoiceApiResponse>,
      PostCreditNotesByIdLineItemsFromInvoiceApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/line-items/from-invoice`,
        method: 'POST',
        body: queryArg.addCreditNoteLineItemsFromInvoiceEndpointRequestModel
      })
    }),
    postCreditNotesTotals: build.mutation<
      ParsedResponse<PostCreditNotesTotalsApiResponse>,
      PostCreditNotesTotalsApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes-totals`,
        method: 'POST',
        body: queryArg.calculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsRequest
      })
    }),
    getCreditNotes: build.query<
      ParsedResponse<GetCreditNotesApiResponse>,
      GetCreditNotesApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes`,
        params: {
          before: queryArg.before,
          after: queryArg.after,
          limit: queryArg.limit,
          sortOrder: queryArg.sortOrder,
          sortBy: queryArg.sortBy,
          creditNoteStatus: queryArg.creditNoteStatus,
          customerId: queryArg.customerId,
          sentBefore: queryArg.sentBefore,
          sentAfter: queryArg.sentAfter
        }
      })
    }),
    postCreditNotes: build.mutation<
      ParsedResponse<PostCreditNotesApiResponse>,
      PostCreditNotesApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes`,
        method: 'POST',
        body: queryArg.createCreditNoteEndpointCreateCreditNoteRequestModel
      })
    }),
    postCreditNotesByCreditnoteLineItems: build.mutation<
      ParsedResponse<PostCreditNotesByCreditnoteLineItemsApiResponse>,
      PostCreditNotesByCreditnoteLineItemsApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-items`,
        method: 'POST',
        body: queryArg.createCreditNoteLineItemEndpointCreateCreditNoteLineItemRequestModel
      })
    }),
    postCreditNotesByCreditnoteLineItemGroups: build.mutation<
      ParsedResponse<PostCreditNotesByCreditnoteLineItemGroupsApiResponse>,
      PostCreditNotesByCreditnoteLineItemGroupsApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-item-groups`,
        method: 'POST',
        body: queryArg.createCreditNoteLineItemGroupEndpointCreateCreditNoteLineItemGroupRequestModel
      })
    }),
    getCreditNotesById: build.query<
      ParsedResponse<GetCreditNotesByIdApiResponse>,
      GetCreditNotesByIdApiArg
    >({
      query: queryArg => ({ url: `/credit-notes/${queryArg.id}` })
    }),
    putCreditNotesById: build.mutation<
      ParsedResponse<PutCreditNotesByIdApiResponse>,
      PutCreditNotesByIdApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCreditNoteEndpointUpdateCreditNoteRequestModel
      })
    }),
    putCreditNotesByCreditnoteLineItemsById: build.mutation<
      ParsedResponse<PutCreditNotesByCreditnoteLineItemsByIdApiResponse>,
      PutCreditNotesByCreditnoteLineItemsByIdApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-items/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCreditNoteLineItemEndpointUpdateCreditNoteLineItemRequestModel
      })
    }),
    deleteCreditNotesByCreditnoteLineItemsById: build.mutation<
      ParsedResponse<DeleteCreditNotesByCreditnoteLineItemsByIdApiResponse>,
      DeleteCreditNotesByCreditnoteLineItemsByIdApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-items/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    putCreditNotesByCreditnoteLineItemGroupsById: build.mutation<
      ParsedResponse<PutCreditNotesByCreditnoteLineItemGroupsByIdApiResponse>,
      PutCreditNotesByCreditnoteLineItemGroupsByIdApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-item-groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCreditNoteLineItemGroupEndpointUpdateCreditNoteLineItemGroupRequestModel
      })
    }),
    deleteCreditNotesByCreditnoteLineItemGroupsById: build.mutation<
      ParsedResponse<DeleteCreditNotesByCreditnoteLineItemGroupsByIdApiResponse>,
      DeleteCreditNotesByCreditnoteLineItemGroupsByIdApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.creditnote}/line-item-groups/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    postCreditNotesByIdFinalizeAndSend: build.mutation<
      ParsedResponse<PostCreditNotesByIdFinalizeAndSendApiResponse>,
      PostCreditNotesByIdFinalizeAndSendApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/finalize-and-send`,
        method: 'POST'
      })
    }),
    postCreditNotesByIdFinalize: build.mutation<
      ParsedResponse<PostCreditNotesByIdFinalizeApiResponse>,
      PostCreditNotesByIdFinalizeApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/finalize`,
        method: 'POST'
      })
    }),
    getCreditNotesByIdLineItemsAll: build.query<
      ParsedResponse<GetCreditNotesByIdLineItemsAllApiResponse>,
      GetCreditNotesByIdLineItemsAllApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/line-items/all`
      })
    }),
    getCreditNotesByIdLineItemGroupsAll: build.query<
      ParsedResponse<GetCreditNotesByIdLineItemGroupsAllApiResponse>,
      GetCreditNotesByIdLineItemGroupsAllApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/line-item-groups/all`
      })
    }),
    postCreditNotesByIdSend: build.mutation<
      ParsedResponse<PostCreditNotesByIdSendApiResponse>,
      PostCreditNotesByIdSendApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/send`,
        method: 'POST'
      })
    }),
    postCreditNotesByIdSendByTestEmail: build.mutation<
      ParsedResponse<PostCreditNotesByIdSendByTestEmailApiResponse>,
      PostCreditNotesByIdSendByTestEmailApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/send/${queryArg.testEmail}`,
        method: 'POST'
      })
    }),
    postCreditNotesByIdVoid: build.mutation<
      ParsedResponse<PostCreditNotesByIdVoidApiResponse>,
      PostCreditNotesByIdVoidApiArg
    >({
      query: queryArg => ({
        url: `/credit-notes/${queryArg.id}/void`,
        method: 'POST'
      })
    }),
    getInsightsOutstandingInvoices: build.query<
      ParsedResponse<GetInsightsOutstandingInvoicesApiResponse>,
      GetInsightsOutstandingInvoicesApiArg
    >({
      query: queryArg => ({
        url: `/insights/outstanding-invoices`,
        params: { currency: queryArg.currency }
      })
    }),
    getInsightsInvoicedRevenue: build.query<
      ParsedResponse<GetInsightsInvoicedRevenueApiResponse>,
      GetInsightsInvoicedRevenueApiArg
    >({
      query: queryArg => ({
        url: `/insights/invoiced-revenue`,
        params: {
          currency: queryArg.currency,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo
        }
      })
    }),
    postInvoiceSettings: build.mutation<
      ParsedResponse<PostInvoiceSettingsApiResponse>,
      PostInvoiceSettingsApiArg
    >({
      query: queryArg => ({
        url: `/invoice-settings`,
        method: 'POST',
        body: queryArg.createInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel
      })
    }),
    postProducts: build.mutation<
      ParsedResponse<PostProductsApiResponse>,
      PostProductsApiArg
    >({
      query: queryArg => ({
        url: `/products`,
        method: 'POST',
        body: queryArg.productRequest
      })
    }),
    getProductsById: build.query<
      ParsedResponse<GetProductsByIdApiResponse>,
      GetProductsByIdApiArg
    >({
      query: queryArg => ({ url: `/products/${queryArg.id}` })
    }),
    putProductsById: build.mutation<
      ParsedResponse<PutProductsByIdApiResponse>,
      PutProductsByIdApiArg
    >({
      query: queryArg => ({
        url: `/products/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateProductEndpointUpdateProductRequestModel
      })
    }),
    getApiNotificationsPolicies: build.query<
      ParsedResponse<GetApiNotificationsPoliciesApiResponse>,
      GetApiNotificationsPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/notifications/policies`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    postApiNotificationsPolicies: build.mutation<
      ParsedResponse<PostApiNotificationsPoliciesApiResponse>,
      PostApiNotificationsPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/notifications/policies`,
        method: 'POST',
        body: queryArg.createNotificationPolicyRequest
      })
    }),
    deleteApiNotificationsPoliciesId: build.mutation<
      ParsedResponse<DeleteApiNotificationsPoliciesIdApiResponse>,
      DeleteApiNotificationsPoliciesIdApiArg
    >({
      query: queryArg => ({
        url: `/notifications/policies/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    getTaxRates: build.query<
      ParsedResponse<GetTaxRatesApiResponse>,
      GetTaxRatesApiArg
    >({
      query: queryArg => ({
        url: `/tax-rates`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    postTaxRates: build.mutation<
      ParsedResponse<PostTaxRatesApiResponse>,
      PostTaxRatesApiArg
    >({
      query: queryArg => ({
        url: `/tax-rates`,
        method: 'POST',
        body: queryArg.createTaxRateRequest
      })
    }),
    putTaxRatesByIdArchive: build.mutation<
      ParsedResponse<PutTaxRatesByIdArchiveApiResponse>,
      PutTaxRatesByIdArchiveApiArg
    >({
      query: queryArg => ({
        url: `/tax-rates/${queryArg.id}/archive`,
        method: 'PUT'
      })
    }),
    postTaxRegistrations: build.mutation<
      ParsedResponse<PostTaxRegistrationsApiResponse>,
      PostTaxRegistrationsApiArg
    >({
      query: queryArg => ({
        url: `/tax-registrations`,
        method: 'POST',
        body: queryArg.createTaxRegistrationEndpointCreateTaxRegistrationRequestModel
      })
    }),
    getTaxRegistrationsForCustomerById: build.query<
      ParsedResponse<GetTaxRegistrationsForCustomerByIdApiResponse>,
      GetTaxRegistrationsForCustomerByIdApiArg
    >({
      query: queryArg => ({
        url: `/tax-registrations/for-customer/${queryArg.id}`
      })
    }),
    putTaxRegistrationsById: build.mutation<
      ParsedResponse<PutTaxRegistrationsByIdApiResponse>,
      PutTaxRegistrationsByIdApiArg
    >({
      query: queryArg => ({
        url: `/tax-registrations/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel
      })
    }),
    getSequenceAccountsById: build.query<
      ParsedResponse<GetSequenceAccountsByIdApiResponse>,
      GetSequenceAccountsByIdApiArg
    >({
      query: queryArg => ({ url: `/sequence-accounts/${queryArg.id}` })
    }),
    putSequenceAccountsById: build.mutation<
      ParsedResponse<PutSequenceAccountsByIdApiResponse>,
      PutSequenceAccountsByIdApiArg
    >({
      query: queryArg => ({
        url: `/sequence-accounts/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateSequenceAccountProductEndpointUpdateSequenceAccountRequest
      })
    }),
    getUsers: build.query<ParsedResponse<GetUsersApiResponse>, GetUsersApiArg>({
      query: queryArg => ({
        url: `/users`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    getUsersById: build.query<
      ParsedResponse<GetUsersByIdApiResponse>,
      GetUsersByIdApiArg
    >({
      query: queryArg => ({ url: `/users/${queryArg.id}` })
    }),
    deleteUsersById: build.mutation<
      ParsedResponse<DeleteUsersByIdApiResponse>,
      DeleteUsersByIdApiArg
    >({
      query: queryArg => ({ url: `/users/${queryArg.id}`, method: 'DELETE' })
    }),
    getOauthClients: build.query<
      ParsedResponse<GetOauthClientsApiResponse>,
      GetOauthClientsApiArg
    >({
      query: queryArg => ({
        url: `/oauth-clients`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    postOauthClients: build.mutation<
      ParsedResponse<PostOauthClientsApiResponse>,
      PostOauthClientsApiArg
    >({
      query: queryArg => ({
        url: `/oauth-clients`,
        method: 'POST',
        body: queryArg.createOAuthClientRequest
      })
    }),
    deleteOauthClientsById: build.mutation<
      ParsedResponse<DeleteOauthClientsByIdApiResponse>,
      DeleteOauthClientsByIdApiArg
    >({
      query: queryArg => ({
        url: `/oauth-clients/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    getSequenceAccountId: build.query<
      ParsedResponse<GetSequenceAccountIdApiResponse>,
      GetSequenceAccountIdApiArg
    >({
      query: queryArg => ({ url: `/sequence-account-id` })
    }),
    getSequenceAccountSettings: build.query<
      ParsedResponse<GetSequenceAccountSettingsApiResponse>,
      GetSequenceAccountSettingsApiArg
    >({
      query: queryArg => ({ url: `/sequence-account-settings` })
    }),
    putSequenceAccountSettings: build.mutation<
      ParsedResponse<PutSequenceAccountSettingsApiResponse>,
      PutSequenceAccountSettingsApiArg
    >({
      query: queryArg => ({
        url: `/sequence-account-settings`,
        method: 'PUT',
        body: queryArg.updateSequenceAccountSettingsEndpointRequestModel
      })
    }),
    getApiIntegrationsCustomers: build.query<
      ParsedResponse<GetApiIntegrationsCustomersApiResponse>,
      GetApiIntegrationsCustomersApiArg
    >({
      query: queryArg => ({ url: `/integrations/customers` })
    }),
    getApiIntegrationsCustomersService: build.query<
      ParsedResponse<GetApiIntegrationsCustomersServiceApiResponse>,
      GetApiIntegrationsCustomersServiceApiArg
    >({
      query: queryArg => ({
        url: `/integrations/customers/${queryArg.service}`
      })
    }),
    postApiIntegrationsGoogleSheetsExport: build.mutation<
      ParsedResponse<PostApiIntegrationsGoogleSheetsExportApiResponse>,
      PostApiIntegrationsGoogleSheetsExportApiArg
    >({
      query: queryArg => ({
        url: `/integrations/google-sheets/export`,
        method: 'POST',
        body: queryArg.exportDataToGoogleSheetsEndpointRequestModel
      })
    }),
    postApiIntegrationsServiceCustomers: build.mutation<
      ParsedResponse<PostApiIntegrationsServiceCustomersApiResponse>,
      PostApiIntegrationsServiceCustomersApiArg
    >({
      query: queryArg => ({
        url: `/integrations/${queryArg.service}/customers`,
        method: 'POST',
        body: queryArg.syncCustomersFromIntegrationEndpointRequestModel
      })
    }),
    postApiIntegrationsServiceCustomersProgress: build.mutation<
      ParsedResponse<PostApiIntegrationsServiceCustomersProgressApiResponse>,
      PostApiIntegrationsServiceCustomersProgressApiArg
    >({
      query: queryArg => ({
        url: `/integrations/${queryArg.service}/customers/progress`,
        method: 'POST',
        body: queryArg.getCustomerImportFromIntegrationProgressEndpointRequestModel
      })
    }),
    postApiIntegrationsServiceSyncInvoiceInvoiceId: build.mutation<
      ParsedResponse<PostApiIntegrationsServiceSyncInvoiceInvoiceIdApiResponse>,
      PostApiIntegrationsServiceSyncInvoiceInvoiceIdApiArg
    >({
      query: queryArg => ({
        url: `/integrations/${queryArg.service}/sync-invoice/${queryArg.invoiceId}`,
        method: 'POST'
      })
    }),
    getPrices: build.query<
      ParsedResponse<GetPricesApiResponse>,
      GetPricesApiArg
    >({
      query: queryArg => ({
        url: `/prices`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before,
          currency: queryArg.currency,
          name: queryArg.name,
          billingFrequency: queryArg.billingFrequency,
          productId: queryArg.productId
        }
      })
    }),
    getPricesById: build.query<
      ParsedResponse<GetPricesByIdApiResponse>,
      GetPricesByIdApiArg
    >({
      query: queryArg => ({ url: `/prices/${queryArg.id}` })
    }),
    getPricesByIdMetadata: build.query<
      ParsedResponse<GetPricesByIdMetadataApiResponse>,
      GetPricesByIdMetadataApiArg
    >({
      query: queryArg => ({ url: `/prices/${queryArg.id}/metadata` })
    }),
    getCreditsFiltered: build.query<
      ParsedResponse<GetCreditsFilteredApiResponse>,
      GetCreditsFilteredApiArg
    >({
      query: queryArg => ({
        url: `/credits/filtered`,
        params: {
          before: queryArg.before,
          after: queryArg.after,
          limit: queryArg.limit,
          sortOrder: queryArg.sortOrder,
          customerId: queryArg.customerId
        }
      })
    }),
    getCreditsByCustomerIdGrants: build.query<
      ParsedResponse<GetCreditsByCustomerIdGrantsApiResponse>,
      GetCreditsByCustomerIdGrantsApiArg
    >({
      query: queryArg => ({ url: `/credits/${queryArg.customerId}/grants` })
    }),
    getCreditsByCustomerIdBalances: build.query<
      ParsedResponse<GetCreditsByCustomerIdBalancesApiResponse>,
      GetCreditsByCustomerIdBalancesApiArg
    >({
      query: queryArg => ({
        url: `/credits/${queryArg.customerId}/balances`
      })
    }),
    postCredits: build.mutation<
      ParsedResponse<PostCreditsApiResponse>,
      PostCreditsApiArg
    >({
      query: queryArg => ({
        url: `/credits`,
        method: 'POST',
        body: queryArg.createCreditGrantEndpointCreateCreditGrantRequestModel
      })
    }),
    getInsightsCustomerTotals: build.query<
      ParsedResponse<GetInsightsCustomerTotalsApiResponse>,
      GetInsightsCustomerTotalsApiArg
    >({
      query: queryArg => ({ url: `/insights/customer-totals` })
    }),
    getCustomersById: build.query<
      ParsedResponse<GetCustomersByIdApiResponse>,
      GetCustomersByIdApiArg
    >({
      query: queryArg => ({ url: `/customers/${queryArg.id}` })
    }),
    putCustomersById: build.mutation<
      ParsedResponse<PutCustomersByIdApiResponse>,
      PutCustomersByIdApiArg
    >({
      query: queryArg => ({
        url: `/customers/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateCustomerRequest
      })
    }),
    getCustomers: build.query<
      ParsedResponse<GetCustomersApiResponse>,
      GetCustomersApiArg
    >({
      query: queryArg => ({
        url: `/customers`,
        params: {
          sortBy: queryArg.sortBy,
          legalName: queryArg.legalName,
          email: queryArg.email,
          alias: queryArg.alias,
          includeArchived: queryArg.includeArchived,
          before: queryArg.before,
          after: queryArg.after,
          limit: queryArg.limit,
          sortOrder: queryArg.sortOrder
        }
      })
    }),
    putCustomersByIdArchive: build.mutation<
      ParsedResponse<PutCustomersByIdArchiveApiResponse>,
      PutCustomersByIdArchiveApiArg
    >({
      query: queryArg => ({
        url: `/customers/${queryArg.id}/archive`,
        method: 'PUT'
      })
    }),
    getCustomersByAliasByAlias: build.query<
      ParsedResponse<GetCustomersByAliasByAliasApiResponse>,
      GetCustomersByAliasByAliasApiArg
    >({
      query: queryArg => ({ url: `/customers/by-alias/${queryArg.alias}` })
    }),
    getCustomersByIdAliases: build.query<
      ParsedResponse<GetCustomersByIdAliasesApiResponse>,
      GetCustomersByIdAliasesApiArg
    >({
      query: queryArg => ({ url: `/customers/${queryArg.id}/aliases` })
    }),
    getCustomerAliases: build.query<
      ParsedResponse<GetCustomerAliasesApiResponse>,
      GetCustomerAliasesApiArg
    >({
      query: queryArg => ({
        url: `/customer-aliases`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    postCustomerAliases: build.mutation<
      ParsedResponse<PostCustomerAliasesApiResponse>,
      PostCustomerAliasesApiArg
    >({
      query: queryArg => ({
        url: `/customer-aliases`,
        method: 'POST',
        body: queryArg.createCustomerAliasEndpointCreateCustomerAliasRequestModel
      })
    }),
    getUsageMetrics: build.query<
      ParsedResponse<GetUsageMetricsApiResponse>,
      GetUsageMetricsApiArg
    >({
      query: queryArg => ({
        url: `/usage-metrics`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    getUsageMetricsByUsageMetricIdCalculate: build.query<
      ParsedResponse<GetUsageMetricsByUsageMetricIdCalculateApiResponse>,
      GetUsageMetricsByUsageMetricIdCalculateApiArg
    >({
      query: queryArg => ({
        url: `/usage-metrics/${queryArg.usageMetricId}/calculate`,
        params: {
          customerAliases: queryArg.customerAliases,
          periodStart: queryArg.periodStart,
          periodEnd: queryArg.periodEnd,
          customParameters: queryArg.customParameters
        }
      })
    }),
    getUniqueUsageEventTypes: build.query<
      ParsedResponse<GetUniqueUsageEventTypesApiResponse>,
      GetUniqueUsageEventTypesApiArg
    >({
      query: queryArg => ({
        url: `/unique-usage-event-types`,
        params: { search: queryArg.search }
      })
    }),
    getUniqueUsageEventProperties: build.query<
      ParsedResponse<GetUniqueUsageEventPropertiesApiResponse>,
      GetUniqueUsageEventPropertiesApiArg
    >({
      query: queryArg => ({
        url: `/unique-usage-event-properties`,
        params: { search: queryArg.search }
      })
    }),
    getInsightsUsageEvents: build.query<
      ParsedResponse<GetInsightsUsageEventsApiResponse>,
      GetInsightsUsageEventsApiArg
    >({
      query: queryArg => ({
        url: `/insights/usage-events`,
        params: {
          eventType: queryArg.eventType,
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo
        }
      })
    }),
    getInsightsUsageMetricsByUsageMetricIdUsageData: build.query<
      ParsedResponse<GetInsightsUsageMetricsByUsageMetricIdUsageDataApiResponse>,
      GetInsightsUsageMetricsByUsageMetricIdUsageDataApiArg
    >({
      query: queryArg => ({
        url: `/insights/usage-metrics/${queryArg.usageMetricId}/usage-data`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo }
      })
    }),
    getSeatSnapshots: build.query<
      ParsedResponse<GetSeatSnapshotsApiResponse>,
      GetSeatSnapshotsApiArg
    >({
      query: queryArg => ({
        url: `/seat-snapshots`,
        params: {
          before: queryArg.before,
          after: queryArg.after,
          sortOrder: queryArg.sortOrder,
          limit: queryArg.limit,
          customerAlias: queryArg.customerAlias,
          seatType: queryArg.seatType,
          customerSnapshotId: queryArg.customerSnapshotId,
          snapshotTimestampBefore: queryArg.snapshotTimestampBefore,
          snapshotTimestampAfter: queryArg.snapshotTimestampAfter,
          sortBy: queryArg.sortBy
        }
      })
    }),
    postSeatSnapshots: build.mutation<
      ParsedResponse<PostSeatSnapshotsApiResponse>,
      PostSeatSnapshotsApiArg
    >({
      query: queryArg => ({
        url: `/seat-snapshots`,
        method: 'POST',
        body: queryArg.createSeatSnapshotEndpointCreateSeatSnapshotRequestModel
      })
    }),
    getSeatTypes: build.query<
      ParsedResponse<GetSeatTypesApiResponse>,
      GetSeatTypesApiArg
    >({
      query: queryArg => ({
        url: `/seat-types`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before
        }
      })
    }),
    postSeatTypes: build.mutation<
      ParsedResponse<PostSeatTypesApiResponse>,
      PostSeatTypesApiArg
    >({
      query: queryArg => ({
        url: `/seat-types`,
        method: 'POST',
        body: queryArg.createSeatTypeEndpointCreateSeatTypeRequestModel
      })
    }),
    getSeatTypesById: build.query<
      ParsedResponse<GetSeatTypesByIdApiResponse>,
      GetSeatTypesByIdApiArg
    >({
      query: queryArg => ({ url: `/seat-types/${queryArg.id}` })
    }),
    getLatestSeatTotals: build.query<
      ParsedResponse<GetLatestSeatTotalsApiResponse>,
      GetLatestSeatTotalsApiArg
    >({
      query: queryArg => ({
        url: `/latest-seat-totals`,
        params: {
          limit: queryArg.limit,
          after: queryArg.after,
          before: queryArg.before,
          customerAliases: queryArg.customerAliases,
          seatTypes: queryArg.seatTypes
        }
      })
    }),
    getSeatBalances: build.query<
      ParsedResponse<GetSeatBalancesApiResponse>,
      GetSeatBalancesApiArg
    >({
      query: queryArg => ({
        url: `/seat-balances`,
        params: {
          excludeZeroQuantity: queryArg.excludeZeroQuantity,
          customerAliases: queryArg.customerAliases
        }
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as integratedApi }
export type DeleteLinkedEntitiesByTypeByIdByServiceApiResponse =
  /** status 200 OK */ LinkedEntity
export type DeleteLinkedEntitiesByTypeByIdByServiceApiArg = {
  /** Entity type */
  type: string
  /** Sequence entity ID */
  id: string
  /** Integration service */
  service: string
}
export type GetInvoiceMerchantDetailsApiResponse =
  /** status 200 OK */ InvoiceMerchantDetails
export type GetInvoiceMerchantDetailsApiArg = {
  /** Invoice currency */
  currency: string
  /** Customer country */
  country: string
  /** Customer state */
  state?: string
}
export type GetMerchantForSequenceAccountApiResponse =
  /** status 200 OK */ Merchant
export type GetMerchantForSequenceAccountApiArg = void
export type PostInvoicesTotalsApiResponse =
  /** status 200 OK */ CalculateInvoiceTotalsEndpointCalculateInvoiceTotalsResponse
export type PostInvoicesTotalsApiArg = {
  calculateInvoiceTotalsEndpointCalculateInvoiceTotalsRequest: CalculateInvoiceTotalsEndpointCalculateInvoiceTotalsRequest
}
export type GetInvoicesApiResponse =
  /** status 200 OK */ ListInvoiceEndpointListInvoicePaginatedResponseModel
export type GetInvoicesApiArg = {
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  before?: string
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Sort order. Default: DESC (descending, most often used for reverse chronological sorting). */
  sortOrder?: string
  /** Sort column. Default: creation time. Options: CUSTOMER_LEGAL_COMPANY_NAME, GROSS_TOTAL, ACCOUNTING_DATE */
  sortBy?: string
  /** Filter by invoice status. Options: IN_PROGRESS, DRAFT, FINAL, SENT, VOIDED */
  invoiceStatus?: string
  /** Filter by payment status. Options: UNPAID, PARTIALLY_PAID, PAID, UNCOLLECTIBLE */
  invoicePaymentStatus?: string
  /** Filter by customer id. */
  customerId?: string
  /** Filter by billing schedule id. */
  billingScheduleId?: string
  /** Filter by due date before. Format: yyyy-MM-dd */
  dueBefore?: string
  /** Filter by due date after. Format: yyyy-MM-dd */
  dueAfter?: string
  /** Filter by sent date before. Format: yyyy-MM-dd */
  sentBefore?: string
  /** Filter by sent date after. Format: yyyy-MM-dd */
  sentAfter?: string
  /** Filter by invoice date before. Format: yyyy-MM-dd */
  invoiceBefore?: string
  /** Filter by invoice date after. Format: yyyy-MM-dd */
  invoiceAfter?: string
  /** Exclude invoices with zero quantity. */
  excludeZeroQuantity?: boolean
  /** Get invoices for a specific currency (provide a comma-separated list) */
  invoiceCurrency?: string
}
export type PostInvoicesApiResponse =
  /** status 201 Created */ CreateInvoiceEndpointProductCreateInvoiceResponseModel
export type PostInvoicesApiArg = {
  createInvoiceEndpointCreateInvoiceRequestModel: CreateInvoiceEndpointCreateInvoiceRequestModel
}
export type PostInvoicesByInvoiceLineItemsApiResponse =
  /** status 201 Created */ LineItemResponseModel
export type PostInvoicesByInvoiceLineItemsApiArg = {
  /** The Invoice ID */
  invoice: string
  createOrUpdateLineItemRequestModel: CreateOrUpdateLineItemRequestModel
}
export type PostInvoicesByInvoiceLineItemGroupsApiResponse =
  /** status 201 Created */ CreateLineItemGroupEndpointProductCreateLineItemGroupResponseModel
export type PostInvoicesByInvoiceLineItemGroupsApiArg = {
  /** The Invoice ID */
  invoice: string
  createLineItemGroupEndpointCreateLineItemGroupRequestModel: CreateLineItemGroupEndpointCreateLineItemGroupRequestModel
}
export type GetInvoicesByIdApiResponse =
  /** status 200 OK */ GetInvoiceEndpointProductGetInvoiceResponseModel
export type GetInvoicesByIdApiArg = {
  /** Invoice ID */
  id: string
}
export type PutInvoicesByIdApiResponse =
  /** status 200 OK */ UpdateInvoiceEndpointProductUpdateInvoiceResponseModel
export type PutInvoicesByIdApiArg = {
  /** Invoice ID */
  id: string
  updateInvoiceEndpointUpdateInvoiceRequestModel: UpdateInvoiceEndpointUpdateInvoiceRequestModel
}
export type PutInvoicesByInvoiceLineItemsByIdApiResponse =
  /** status 200 OK */ LineItemResponseModel
export type PutInvoicesByInvoiceLineItemsByIdApiArg = {
  /** The Invoice ID */
  invoice: string
  /** Line Item ID */
  id: string
  createOrUpdateLineItemRequestModel: CreateOrUpdateLineItemRequestModel
}
export type DeleteInvoicesByInvoiceLineItemsByIdApiResponse =
  /** status 200 OK */ LineItemResponseModel
export type DeleteInvoicesByInvoiceLineItemsByIdApiArg = {
  /** The Invoice ID */
  invoice: string
  /** Line Item ID */
  id: string
}
export type PutInvoicesByInvoiceLineItemGroupsByIdApiResponse =
  /** status 200 OK */ UpdateLineItemGroupEndpointProductUpdateLineItemGroupResponseModel
export type PutInvoicesByInvoiceLineItemGroupsByIdApiArg = {
  /** The Invoice ID */
  invoice: string
  /** Line Item Group ID */
  id: string
  updateLineItemGroupEndpointUpdateLineItemGroupRequestModel: UpdateLineItemGroupEndpointUpdateLineItemGroupRequestModel
}
export type DeleteInvoicesByInvoiceLineItemGroupsByIdApiResponse =
  /** status 200 OK */ DeleteLineItemGroupEndpointProductDeleteLineItemGroupResponseModel
export type DeleteInvoicesByInvoiceLineItemGroupsByIdApiArg = {
  /** The Invoice ID */
  invoice: string
  /** Line Item Group ID */
  id: string
}
export type PostInvoicesByIdFinalizeAndSendApiResponse =
  /** status 200 OK */ Invoice1
export type PostInvoicesByIdFinalizeAndSendApiArg = {
  /** Invoice Id */
  id: string
}
export type PostInvoicesFinalizeAndSendApiResponse = unknown
export type PostInvoicesFinalizeAndSendApiArg = {
  finaliseAndSendMultipleInvoicesEndpointRequestModel: FinaliseAndSendMultipleInvoicesEndpointRequestModel
}
export type PostInvoicesByIdFinalizeApiResponse = /** status 200 OK */ Invoice1
export type PostInvoicesByIdFinalizeApiArg = {
  /** Invoice Id */
  id: string
}
export type PostInvoicesFinalizeApiResponse = unknown
export type PostInvoicesFinalizeApiArg = {
  finaliseMultipleInvoicesEndpointRequestModel: FinaliseMultipleInvoicesEndpointRequestModel
}
export type GetInvoicesInvoicesGridApiResponse =
  /** status 200 OK */ InvoicesGrid
export type GetInvoicesInvoicesGridApiArg = {
  /** Get invoices grid for a specific currency (cannot mix currencies) */
  invoiceCurrency: string
  /** Filter invoices by one or many comma-separated customer IDs */
  customerIds?: string
  /** Filter invoices by one or many comma-separated statuses */
  invoiceStatuses?: string
  /** Filter invoices by one or many comma-separated payment statuses */
  paymentStatuses?: string
}
export type GetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataApiResponse =
  /** status 200 OK */ OptionalUsageDataResponse
export type GetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataApiArg =
  {
    /** Invoice ID */
    invoiceId: string
    /** Line item group ID */
    lineItemGroupId: string
  }
export type GetInvoicesByIdLineItemGroupsAllApiResponse =
  /** status 200 OK */ ListAllLineItemGroupsUnpaginatedEndpointListAllLineItemGroupsResponseModel
export type GetInvoicesByIdLineItemGroupsAllApiArg = {
  /** Invoice Id */
  id: string
}
export type GetInvoicesByIdLineItemsAllApiResponse =
  /** status 200 OK */ ListAllLineItemsUnpaginatedEndpointListAllLineItemsResponseModel
export type GetInvoicesByIdLineItemsAllApiArg = {
  /** Invoice Id */
  id: string
}
export type GetInvoicesByInvoiceIdUsageItemGroupsApiResponse =
  /** status 200 OK */ ListUsageItemGroupEndpointListUsageItemGroupResponseModel
export type GetInvoicesByInvoiceIdUsageItemGroupsApiArg = {
  /** invoiceId */
  invoiceId: string
}
export type PostInvoicesByIdRecalculateApiResponse =
  /** status 200 OK */ Invoice1
export type PostInvoicesByIdRecalculateApiArg = {
  /** Invoice ID */
  id: string
}
export type PostInvoicesRecalculateApiResponse = unknown
export type PostInvoicesRecalculateApiArg = {
  recalculateMultipleInvoicesEndpointRequestModel: RecalculateMultipleInvoicesEndpointRequestModel
}
export type PostInvoicesByIdSendApiResponse = /** status 200 OK */ Invoice1
export type PostInvoicesByIdSendApiArg = {
  /** Invoice Id */
  id: string
}
export type PostInvoicesByIdSendPaymentReminderApiResponse =
  /** status 200 OK */ Invoice1
export type PostInvoicesByIdSendPaymentReminderApiArg = {
  /** Invoice ID */
  id: string
}
export type PostInvoicesSendApiResponse = unknown
export type PostInvoicesSendApiArg = {
  sendMultipleInvoicesEndpointRequestModel: SendMultipleInvoicesEndpointRequestModel
}
export type PostInvoicesByIdSendByTestEmailApiResponse =
  /** status 200 OK */ Invoice1
export type PostInvoicesByIdSendByTestEmailApiArg = {
  /** Invoice Id */
  id: string
  /** Test email address */
  testEmail: string
}
export type PutInvoicesByIdPaymentStatusApiResponse =
  /** status 200 OK */ Invoice1
export type PutInvoicesByIdPaymentStatusApiArg = {
  /** Invoice ID */
  id: string
  updateInvoicePaymentStatusEndpointRequestModel: UpdateInvoicePaymentStatusEndpointRequestModel
}
export type PutInvoicesPaymentStatusApiResponse = unknown
export type PutInvoicesPaymentStatusApiArg = {
  updateMultipleInvoicesPaymentStatusEndpointRequestModel: UpdateMultipleInvoicesPaymentStatusEndpointRequestModel
}
export type PostInvoicesByIdVoidApiResponse = /** status 200 OK */ Invoice1
export type PostInvoicesByIdVoidApiArg = {
  /** Invoice ID */
  id: string
}
export type PostCreditNotesByIdLineItemsFromInvoiceApiResponse = unknown
export type PostCreditNotesByIdLineItemsFromInvoiceApiArg = {
  /** Credit Note Id */
  id: string
  addCreditNoteLineItemsFromInvoiceEndpointRequestModel: AddCreditNoteLineItemsFromInvoiceEndpointRequestModel
}
export type PostCreditNotesTotalsApiResponse =
  /** status 200 OK */ CalculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsResponse
export type PostCreditNotesTotalsApiArg = {
  calculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsRequest: CalculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsRequest
}
export type GetCreditNotesApiResponse =
  /** status 200 OK */ ListCreditNoteEndpointProductListCreditNotePaginatedResponseModel
export type GetCreditNotesApiArg = {
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  before?: string
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Sort order. Default: DESC (descending, most often used for reverse chronological sorting). */
  sortOrder?: string
  /** Sort column. Default: creation time. Options: GROSS_TOTAL, CREDIT_NOTE_NUMBER, STATUS */
  sortBy?: string
  /** Filter by credit note status. Options: DRAFT, FINAL, SENT, VOIDED */
  creditNoteStatus?: string
  /** Filter by customer id. */
  customerId?: string
  /** Filter by sent date before. Format: yyyy-MM-dd */
  sentBefore?: string
  /** Filter by sent date after. Format: yyyy-MM-dd */
  sentAfter?: string
}
export type PostCreditNotesApiResponse =
  /** status 201 Created */ CreateCreditNoteEndpointProductCreateCreditNoteResponseModel
export type PostCreditNotesApiArg = {
  createCreditNoteEndpointCreateCreditNoteRequestModel: CreateCreditNoteEndpointCreateCreditNoteRequestModel
}
export type PostCreditNotesByCreditnoteLineItemsApiResponse =
  /** status 201 Created */ CreateCreditNoteLineItemEndpointProductCreateCreditNoteLineItemResponseModel
export type PostCreditNotesByCreditnoteLineItemsApiArg = {
  /** The CreditNote ID */
  creditnote: string
  createCreditNoteLineItemEndpointCreateCreditNoteLineItemRequestModel: CreateCreditNoteLineItemEndpointCreateCreditNoteLineItemRequestModel
}
export type PostCreditNotesByCreditnoteLineItemGroupsApiResponse =
  /** status 201 Created */ CreateCreditNoteLineItemGroupEndpointProductCreateCreditNoteLineItemGroupResponseModel
export type PostCreditNotesByCreditnoteLineItemGroupsApiArg = {
  /** The CreditNote ID */
  creditnote: string
  createCreditNoteLineItemGroupEndpointCreateCreditNoteLineItemGroupRequestModel: CreateCreditNoteLineItemGroupEndpointCreateCreditNoteLineItemGroupRequestModel
}
export type GetCreditNotesByIdApiResponse =
  /** status 200 OK */ GetCreditNoteEndpointProductGetCreditNoteResponseModel
export type GetCreditNotesByIdApiArg = {
  /** Credit Note ID */
  id: string
}
export type PutCreditNotesByIdApiResponse =
  /** status 200 OK */ UpdateCreditNoteEndpointProductUpdateCreditNoteResponseModel
export type PutCreditNotesByIdApiArg = {
  /** Credit Note ID */
  id: string
  updateCreditNoteEndpointUpdateCreditNoteRequestModel: UpdateCreditNoteEndpointUpdateCreditNoteRequestModel
}
export type PutCreditNotesByCreditnoteLineItemsByIdApiResponse =
  /** status 200 OK */ UpdateCreditNoteLineItemEndpointProductUpdateCreditNoteLineItemResponseModel
export type PutCreditNotesByCreditnoteLineItemsByIdApiArg = {
  /** The CreditNote ID */
  creditnote: string
  /** Credit Note Line Item ID */
  id: string
  updateCreditNoteLineItemEndpointUpdateCreditNoteLineItemRequestModel: UpdateCreditNoteLineItemEndpointUpdateCreditNoteLineItemRequestModel
}
export type DeleteCreditNotesByCreditnoteLineItemsByIdApiResponse =
  /** status 200 OK */ DeleteCreditNoteLineItemEndpointProductDeleteCreditNoteLineItemResponseModel
export type DeleteCreditNotesByCreditnoteLineItemsByIdApiArg = {
  /** The CreditNote ID */
  creditnote: string
  /** Credit Note Line Item ID */
  id: string
}
export type PutCreditNotesByCreditnoteLineItemGroupsByIdApiResponse =
  /** status 200 OK */ UpdateCreditNoteLineItemGroupEndpointProductUpdateCreditNoteLineItemGroupResponseModel
export type PutCreditNotesByCreditnoteLineItemGroupsByIdApiArg = {
  /** The CreditNote ID */
  creditnote: string
  /** Credit Note Line Item Group ID */
  id: string
  updateCreditNoteLineItemGroupEndpointUpdateCreditNoteLineItemGroupRequestModel: UpdateCreditNoteLineItemGroupEndpointUpdateCreditNoteLineItemGroupRequestModel
}
export type DeleteCreditNotesByCreditnoteLineItemGroupsByIdApiResponse =
  /** status 200 OK */ DeleteCreditNoteLineItemGroupEndpointProductDeleteCreditNoteLineItemGroupResponseModel
export type DeleteCreditNotesByCreditnoteLineItemGroupsByIdApiArg = {
  /** The CreditNote ID */
  creditnote: string
  /** Credit Note Line Item Group ID */
  id: string
}
export type PostCreditNotesByIdFinalizeAndSendApiResponse =
  /** status 200 OK */ CreditNote
export type PostCreditNotesByIdFinalizeAndSendApiArg = {
  /** Credit Note Id */
  id: string
}
export type PostCreditNotesByIdFinalizeApiResponse =
  /** status 200 OK */ CreditNote
export type PostCreditNotesByIdFinalizeApiArg = {
  /** Credit Note Id */
  id: string
}
export type GetCreditNotesByIdLineItemsAllApiResponse =
  /** status 200 OK */ ListAllCreditNoteLineItemsUnpaginatedEndpointListAllLineItemsResponseModel
export type GetCreditNotesByIdLineItemsAllApiArg = {
  /** Credit Note Id */
  id: string
}
export type GetCreditNotesByIdLineItemGroupsAllApiResponse =
  /** status 200 OK */ ListAllCreditNoteLineItemGroupsUnpaginatedEndpointListAllCreditNoteLineItemGroupsResponseModel
export type GetCreditNotesByIdLineItemGroupsAllApiArg = {
  /** Credit Note Id */
  id: string
}
export type PostCreditNotesByIdSendApiResponse = /** status 200 OK */ CreditNote
export type PostCreditNotesByIdSendApiArg = {
  /** Credit Note Id */
  id: string
}
export type PostCreditNotesByIdSendByTestEmailApiResponse =
  /** status 200 OK */ CreditNote
export type PostCreditNotesByIdSendByTestEmailApiArg = {
  /** Credit Note Id */
  id: string
  /** Test email address */
  testEmail: string
}
export type PostCreditNotesByIdVoidApiResponse = /** status 200 OK */ CreditNote
export type PostCreditNotesByIdVoidApiArg = {
  /** Credit Note ID */
  id: string
}
export type GetInsightsOutstandingInvoicesApiResponse =
  /** status 200 OK */ GetOutstandingInvoicesResponseModel
export type GetInsightsOutstandingInvoicesApiArg = {
  /** Filter by currency */
  currency: string
}
export type GetInsightsInvoicedRevenueApiResponse =
  /** status 200 OK */ GetInvoicedRevenueResponseModel
export type GetInsightsInvoicedRevenueApiArg = {
  /** Filter by currency */
  currency: string
  /** Start date (inclusive) - must be the first day of the given month */
  dateFrom: string
  /** End date (inclusive) */
  dateTo: string
}
export type PostInvoiceSettingsApiResponse =
  /** status 201 Created */ CreateInvoiceSettingsEndpointProductCreateInvoiceSettingsResponseModel
export type PostInvoiceSettingsApiArg = {
  createInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel: CreateInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel
}
export type PostProductsApiResponse = /** status 201 Created */ ProductResponse
export type PostProductsApiArg = {
  productRequest: ProductRequest
}
export type GetProductsByIdApiResponse = /** status 200 OK */ ProductResponse
export type GetProductsByIdApiArg = {
  /** Unique Product ID */
  id: string
}
export type PutProductsByIdApiResponse = /** status 200 OK */ ProductResponse
export type PutProductsByIdApiArg = {
  /** Unique Product ID */
  id: string
  updateProductEndpointUpdateProductRequestModel: UpdateProductEndpointUpdateProductRequestModel
}
export type GetApiNotificationsPoliciesApiResponse =
  /** status 200 OK */ NotificationPolicies
export type GetApiNotificationsPoliciesApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type PostApiNotificationsPoliciesApiResponse =
  /** status 201 Created */ NotificationPolicy
export type PostApiNotificationsPoliciesApiArg = {
  createNotificationPolicyRequest: CreateNotificationPolicyRequest
}
export type DeleteApiNotificationsPoliciesIdApiResponse =
  /** status 200 OK */ NotificationPolicy
export type DeleteApiNotificationsPoliciesIdApiArg = {
  /** Notification policy ID */
  id: string
}
export type GetTaxRatesApiResponse =
  /** status 200 OK */ ListTaxRateEndpointProductListTaxRatePaginatedResponseModel
export type GetTaxRatesApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type PostTaxRatesApiResponse =
  /** status 201 Created */ CreateTaxRateEndpointProductCreateTaxRateResponseModel
export type PostTaxRatesApiArg = {
  createTaxRateRequest: CreateTaxRateRequest
}
export type PutTaxRatesByIdArchiveApiResponse =
  /** status 200 OK */ ArchiveTaxRateEndpointProductArchiveTaxRateResponseModel
export type PutTaxRatesByIdArchiveApiArg = {
  /** TaxRate ID */
  id: string
}
export type PostTaxRegistrationsApiResponse =
  /** status 201 Created */ CreateTaxRegistrationEndpointProductCreateTaxRegistrationResponseModel
export type PostTaxRegistrationsApiArg = {
  createTaxRegistrationEndpointCreateTaxRegistrationRequestModel: CreateTaxRegistrationEndpointCreateTaxRegistrationRequestModel
}
export type GetTaxRegistrationsForCustomerByIdApiResponse =
  /** status 200 OK */ GetTaxRegistrationForCustomerProductResponse
export type GetTaxRegistrationsForCustomerByIdApiArg = {
  /** Customer ID */
  id: string
}
export type PutTaxRegistrationsByIdApiResponse =
  /** status 200 OK */ UpdateTaxRegistrationEndpointProductUpdateTaxRegistrationResponseModel
export type PutTaxRegistrationsByIdApiArg = {
  /** Tax registration ID */
  id: string
  updateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel: UpdateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel
}
export type GetSequenceAccountsByIdApiResponse =
  /** status 200 OK */ SequenceAccount
export type GetSequenceAccountsByIdApiArg = {
  /** Sequence account ID */
  id: string
}
export type PutSequenceAccountsByIdApiResponse =
  /** status 200 OK */ SequenceAccount
export type PutSequenceAccountsByIdApiArg = {
  /** Sequence Account ID */
  id: string
  updateSequenceAccountProductEndpointUpdateSequenceAccountRequest: UpdateSequenceAccountProductEndpointUpdateSequenceAccountRequest
}
export type GetUsersApiResponse = /** status 200 OK */ ListSequenceUsersResponse
export type GetUsersApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type GetUsersByIdApiResponse = /** status 200 OK */ SequenceUserResponse
export type GetUsersByIdApiArg = {
  /** Sequence User ID */
  id: string
}
export type DeleteUsersByIdApiResponse =
  /** status 200 OK */ SequenceUserResponse
export type DeleteUsersByIdApiArg = {
  /** Sequence User ID */
  id: string
}
export type GetOauthClientsApiResponse =
  /** status 200 OK */ ListOAuthClientsEndpointResponseModel
export type GetOauthClientsApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type PostOauthClientsApiResponse =
  /** status 201 Created */ CreateOAuthClientResponse
export type PostOauthClientsApiArg = {
  createOAuthClientRequest: CreateOAuthClientRequest
}
export type DeleteOauthClientsByIdApiResponse = /** status 200 OK */ OAuthClient
export type DeleteOauthClientsByIdApiArg = {
  /** Client ID */
  id: string
}
export type GetSequenceAccountIdApiResponse =
  /** status 200 OK */ GetSequenceAccountIdEndpointGetSequenceAccountIdResponse
export type GetSequenceAccountIdApiArg = void
export type GetSequenceAccountSettingsApiResponse =
  /** status 200 OK */ SequenceAccountSettings
export type GetSequenceAccountSettingsApiArg = void
export type PutSequenceAccountSettingsApiResponse =
  /** status 200 OK */ SequenceAccountSettings
export type PutSequenceAccountSettingsApiArg = {
  updateSequenceAccountSettingsEndpointRequestModel: UpdateSequenceAccountSettingsEndpointRequestModel
}
export type GetApiIntegrationsCustomersApiResponse =
  /** status 200 OK */ GetIntegrationCustomersEndpointResponseModel
export type GetApiIntegrationsCustomersApiArg = void
export type GetApiIntegrationsCustomersServiceApiResponse =
  /** status 200 OK */ GetIntegrationCustomersForServiceEndpointResponseModel
export type GetApiIntegrationsCustomersServiceApiArg = {
  /** Integration service */
  service: string
}
export type PostApiIntegrationsGoogleSheetsExportApiResponse = unknown
export type PostApiIntegrationsGoogleSheetsExportApiArg = {
  exportDataToGoogleSheetsEndpointRequestModel: ExportDataToGoogleSheetsEndpointRequestModel
}
export type PostApiIntegrationsServiceCustomersApiResponse =
  /** status 201 Created */ SyncCustomersFromIntegrationEndpointResponseModel
export type PostApiIntegrationsServiceCustomersApiArg = {
  /** Integration service */
  service: string
  syncCustomersFromIntegrationEndpointRequestModel: SyncCustomersFromIntegrationEndpointRequestModel
}
export type PostApiIntegrationsServiceCustomersProgressApiResponse =
  /** status 200 OK */ GetCustomerImportFromIntegrationProgressEndpointResponseModel
export type PostApiIntegrationsServiceCustomersProgressApiArg = {
  /** Integration service */
  service: string
  getCustomerImportFromIntegrationProgressEndpointRequestModel: GetCustomerImportFromIntegrationProgressEndpointRequestModel
}
export type PostApiIntegrationsServiceSyncInvoiceInvoiceIdApiResponse = unknown
export type PostApiIntegrationsServiceSyncInvoiceInvoiceIdApiArg = {
  /** Integration service */
  service: string
  /** Invoice Id */
  invoiceId: string
}
export type GetPricesApiResponse =
  /** status 200 OK */ ListPriceEndpointProductListPricePaginatedResponseModel
export type GetPricesApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
  /** Filter by currency. */
  currency?: string
  /** Filter by name. */
  name?: string
  /** Filter by billing frequency. */
  billingFrequency?: string
  /** Filter by product ID. */
  productId?: string
}
export type GetPricesByIdApiResponse =
  /** status 200 OK */ GetPriceEndpointProductGetPriceResponseModel
export type GetPricesByIdApiArg = {
  /** Unique Price ID */
  id: string
}
export type GetPricesByIdMetadataApiResponse =
  /** status 200 OK */ PriceMetadata
export type GetPricesByIdMetadataApiArg = {
  /** Unique price ID */
  id: string
}
export type GetCreditsFilteredApiResponse =
  /** status 200 OK */ ListByQueryIdCreditGrantEndpointProductListByQueryIdCreditGrantPaginatedResponseModel
export type GetCreditsFilteredApiArg = {
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  before?: string
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Sort order. Default: DESC (descending, most often used for reverse chronological sorting). */
  sortOrder?: string
  /** The id to filter by */
  customerId: string
}
export type GetCreditsByCustomerIdGrantsApiResponse =
  /** status 200 OK */ ListCreditGrantsForCustomerEndpointCreditGrantsResponse
export type GetCreditsByCustomerIdGrantsApiArg = {
  /** Customer ID */
  customerId: string
}
export type GetCreditsByCustomerIdBalancesApiResponse =
  /** status 200 OK */ CreditBalancesReply
export type GetCreditsByCustomerIdBalancesApiArg = {
  /** Customer ID */
  customerId: string
}
export type PostCreditsApiResponse =
  /** status 201 Created */ CreateCreditGrantEndpointProductCreateCreditGrantResponseModel
export type PostCreditsApiArg = {
  createCreditGrantEndpointCreateCreditGrantRequestModel: CreateCreditGrantEndpointCreateCreditGrantRequestModel
}
export type GetInsightsCustomerTotalsApiResponse =
  /** status 200 OK */ GetTotalCustomersResponseModel
export type GetInsightsCustomerTotalsApiArg = void
export type GetCustomersByIdApiResponse = /** status 200 OK */ Customer2
export type GetCustomersByIdApiArg = {
  /** Customer ID */
  id: string
}
export type PutCustomersByIdApiResponse = /** status 200 OK */ Customer2
export type PutCustomersByIdApiArg = {
  /** Customer ID */
  id: string
  updateCustomerRequest: UpdateCustomerRequest
}
export type GetCustomersApiResponse = /** status 200 OK */ ListCustomersResponse
export type GetCustomersApiArg = {
  /** Sort column. Default: creation time. */
  sortBy?: string
  /** Filter by legal name */
  legalName?: string
  /** Filter by email */
  email?: string
  /** Filter by alias */
  alias?: string
  /** Include archived customers */
  includeArchived?: boolean
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  before?: string
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Sort order. Default: DESC (descending, most often used for reverse chronological sorting). */
  sortOrder?: string
}
export type PutCustomersByIdArchiveApiResponse = /** status 200 OK */ Customer2
export type PutCustomersByIdArchiveApiArg = {
  /** Customer ID */
  id: string
}
export type GetCustomersByAliasByAliasApiResponse =
  /** status 200 OK */ Customer2
export type GetCustomersByAliasByAliasApiArg = {
  /** Customer Alias */
  alias: string
}
export type GetCustomersByIdAliasesApiResponse =
  /** status 200 OK */ GetCustomerAliasesEndpointEndpointResponse
export type GetCustomersByIdAliasesApiArg = {
  /** Customer ID */
  id: string
}
export type GetCustomerAliasesApiResponse =
  /** status 200 OK */ ListCustomerAliasEndpointProductListCustomerAliasPaginatedResponseModel
export type GetCustomerAliasesApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type PostCustomerAliasesApiResponse =
  /** status 201 Created */ CreateCustomerAliasEndpointProductCreateCustomerAliasResponseModel
export type PostCustomerAliasesApiArg = {
  createCustomerAliasEndpointCreateCustomerAliasRequestModel: CreateCustomerAliasEndpointCreateCustomerAliasRequestModel
}
export type GetUsageMetricsApiResponse =
  /** status 200 OK */ PrivilegedListUsageMetricEndpointProductResponseModel
export type GetUsageMetricsApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type GetUsageMetricsByUsageMetricIdCalculateApiResponse =
  /** status 200 OK */ UsageMetricCalculationResponse
export type GetUsageMetricsByUsageMetricIdCalculateApiArg = {
  /** Usage Metric ID */
  usageMetricId: string
  /** Customer Aliases / internal IDs */
  customerAliases: string
  /** Billing period start */
  periodStart: string
  /** Billing period end (inclusive) */
  periodEnd: string
  /** Custom parameter values */
  customParameters?: string
}
export type GetUniqueUsageEventTypesApiResponse =
  /** status 200 OK */ ListUniqueUsageEventTypesEndpointEndpointResponseModel
export type GetUniqueUsageEventTypesApiArg = {
  /** Search filter */
  search?: string
}
export type GetUniqueUsageEventPropertiesApiResponse =
  /** status 200 OK */ ListUniqueUsageEventPropertiesEndpointEndpointResponseModel
export type GetUniqueUsageEventPropertiesApiArg = {
  /** Search filter */
  search?: string
}
export type GetInsightsUsageEventsApiResponse =
  /** status 200 OK */ GetUsageEventsInsightsResponseModel
export type GetInsightsUsageEventsApiArg = {
  /** Filter by event type */
  eventType?: string
  /** Start date (inclusive) */
  dateFrom: string
  /** End date (inclusive) */
  dateTo: string
}
export type GetInsightsUsageMetricsByUsageMetricIdUsageDataApiResponse =
  /** status 200 OK */ OptionalUsageDataResponse1
export type GetInsightsUsageMetricsByUsageMetricIdUsageDataApiArg = {
  /** Usage Metric ID */
  usageMetricId: string
  /** Start date (inclusive) */
  dateFrom: string
  /** End date (inclusive) */
  dateTo: string
}
export type GetSeatSnapshotsApiResponse =
  /** status 200 OK */ ListSeatSnapshotsEndpointEndpointResponseModel
export type GetSeatSnapshotsApiArg = {
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  before?: string
  /** Pagination cursor. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Sort order. Default: DESC (descending, most often used for reverse chronological sorting). */
  sortOrder?: string
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Filter snapshots by customer alias */
  customerAlias?: string
  /** Filter snapshots by seat type */
  seatType?: string
  /** Filter events by customer snapshot id */
  customerSnapshotId?: string
  /** Filter snapshots by timestamp before */
  snapshotTimestampBefore?: string
  /** Filter snapshots by timestamp after */
  snapshotTimestampAfter?: string
  /** Sort column. Default: creation time. */
  sortBy?: string
}
export type PostSeatSnapshotsApiResponse =
  /** status 201 Created */ CreateSeatSnapshotEndpointProductCreateSeatSnapshotResponseModel
export type PostSeatSnapshotsApiArg = {
  createSeatSnapshotEndpointCreateSeatSnapshotRequestModel: CreateSeatSnapshotEndpointCreateSeatSnapshotRequestModel
}
export type GetSeatTypesApiResponse =
  /** status 200 OK */ ListSeatTypeEndpointProductListSeatTypePaginatedResponseModel
export type GetSeatTypesApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
}
export type PostSeatTypesApiResponse =
  /** status 201 Created */ CreateSeatTypeEndpointProductCreateSeatTypeResponseModel
export type PostSeatTypesApiArg = {
  createSeatTypeEndpointCreateSeatTypeRequestModel: CreateSeatTypeEndpointCreateSeatTypeRequestModel
}
export type GetSeatTypesByIdApiResponse =
  /** status 200 OK */ GetSeatTypeEndpointProductGetSeatTypeResponseModel
export type GetSeatTypesByIdApiArg = {
  /** id */
  id: string
}
export type GetLatestSeatTotalsApiResponse =
  /** status 200 OK */ ListLatestSeatTotalsEndpointProductListLatestSeatTotalsPaginatedResponseModel
export type GetLatestSeatTotalsApiArg = {
  /** Maximum number of objects to return per-page. */
  limit?: number
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.after` or `pagination.before` to retrieve each page. */
  after?: string
  /** Pagination offset. To page through items, omit this parameter to retrieve the first page, and then successively use the value you get from `pagination.next` or `pagination.previous` to retrieve each page. */
  before?: string
  /** Filter seat details by customer alias */
  customerAliases?: string
  /** Filter seat details by seat type */
  seatTypes?: string
}
export type GetSeatBalancesApiResponse =
  /** status 200 OK */ ListCustomerSeatBalancesEndpointListCustomerSeatBalancesPaginatedResponseModel
export type GetSeatBalancesApiArg = {
  /** Exclude customers with no seats usage. Defaults to false. */
  excludeZeroQuantity?: boolean
  /** Filter seat balances by customer alias */
  customerAliases?: string
}
export type EntityType =
  | 'CREDIT_NOTE'
  | 'CUSTOMER'
  | 'INVOICE'
  | 'PRICE'
  | 'QUOTE'
  | 'QUOTE_PRICE'
export type IntegrationService =
  | 'Amazon_Redshift'
  | 'Avalara'
  | 'Google_BigQuery'
  | 'Google_Sheets'
  | 'HubSpot'
  | 'NetSuite'
  | 'QuickBooks_Online'
  | 'Salesforce'
  | 'Slack'
  | 'Snowflake'
  | 'Stripe'
  | 'Xero'
export type LinkedEntity = {
  id: string
  entityId: string
  sequenceAccountId: string
  entityType: EntityType
  externalService: IntegrationService
  externalAccountId?: string
  externalId: string
  externalUrl?: string
  createdAt: string
}
export type State =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'
  | 'AB'
  | 'BC'
  | 'MB'
  | 'NB'
  | 'NL'
  | 'NS'
  | 'NT'
  | 'NU'
  | 'ON'
  | 'PE'
  | 'QC'
  | 'SK'
  | 'YT'
export type CountryCode =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'
export type Address1 = {
  line1: string
  line2?: string
  town: string
  state?: State
  postcode: string
  country: CountryCode
}
export type SortCodeAccountNumber = {
  sortCode: string
  accountNumber: string
}
export type UsAchDestination1 = {
  accountNumber: string
  accountName: string
  bankName: string
  bankRoutingNumber: string
}
export type UsWireDestination1 = {
  accountNumber: string
  accountName: string
  bankName: string
  bankRoutingNumber: string
  swiftCode: string
  bankAddress: Address1
}
export type InvoiceMerchantDetails = {
  address: Address1
  phoneNumber?: string
  email: string
  taxId?: string
  iban?: string
  ukAccountDetails?: SortCodeAccountNumber
  usAchDetails?: UsAchDestination1
  usWireDetails?: UsWireDestination1
  legalCompanyName: string
  customFields: any[]
  includeBeneficiaryAddressInPaymentDetails: boolean
}
export type Currency =
  | 'ARS'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CNY'
  | 'COP'
  | 'CZK'
  | 'DKK'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'ILS'
  | 'JPY'
  | 'KRW'
  | 'MXN'
  | 'NOK'
  | 'NZD'
  | 'PLN'
  | 'SEK'
  | 'SGD'
  | 'THB'
  | 'USD'
  | 'UYU'
  | 'ZAR'
export type BankPaymentDestinationType =
  | 'IBAN'
  | 'UK_BANK_ACCOUNT'
  | 'US_ACH'
  | 'US_WIRE'
  | 'CA_BANK_ACCOUNT'
  | 'CA_INTERNATIONAL'
  | 'AUSTRALIAN'
export type CanadianBankAccountDestination1 = {
  transitNumber: string
  institutionNumber: string
  accountNumber: string
}
export type IntermediaryBank1 = {
  bankName: string
  bankAddress: Address1
  swiftCode: string
  routingNumber: string
}
export type CanadianInternationalDestination1 = {
  legalName: string
  accountNumber: string
  transitNumber: string
  bankName: string
  bankAddress: Address1
  institutionNumber: string
  swiftCode: string
  intermediaryBank?: IntermediaryBank1
}
export type AustralianBankAccountDestination1 = {
  legalName: string
  accountNumber: string
  swiftCode: string
  bsb: string
}
export type BankPaymentDestinations = {
  type: BankPaymentDestinationType
  iban?: string
  ukBankAccount?: SortCodeAccountNumber
  usAchDestination?: UsAchDestination1
  usWireDestination?: UsWireDestination1
  canadianBankAccount?: CanadianBankAccountDestination1
  canadianInternationalDestination?: CanadianInternationalDestination1
  australianDestination?: AustralianBankAccountDestination1
}
export type MerchantKeyValuePair = {
  key: Currency
  value: BankPaymentDestinations
}
export type Merchant = {
  id: string
  sequenceAccountId: string
  invoiceNumberPrefix: string
  creditNoteNumberPrefix: string
  legalCompanyName: string
  address: Address1
  phoneNumber?: string
  email: string
  defaultDueDateDays: number
  lastInvoiceNumber: number
  lastCreditNoteNumber: number
  bankAccountDetails: MerchantKeyValuePair[]
  customerPortalEnabled: boolean
  includeBeneficiaryAddressInPaymentDetails: boolean
  ccMerchantOnInvoiceEmails: boolean
}
export type Total = {
  netTotal: string
  totalTax: string
  grossTotal: string
}
export type CalculateInvoiceTotalsEndpointLineItemTotal = {
  id: string
  total: Total
}
export type CalculateInvoiceTotalsEndpointLineItemGroupTotal = {
  id: string
  total: Total
}
export type CalculateInvoiceTotalsEndpointCalculateInvoiceTotalsResponse = {
  total: Total
  lineItemTotals: CalculateInvoiceTotalsEndpointLineItemTotal[]
  lineItemGroupTotals: CalculateInvoiceTotalsEndpointLineItemGroupTotal[]
}
export type DateRange = {
  startDate: string
  endDate: string
}
export type RateDisplay = 'ABSOLUTE' | 'PERCENTAGE'
export type IntegrationExternalIdentifier = {
  key: IntegrationService
  value: string
}
export type RevenueClassification = 'PREPAYMENT' | 'EARNED' | 'BURNDOWN'
export type LineItemResponseModel = {
  id: string
  invoiceId: string
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  servicePeriodStart?: string
  servicePeriodEnd?: string
  servicePeriod?: DateRange
  index: number
  groupId?: string
  netTotal: string
  totalTax: string
  grossTotal: string
  rateDisplay: RateDisplay
  externalIds: IntegrationExternalIdentifier[]
  revenueClassification?: RevenueClassification
}
export type CalculateInvoiceTotalsEndpointCalculateInvoiceTotalsRequest = {
  lineItems: LineItemResponseModel[]
}
export type InvoiceStatus =
  | 'IN_PROGRESS'
  | 'DRAFT'
  | 'FINAL'
  | 'SENT'
  | 'VOIDED'
export type InvoicePaymentOption = 'BANK_TRANSFER' | 'LINK'
export type InclusiveDateRange = {
  start: string
  endInclusive: string
}
export type KeyValuePair = {
  key: string
  value: string
}
export type InvoicePaymentStatus =
  | 'UNPAID'
  | 'PARTIALLY_PAID'
  | 'PAID'
  | 'UNCOLLECTIBLE'
export type LinkInfo = {
  externalId: string
  externalService: IntegrationService
  syncTime: string
}
export type TaxStatus = 'TAXED' | 'TAX_EXEMPT' | 'REVERSE_CHARGED'
export type Invoice1 = {
  id: string
  sequenceAccountId: string
  status: InvoiceStatus
  currency: Currency
  invoiceNumber?: string
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  customerId: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  paymentStatus: InvoicePaymentStatus
  createdAt: string
  creditNoteIds: string[]
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  accountingDate: string
  customerTaxStatus?: TaxStatus
}
export type PaginationMeta = {
  after?: string
  before?: string
  totalResultSize?: number
}
export type ListInvoiceEndpointListInvoicePaginatedResponseModel = {
  items: Invoice1[]
  pagination: PaginationMeta
}
export type InvoiceDunningStatus =
  | 'SCHEDULED'
  | 'OVERDUE_CHECK_SCHEDULED'
  | 'COMPLETED'
  | 'FAILED'
  | 'NOT_REQUIRED'
export type CreateInvoiceEndpointProductCreateInvoiceResponseModel = {
  id: string
  sequenceAccountId: string
  billingScheduleId?: string
  status: InvoiceStatus
  currency: Currency
  invoiceNumber?: string
  issueDate?: string
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  customerId: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  paymentStatus: InvoicePaymentStatus
  createdAt: string
  creditNoteIds: string[]
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  creditBalances?: string
  dunningStatus?: InvoiceDunningStatus
  accountingDate: string
  calculatedAt?: string
  customerTaxStatus?: TaxStatus
}
export type CreateInvoiceEndpointCreateInvoiceRequestModel = {
  billingScheduleId?: string
  currency: Currency
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails?: string[]
  customerLegalCompanyName?: string
  customerBillingAddress?: Address1
  customerShippingAddress?: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  customerId: string
  metadata?: KeyValuePair[]
  creditBalances?: string
  billingRunId?: string
  accountingDate?: string
}
export type CreateOrUpdateLineItemRequestModel = {
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  servicePeriodStart?: string
  servicePeriodEnd?: string
  servicePeriod?: DateRange
  groupId?: string
  priceId?: string
  rateDisplay?: RateDisplay
  externalIds: IntegrationExternalIdentifier[]
  revenueClassification?: RevenueClassification
  creditGrantId?: string
}
export type CreateLineItemGroupEndpointProductCreateLineItemGroupResponseModel =
  {
    id: string
    invoiceId: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type CreateLineItemGroupEndpointCreateLineItemGroupRequestModel = {
  title: string
  description?: string
}
export type GetInvoiceEndpointProductGetInvoiceResponseModel = {
  id: string
  sequenceAccountId: string
  billingScheduleId?: string
  status: InvoiceStatus
  currency: Currency
  invoiceNumber?: string
  issueDate?: string
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  customerId: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  paymentStatus: InvoicePaymentStatus
  createdAt: string
  creditNoteIds: string[]
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  creditBalances?: string
  dunningStatus?: InvoiceDunningStatus
  accountingDate: string
  calculatedAt?: string
  customerTaxStatus?: TaxStatus
}
export type UpdateInvoiceEndpointProductUpdateInvoiceResponseModel = {
  id: string
  sequenceAccountId: string
  billingScheduleId?: string
  status: InvoiceStatus
  currency: Currency
  invoiceNumber?: string
  issueDate?: string
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  customerId: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  paymentStatus: InvoicePaymentStatus
  createdAt: string
  creditNoteIds: string[]
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  creditBalances?: string
  dunningStatus?: InvoiceDunningStatus
  accountingDate: string
  calculatedAt?: string
  customerTaxStatus?: TaxStatus
}
export type UpdateInvoiceEndpointUpdateInvoiceRequestModel = {
  dueDate?: string
  purchaseOrderNumber?: string
  reference?: string
  customerEmails?: string[]
  customerLegalCompanyName?: string
  customerBillingAddress?: Address1
  customerShippingAddress?: Address1
  memo?: string
  paymentOptions?: InvoicePaymentOption[]
  billingPeriod?: InclusiveDateRange
  metadata?: KeyValuePair[]
  creditBalances?: string
  billingRunId?: string
  accountingDate?: string
}
export type UpdateLineItemGroupEndpointProductUpdateLineItemGroupResponseModel =
  {
    id: string
    invoiceId: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type UpdateLineItemGroupEndpointUpdateLineItemGroupRequestModel = {
  title: string
  description?: string
}
export type DeleteLineItemGroupEndpointProductDeleteLineItemGroupResponseModel =
  {
    id: string
    invoiceId: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type FinaliseAndSendMultipleInvoicesEndpointRequestModel = {
  invoiceIds: string[]
}
export type FinaliseMultipleInvoicesEndpointRequestModel = {
  invoiceIds: string[]
}
export type MonthDetails = {
  month: string
  total: string
  netTotal: string
  invoiceIds: string[]
}
export type CustomerDetails = {
  customerId: string
  legalName: string
  total: string
  netTotal: string
  monthDetails: MonthDetails[]
}
export type InvoicesGrid = {
  sequenceAccountId: string
  customerDetails: CustomerDetails[]
  accountTotal: string
  accountNetTotal: string
  monthDetails: MonthDetails[]
}
export type UsageData = {
  periodStart: string
  amount: string
  cumulativeAmount: string
}
export type UsageDataResponse = {
  usageStart: string
  usageEnd: string
  metricName: string
  metricId: string
  total: string
  usageData: UsageData[]
}
export type OptionalUsageDataResponse = {
  data?: UsageDataResponse
}
export type LineItemGroup1 = {
  id: string
  invoiceId: string
  title: string
  description?: string
  index: number
  netTotal: string
  totalTax: string
  grossTotal: string
}
export type ListAllLineItemGroupsUnpaginatedEndpointListAllLineItemGroupsResponseModel =
  {
    items: LineItemGroup1[]
  }
export type ListAllLineItemsUnpaginatedEndpointListAllLineItemsResponseModel = {
  items: LineItemResponseModel[]
}
export type UsageItem = {
  id: string
  invoiceId: string
  title: string
  value: string
  groupedValues: any[]
  lineItemGroupId?: string
  usageItemGroupId?: string
}
export type GetUsageItemGroupEndpointGetUsageItemGroupResponseModel = {
  id: string
  invoiceId: string
  title: string
  items: UsageItem[]
}
export type ListUsageItemGroupEndpointListUsageItemGroupResponseModel = {
  items: GetUsageItemGroupEndpointGetUsageItemGroupResponseModel[]
}
export type RecalculateMultipleInvoicesEndpointRequestModel = {
  invoiceIds: string[]
}
export type SendMultipleInvoicesEndpointRequestModel = {
  invoiceIds: string[]
}
export type UpdateInvoicePaymentStatusEndpointRequestModel = {
  paymentStatus: InvoicePaymentStatus
}
export type UpdateMultipleInvoicesPaymentStatusEndpointRequestModel = {
  paymentStatus: InvoicePaymentStatus
  invoiceIds: string[]
}
export type AddCreditNoteLineItemsFromInvoiceEndpointRequestModel = {
  lineItemIds: string[]
}
export type CalculateCreditNoteTotalsEndpointLineItemTotal = {
  id: string
  total: Total
}
export type CalculateCreditNoteTotalsEndpointLineItemGroupTotal = {
  id: string
  total: Total
}
export type CalculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsResponse =
  {
    total: Total
    lineItemTotals: CalculateCreditNoteTotalsEndpointLineItemTotal[]
    lineItemGroupTotals: CalculateCreditNoteTotalsEndpointLineItemGroupTotal[]
  }
export type CreditNoteLineItem = {
  id: string
  creditNoteId: string
  groupId?: string
  lineItemId?: string
  title: string
  description?: string
  quantity: string
  rate: string
  taxRate: string
  index: number
  netTotal: string
  totalTax: string
  grossTotal: string
  rateDisplay: RateDisplay
  externalIds: any[]
}
export type CalculateCreditNoteTotalsEndpointCalculateCreditNoteTotalsRequest =
  {
    lineItems: CreditNoteLineItem[]
  }
export type CreditNoteStatus = 'IN_PROGRESS' | 'DRAFT' | 'FINAL' | 'SENT' | 'VOIDED'
export type CreditNote = {
  id: string
  sequenceAccountId: string
  status: CreditNoteStatus
  invoiceId?: string
  invoiceNumber?: string
  currency: Currency
  issueDate?: string
  billingPeriodStart?: string
  billingPeriodEnd?: string
  creditNoteNumber?: string
  purchaseOrderNumber?: string
  customerId: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: any[]
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  settings: { generateCashCreditGrant: 'ENABLED' | 'DISABLED' }
  creditGrantId?: string
}
export type PaginationResponse = {
  after?: string
  before?: string
  totalResultSize?: number
}
export type ListCreditNoteEndpointProductListCreditNotePaginatedResponseModel =
  {
    items: CreditNote[]
    pagination: PaginationResponse
  }
export type CreateCreditNoteEndpointProductCreateCreditNoteResponseModel = {
  id: string
  sequenceAccountId: string
  status: CreditNoteStatus
  invoiceId?: string
  invoiceNumber?: string
  billingScheduleId?: string
  currency: Currency
  issueDate?: string
  billingPeriodStart?: string
  billingPeriodEnd?: string
  creditNoteNumber?: string
  purchaseOrderNumber?: string
  customerId: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  settings: { generateCashCreditGrant: 'ENABLED' | 'DISABLED' }
  creditGrantId?: string
}
export type CreateCreditNoteEndpointCreateCreditNoteRequestModel = {
  invoiceId?: string
  currency: Currency
  billingPeriodStart?: string
  billingPeriodEnd?: string
  purchaseOrderNumber?: string
  customerId: string
  customerEmails?: string[]
  customerLegalCompanyName?: string
  customerBillingAddress?: Address1
  customerShippingAddress?: Address1
  memo?: string
  metadata?: KeyValuePair[]
  customerTaxId?: string
}
export type CreateCreditNoteLineItemEndpointProductCreateCreditNoteLineItemResponseModel =
  {
    id: string
    creditNoteId: string
    groupId?: string
    lineItemId?: string
    title: string
    description?: string
    quantity: string
    rate: string
    taxRate: string
    index: number
    priceId?: string
    netTotal: string
    totalTax: string
    grossTotal: string
    rateDisplay: RateDisplay
    externalIds: IntegrationExternalIdentifier[]
  }
export type CreateCreditNoteLineItemEndpointCreateCreditNoteLineItemRequestModel =
  {
    creditNoteId: string
    groupId?: string
    lineItemId?: string
    title: string
    description?: string
    quantity: string
    rate: string
    taxRate: string
    rateDisplay?: RateDisplay
    externalIds: IntegrationExternalIdentifier[]
  }
export type CreateCreditNoteLineItemGroupEndpointProductCreateCreditNoteLineItemGroupResponseModel =
  {
    id: string
    creditNoteId: string
    lineItemGroupId?: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type CreateCreditNoteLineItemGroupEndpointCreateCreditNoteLineItemGroupRequestModel =
  {
    lineItemGroupId?: string
    title: string
    description?: string
  }
export type GetCreditNoteEndpointProductGetCreditNoteResponseModel = {
  id: string
  sequenceAccountId: string
  status: CreditNoteStatus
  invoiceId?: string
  invoiceNumber?: string
  billingScheduleId?: string
  currency: Currency
  issueDate?: string
  billingPeriodStart?: string
  billingPeriodEnd?: string
  creditNoteNumber?: string
  purchaseOrderNumber?: string
  customerId: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  settings: { generateCashCreditGrant: 'ENABLED' | 'DISABLED' }
  creditGrantId?: string
}
export type UpdateCreditNoteEndpointProductUpdateCreditNoteResponseModel = {
  id: string
  sequenceAccountId: string
  status: CreditNoteStatus
  invoiceId?: string
  invoiceNumber?: string
  billingScheduleId?: string
  currency: Currency
  issueDate?: string
  billingPeriodStart?: string
  billingPeriodEnd?: string
  creditNoteNumber?: string
  purchaseOrderNumber?: string
  customerId: string
  customerEmails: string[]
  customerLegalCompanyName: string
  customerBillingAddress: Address1
  customerShippingAddress: Address1
  memo?: string
  totalTax: string
  netTotal: string
  grossTotal: string
  metadata: KeyValuePair[]
  customerTaxId?: string
  linkedServices: LinkInfo[]
  merchantDetails?: InvoiceMerchantDetails
  settings: { generateCashCreditGrant: 'ENABLED' | 'DISABLED' }
  creditGrantId?: string
}
export type UpdateCreditNoteEndpointUpdateCreditNoteRequestModel = {
  billingPeriodStart?: string
  billingPeriodEnd?: string
  purchaseOrderNumber?: string
  customerEmails?: string[]
  customerLegalCompanyName?: string
  customerBillingAddress?: Address1
  customerShippingAddress?: Address1
  memo?: string
  metadata?: KeyValuePair[]
  customerTaxId?: string
  settings: { generateCashCreditGrant: 'ENABLED' | 'DISABLED' }
}
export type UpdateCreditNoteLineItemEndpointProductUpdateCreditNoteLineItemResponseModel =
  {
    id: string
    creditNoteId: string
    groupId?: string
    lineItemId?: string
    title: string
    description?: string
    quantity: string
    rate: string
    taxRate: string
    index: number
    priceId?: string
    netTotal: string
    totalTax: string
    grossTotal: string
    rateDisplay: RateDisplay
    externalIds: IntegrationExternalIdentifier[]
  }
export type UpdateCreditNoteLineItemEndpointUpdateCreditNoteLineItemRequestModel =
  {
    title: string
    description?: string
    quantity: string
    rate: string
    taxRate: string
    rateDisplay?: RateDisplay
    externalIds: IntegrationExternalIdentifier[]
  }
export type DeleteCreditNoteLineItemEndpointProductDeleteCreditNoteLineItemResponseModel =
  {
    id: string
    creditNoteId: string
    groupId?: string
    lineItemId?: string
    title: string
    description?: string
    quantity: string
    rate: string
    taxRate: string
    index: number
    priceId?: string
    netTotal: string
    totalTax: string
    grossTotal: string
    rateDisplay: RateDisplay
    externalIds: IntegrationExternalIdentifier[]
  }
export type UpdateCreditNoteLineItemGroupEndpointProductUpdateCreditNoteLineItemGroupResponseModel =
  {
    id: string
    creditNoteId: string
    lineItemGroupId?: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type UpdateCreditNoteLineItemGroupEndpointUpdateCreditNoteLineItemGroupRequestModel =
  {
    title: string
    description?: string
  }
export type DeleteCreditNoteLineItemGroupEndpointProductDeleteCreditNoteLineItemGroupResponseModel =
  {
    id: string
    creditNoteId: string
    lineItemGroupId?: string
    title: string
    description?: string
    index: number
    netTotal: string
    totalTax: string
    grossTotal: string
  }
export type ListAllCreditNoteLineItemsUnpaginatedEndpointListAllLineItemsResponseModel =
  {
    items: CreditNoteLineItem[]
  }
export type CreditNoteLineItemGroup = {
  id: string
  creditNoteId: string
  lineItemGroupId?: string
  title: string
  description?: string
  index: number
  netTotal: string
  totalTax: string
  grossTotal: string
}
export type ListAllCreditNoteLineItemGroupsUnpaginatedEndpointListAllCreditNoteLineItemGroupsResponseModel =
  {
    items: CreditNoteLineItemGroup[]
  }
export type OutstandingInvoicesGroup = {
  numberOfInvoices: number
  amount: number
}
export type GetOutstandingInvoicesResponseModel = {
  currency: Currency
  totalAmount: number
  outstandingNow: OutstandingInvoicesGroup
  overdueFrom0to30days: OutstandingInvoicesGroup
  overdueFrom31to60days: OutstandingInvoicesGroup
  overdueFrom61days: OutstandingInvoicesGroup
}
export type InvoicedRevenueByMonth = {
  amount: number
  periodStart: string
}
export type GetInvoicedRevenueResponseModel = {
  currency: Currency
  totalAmount: number
  invoicedRevenueByMonth: InvoicedRevenueByMonth[]
}
export type PaymentProvider = 'STRIPE' | 'NONE'
export type CreateInvoiceSettingsEndpointProductCreateInvoiceSettingsResponseModel =
  {
    id: string
    sequenceAccountId: string
    invoiceId: string
    customerId: string
    paymentProvider: PaymentProvider
  }
export type CreateInvoiceSettingsEndpointCreateInvoiceSettingsRequestModel = {
  invoiceId: string
  customerId?: string
  paymentProvider: PaymentProvider
}
export type ProductResponse = {
  id: string
  name: string
  label: string
  sequenceAccountId: string
  createdAt: string
  updatedAt: string
}
export type ProductRequest = {
  name: string
  label?: string
}
export type UpdateProductEndpointUpdateProductRequestModel = {
  name: string
  label?: string
}
export type NotificationType =
  | 'CUSTOMER_CREATED'
  | 'CUSTOMER_UPDATED'
  | 'CUSTOMER_ARCHIVED'
  | 'INVOICE_CREATED'
  | 'INVOICE_ISSUED'
  | 'INVOICE_UPDATED'
  | 'INTEGRATION_SYNC_COMPLETED'
  | 'INTEGRATION_WEBHOOK_HANDLED'
  | 'MERCHANT_UPDATED'
  | 'BILLING_SCHEDULE_CREATED'
  | 'BILLING_SCHEDULE_UPDATED'
  | 'BILLING_SCHEDULE_ARCHIVED'
  | 'CREDIT_NOTE_CREATED'
  | 'CREDIT_NOTE_UPDATED'
  | 'CREDIT_NOTE_ISSUED'
  | 'QUOTE_PUBLISHED'
  | 'QUOTE_ACCEPTED'
export type NotificationChannel = 'WEBHOOK' | 'EMAIL'
export type NotificationPolicy = {
  id: string
  createdAt: string
  sequenceAccountId: string
  name: string
  notificationTypes?: NotificationType[]
  recipients: string[]
  channel: NotificationChannel
  webhookSecret?: string
}
export type NotificationPolicies = {
  items: NotificationPolicy[]
  pagination: PaginationResponse
}
export type DatabaseErrors = {
  errors: any[]
}
export type CreateNotificationPolicyRequest = {
  name: string
  notificationTypes?: NotificationType[]
  recipients: string[]
  channel: NotificationChannel
  webhookSecret?: string
}
export type CountryCode1 =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'
export type ListTaxRateEndpointProductListTaxRateResponseModel = {
  id: string
  versionId: string
  name: string
  sequenceAccountId: string
  version: number
  isPublished: boolean
  invoiceName: string
  invoiceShortName: string
  description?: string
  amount: string
  country: CountryCode1
  region?: string
  subRates: any[]
  isArchived: boolean
}
export type ListTaxRateEndpointProductListTaxRatePaginatedResponseModel = {
  items: ListTaxRateEndpointProductListTaxRateResponseModel[]
  pagination: PaginationMeta
}
export type CreateTaxRateEndpointProductCreateTaxRateResponseModel = {
  id: string
  versionId: string
  sequenceAccountId: string
  version: number
  isPublished: boolean
  name: string
  invoiceName: string
  invoiceShortName: string
  description?: string
  amount: string
  country: CountryCode1
  region?: string
  taxCategoryId?: string
  subRates?: any[]
  isArchived: boolean
}
export type CreateTaxRateRequest = {
  isPublished: boolean
  name: string
  invoiceName: string
  invoiceShortName: string
  description?: string
  amount: string
  country: CountryCode1
  region?: string
  taxCategoryId?: string
  subRates?: any[]
}
export type ArchiveTaxRateEndpointProductArchiveTaxRateResponseModel = {
  id: string
  versionId: string
  sequenceAccountId: string
  version: number
  isPublished: boolean
  name: string
  invoiceName: string
  invoiceShortName: string
  description?: string
  amount: string
  country: CountryCode1
  region?: string
  taxCategoryId?: string
  subRates?: any[]
  isArchived: boolean
}
export type State1 =
  | 'AL'
  | 'AK'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DC'
  | 'DE'
  | 'FL'
  | 'GA'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PA'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'
  | 'AB'
  | 'BC'
  | 'MB'
  | 'NB'
  | 'NL'
  | 'NS'
  | 'ON'
  | 'PE'
  | 'QC'
  | 'SK'
  | 'NU'
  | 'NT'
  | 'YT'
export type CreateTaxRegistrationEndpointProductCreateTaxRegistrationResponseModel =
  {
    id: string
    sequenceAccountId: string
    taxIdentifier: string
    customerId?: string
    merchantId?: string
    country: CountryCode1
    state?: State1
  }
export type CreateTaxRegistrationEndpointCreateTaxRegistrationRequestModel = {
  taxIdentifier: string
  customerId?: string
  merchantId?: string
  country: CountryCode1
  state?: State1
}
export type GetTaxRegistrationForCustomerCustomerTaxRegistration = {
  id: string
  sequenceAccountId: string
  taxIdentifier: string
  customerId: string
  country: CountryCode1
  state?: State1
}
export type GetTaxRegistrationForCustomerProductResponse = {
  items: GetTaxRegistrationForCustomerCustomerTaxRegistration[]
  pagination: PaginationResponse
}
export type UpdateTaxRegistrationEndpointProductUpdateTaxRegistrationResponseModel =
  {
    id: string
    sequenceAccountId: string
    taxIdentifier: string
    customerId?: string
    merchantId?: string
    country: CountryCode1
    state?: State1
  }
export type UpdateTaxRegistrationEndpointUpdateTaxRegistrationRequestModel = {
  taxIdentifier: string
  customerId?: string
  merchantId?: string
  country: CountryCode1
  state?: State1
}
export type CountryCode2 =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'
export type SequenceAccount = {
  id: string
  createdAt: string
  sequenceOrganisationId: string
  companyName: string
  companyNumber: string
  country: CountryCode2
  sendCustomerNotifications: boolean
  alertEmailAddresses: string[]
}
export type UpdateSequenceAccountProductEndpointUpdateSequenceAccountRequest = {
  alertEmailAddresses: string[]
}
export type SequenceUserResponse = {
  id: string
  email: string
  sequenceAccountIds: string[]
  state: string
}
export type ListSequenceUsersResponse = {
  items: SequenceUserResponse[]
  pagination: PaginationResponse
}
export type OAuthClient = {
  id: string
  sequenceAccountId: string
  name: string
  scopes: string[]
  pinnedApiVersion?: string
}
export type ListOAuthClientsEndpointResponseModel = {
  items: OAuthClient[]
  pagination: PaginationResponse
}
export type CreateOAuthClientResponse = {
  id: string
  name: string
  secret: string
}
export type ErrorItem = {
  code: string
  message: string
  key?: string
  errors?: any[]
}
export type ErrorObject = {
  type: string
  message: string
  errors?: ErrorItem[]
}
export type ApiError = {
  error: ErrorObject
}
export type CreateOAuthClientRequest = {
  scopes: string[]
  accountId: string
  clientName?: string
  pinApiVersion?: boolean
}
export type GetSequenceAccountIdEndpointGetSequenceAccountIdResponse = {
  id: string
}
export type Currency1 =
  | 'ARS'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CNY'
  | 'COP'
  | 'CZK'
  | 'DKK'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'ILS'
  | 'JPY'
  | 'KRW'
  | 'MXN'
  | 'NOK'
  | 'NZD'
  | 'PLN'
  | 'SEK'
  | 'SGD'
  | 'THB'
  | 'USD'
  | 'UYU'
  | 'ZAR'
export type SequenceAccountSettings = {
  id: string
  sequenceAccountId: string
  enabledCurrencies: Currency1[]
}
export type UpdateSequenceAccountSettingsEndpointRequestModel = {
  enabledCurrencies: Currency1[]
}
export type IntegrationService1 =
  | 'Amazon_Redshift'
  | 'Avalara'
  | 'Google_BigQuery'
  | 'Google_Sheets'
  | 'HubSpot'
  | 'NetSuite'
  | 'QuickBooks_Online'
  | 'Salesforce'
  | 'Slack'
  | 'Snowflake'
  | 'Stripe'
  | 'Xero'
export type Customer1 = {
  id: string
  companyName: string
  country?: string
  isLinked: boolean
  invalidReason?: string
  externalUrl: string
}
export type GetIntegrationCustomersEndpointCustomers = {
  key: IntegrationService1
  value: Customer1[]
}
export type GetIntegrationCustomersEndpointResponseModel = {
  customers: GetIntegrationCustomersEndpointCustomers[]
}
export type GetIntegrationCustomersForServiceEndpointResponseModel = {
  items: Customer1[]
}
export type Template =
  | 'ALL'
  | 'USAGE_ANALYTICS'
  | 'AR_ANALYTICS'
  | 'SALES_COMMISSIONS'
  | 'REVENUE_ANALYTICS'
export type ExportDataToGoogleSheetsEndpointRequestModel = {
  template: Template
  sheetUrl: string
}
export type SyncCustomersFromIntegrationEndpointResponseModel = {
  count: number
}
export type SyncCustomersFromIntegrationEndpointRequestModel = {
  customerIds: string[]
}
export type GetCustomerImportFromIntegrationProgressEndpointResponseModel = {
  totalCount: number
  currentCount: number
}
export type GetCustomerImportFromIntegrationProgressEndpointRequestModel = {
  customerIds: string[]
}
export type OneTime = {
  price: string
  pricingType: 'ONE_TIME'
}
export type Fixed = {
  price: string
  pricingType: 'FIXED'
}
export type Linear = {
  pricePerUnit: string
  usageMetricId: string
  isPricePercentage: boolean
  pricingType: 'LINEAR'
}
export type Package = {
  packageSize: string
  pricePerPackage: string
  usageMetricId: string
  pricingType: 'PACKAGE'
}
export type UsageTier = {
  upperBound?: string
  price: string
  fee?: string
  isPricePercentage: boolean
}
export type Volume = {
  tiers: UsageTier[]
  usageMetricId: string
  pricingType: 'VOLUME'
}
export type UsageCalculationMode = 'BILLING_PERIOD' | 'CUMULATIVE'
export type Graduated = {
  tiers: UsageTier[]
  usageMetricId: string
  usageCalculationMode: UsageCalculationMode
  pricingType: 'GRADUATED'
}
export type SeatProrationStrategy =
  | 'USE_FIRST'
  | 'USE_MAXIMUM'
  | 'PRORATE_INCREMENTS'
  | 'PRORATE_ALL_CHANGES'
export type SeatChargeMethod =
  | 'CHARGE_FIXED'
  | 'CHARGE_OVERAGES'
  | 'CHARGE_CONSUMED'
  | 'CHARGE_MINIMUM'
export type BillingFrequency =
  | 'ON_DEMAND'
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'HALF_YEARLY'
  | 'YEARLY'
export type SeatBased = {
  seatTypeId: string
  pricePerSeat: string
  prorationStrategy: SeatProrationStrategy
  includedSeats: number
  chargeMethod: SeatChargeMethod
  overagesBillingFrequency?: BillingFrequency
  tiers: UsageTier[]
  pricingType: 'SEAT_BASED'
  prorateFlatFees: boolean
}
export type BillingType = 'IN_ARREARS' | 'IN_ADVANCE'
export type PriceParameter2 = {
  parameterId: string
  value: string
}
export type Price = {
  id: string
  sequenceAccountId: string
  productId: string
  name: string
  currency: Currency
  structure:
    | ({
        pricingType: 'ONE_TIME'
      } & OneTime)
    | ({
        pricingType: 'FIXED'
      } & Fixed)
    | ({
        pricingType: 'LINEAR'
      } & Linear)
    | ({
        pricingType: 'PACKAGE'
      } & Package)
    | ({
        pricingType: 'VOLUME'
      } & Volume)
    | ({
        pricingType: 'GRADUATED'
      } & Graduated)
    | ({
        pricingType: 'SEAT_BASED'
      } & SeatBased)
  billingFrequency: BillingFrequency
  billingType: BillingType
  createdAt: string
  updatedAt?: string
  externalIds: IntegrationExternalIdentifier[]
  parameters: PriceParameter2[]
}
export type ListPriceEndpointProductListPricePaginatedResponseModel = {
  items: Price[]
  pagination: PaginationMeta
}
export type GetPriceEndpointProductGetPriceResponseModel = {
  id: string
  sequenceAccountId: string
  productId: string
  name: string
  currency: Currency
  structure:
    | ({
        pricingType: 'ONE_TIME'
      } & OneTime)
    | ({
        pricingType: 'FIXED'
      } & Fixed)
    | ({
        pricingType: 'LINEAR'
      } & Linear)
    | ({
        pricingType: 'PACKAGE'
      } & Package)
    | ({
        pricingType: 'VOLUME'
      } & Volume)
    | ({
        pricingType: 'GRADUATED'
      } & Graduated)
    | ({
        pricingType: 'SEAT_BASED'
      } & SeatBased)
  billingFrequency: BillingFrequency
  billingType: BillingType
  createdAt: string
  updatedAt?: string
  externalIds: IntegrationExternalIdentifier[]
  parameters: PriceParameter2[]
}
export type PriceMetadataBillingScheduleDetail = {
  billingScheduleId: string
  customerLegalName: string
  customerId: string
}
export type PriceMetadata = {
  billingSchedules: PriceMetadataBillingScheduleDetail[]
}
export type CreditUnitType = 'CURRENCY' | 'METRIC'
export type ListByQueryIdCreditGrantEndpointProductListbyqueryidCreditGrantResponseModel =
  {
    id: string
    sequenceAccountId: string
    customerId: string
    name: string
    creditUnitType: CreditUnitType
    currency: Currency
    metricId?: string
    amount: number
    costOfCredit: number
    taxRateId?: string
    effectiveDate?: string
    expiryDate?: string
    createdAt: string
  }
export type ListByQueryIdCreditGrantEndpointProductListByQueryIdCreditGrantPaginatedResponseModel =
  {
    items: ListByQueryIdCreditGrantEndpointProductListbyqueryidCreditGrantResponseModel[]
    pagination: PaginationMeta
  }
export type ListCreditGrantsForCustomerEndpointCreditGrantResponse = {
  id: string
  name: string
  grantAmount: number
  balance: number
  issueDate: string
  expiryDate?: string
}
export type ListCreditGrantsForCustomerEndpointCreditGrantsResponse = {
  items: ListCreditGrantsForCustomerEndpointCreditGrantResponse[]
}
export type CreditType = 'CASH' | 'USAGE'
export type CreditGrant = {
  id: string
  sequenceAccountId: string
  customerId: string
  type: CreditType
  currency: Currency
  metricId?: string
  name: string
  originalAmount: string
  currentBalance: string
  expiryDate?: string
  costOfCredit: string
  taxRateId?: string
  effectiveDate?: string
  createdAt: string
}
export type CreditTransaction = {
  id?: string
  grantId: string
  reason?: string
  invoiceId?: string
  billingRunId?: string
  type: string
  amount: string
  date: string
}
export type CreditBalance = {
  id: string
  type: CreditType
  name: string
  balance: string
  grants: CreditGrant[]
  transactions: CreditTransaction[]
}
export type CreditBalancesReply = {
  items: CreditBalance[]
}
export type CreateCreditGrantEndpointProductCreateCreditGrantResponseModel = {
  id: string
  sequenceAccountId: string
  customerId: string
  name: string
  creditUnitType: CreditUnitType
  currency?: Currency
  metricId?: string
  amount: number
  costOfCredit?: number
  effectiveDate?: string
  expiryDate?: string
  createdAt: string
}
export type CreateCreditGrantEndpointCreateCreditGrantRequestModel = {
  sequenceAccountId: string
  customerId: string
  name: string
  creditUnitType: CreditUnitType
  currency: Currency
  metricId?: string
  amount: number
  costOfCredit?: number
  effectiveDate?: string
  expiryDate?: string
  createInvoice: boolean
}
export type GetTotalCustomersResponseModel = {
  total: number
  numCustomersWithActiveSchedule: number
  numCustomersNoLongerBilled: number
  numCustomersNeverBilled: number
}
export type Address = {
  line1: string
  line2?: string
  town: string
  state?: State
  postcode: string
  country: CountryCode
}
export type KeyValueLinkInfo = {
  key: IntegrationService
  value: string
  syncTime: string
}
export type Customer2 = {
  id: string
  createdAt: string
  sequenceAccountId: string
  legalName: string
  contactName?: string
  address: Address
  email: string
  billingEmails: string[]
  telephone?: string
  url?: string
  taxStatus: TaxStatus
  label?: string
  externalIds: KeyValueLinkInfo[]
  archivedAt?: string
}
export type IntegrationExternalIdentifier1 = {
  key: IntegrationService
  value: string
}
export type UpdateCustomerRequest = {
  legalName: string
  contactName?: string
  address: Address
  email: string
  billingEmails?: string[]
  telephone?: string
  url?: string
  label?: string
  externalIds?: IntegrationExternalIdentifier1[]
  taxStatus: TaxStatus
  customerAliases?: string[]
}
export type ListCustomersResponse = {
  items: Customer2[]
  pagination: PaginationMeta
}
export type CustomerAlias = {
  id: string
  sequenceAccountId: string
  customerId: string
  value: string
  createdAt: string
}
export type GetCustomerAliasesEndpointEndpointResponse = {
  items: CustomerAlias[]
}
export type ListCustomerAliasEndpointProductListCustomerAliasResponseModel = {
  id: string
  sequenceAccountId: string
  customerId: string
  value: string
  createdAt: string
  deletedAt?: string
  label?: string
}
export type ListCustomerAliasEndpointProductListCustomerAliasPaginatedResponseModel =
  {
    items: ListCustomerAliasEndpointProductListCustomerAliasResponseModel[]
    pagination: PaginationMeta
  }
export type CreateCustomerAliasEndpointProductCreateCustomerAliasResponseModel =
  {
    id: string
    sequenceAccountId: string
    customerId: string
    value: string
    createdAt: string
    deletedAt?: string
    label?: string
  }
export type CreateCustomerAliasEndpointCreateCustomerAliasRequestModel = {
  sequenceAccountId: string
  customerId: string
  value: string
  deletedAt?: string
  label?: string
}
export type MetricType = 'SIMPLE' | 'GROUPED'
export type AggregationType = 'COUNT' | 'UNIQUE' | 'SUM' | 'CUSTOM'
export type CustomParameterType = 'INTEGER' | 'POSITIVE_INTEGER'
export type CustomMetricParameter = {
  id: string
  usageMetricId: string
  name: string
  type: CustomParameterType
  description: string
  defaultValue: string
}
export type UsageMetric1 = {
  id: string
  sequenceAccountId: string
  name: string
  description?: string
  metricType: MetricType
  eventType: string
  aggregationType: AggregationType
  unit?: string
  createdAt?: string
  deletedAt?: string
  propertyFilters: {
    [key: string]: any
  }
  caseSensitive?: boolean
  propertiesToNegate?: any[]
  parameters: CustomMetricParameter[]
}
export type PrivilegedListUsageMetricEndpointProductResponseModel = {
  items: UsageMetric1[]
  pagination: PaginationMeta
}
export type UsageMetricCalculationResponse = {
  name: string
  metricType: MetricType
  eventType: string
  aggregationType: AggregationType
  eventCount: number
  value: number
  unit?: string
  minEventId?: string
  maxEventId?: string
}
export type ListUniqueUsageEventTypesEndpointEndpointResponseModel = {
  items: string[]
}
export type ListUniqueUsageEventPropertiesEndpointEndpointResponseModel = {
  items: string[]
}
export type UsageEventsByDay = {
  eventCount: number
  date: string
}
export type GetUsageEventsInsightsResponseModel = {
  totalEventCount: number
  eventsByDay: UsageEventsByDay[]
}
export type UsageDataResponse1 = {
  usageStart: string
  usageEnd: string
  metricName: string
  metricId: string
  total: string
  usageData: UsageData[]
}
export type OptionalUsageDataResponse1 = {
  data?: UsageDataResponse1
}
export type SeatSnapshot = {
  id: string
  customerSnapshotId: string
  customerAlias: string
  seatType: string
  sequenceAccountId: string
  snapshotTimestamp: string
  total: number
  seatChanges: any[]
}
export type ListSeatSnapshotsEndpointEndpointResponseModel = {
  items: SeatSnapshot[]
  pagination: PaginationMeta
}
export type SeatChangeType = 'SEAT_ADDED' | 'SEAT_REMOVED'
export type SeatChange = {
  id: string
  seatSnapshotId: string
  externalId?: string
  timestamp: string
  description?: string
  reason?: string
  changeType: SeatChangeType
}
export type CreateSeatSnapshotEndpointProductCreateSeatSnapshotResponseModel = {
  id: string
  customerSnapshotId: string
  customerAlias: string
  seatType: string
  sequenceAccountId: string
  snapshotTimestamp: string
  total: number
  seatChanges: SeatChange[]
}
export type SeatChangeRequest = {
  externalId?: string
  timestamp: string
  description?: string
  reason?: string
  changeType: SeatChangeType
}
export type CreateSeatSnapshotEndpointCreateSeatSnapshotRequestModel = {
  customerSnapshotId?: string
  customerAlias: string
  seatType: string
  snapshotTimestamp: string
  total: number
  seatChanges: SeatChangeRequest[]
}
export type ListSeatTypeEndpointProductListSeatTypeResponseModel = {
  id: string
  sequenceAccountId: string
  key: string
  description?: string
  label: string
  sequenceUserId?: string
  createdAt: string
  updatedAt: string
}
export type ListSeatTypeEndpointProductListSeatTypePaginatedResponseModel = {
  items: ListSeatTypeEndpointProductListSeatTypeResponseModel[]
  pagination: PaginationMeta
}
export type CreateSeatTypeEndpointProductCreateSeatTypeResponseModel = {
  id: string
  sequenceAccountId: string
  key: string
  description?: string
  label: string
  sequenceUserId?: string
  createdAt: string
  updatedAt: string
}
export type CreateSeatTypeEndpointCreateSeatTypeRequestModel = {
  key: string
  description?: string
  label: string
  sequenceUserId?: string
}
export type GetSeatTypeEndpointProductGetSeatTypeResponseModel = {
  id: string
  sequenceAccountId: string
  key: string
  description?: string
  label: string
  sequenceUserId?: string
  createdAt: string
  updatedAt: string
}
export type ListLatestSeatTotalsEndpointProductListLatestSeatTotalsResponseModel =
  {
    id: string
    customerAlias: string
    seatType: string
    total: number
    changeTimestamp: string
  }
export type ListLatestSeatTotalsEndpointProductListLatestSeatTotalsPaginatedResponseModel =
  {
    items: ListLatestSeatTotalsEndpointProductListLatestSeatTotalsResponseModel[]
    pagination: PaginationMeta
  }
export type SeatBalance = {
  seatType: string
  total: number
  changeTimestamp: string
}
export type CustomerSeatBalances = {
  id: string
  sequenceAccountId: string
  legalName: string
  customerAliases: string[]
  total: number
  changeTimestamp: string
  balances: SeatBalance[]
}
export type ListCustomerSeatBalancesEndpointListCustomerSeatBalancesPaginatedResponseModel =
  {
    items: CustomerSeatBalances[]
    pagination: PaginationMeta
  }
export const {
  useGetInvoiceMerchantDetailsQuery,
  useGetMerchantForSequenceAccountQuery,
  usePostInvoicesTotalsMutation,
  useGetInvoicesQuery,
  usePostInvoicesMutation,
  usePostInvoicesByInvoiceLineItemsMutation,
  usePostInvoicesByInvoiceLineItemGroupsMutation,
  useGetInvoicesByIdQuery,
  usePutInvoicesByIdMutation,
  usePutInvoicesByInvoiceLineItemsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemsByIdMutation,
  usePutInvoicesByInvoiceLineItemGroupsByIdMutation,
  useDeleteInvoicesByInvoiceLineItemGroupsByIdMutation,
  usePostInvoicesByIdFinalizeAndSendMutation,
  usePostInvoicesFinalizeAndSendMutation,
  usePostInvoicesByIdFinalizeMutation,
  usePostInvoicesFinalizeMutation,
  useGetInvoicesInvoicesGridQuery,
  useGetInvoicesByInvoiceIdLineItemGroupsByLineItemGroupIdUsageDataQuery,
  useGetInvoicesByIdLineItemGroupsAllQuery,
  useGetInvoicesByIdLineItemsAllQuery,
  useGetInvoicesByInvoiceIdUsageItemGroupsQuery,
  usePostInvoicesByIdRecalculateMutation,
  usePostInvoicesRecalculateMutation,
  usePostInvoicesByIdSendMutation,
  usePostInvoicesByIdSendPaymentReminderMutation,
  usePostInvoicesSendMutation,
  usePostInvoicesByIdSendByTestEmailMutation,
  usePutInvoicesByIdPaymentStatusMutation,
  usePutInvoicesPaymentStatusMutation,
  usePostInvoicesByIdVoidMutation,
  usePostCreditNotesByIdLineItemsFromInvoiceMutation,
  usePostCreditNotesTotalsMutation,
  useGetCreditNotesQuery,
  usePostCreditNotesMutation,
  usePostCreditNotesByCreditnoteLineItemsMutation,
  usePostCreditNotesByCreditnoteLineItemGroupsMutation,
  useGetCreditNotesByIdQuery,
  usePutCreditNotesByIdMutation,
  usePutCreditNotesByCreditnoteLineItemsByIdMutation,
  useDeleteCreditNotesByCreditnoteLineItemsByIdMutation,
  usePutCreditNotesByCreditnoteLineItemGroupsByIdMutation,
  useDeleteCreditNotesByCreditnoteLineItemGroupsByIdMutation,
  usePostCreditNotesByIdFinalizeAndSendMutation,
  usePostCreditNotesByIdFinalizeMutation,
  useGetCreditNotesByIdLineItemsAllQuery,
  useGetCreditNotesByIdLineItemGroupsAllQuery,
  usePostCreditNotesByIdSendMutation,
  usePostCreditNotesByIdSendByTestEmailMutation,
  usePostCreditNotesByIdVoidMutation,
  useGetInsightsOutstandingInvoicesQuery,
  useGetInsightsInvoicedRevenueQuery,
  usePostInvoiceSettingsMutation,
  usePostProductsMutation,
  useGetProductsByIdQuery,
  usePutProductsByIdMutation,
  useGetApiNotificationsPoliciesQuery,
  usePostApiNotificationsPoliciesMutation,
  useDeleteApiNotificationsPoliciesIdMutation,
  useGetTaxRatesQuery,
  usePostTaxRatesMutation,
  usePutTaxRatesByIdArchiveMutation,
  usePostTaxRegistrationsMutation,
  useGetTaxRegistrationsForCustomerByIdQuery,
  usePutTaxRegistrationsByIdMutation,
  useGetSequenceAccountsByIdQuery,
  usePutSequenceAccountsByIdMutation,
  useGetUsersQuery,
  useGetUsersByIdQuery,
  useDeleteUsersByIdMutation,
  useGetOauthClientsQuery,
  usePostOauthClientsMutation,
  useDeleteOauthClientsByIdMutation,
  useGetSequenceAccountIdQuery,
  useGetSequenceAccountSettingsQuery,
  usePutSequenceAccountSettingsMutation,
  useGetApiIntegrationsCustomersQuery,
  useGetApiIntegrationsCustomersServiceQuery,
  usePostApiIntegrationsGoogleSheetsExportMutation,
  usePostApiIntegrationsServiceCustomersMutation,
  usePostApiIntegrationsServiceCustomersProgressMutation,
  usePostApiIntegrationsServiceSyncInvoiceInvoiceIdMutation,
  useGetPricesQuery,
  useGetPricesByIdQuery,
  useGetPricesByIdMetadataQuery,
  useGetCreditsFilteredQuery,
  useGetCreditsByCustomerIdGrantsQuery,
  useGetCreditsByCustomerIdBalancesQuery,
  usePostCreditsMutation,
  useGetInsightsCustomerTotalsQuery,
  useGetCustomersByIdQuery,
  usePutCustomersByIdMutation,
  useGetCustomersQuery,
  usePutCustomersByIdArchiveMutation,
  useGetCustomersByAliasByAliasQuery,
  useGetCustomersByIdAliasesQuery,
  useGetCustomerAliasesQuery,
  usePostCustomerAliasesMutation,
  useGetUsageMetricsQuery,
  useGetUsageMetricsByUsageMetricIdCalculateQuery,
  useGetUniqueUsageEventTypesQuery,
  useGetUniqueUsageEventPropertiesQuery,
  useGetInsightsUsageEventsQuery,
  useGetInsightsUsageMetricsByUsageMetricIdUsageDataQuery,
  useGetSeatSnapshotsQuery,
  usePostSeatSnapshotsMutation,
  useGetSeatTypesQuery,
  usePostSeatTypesMutation,
  useGetSeatTypesByIdQuery,
  useGetLatestSeatTotalsQuery,
  useGetSeatBalancesQuery
} = injectedRtkApi
