import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Circle,
  Flex,
  IconButton,
  Text
} from '@chakra-ui/react'
import { CrossIcon } from '@sequencehq/core-components'
import {
  accountDetailsTypeDescription,
  Currency,
  currencyToName,
  MerchantBankAccountDetailsModel,
  toCurrencyDescription
} from '@sequencehq/core-models'
import { Lato16Regular, OrangeOrange50 } from '@sequencehq/design-tokens'
import { CheckboxField, FormErrors } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { CanadianBankAccountFields } from 'components/BankDetailsSettings/CanadianBankAccountFields'
import { CanadianInternationalFields } from 'components/BankDetailsSettings/CanadianInternationalFields'
import { IbanFields } from 'components/BankDetailsSettings/IbanFields'
import { UkBankAccountFields } from 'components/BankDetailsSettings/UkBankAccountFields'
import { UsAchFields } from 'components/BankDetailsSettings/UsAchFields'
import { UsWireFields } from 'components/BankDetailsSettings/UsWireFields'
import { SelectField } from 'components/FormFields'
import Spinner from 'components/Loading/Spinner'
import SettingsTab from 'components/SettingsPage/SettingsTab'
import { MerchantIcon, PlusIcon } from '@sequencehq/core-components'
import { useGetSequenceAccountSettingsQuery } from 'features/api'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { handleFormResponse } from 'lib/formValidation'
import { FC, Fragment, memo, useMemo } from 'react'
import { AlertTriangle } from 'react-feather'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { match } from 'ts-pattern'
import { set } from 'lodash/fp'
import { useNotifications } from 'lib/hooks/useNotifications'
import { AustralianBankAccountFields } from 'components/BankDetailsSettings/AustralianBankAccountFields'
import { useFetchMerchantBySequenceAccountId } from 'components/MerchantSettings/hooks/useFetchMerchantBySequenceAccountId.ts'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'

interface FormValues {
  bankAccountDetails: any[]
  includeBeneficiaryAddressInPaymentDetails: boolean
}

const BankDetailsSettings: FC = memo(() => {
  const notifications = useNotifications()
  const {
    merchant,
    loading: isFetchingMerchant,
    refetch: refetchMerchant
  } = useFetchMerchantBySequenceAccountId()

  const initialValues = useMemo(() => {
    if (isFetchingMerchant || !merchant) {
      return
    }

    return {
      ...merchant,
      bankAccountDetails: merchant.bankAccountDetails.map(details =>
        details.value.type === 'CA_INTERNATIONAL' &&
        details.value.canadianInternationalDestination?.intermediaryBank
          ? {
              key: details.key,
              value: {
                ...details.value,
                canadianInternationalDestination: {
                  ...details.value.canadianInternationalDestination,
                  includeIntermediaryBank: true
                }
              }
            }
          : details
      )
    }
  }, [merchant])

  if (!initialValues || !merchant) {
    return <Spinner></Spinner>
  }

  return (
    <SettingsTab
      title="Merchant Details"
      summarySection={
        <Flex flexDirection="row">
          <Circle backgroundColor="#F0F4F7" size="37px">
            <MerchantIcon></MerchantIcon>
          </Circle>
          <Box width="8px"></Box>
          <Flex flexDirection="column">
            <Box height="12px"></Box>
            <Text>Manage bank details for receiving payments </Text>
          </Flex>
        </Flex>
      }
    >
      <Form<FormValues>
        keepDirtyOnReinitialize
        destroyOnUnregister
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return
            changeValue(state, field, () => value)
          }
        }}
        initialValues={initialValues}
        onSubmit={async (values, form) => {
          const registeredFields = form.getRegisteredFields()

          const keyReplaces = (
            values.bankAccountDetails as { key: string }[]
          ).map((account, idx) => ({
            formKeyPrefix: `bankAccountDetails[${idx}]`,
            resultKeyPrefix: account?.key
              ? `bankAccountDetails[${account.key}]`
              : `bankAccountDetails.${idx}`
          }))

          const remappedKeys = registeredFields.reduce((acc, formKey) => {
            const replaceMatch = keyReplaces.find(replaceConfig =>
              formKey.startsWith(replaceConfig.formKeyPrefix)
            )

            if (!replaceMatch) {
              return acc
            }

            return {
              ...acc,
              [formKey.replace(
                replaceMatch.formKeyPrefix,
                replaceMatch.resultKeyPrefix
              )]: formKey
            }
          }, {})

          const result = await dashboard20240730Client.putMerchant({
            id: merchant.id,
            body: {
              ...merchant,
              ...values,
              bankAccountDetails: values.bankAccountDetails.map(details =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
                details.value.type === 'CA_INTERNATIONAL' &&
                /* eslint-disable @typescript-eslint/no-unsafe-member-access */
                !details.value.canadianInternationalDestination
                  ?.includeIntermediaryBank
                  ? {
                      key: details.key,
                      value: {
                        ...details.value,
                        canadianInternationalDestination: {
                          ...details.value.canadianInternationalDestination,
                          intermediaryBank: undefined
                        }
                      }
                    }
                  : /* eslint-enable */
                    details
              )
            }
          })

          const errors = handleFormResponse(
            result,
            registeredFields,
            remappedKeys
          )

          if (!errors) {
            notifications.displayNotification('Bank details updated', {
              type: 'success'
            })
            await refetchMerchant()
            return
          }

          const bankAccountDetailsErrors = (
            Object.entries(errors ?? {}).reduce((acc, [key, value]) => {
              return set(key)(value)(acc)
            }, {}) as { bankAccountDetails: unknown[] }
          )?.bankAccountDetails

          if (!bankAccountDetailsErrors) {
            return errors
          }

          return {
            ...errors,
            bankAccountDetails: bankAccountDetailsErrors.reduce(
              (acc: { [key: number]: unknown }, error, idx) => ({
                ...acc,
                [idx]: error
              }),
              {}
            )
          }
        }}
        render={({
          handleSubmit,
          submitting,
          submitError,
          dirty,
          errors,
          submitErrors,
          values
        }) => {
          return (
            <Flex px={5} py={2} flexDirection="column" w="100%">
              <FormErrors formError={submitError} />

              <Box height={4} />
              <CheckboxField fieldName="includeBeneficiaryAddressInPaymentDetails">
                <Text {...Lato16Regular} paddingRight="8px">
                  Include beneficiary address in payment details
                </Text>
              </CheckboxField>
              <Box height={4} />

              <BankAccountDetails
                errors={{
                  ...(errors ?? {}),
                  ...(submitErrors ?? {})
                }}
                values={values}
              ></BankAccountDetails>

              <Box height={5} />
              <Flex justifyContent="flex-end">
                <Box w="16px"></Box>
                <Button
                  variant="primary"
                  isDisabled={submitting || !dirty}
                  onClick={() => void handleSubmit()}
                >
                  Save changes
                </Button>
              </Flex>
            </Flex>
          )
        }}
      />
    </SettingsTab>
  )
})

export default BankDetailsSettings

interface BankAccountDetailsProps {
  errors: ValidationErrors
  values: any
}

const BankAccountDetails: FC<BankAccountDetailsProps> = ({
  errors,
  values
}) => {
  const { data: accountSettings } = useGetSequenceAccountSettingsQuery()
  const availableCurrencies = accountSettings?.value()?.enabledCurrencies || []

  const bankDetailsErrors = errors ? errors['bankAccountDetails'] : undefined
  return (
    <FieldArray<MerchantBankAccountDetailsModel> name="bankAccountDetails">
      {({ fields }) => (
        <Accordion>
          <Box height={2} />
          {fields.map((name, index) => (
            <Fragment key={index}>
              <AccordionItem
                backgroundColor="white"
                borderRadius="8px"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.40"
                w="100%"
              >
                <h2>
                  <AccordionButton py={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex flexDirection="row">
                        <Text textStyle="accordionButtonTitle">
                          {toCurrencyDescription(fields.value[index]['key'])} -{' '}
                          {
                            accountDetailsTypeDescription[
                              fields.value[index].value['type']
                            ]
                          }
                        </Text>
                        <Box width="20px"></Box>
                        {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          !!bankDetailsErrors && bankDetailsErrors[index] && (
                            <AlertTriangle
                              size="20px"
                              color={OrangeOrange50}
                            ></AlertTriangle>
                          )
                        }
                      </Flex>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={8} pt={1}>
                  <Flex flexDirection="column">
                    <BankAccountDetailsGroup
                      fieldName={name}
                      field={fields.value[index]}
                      removeGroup={() => fields.remove(index)}
                      currencyOptions={availableCurrencies}
                      values={values}
                      index={index}
                    ></BankAccountDetailsGroup>
                    <Box height="24px" />
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              <Box height={2}></Box>
            </Fragment>
          ))}

          <Box height={4} />
          {availableCurrencies.length > 0 && (
            <Button
              size="sm"
              leftIcon={<PlusIcon />}
              variant="secondary"
              onClick={() =>
                fields.push({
                  key:
                    availableCurrencies.find(
                      ccy =>
                        !fields.value.map(field => field['key']).includes(ccy)
                    ) || availableCurrencies[0],
                  value: { type: 'IBAN', iban: undefined }
                })
              }
            >
              Add details for another currency
            </Button>
          )}
        </Accordion>
      )}
    </FieldArray>
  )
}

interface BankAccountDetailsGroupProps {
  fieldName: string
  field: MerchantBankAccountDetailsModel
  removeGroup: () => MerchantBankAccountDetailsModel
  currencyOptions: Currency[]
  values: any
  index: number
}

const BankAccountDetailsGroup: FC<BankAccountDetailsGroupProps> = ({
  fieldName,
  field,
  removeGroup,
  currencyOptions,
  values,
  index
}) => {
  return (
    <>
      <Flex width="100%">
        <Box width="250px">
          <SelectField
            fieldName={`${fieldName}key`}
            fieldLabel="Currency"
            options={currencyOptions.map(ccy => ({
              value: ccy,
              label: `${ccy} - ${currencyToName[ccy]}`
            }))}
            validate={required}
          />
        </Box>

        <Box width="10px"></Box>

        <Box width="200px">
          <SelectField
            fieldName={`${fieldName}.value.type`}
            fieldLabel="Account Type"
            options={[
              { label: accountDetailsTypeDescription['IBAN'], value: 'IBAN' },
              {
                label: accountDetailsTypeDescription['UK_BANK_ACCOUNT'],
                value: 'UK_BANK_ACCOUNT'
              },
              {
                label: accountDetailsTypeDescription['US_ACH'],
                value: 'US_ACH'
              },
              {
                label: accountDetailsTypeDescription['US_WIRE'],
                value: 'US_WIRE'
              },
              {
                label: accountDetailsTypeDescription['CA_BANK_ACCOUNT'],
                value: 'CA_BANK_ACCOUNT'
              },
              {
                label: accountDetailsTypeDescription['CA_INTERNATIONAL'],
                value: 'CA_INTERNATIONAL'
              },
              {
                label: accountDetailsTypeDescription['AUSTRALIAN'],
                value: 'AUSTRALIAN'
              }
            ]}
            validate={required}
          />
        </Box>

        <Box width="10px"></Box>

        {match(field.value.type)
          .with('IBAN', () => <IbanFields fieldName={fieldName}></IbanFields>)
          .with('UK_BANK_ACCOUNT', () => (
            <UkBankAccountFields fieldName={fieldName}></UkBankAccountFields>
          ))
          .with('US_ACH', () => (
            <UsAchFields fieldName={fieldName}></UsAchFields>
          ))
          .with('US_WIRE', () => (
            <UsWireFields fieldName={fieldName}></UsWireFields>
          ))
          .with('CA_BANK_ACCOUNT', () => (
            <CanadianBankAccountFields
              fieldName={fieldName}
            ></CanadianBankAccountFields>
          ))
          .with('CA_INTERNATIONAL', () => (
            <CanadianInternationalFields
              fieldName={fieldName}
              values={values}
              index={index}
            ></CanadianInternationalFields>
          ))
          .with('AUSTRALIAN', () => (
            <AustralianBankAccountFields
              fieldName={fieldName}
            ></AustralianBankAccountFields>
          ))
          .exhaustive()}
        <Flex flexGrow="1"></Flex>
        <Box width="10px"></Box>
        <Flex flexDirection="column" width="28px">
          <Box height="24px"></Box>
          <IconButton
            aria-label="Delete details for currency"
            icon={<CrossIcon />}
            onClick={() => {
              removeGroup()
            }}
            variant="clear"
          />
        </Flex>
      </Flex>
    </>
  )
}
