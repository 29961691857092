import { Button, Link, Text } from '@chakra-ui/react'
import { Lato14ExtendedRegular, Lato16Bold } from '@sequencehq/design-tokens'
import { PageContainer } from 'modules/LoginApp/components/common/PageContainer'
import { Section } from 'modules/LoginApp/components/common/Section'
import { Wordmark } from '@sequencehq/core-components'

export const PkceMismatchPage = () => {
  return (
    <PageContainer>
      <Wordmark width="126" height="26.74" />
      <Section gap="8px">
        <Text {...Lato16Bold} color="gray.90">
          There seems to be a mix-up with your magic link
        </Text>
        <Text {...Lato14ExtendedRegular} color="gray.80">
          If you've requested more than one link, only the latest one will work.
          Please check your email for the most recent magic link we sent you or
          try again.
        </Text>
        <Button
          as={Link}
          href="/auth/login"
          mt="2"
          variant="primary2"
          width="100%"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Try again
        </Button>
      </Section>
    </PageContainer>
  )
}

export default PkceMismatchPage
