import {
  CubeReducerState,
  Phase,
  Discount,
  Minimum,
  CubeEditorData,
  PhaseDuration
} from 'modules/Cube/domain/cube.domain.types'
import { LoadedBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useLoadBillingScheduleEditor'

import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'

import deepmerge from 'deepmerge'
import { scheduleStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { sortArrayByStartDate } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/sortArrayByStartDate'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { durationsToDateAdapter } from '@sequencehq/utils/dates'
import { groupBy } from 'lodash/fp'
import invariant from 'tiny-invariant'

/**
 * Loads the duration of a phase from the start/end dates of phases on the API
 * response. Also, importantly, accounts for the 'MILESTONE' duration which is
 * a special - and somewhat hacky - case.
 * @param param0
 * @returns
 */
export const parseBillingSchedulePhaseDuration = ({
  startDate,
  endDate
}: {
  startDate: Date | undefined
  endDate: Date | undefined
}): PhaseDuration => {
  /**
   * The open ended case is where the schedule never ends. Therefore, the
   * end date of the open ended phase - which must be the last phase - is
   * undefined.
   */
  if (!endDate) {
    return 'OPEN_ENDED'
  }

  const duration = durationsToDateAdapter.toDuration(startDate)(endDate)

  invariant(duration, 'Duration should be defined')
  /**
   * Milestone billing is a special case where a phase has no defined end
   * date, but may be followed by other phases. We need to distinguish between
   * open ended and milestone because of the aforementioned behaviour differences.
   * The API doesn't support an enum or similar for this, so for now we work
   * around this by converting a MILESTONE phase into a 100 year duration.
   *
   * This is an unreasonably long duration, so is 'safe' for us to use as the
   * replacement for an enum value until we have the API support.
   */
  if (duration?.years === 100) {
    return 'MILESTONE'
  }
  return duration
}

export const loadBillingScheduleFromPhases = (
  billingScheduleData: LoadedBillingScheduleData
): {
  common: CubeEditorData['common']
  schedule: CubeEditorData['schedule']
  phases: CubeEditorData['phases']
  minimums: Record<Minimum['id'], Minimum>
  discounts: Record<Discount['id'], Discount>
} => {
  const { phases, minimums, allPhaseDiscounts } =
    billingScheduleData.billingSchedule.phases.reduce(
      (acc, phase) => {
        const minimumsInPhase = (phase.minimums ?? []).map(minimum => ({
          id: crypto.randomUUID(),
          name: '',
          value: `${minimum.amount}`,
          scope: {
            target: minimum.restrictToPrices?.length ? 'specific' : 'allUsage',
            priceIds: minimum.restrictToPrices
          }
        })) as Minimum[]

        const phaseDiscounts = (phase.discounts ?? []).map(discount => ({
          id: crypto.randomUUID(),
          amount: discount.amount,
          message: discount.message,
          priceIds: discount.restrictToPrices,
          discountCalculationType: discount.type,
          duration: 'FOREVER',
          applyToAllPrices: !discount.restrictToPrices.length
        })) as Discount[]

        return deepmerge(acc, {
          phases: [
            {
              id: phase.id,
              name: phase.name ?? '',
              startDate: apiDatesAdapters.fromApi(phase.startDate),
              endDate: phase.endDate
                ? apiDatesAdapters.fromApi(phase.endDate)
                : undefined,
              minimumIds: minimumsInPhase.map(({ id }) => id),
              priceIds: phase.priceIds,
              discountIds: phaseDiscounts.map(({ id }) => id),
              recurrencePreference: phase.recurrencePreference
            }
          ],
          minimums: minimumsInPhase,
          allPhaseDiscounts: phaseDiscounts
        })
      },
      {} as {
        phases: (Omit<Phase, 'duration'> & {
          startDate: Date | undefined
          endDate: Date | undefined
        })[]
        minimums: Minimum[]
        allPhaseDiscounts: Discount[]
      }
    )

  const sortedPhases: Array<Phase> = sortArrayByStartDate<
    Omit<Phase, 'duration'> & {
      startDate: Date | undefined
      endDate: Date | undefined
    }
  >(phases).map(({ startDate, endDate, ...rest }) => ({
    ...rest,
    duration: parseBillingSchedulePhaseDuration({ startDate, endDate })
  }))

  const currency = billingScheduleData.prices[0]?.currency ?? undefined

  return {
    common: {
      id: billingScheduleData.billingSchedule.id,
      startDate: apiDatesAdapters.fromApi(
        billingScheduleData.billingSchedule.startDate
      ),
      status: scheduleStatusAdapter.in(
        billingScheduleData.billingSchedule.status
      ),
      currency,
      title: '',
      alias: '',
      customerId: billingScheduleData.billingSchedule.customerId,
      isArchived: Boolean(billingScheduleData.billingSchedule.archivedAt),
      phaseIds: sortedPhases.map(({ id }) => id),
      createdAt: new Date(billingScheduleData.billingSchedule.createdAt)
    },
    schedule: {
      stripePayment:
        billingScheduleData.billingScheduleSettings?.paymentProvider ===
        'STRIPE',
      stripeAutoCharge: Boolean(
        billingScheduleData.billingScheduleSettings?.autoCharge
      ),
      recurrenceDayOfMonth:
        billingScheduleData.billingSchedule.recurrenceDayOfMonth ?? 1,
      purchaseOrderNumber:
        billingScheduleData.billingSchedule.purchaseOrderNumber ?? '',
      reference: billingScheduleData.billingSchedule.reference ?? '',
      label: billingScheduleData.billingSchedule.label ?? '',
      autoIssueInvoices:
        billingScheduleData.billingSchedule.autoIssueInvoices ?? false,
      taxRateId:
        billingScheduleData.billingSchedule.taxRates?.[0]?.taxRateId ?? '',
      rollUpBilling: billingScheduleData.billingSchedule.rollUpBilling ?? false,
      attachmentAssets:
        billingScheduleData.billingSchedule.attachmentAssets ?? []
    },
    phases: arrayToIdKeyedObject(sortedPhases),
    minimums: arrayToIdKeyedObject(minimums),
    discounts: arrayToIdKeyedObject(allPhaseDiscounts)
  }
}

const transformListPrices = (
  listPrices: LoadedBillingScheduleData['listPrices']
) => {
  return groupBy('productId', listPrices)
}

/**
 * A collection of loader/transformers to create loadAction data from a variety of sources (e.g.
 * different Api versions, imports, or potentially even quotes in the future)
 * @param billingScheduleData
 * @returns
 */
export const phasesAdapterIn = (
  billingScheduleData: LoadedBillingScheduleData,
  configuration: CubeReducerState['configuration']
): Pick<CubeReducerState, 'data' | 'configuration'> => {
  const transformedBillingScheduleData =
    loadBillingScheduleFromPhases(billingScheduleData)

  return {
    data: {
      ...transformedBillingScheduleData,
      quote: INITIAL_CUBE_STATE.data.quote,
      presentation: INITIAL_CUBE_STATE.data.presentation,
      customers: arrayToIdKeyedObject(billingScheduleData.customers),
      taxRates: arrayToIdKeyedObject(billingScheduleData.taxRates),
      products: arrayToIdKeyedObject(billingScheduleData.products),
      prices: arrayToIdKeyedObject(billingScheduleData.prices),
      listPrices: transformListPrices(billingScheduleData.listPrices),
      merchantBranding: INITIAL_CUBE_STATE.data.merchantBranding,
      contacts: INITIAL_CUBE_STATE.data.contacts
    },
    configuration: {
      ...configuration,
      currency: {
        default: billingScheduleData.enabledCurrencies[0],
        enabled: billingScheduleData.enabledCurrencies
      }
    }
  }
}
