import { TaxRate } from 'Settings/domain/taxRates.types'
import {
  type CountriesAlpha2,
  countriesAlpha2
} from '@sequencehq/api/commonEnums'

export function groupTaxRatesByCountry(
  _taxRates: TaxRate[],
  options: { sortByCountry: boolean } = { sortByCountry: true }
) {
  let taxRates = _taxRates

  if (options.sortByCountry) {
    taxRates = _taxRates.sort((a, b) => {
      const countryNameA = countriesAlpha2[a.country] || a.country
      const countryNameB = countriesAlpha2[b.country] || b.country

      return countryNameA.localeCompare(countryNameB)
    })
  }

  return taxRates.reduce(
    (acc, taxRate) => ({
      ...acc,
      [taxRate.country]: [...(acc[taxRate.country] || []), taxRate].sort(
        (a, b) => {
          return a.taxCategoryName?.localeCompare(b.taxCategoryName || '') || -1
        }
      )
    }),
    {} as Record<CountriesAlpha2, TaxRate[]>
  )
}
