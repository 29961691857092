import { PricingStructure } from '@sequencehq/api/src/clients/dashboard/v20240730/commonModels/pricingStructure'
import {
  BillingFrequency,
  DiscountCalculationType,
  PriceModel
} from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import {
  applyDiscountToAmount,
  getPriceStructureAmount
} from '../prices/calculateDiscountedPrice'

export type Discount = {
  restrictToPrices: string[]
  amount: string
  discountCalculationType: DiscountCalculationType
}

export type Price = {
  structure: PricingStructure
  billingFrequency: BillingFrequency
  id?: string
  temporaryId?: string
}

export type Phase = {
  discounts?: Discount[]
  prices: Price[]
  duration?: {
    months?: number
    years?: number
  }
}

const getDiscountPriceIdMap = (discounts: Discount[]) => {
  if (discounts[0]?.restrictToPrices.length === 0) {
    return {
      applyToAllPrices: discounts[0]
    }
  }

  return discounts.reduce((discountPriceIdMap, discount) => {
    return {
      ...discountPriceIdMap,
      ...discount.restrictToPrices.reduce(
        (curr, priceId) => ({
          ...curr,
          [priceId]: discount
        }),
        {}
      )
    }
  }, {})
}

const getPriceFullRate = (price: Price) => {
  const amount = getPriceStructureAmount(price as Pick<PriceModel, 'structure'>)

  if (price.structure.pricingType === 'SEAT_BASED') {
    if (!price.structure.tiers?.length) {
      return amount * price.structure.contractedMinimumSeats
    }

    const { contractedMinimumSeats, tiers } = price.structure
    const total = tiers.reduce((acc, tier, tierIndex) => {
      const minInclusive: number =
        tierIndex === 0 ? 1 : Number(tiers[tierIndex - 1].upperBound) + 1

      const maxInclusive: number = tier.upperBound
        ? Number(tier.upperBound)
        : Infinity

      const seatsConsumed: number = Array.from({
        length: contractedMinimumSeats
      }).reduce((acc: number, _, seatsConsumedIndex: number) => {
        const seatNumber = seatsConsumedIndex + 1

        if (seatNumber >= minInclusive && seatNumber <= maxInclusive) {
          return acc + 1
        }

        return acc
      }, 0)

      const pricePerSeat = Number(tier.price)
      const totalForSeats = seatsConsumed * pricePerSeat
      const totalForFixedFee =
        seatsConsumed > 0 && tier.fee ? Number(tier.fee) : 0
      return acc + totalForSeats + totalForFixedFee
    }, 0)

    return total
  }

  if (
    price.structure.pricingType === 'FIXED' ||
    price.structure.pricingType === 'ONE_TIME'
  ) {
    return amount
  }
}

export const calculatePhaseTotalAmount = (
  phases: Phase[]
): { totalFullRate: number; totalDiscountedRate: number } => {
  return phases.reduce(
    (accum, phase) => {
      if (!phase.duration) {
        return accum
      }

      const discountPriceIdMap: Record<string, Discount> =
        phase.discounts && phase.discounts.length > 0
          ? getDiscountPriceIdMap(phase.discounts)
          : {}

      const durationInMonths =
        (phase.duration?.months ?? 0) + (phase.duration?.years ?? 0) * 12

      const price = phase.prices.reduce(
        (acc, price) => {
          const isPricingTypeSupported = [
            'ONE_TIME',
            'FIXED',
            'SEAT_BASED'
          ].includes(price.structure.pricingType)

          const hasNoMinimumSeat =
            price.structure.pricingType === 'SEAT_BASED' &&
            !price.structure.contractedMinimumSeats

          if (!isPricingTypeSupported || hasNoMinimumSeat) {
            return acc
          }

          const amountMultiplier = match(price.billingFrequency)
            .with('MONTHLY', () => durationInMonths)
            .with('QUARTERLY', () => durationInMonths / 3)
            .with('YEARLY', () => durationInMonths / 12)
            .otherwise(() => 1)

          const priceFullRate = getPriceFullRate(price)

          if (!priceFullRate) {
            return acc
          }

          const priceId = price.temporaryId ?? price.id

          const discount =
            discountPriceIdMap['applyToAllPrices'] ??
            discountPriceIdMap[priceId!] ??
            undefined

          const priceDiscountedRate = discount
            ? applyDiscountToAmount({
                amount: priceFullRate,
                discount: {
                  amount: Number(discount.amount),
                  discountCalculationType: discount.discountCalculationType
                }
              })
            : priceFullRate

          return {
            priceFullRate: acc.priceFullRate + priceFullRate * amountMultiplier,
            priceDiscountedRate:
              acc.priceDiscountedRate + priceDiscountedRate * amountMultiplier
          }
        },
        {
          priceFullRate: 0,
          priceDiscountedRate: 0
        }
      )

      return {
        totalFullRate: accum.totalFullRate + price.priceFullRate,
        totalDiscountedRate:
          accum.totalDiscountedRate + price.priceDiscountedRate
      }
    },
    {
      totalFullRate: 0,
      totalDiscountedRate: 0
    }
  )
}
