import { usePricingEditorDomainContext } from '../../communication'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import Sidebar from 'components/Sidebar'
import { CreateSeatTypeForm } from 'Seats/SeatMetric'
import invariant from 'tiny-invariant'

const useConnector = () => {
  const ctx = usePricingEditorDomainContext()
  const navigate = useNavigate()

  const onSuccess = useCallback(
    (newMetric: { id: string }) => {
      invariant(
        ['SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR'].includes(
          ctx.queries.rawData.data.pricingEditorData.common.pricingModel
        ),
        'Only seat based pricing models are valid'
      )

      ctx.mutators.common.updatePricingEditorData({
        [ctx.queries.rawData.data.pricingEditorData.common.pricingModel]: {
          seatMetricId: newMetric.id
        }
      })
      navigate('..')
    },
    [navigate, ctx]
  )

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    onSuccess,
    onCancel
  }
}

export const PricingEditorSeatMetricRouteComponent = () => {
  const seatMetricDrawerProps = useConnector()

  return (
    <Sidebar>
      <CreateSeatTypeForm
        onSuccess={seatMetricDrawerProps.onSuccess}
        handleCancel={seatMetricDrawerProps.onCancel}
      />
    </Sidebar>
  )
}
