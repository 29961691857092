import { useQuery, useSequenceInfiniteQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { keyBy } from 'lodash'
import { useMemo } from 'react'
import { allIds } from '../utils'

export const useEnrichedBulkActions = () => {
  const { infiniteQuery } = useSequenceInfiniteQuery(
    dashboard20240730Client.getBulkActions
  )
  const pages = infiniteQuery.data?.pages

  const bulkActions = useMemo(
    () => pages?.flatMap(page => page.items).flat() ?? [],
    [pages]
  )

  // Right now this is coupled to the expectation that the bulk actions always apply to invoices
  const invoiceIds = useMemo(
    () => new Set(bulkActions.map(allIds).flat()),
    [bulkActions]
  )

  const invoicesLite = useQuery(dashboard20240730Client.listInvoicesLite, {
    invoiceIds: Array.from(invoiceIds)
  })

  const invoices = keyBy(invoicesLite.data?.items, 'id')

  const { hasNextPage, fetchNextPage } = infiniteQuery

  const isLoading = infiniteQuery.isLoading || invoicesLite.isLoading

  return { hasNextPage, fetchNextPage, isLoading, bulkActions, invoices }
}
