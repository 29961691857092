import { Button, Flex, Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey30, Lato16Bold } from '@sequencehq/design-tokens'
import { useMinimumEditorContent } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/MinimumEditorContent/useMinimumEditorContent'
import { MinimumEditorForm } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/MinimumEditorForm/MinimumEditorForm'
import * as Sentry from '@sentry/react'
import { Blocker } from 'common/components/Blocker'

export const MinimumEditorContent = () => {
  const contentHook = useMinimumEditorContent()
  return (
    <Grid
      templateAreas={`
"header"
"body"
"footer"
`}
      templateRows={`56px 1fr 56px`}
      width="100%"
      height="100%"
      position="relative"
    >
      <Blocker
        name="minimum editor"
        dirty={contentHook.blockerEnabled}
        submitting={contentHook.submitting}
      />
      <Flex
        gridArea="header"
        borderBottom={`1px solid ${GreyGrey30}`}
        {...Lato16Bold}
        height="56px"
        alignItems="center"
        paddingLeft="24px"
      >
        {contentHook.title}
      </Flex>
      <GridItem gridArea="body" overflow="auto">
        <MinimumEditorForm />
      </GridItem>
      <Flex
        gridArea="footer"
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
        padding="0 24px"
        gap="8px"
        borderTop={`1px solid ${GreyGrey30}`}
      >
        <Button variant="secondary2" onClick={contentHook.close.handler}>
          {contentHook.close.text}
        </Button>
        {!contentHook.save.hidden && (
          <Button
            data-testid="minimumEditor.submit"
            variant="primary2"
            onClick={() => {
              contentHook.save.handler().catch(e => Sentry.captureException(e))
            }}
          >
            {contentHook.save.text}
          </Button>
        )}
      </Flex>
    </Grid>
  )
}
