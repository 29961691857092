import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Outlet, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ProductMeta } from 'modules/Products/Product/ProductMeta'
import { ProductPriceVariants } from 'modules/Products/Product/components/ProductPriceVariants'
import { ProductHeader } from 'modules/Products/Product/ProductHeader'
import {
  ProductContextProvider,
  useProductContext
} from 'modules/Products/hooks/useProductContext'
import { ProductListPrices } from 'modules/Products/Product/components/ProductListPrices'
import { useFlags } from 'launchdarkly-react-client-sdk'
import NotFound from 'components/ErrorPages/NotFound'
import { ProductInspector } from 'Products/Product/ProductInspector'

export const Product = () => {
  const { productId, priceId } = useParams<{
    productId: string
    priceId?: string
  }>()
  invariant(productId, 'productId is required')

  return (
    <ProductContextProvider productId={productId} priceId={priceId}>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <ProductContent />
        </Flex>
      </Flex>
    </ProductContextProvider>
  )
}

const ProductContent = () => {
  const flags = useFlags()

  const {
    data: { product }
  } = useProductContext()

  if (product.id === 'uninitialized') {
    return <NotFound />
  }

  return (
    <Grid
      flexDirection="column"
      gridTemplateColumns={'1fr auto'}
      gridTemplateRows={'auto 1fr'}
      gridTemplateAreas={`"header header" "content inspector"`}
      overflow="hidden"
      height="100vh"
      width="100%"
    >
      <GridItem gridArea="header">
        <ProductHeader />
      </GridItem>
      <GridItem gridArea="content" overflow="auto" height="100%" width="100%">
        <Flex
          width="100%"
          flexDirection="column"
          gap="6px"
          flex={1}
          overflowY="scroll"
        >
          <ProductMeta />
          <ProductListPrices />
          <ProductPriceVariants />
          <Outlet />
        </Flex>
      </GridItem>
      {flags.useQuickBooksProductLinking && (
        <GridItem>
          <ProductInspector productId={product.id} />
        </GridItem>
      )}
    </Grid>
  )
}
