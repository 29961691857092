import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { NEW_PRODUCT_PATTERN } from 'modules/Cube/domain/cube.constants'
import { Minimum, Product } from 'modules/Cube/domain/cube.domain.types'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { EditorMode } from 'common/drawers/PricingEditor/domain'
import { v1ApiCreateVariantPriceResponse } from 'common/drawers/PricingEditor/communication/external/v1/ports/variant.api.v1/entitySaving'
import { quoteStatusAdapter } from 'Cube/utils/status.adapter'
import { v4 as uuid } from 'uuid'
import { NEW_PRICE_PREFIX as PRICING_EDITOR_NEW_PRICE_PREFIX } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

/**
 * The outlet connector is how we expect, and enforce, the interface of the
 * module to be set in relation to the context of the main billing schedule loop.
 * Using the OutletContext hook outside of the context of an outlet connector
 * is not something we encourage!
 * @returns
 */
export const usePricingEditorConnector = () => {
  const navigate = useNavigate()
  const params = useParams<{
    phaseId?: string
    productId: string
    listPriceId?: string
  }>()
  const cubeContext = useCubeContext()

  const currentPhase = useMemo(() => {
    if (!params.phaseId) {
      return
    }
    return cubeContext.queries.resolvedPhases[params.phaseId]
  }, [cubeContext, params.phaseId])

  /**
   * Error handling that will redirect to the main editor if the current phase doesn't
   * exist - this may happen upon a refresh.
   */
  useEffect(() => {
    if (!currentPhase) {
      navigate('..')
      return
    }
  }, [currentPhase, navigate])

  const productId = useMemo(() => {
    if (!params.productId || params.productId === 'new') {
      return
    }

    return params.productId
  }, [params.productId])

  const listPriceId = useMemo(() => {
    if (!params.listPriceId) {
      return
    }

    return params.listPriceId
  }, [params.listPriceId])

  const currentPrice = useMemo(() => {
    // If there is a productId, this means we're editing an existing price
    if (!currentPhase || !productId) {
      return
    }

    return currentPhase.prices.find(price => price.productId === productId)
  }, [productId, currentPhase])

  const existingProduct: Product | undefined = useMemo(() => {
    if (!productId) {
      return
    }

    return cubeContext.queries.rawData.data.products[productId]
  }, [cubeContext, productId])

  const listPrice = useMemo(() => {
    if (!listPriceId) {
      return
    }

    const price = Object.values(cubeContext.queries.rawData.data.listPrices)
      .flatMap(listPrices => listPrices)
      .find(({ id }) => id === listPriceId)

    return price
  }, [cubeContext, listPriceId])

  const mode = useMemo(() => {
    if (!currentPhase) {
      return EditorMode.REVIEW
    }

    if (!productId && !listPriceId) {
      navigate('..')
      return EditorMode.REVIEW
    }

    if (!currentPrice) {
      return cubeContext.queries.availableFeatures.phases[currentPhase.id].phase
        .product.add.available.enabled
        ? EditorMode.CREATE
        : EditorMode.REVIEW
    }

    /**
     * Creating a new price, when the price is already 'active', is not
     * allowed as we can run into all kinds of jank in the billing engine! We also can't
     * change 'benign' fields such as name safely, as:
     *
     * - Dedupe means the name will inadvertently change other schedules
     * - If we create a new price, it may confuse the billing engine.
     *
     * For now, we simply don't allow changing of anything for an active price
     * price either. This is a cutback vs. what we previous had, but what
     * we previously had is potentially problematic and generally just
     * complexity that is not reasonably to maintain on the FE.
     */
    const canEdit =
      productId &&
      cubeContext.queries.availableFeatures.phases[currentPhase.id].products[
        productId
      ].edit.available.enabled

    return canEdit ? EditorMode.EDIT : EditorMode.REVIEW
  }, [
    currentPhase,
    productId,
    listPriceId,
    currentPrice,
    cubeContext.queries.availableFeatures.phases,
    navigate,
    existingProduct
  ])

  const existingProductPrice = useMemo(() => {
    if (!currentPhase || !productId) {
      return
    }

    const existingPrice = cubeContext.queries.resolvedPhases[
      currentPhase.id
    ].prices.find(price => price.productId === productId)

    if (!existingPrice) {
      return
    }

    return existingPrice
  }, [cubeContext.queries, currentPhase, productId])

  const onSave = useCallback(
    ({ price: newPrice }: { price: v1ApiCreateVariantPriceResponse }) => {
      /**
       * With this update we want to add our new price to the price datastructure,
       * and to the list of prices that belong to the current phase.
       */
      if (!currentPhase || !newPrice) {
        navigate('..')
        return
      }

      const existingPrices =
        cubeContext.queries.resolvedPhases[currentPhase.id]?.prices
      const existingDiscountForPrice = existingProductPrice
        ? cubeContext.queries.resolvedPhases[currentPhase.id]?.discounts?.find(
            discount => discount.priceIds.includes(existingProductPrice?.id)
          )
        : undefined
      const existingMinimumForPrice: Minimum | undefined = existingProductPrice
        ? cubeContext.queries.resolvedPhases[currentPhase.id]?.minimums?.find(
            minimum => minimum.scope.priceIds.includes(existingProductPrice.id)
          )
        : undefined

      cubeContext.mutators.updateData({
        prices: {
          [newPrice.id]: newPrice
        },
        phases: {
          [currentPhase.id]: {
            priceIds: existingProductPrice
              ? existingPrices.map(existingPrice => {
                  if (existingPrice.productId === newPrice.productId) {
                    return newPrice.id
                  }
                  return existingPrice.id
                })
              : [
                  ...existingPrices.map(existingPrice => existingPrice.id),
                  newPrice.id
                ]
          }
        },
        discounts: existingDiscountForPrice
          ? {
              [existingDiscountForPrice.id]: {
                ...existingDiscountForPrice,
                priceIds: [...existingDiscountForPrice.priceIds, newPrice.id]
              }
            }
          : {},
        minimums: existingMinimumForPrice
          ? {
              [existingMinimumForPrice.id]: {
                ...existingMinimumForPrice,
                scope: {
                  ...existingMinimumForPrice.scope,
                  priceIds: [
                    ...existingMinimumForPrice.scope.priceIds,
                    newPrice.id
                  ]
                }
              }
            }
          : {}
      })

      navigate('..')
    },
    [currentPhase, cubeContext, existingProductPrice, navigate]
  )

  const scheduleCurrency: Currency | undefined = useMemo(() => {
    return cubeContext.queries.selectedCurrency
  }, [cubeContext])

  /**
   * This error handling will automatically close the drawer if we
   * are attempting to load a 'new' product that does not exist,
   * or work against a phase that doesn't exist either.
   */
  useEffect(() => {
    if (productId?.match(NEW_PRODUCT_PATTERN) && !existingProduct) {
      navigate('..')
      return
    }

    if (!currentPrice && mode === EditorMode.REVIEW) {
      navigate('..')
      return
    }
  }, [navigate, currentPrice, productId, existingProduct, mode])

  const existingPrice = useMemo(() => {
    if (existingProductPrice) {
      return existingProductPrice
    }

    if (listPrice) {
      return {
        ...listPrice,
        /**
         * We need to improve the interface of the unified pricing
         * editor to allow to us to create a new price from a list price
         * id more explicitly. As it stands, we need to pass in an
         * appropriate id that would be considered a 'new price'
         * for the editor.
         */
        id: `${PRICING_EDITOR_NEW_PRICE_PREFIX}${uuid()}`
      }
    }
  }, [existingProductPrice, listPrice])

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  const isQuote = Boolean(
    quoteStatusAdapter.out(cubeContext.queries.rawData.data.common.status)
  )

  return {
    mode,
    existingData: {
      price: existingPrice,
      listPrice
    },
    disableDefaultPricePorts: isQuote,
    onSave,
    onCancel,
    scheduleCurrency
  }
}
