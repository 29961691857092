import { type InvoiceEditorReducerState } from 'InvoiceEditor/domainManagement/invoiceEditor.types'

export const UNGROUPED_LINE_ITEM_GROUP_ID_PATTERN = /^ungrouped:/

export const INITIAL_DATA: InvoiceEditorReducerState['data'] = {
  invoice: {
    id: '',
    currency: 'GBP',
    paymentStatus: 'UNPAID',
    status: 'DRAFT',
    billingPeriod: '',
    dueDate: undefined,
    linkedServices: [],
    customerId: '',
    isReady: false,
    accountingDate: '',
    customerTaxStatus: undefined,
    paymentOptions: [],
    metadata: [],
    creditBalances: '',
    calculatedAt: '',
    grossTotal: 0
  },
  merchant: {
    accountDetails: []
  },
  recipient: {
    customerAddressFields: [],
    customerEmails: [],
    customerId: '',
    customerLegalName: '',
    isArchived: false
  },
  totals: {
    netTotal: '',
    totalTax: '',
    grossTotal: '',
    taxLabel: 'Tax'
  },
  lineItems: {},
  lineItemGroups: {},
  lineItemGroupUsage: [],
  customer: {
    contacts: [],
    integrationIds: []
  },
  subAccountUsageBreakdown: [],
  prices: {}
}

export const INITIAL_REDUCER_STATE: InvoiceEditorReducerState = {
  data: INITIAL_DATA,
  configuration: {
    features: {
      displaySubAccountUsage: false
    }
  },
  editor: {
    loaded: false
  },
  derived: {
    warnings: [],
    queries: {
      availableFeatures: {
        canCreateCreditNote: false,
        canDownloadInvoice: false,
        canPreviewInvoice: false,
        canDraftInvoice: false,
        canEditCustomer: false,
        canEditInvoice: false,
        canEditLineItems: false,
        canFinaliseAndSendInvoice: false,
        canFinaliseInvoice: false,
        finaliseInvoiceDisabled: false,
        canRecalculateInvoice: false,
        canSendInvoice: false,
        canSendPaymentReminder: false,
        canSendTestInvoice: false,
        canSyncCustomerToIntegrations: false,
        canSyncInvoiceToIntegrations: false,
        canUpdatePaymentCollectionMethod: false,
        canUpdatePaymentStatus: false,
        canViewInCustomerPortal: false,
        canVoidInvoice: false,
        canEditMemo: false,
        canEditDueDate: false,
        canEditPurchaseOrderNumber: false,
        canEditReference: false,
        canViewSubAccountUsage: false,
        canUseTaxCategories: false,
        canEditInvoiceDate: false,
        canEditBillingPeriod: false,
        canArchiveInvoice: false
      },
      subAccountUsageBreakdownByLineItemGroup: {}
    },
    validation: {
      update: []
    }
  },
  initialData: INITIAL_DATA
}
