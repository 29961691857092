import {
  GraduatedPrice,
  PriceEditorPercentageTier,
  PriceEditorTier,
  VolumePrice
} from 'common/drawers/PricingEditor/domain'
import { decimalFromPercentage, toMoney } from '@sequencehq/utils'

type PriceTierTableRow = {
  firstUnit: string
  lastUnit: string
  perUnit: string
  flatFee: string
}

export const percentageFormatter = (percentage: string) =>
  Number(decimalFromPercentage(percentage)).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 10
  })

export const priceTierRowFormatter = (
  tier: PriceEditorTier | PriceEditorPercentageTier,
  index: number,
  tiers: Array<PriceEditorTier | PriceEditorPercentageTier>,
  price: VolumePrice | GraduatedPrice
): PriceTierTableRow => {
  const previousTier = tiers[index - 1]
  const flatFee =
    Number(tier?.flatFee) > 0
      ? toMoney(
          { currency: price.common.currency, value: tier.flatFee },
          '',
          10
        )
      : ''

  if ('unitPercentage' in tier) {
    const firstUnit = previousTier?.lastUnit
      ? toMoney(
          {
            currency: price.common.currency,
            value: (Number.parseFloat(previousTier.lastUnit) + 0.01).toString()
          },
          '',
          10
        )
      : toMoney({ currency: price.common.currency, value: '0' }, '', 10)

    const lastUnit = tier?.lastUnit
      ? toMoney(
          { currency: price.common.currency, value: tier.lastUnit },
          '',
          10
        )
      : '∞'

    const perUnit = percentageFormatter(tier.unitPercentage)

    return { firstUnit, lastUnit, perUnit, flatFee }
  }

  const firstUnit = previousTier?.lastUnit
    ? (Number(previousTier.lastUnit) + 1).toString()
    : '0'

  const lastUnit = tier?.lastUnit || '∞'

  const perUnit = toMoney(
    {
      currency: price.common.currency,
      value: tier.unitPrice
    },
    '',
    10
  )

  return { firstUnit, lastUnit, perUnit, flatFee }
}
