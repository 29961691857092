import { UpdateCustomerError } from 'Customer/hooks/useCustomerRoot'
import { match } from 'ts-pattern'
import { Banner } from '@sequencehq/core-components'
import { Text } from '@chakra-ui/react'
import { Lato13Bold } from '@sequencehq/design-tokens'
import { CreateCustomerError } from 'components/Customers/hooks/useCreateCustomer'

type CreateCustomerFormErrorBannerProps = {
  operation: 'CREATE'
  error?: CreateCustomerError
}

type EditCustomerFormErrorBannerProps = {
  operation: 'UPDATE'
  error?: UpdateCustomerError
}

type Props =
  | CreateCustomerFormErrorBannerProps
  | EditCustomerFormErrorBannerProps

export const CustomerFormErrorBanner = ({ error, operation }: Props) => {
  if (!error) {
    return null
  }

  return match(error.type)
    .with('INVALID_AVALARA_ADDRESS', () => (
      <Banner
        data-testid="customerForm.avalaraAddressErrorBanner"
        variant="error"
        alignIcon="center"
      >
        <Text {...Lato13Bold} color="inherit" marginBottom="4px">
          Address lookup failed:
        </Text>
        Please check address details for tax calculation with Avalara.
      </Banner>
    ))
    .with('OTHER', () => (
      <Banner
        data-testid="customerForm.otherErrorBanner"
        variant="error"
        alignIcon="center"
      >
        <Text {...Lato13Bold} color="inherit" marginBottom="4px">
          Failed to {operation === 'CREATE' ? 'create' : 'update'} customer:
        </Text>
        Please try again.
      </Banner>
    ))
    .exhaustive()
}
