import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import Spinner from 'components/Loading/Spinner'
import { SteppedModal } from 'common/components/SteppedModal/SteppedModal'
import { TaxRatesModalUI } from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import { match } from 'ts-pattern'
import { useQuery } from '@sequencehq/api/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useCreateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import { type ComponentProps, useEffect, useState } from 'react'
import type { ValidationItem } from 'common/components/SteppedModal/useSteppedModal'
import {
  type CountriesAlpha2,
  countriesAlpha2,
  State
} from '@sequencehq/api/commonEnums'
import type { ApiLineItemGroupTaxCategory } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { isEmpty } from 'lodash'
import { useTaxIntegrationProvider } from 'lib/hooks/useTaxIntegrationProvider.ts'
import type { TaxStatus } from '@sequencehq/core-models'
import { TaxRate } from 'Settings/domain/taxRates.types.ts'

const baseValidation = {
  taxRates: {
    stepKey: 'TAX_RATES',
    stepName: 'Tax rates',
    component: TaxRatesModalUI
  }
}

const getNextStep = (key: string) =>
  match(key)
    .with('TAX_RATES', () => 'CONFIRM')
    .otherwise(() => 'CONFIRM')

function useSteppedInvoiceConfirmationModal({
  onClose,
  customerTaxStatus,
  customerCountry,
  customerState,
  taxCategoriesOnInvoice,
  recalculateInvoice
}: {
  onClose: () => void
  customerTaxStatus?: TaxStatus
  customerCountry?: CountriesAlpha2
  customerState?: State
  taxCategoriesOnInvoice: ApiLineItemGroupTaxCategory[]
  recalculateInvoice: () => void
}) {
  const [validation, setValidation] = useState<ValidationItem[] | null>(null)

  const { isExternalTaxIntegrationProvider } = useTaxIntegrationProvider()

  const { data: taxRatesData, isLoading: taxRatesLoading } = useQuery(
    dashboardv99990101Client.getTaxRates,
    undefined,
    {
      enabled: !isExternalTaxIntegrationProvider
    }
  )

  const createTaxRate = useCreateTaxRate({ invalidateQueries: false })

  const taxRates = taxRatesData?.items ?? []

  const taxRatesForCustomerCountry = taxRates.filter(
    ({ country }) => country === customerCountry
  )

  const isCustomerTaxable = customerTaxStatus === 'TAXED'

  const unconfiguredTaxCategories = taxCategoriesOnInvoice.filter(
    taxCategory =>
      !taxRatesForCustomerCountry.some(
        taxRate => taxRate.taxCategoryId === taxCategory.id
      )
  )

  const validData = {
    // We DONT want to show the blocker when:
    // 1. Customer is not taxable OR
    // 2. There is an external tax integration OR
    // 3. There are no tax categories on the invoice OR
    // 4. There are tax rates for every tax category on the invoice for the customer's country
    taxRates:
      !isCustomerTaxable || // 1
      isExternalTaxIntegrationProvider || // 2
      isEmpty(taxCategoriesOnInvoice) || // 3
      unconfiguredTaxCategories.length === 0 // 4
  }

  const validationItems: ValidationItem[] = Object.entries(validData).reduce(
    (acc, [key, value]) => {
      if (value === true) {
        return acc
      }

      const componentProps = match(key)
        .with(
          'taxRates',
          (): ComponentProps<typeof TaxRatesModalUI> => ({
            countriesWithTaxRates: {
              [customerCountry as CountriesAlpha2]: taxRates.filter(
                taxRate => taxRate.country === customerCountry
              )
            } as Record<CountriesAlpha2, TaxRate[]>,
            preselectedCountry: customerCountry,
            preselectedState: customerState,
            taxCategories: unconfiguredTaxCategories,
            onSubmit: async values => {
              await createTaxRate(values)
              recalculateInvoice()
            },
            options: {
              closeOnCancel: false,
              closeOnSubmit: false,
              submitText: 'Save tax rates',
              onCancel: onClose,
              onClose: onClose
            }
          })
        )
        .otherwise(() => ({}))

      const validationMessage = match(key as keyof typeof validData)
        .with('taxRates', () =>
          customerCountry
            ? `Tax rates for ${countriesAlpha2[customerCountry]}`
            : `Customer has no country specified`
        )
        .exhaustive()

      return [
        ...acc,
        {
          ...baseValidation[key as keyof typeof baseValidation],
          message: validationMessage,
          nextStep: getNextStep(key),
          componentProps
        } as ValidationItem
      ]
    },
    [] as ValidationItem[]
  )

  useEffect(() => {
    if (!taxRatesLoading && validation === null) {
      setValidation(validationItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxRatesLoading, validationItems])

  return {
    loading: taxRatesLoading,
    validation
  }
}

interface Props {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
  customerTaxStatus?: TaxStatus
  customerCountry?: CountriesAlpha2
  customerState?: State
  validationSummaryText: string
  confirmationSummaryText: string
  confirmTitleText: string
  confirmActionText: string
  taxCategoriesOnInvoice: ApiLineItemGroupTaxCategory[]
  recalculateInvoice: () => void
}

export const SteppedInvoiceConfirmationModal = ({
  onClose,
  onConfirm,
  submitting,
  customerTaxStatus,
  customerCountry,
  customerState,
  validationSummaryText,
  confirmationSummaryText,
  taxCategoriesOnInvoice,
  confirmTitleText,
  confirmActionText,
  recalculateInvoice
}: Props) => {
  const { loading, validation } = useSteppedInvoiceConfirmationModal({
    onClose,
    customerTaxStatus,
    customerCountry,
    customerState,
    taxCategoriesOnInvoice,
    recalculateInvoice
  })

  if (loading || validation === null) {
    return (
      <ModalContextProvider isOpen>
        <SimpleDialogUI title={confirmTitleText}>
          <Spinner />
        </SimpleDialogUI>
      </ModalContextProvider>
    )
  }

  return (
    <SteppedModal
      canSkip={false}
      onClose={onClose}
      onFinalConfirm={onConfirm}
      submitting={submitting}
      validation={validation}
      validationSummaryText={validationSummaryText}
      confirmationSummaryText={confirmationSummaryText}
      confirmTitleText={confirmTitleText}
      confirmActionText={confirmActionText}
    />
  )
}
