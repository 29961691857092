import { Flex } from '@chakra-ui/react'
import { StartBillingFormProps } from 'components/BillingSchedules/components/modals/StartBilling/types'
import { noReturn } from 'lib/noReturn'
import { FC } from 'react'
import { Form } from 'react-final-form'

const StartBillingForm: FC<
  {
    billingScheduleId: string
    submitForm: (args: { id: string }) => Promise<void>
    formId: string
  } & StartBillingFormProps
> = ({ billingScheduleId, submitForm, formId, onSuccess, onFail }) => {
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={() =>
        submitForm({
          id: billingScheduleId
        })
          .then(() => {
            onSuccess?.()
          })
          .catch(() => {
            onFail?.()
          })
      }
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            The schedule will start generating invoices based on the defined
            prices and billing dates.
          </Flex>
        )
      }}
    />
  )
}

export default StartBillingForm
