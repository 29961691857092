import { useCallback, useMemo } from 'react'

import { Flex, IconButton, Text } from '@chakra-ui/react'
import { PencilSquareIcon } from '@heroicons/react/16/solid'

import { Lato13Regular } from '@sequencehq/design-tokens'
import { DateRangePicker } from '@sequencehq/core-components'
import { formatDateRange, formatISO } from '@sequencehq/utils/dist/dates'

import { AggregatedCreditNote } from 'CreditNotes/types'
import { SummaryRow } from './SummaryRow'

type Props = {
  updateCreditNoteDetails: ({
    billingPeriodStart,
    billingPeriodEnd
  }: {
    billingPeriodStart: AggregatedCreditNote['billingPeriodStart']
    billingPeriodEnd: AggregatedCreditNote['billingPeriodEnd']
  }) => void
  billingPeriodStart: AggregatedCreditNote['billingPeriodStart']
  billingPeriodEnd: AggregatedCreditNote['billingPeriodEnd']
  status: AggregatedCreditNote['status']
}

export const CreditNoteBillingPeriod = ({
  updateCreditNoteDetails,
  billingPeriodStart,
  billingPeriodEnd,
  status
}: Props) => {
  const formattedBillingPeriod = useMemo(() => {
    if (
      typeof billingPeriodStart === 'undefined' ||
      typeof billingPeriodEnd === 'undefined'
    ) {
      return undefined
    }

    return formatDateRange({
      from: new Date(billingPeriodStart),
      to: new Date(billingPeriodEnd)
    })
  }, [billingPeriodStart, billingPeriodEnd])

  const defaultDates = useMemo(
    () =>
      billingPeriodStart && billingPeriodEnd
        ? [new Date(billingPeriodStart), new Date(billingPeriodEnd)]
        : undefined,
    [billingPeriodStart, billingPeriodEnd]
  )

  const onSubmit = useCallback(
    (selectedDates: [Date, Date]) => {
      const formattedDates =
        selectedDates.filter(Boolean).length === 2
          ? selectedDates.map(date => formatISO(date))
          : undefined

      if (!formattedDates || formattedDates?.filter(Boolean).length === 0) {
        return
      }

      updateCreditNoteDetails({
        billingPeriodStart: formattedDates[0],
        billingPeriodEnd: formattedDates[1]
      })
    },
    [updateCreditNoteDetails]
  )

  const dateRangePickerComponent = useMemo(() => {
    if (status !== 'DRAFT') {
      return null
    }

    return (
      <DateRangePicker
        defaultDates={defaultDates}
        onSubmit={onSubmit}
        data-testid="credit-note-billing-period-date-picker"
      >
        <IconButton
          aria-label="Edit billing period"
          data-testid="credit-note-billing-period-edit"
          minWidth="16px"
          height="16px"
          width="16px"
          icon={<PencilSquareIcon height="16px" width="16px" />}
          variant="ghost2"
          visibility="hidden"
          _groupHover={{ visibility: 'visible' }}
        />
      </DateRangePicker>
    )
  }, [status, defaultDates, onSubmit])

  return (
    <SummaryRow
      label="Billing period"
      value={
        <Flex alignItems="center" gap={1} role="group">
          <Text
            {...Lato13Regular}
            data-testid="credit-note-billing-period-formatted"
          >
            {formattedBillingPeriod ? formattedBillingPeriod : '-'}
          </Text>
          {dateRangePickerComponent}
        </Flex>
      }
    />
  )
}
