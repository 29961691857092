import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useCallback, useMemo, useState } from 'react'

export const useVariantPriceLayout = (props: {
  variantPriceId?: string
  onCancel?: () => void
}) => {
  const context = usePricingEditorDomainContext()
  const [submitting, setSubmitting] = useState(false)

  const title = useMemo(() => {
    if (
      props.variantPriceId &&
      !props.variantPriceId.match(NEW_PRICE_PATTERN)
    ) {
      return `Edit price`
    }

    const productName = context.queries.rawData.data.product.name

    if (!productName) {
      return `Add price variant`
    }

    return `Add price variant to ${productName}`
  }, [props.variantPriceId, context.queries.rawData.data.product.name])

  const submitButtonText = useMemo(() => {
    if (
      props.variantPriceId &&
      !props.variantPriceId.match(NEW_PRICE_PATTERN)
    ) {
      return `Update price`
    }

    return `Add new price`
  }, [props.variantPriceId])

  const submittingButtonText = useMemo(() => {
    if (
      props.variantPriceId &&
      !props.variantPriceId.match(NEW_PRICE_PATTERN)
    ) {
      return `Updating price`
    }

    return `Adding new price`
  }, [props.variantPriceId])

  const submitDisabled = useMemo(() => {
    return !context.queries.availableFeatures.common.form.available.enabled
  }, [context.queries.availableFeatures.common.form.available.enabled])

  const onSave = useCallback(async () => {
    setSubmitting(true)
    const result = await context.mutators.external.out.save()
    setSubmitting(false)

    return result
  }, [context.mutators.external.out])

  const pricingModel = useMemo(
    () => context.queries.rawData.data.pricingEditorData.common.pricingModel,
    [context.queries.rawData.data.pricingEditorData.common.pricingModel]
  )

  const isListPriceBasedVariant = useMemo(
    () =>
      Boolean(
        context.queries.rawData.data.pricingEditorData.common.listPriceId
      ),
    [context.queries.rawData.data.pricingEditorData.common.listPriceId]
  )

  const formValid = useMemo(
    () => context.queries.rawData.editor.valid,
    [context.queries.rawData.editor.valid]
  )

  const onCancel = useCallback(() => {
    props.onCancel?.()
  }, [props.onCancel])

  return {
    formValid,
    isListPriceBasedVariant,
    onCancel,
    pricingModel,
    submit: {
      action: onSave,
      text: submitButtonText,
      submittingText: submittingButtonText,
      disabled: submitDisabled
    },
    submitting,
    title
  }
}
