import { Box } from '@chakra-ui/react'
import { BookmarkIcon } from '@heroicons/react/16/solid'
import { NavLinkButton } from 'components/NavigationV3/components/NavLinks/NavLink'
import { NavSection } from 'components/NavigationV3/components/NavSection'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation } from 'react-router-dom'
import { loadSavedViews } from 'SavedViews/localStorage'

export const SavedViewsNavigation = () => {
  const savedViews = loadSavedViews()
  const { pathname } = useLocation()
  const flags = useFlags()

  if (!savedViews.length || !flags.useSavedViews) return null

  return (
    <Box padding="8px 8px" userSelect="none">
      <NavSection>Saved views</NavSection>
      {savedViews.map(view => (
        <NavLinkButton
          key={view.id}
          href={`saved-view/${view.id}`}
          icon={BookmarkIcon}
          isActive={pathname.startsWith(`/saved-view/${view.id}`)}
        >
          {view.name}
        </NavLinkButton>
      ))}
    </Box>
  )
}
