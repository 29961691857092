import { IntegrationConfig } from 'modules/Integrations/domain'
import logo from './zapierLogo.svg'
import { NoConfiguration } from 'modules/Integrations/integrationsConfig/common/NoConfiguration'
import { ZapierIntegrationDetail } from 'modules/Integrations/integrationsConfig/zapier/ZapierIntegrationDetail/ZapierIntegrationDetail.tsx'

export const zapierIntegrationConfig: IntegrationConfig = {
  logoUrl: logo,
  smallLogoUrl: logo,
  statusLabel: null,
  documentationUrl: 'https://docs.sequencehq.com/integrations/zapier',
  title: 'Zapier',
  features: {
    canDeleteLinks: false,
    canUninstall: false,
    isDocumentationOnly: true
  },
  description: 'Use Sequence events to create sophisticated workflows',
  components: {
    detail: ZapierIntegrationDetail,
    management: NoConfiguration
  }
}
