import { useMemo, Fragment } from 'react'
import { match, P } from 'ts-pattern'
import { Flex, Text, Grid } from '@chakra-ui/react'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import {
  GraduatedPrice,
  Price,
  VolumePrice
} from 'common/drawers/PricingEditor/domain'
import { priceTierRowFormatter } from './formatters/formatters'

type TiersTableProps = {
  price: Price
}

export const TiersTable = ({ price }: TiersTableProps) => {
  const formattedTiers = useMemo(
    () =>
      match(price)
        .with(
          { common: { pricingModel: P.union('VOLUME', 'GRADUATED') } },
          matchedPrice => {
            const isPercentage =
              matchedPrice.modelSpecific.usageTierType === 'PERCENTAGE'
            const priceTiers = isPercentage
              ? matchedPrice.modelSpecific.percentageTiers
              : matchedPrice.modelSpecific.tiers

            return priceTiers.map((tier, index, tiers) =>
              priceTierRowFormatter(
                tier,
                index,
                tiers,
                price as VolumePrice | GraduatedPrice
              )
            )
          }
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
          matchedPrice => {
            const priceTiers = matchedPrice.modelSpecific.tiers

            return priceTiers.map((tier, index, tiers) =>
              priceTierRowFormatter(
                tier,
                index,
                tiers,
                price as VolumePrice | GraduatedPrice
              )
            )
          }
        )
        .otherwise(() => []),
    [price]
  )

  if (!formattedTiers.length) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={4} mt={6}>
      <Text {...Lato14Bold} color={GreyGrey100}>
        Tiers
      </Text>

      <Grid templateColumns="160px 1fr" gap={3}>
        {formattedTiers.map((tier, index) => {
          return (
            <Fragment key={index}>
              <Text {...Lato13Regular} color={GreyGrey70}>
                From {tier.firstUnit}{' '}
                {tier.lastUnit === '∞' ? ' and more' : `to ${tier.lastUnit}`}
              </Text>

              <Text
                {...Lato13Regular}
                color={GreyGrey90}
                data-testid={`priceEditor.review.tier.${index}`}
              >
                {tier.perUnit} {tier.flatFee ? `+ ${tier.flatFee}` : ''}
              </Text>
            </Fragment>
          )
        })}
      </Grid>
    </Flex>
  )
}
