import { Flex, Img } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC, ReactNode } from 'react'
import { quickBooksIntegrationConfig } from '../quickbooks.integration.config'
import { LinkCustomerToServiceWidget } from '../../common/LinkEntities/LinkCustomerToService'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import { LinkedInvoiceWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkedInvoiceWidget/LinkedInvoiceWidget'
import { useIntegrationAuthError } from 'Integrations/utils/useIntegrationAuthError'
import { NotAuthenticatedItemContent } from 'Integrations/integrationsConfig/common/NotAuthenticatedItemContent'
import { LinkProductWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkProductWidget/LinkProductWidget'
import { LinkOrCreateCustomerModal } from 'Integrations/integrationsConfig/common/LinkEntities/LinkCustomerToService/LinkOrCreateCustomerModal'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LinkQuickbooksItemModal } from 'Integrations/integrationsConfig/quickBooks/InspectorItem/LinkQuickbooksItemModal'

export interface InspectorItemProps {
  customerId: string
  canUnlink?: boolean
}

export const LinkQuickbooksProductWidget = (props: {
  productId: string
  canUnlink?: boolean
}) => (
  <LinkProductWidget
    productId={props.productId}
    sequenceEntityLabel="product"
    externalEntityLabel="item"
    service="QuickBooks_Online"
    linkModal={LinkQuickbooksItemModal}
  />
)

export const LinkQuickbooksCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => {
  const flags = useFlags()

  return (
    <LinkCustomerToServiceWidget
      customerId={props.customerId}
      sequenceEntityLabel="customer"
      externalEntityLabel="customer"
      service="QuickBooks_Online"
      canUnlink={props.canUnlink}
      linkModal={
        flags.useNewQuickBooksCustomerModal
          ? LinkOrCreateCustomerModal
          : undefined
      }
    />
  )
}

export const LinkQuickbooksInvoiceWidget: FC<{
  invoiceId: string
}> = props => (
  <LinkedInvoiceWidget
    invoiceId={props.invoiceId}
    service="QuickBooks_Online"
  />
)

export const QuickBooksInspectorItem: FC<{
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('QuickBooks_Online')
  const integrationAuthError = useIntegrationAuthError('QuickBooks_Online')

  if (!integrationActive && !integrationAuthError) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="quickbooks.inspector.item"
      content={
        integrationAuthError ? (
          <NotAuthenticatedItemContent
            service="QuickBooks_Online"
            data-testid="quickbooks.inspector.content.unauth"
            serviceDescription={quickBooksIntegrationConfig.description}
            serviceName={quickBooksIntegrationConfig.title}
            logo={
              <Img
                src={quickBooksIntegrationConfig.smallLogoUrl}
                height="24px"
                width="24px"
              />
            }
          />
        ) : (
          <Inspector.Content
            data-testid="quickbooks.inspector.content"
            title="QuickBooks"
            icon={<SmallIcon url={quickBooksIntegrationConfig.smallLogoUrl} />}
          >
            <Flex flexDirection="column" gap="16px">
              {props.children}
            </Flex>
          </Inspector.Content>
        )
      }
      tooltip="QuickBooks"
      icon={<SmallIcon url={quickBooksIntegrationConfig.smallLogoUrl} />}
    />
  )
}
