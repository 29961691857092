import { PricingEditorReducerState } from '../../pricingEditor.domain.types'

const getBreadcrumbs = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['queries']['breadcrumbSteps'] => {
  return prevState.editor.steps.map(step => ({
    name: step.name,
    mode: step.mode,
    active: step.mode === prevState.configuration.mode
  }))
}

export const getBreadcrumbSteps = (
  prevState: PricingEditorReducerState
): PricingEditorReducerState['queries']['breadcrumbSteps'] => {
  return getBreadcrumbs(prevState)
}
