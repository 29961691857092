import { TaxStatus, taxStatusMap } from '@sequencehq/core-models'
import {
  State,
  CountriesAlpha2,
  useLoadAllItemsQuery
} from '@sequencehq/api/utils'
import {
  countriesAlpha2,
  statesForCountry,
  getStateOptions
} from '@sequencehq/api/utils/commonEnums'
import { useForm } from '@sequencehq/utils'
import { required, skip } from '@sequencehq/validation'
import type {
  CustomerContact,
  CustomerContactBillingPreference
} from 'CustomerContacts/domain/CustomerContacts.domain.types'
import type { ContactBody } from 'CustomerContacts/view/forms/ContactModalForm/useContactModalForm'
import { startCase } from 'lodash'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { useCustomerRoot } from 'Customer/hooks/useCustomerRoot'
import { useCallback, useMemo, useState } from 'react'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useFlags } from 'launchdarkly-react-client-sdk'

type Customer = Omit<
  Dashboardv99990101Api.PutCustomer.UpdateCustomerBody,
  'contacts'
> & {
  contacts: CustomerContact[]
  showTaxRegistration: boolean
  taxCountry: CountriesAlpha2
  taxState?: State
  taxIdentifier?: string
  taxRegistrationId?: string
}

const taxStatusFormDropdownOptions: Record<
  TaxStatus,
  ReadonlyArray<{ value: TaxStatus; label: string }>
> = {
  TAXED: [
    {
      value: 'TAXED',
      label: 'Taxable'
    },
    {
      value: 'TAX_EXEMPT',
      label: 'Exempt'
    },
    {
      value: 'REVERSE_CHARGED',
      label: 'Reverse charge'
    }
  ],
  REVERSE_CHARGED: [
    {
      value: 'REVERSE_CHARGED',
      label: 'Reverse charge'
    },
    {
      value: 'TAX_EXEMPT',
      label: 'Exempt'
    }
  ],
  TAX_EXEMPT: [
    {
      value: 'TAX_EXEMPT',
      label: 'Exempt'
    },
    {
      value: 'REVERSE_CHARGED',
      label: 'Reverse charge'
    }
  ]
}

export const useEditCustomer = () => {
  const {
    customer: customerDetails,
    customerAliases,
    taxRegistration,
    refetchCustomer,
    refetchCustomerAliases,
    refetchTaxRegistration
  } = useCustomerContext()
  const {
    functions: { editCustomer }
  } = useCustomerRoot({
    refetchCustomer,
    refetchCustomerAliases,
    refetchTaxRegistration
  })

  const flags = useFlags()

  const { data: customersData } = useLoadAllItemsQuery(
    dashboardv99990101Client.getCustomers
  )
  const customers = customersData?.pages?.flatMap(page => page.items) ?? []

  const [isValid, setIsValid] = useState<boolean>(true)
  const [customer, setCustomer] = useState<Customer>()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const initialState: Customer = useMemo(
    () => ({
      legalName: customerDetails.legalName ?? '',
      customerAliases: customerAliases.map(({ value }) => value) ?? [],
      label: customerDetails.label ?? '',
      address: {
        line1: customerDetails.address?.line1 ?? '',
        line2: customerDetails.address?.line2,
        town: customerDetails.address?.town ?? '',
        postcode: customerDetails.address?.postcode ?? '',
        country: customerDetails.address?.country ?? 'GB',
        state: customerDetails.address.state
      },
      taxStatus: customerDetails.taxStatus ?? 'TAX_EXEMPT',
      parentId: customerDetails?.organizations[0]?.owner?.id,
      contacts: customerDetails?.contacts ?? [],
      showTaxRegistration: !!taxRegistration,
      taxCountry: taxRegistration?.country ?? ('GB' as CountriesAlpha2),
      taxState: taxRegistration?.state,
      taxIdentifier: taxRegistration?.taxIdentifier,
      taxRegistrationId: taxRegistration?.id
    }),
    [
      customerAliases,
      customerDetails.address,
      customerDetails.label,
      customerDetails.legalName,
      customerDetails.organizations,
      customerDetails.taxStatus,
      customerDetails.contacts,
      taxRegistration?.country,
      taxRegistration?.state
    ]
  )

  const address = useMemo(
    () => customer?.address ?? initialState.address,
    [customer, initialState]
  )

  const taxCountry = useMemo(
    () => customer?.taxCountry ?? initialState.taxCountry,
    [customer, initialState]
  )

  const stateOptions: { label: string; value: string }[] = useMemo(() => {
    return getStateOptions(address?.country)
  }, [address])

  const taxStateOptions: { label: string; value: string }[] = useMemo(() => {
    return getStateOptions(taxCountry)
  }, [taxCountry])

  const customerOptions = useMemo(() => {
    if (!customers) {
      return []
    }

    const options = customers
      .filter(item => {
        return !item.organizations.some(org =>
          org.members.some(member => member.id === item.id)
        )
      })
      .filter(({ id }) => id !== customerDetails.id)

    const formattedOptions = options.map(({ id, legalName }) => ({
      value: id,
      label: legalName
    }))

    return [
      { value: '', label: 'Choose a parent account' },
      ...formattedOptions
    ]
  }, [customerDetails.id, customers])

  const { fields } = useForm<Customer>({
    value: initialState,
    showValidationErrors: !isValid,
    fieldConfiguration: [
      {
        property: 'legalName',
        validation: [required]
      },
      {
        property: 'label'
      },
      {
        property: 'address.line1',
        validation: [required]
      },
      {
        property: 'address.line2'
      },
      {
        property: 'address.town',
        validation: [required]
      },
      {
        property: 'address.state',
        options: stateOptions,
        validation: stateOptions.length > 0 ? [required] : []
      },
      {
        property: 'address.country',
        options: Object.entries(countriesAlpha2).map(([value, label]) => ({
          value,
          label
        })),
        validation: [required]
      },
      {
        property: 'address.postcode',
        validation: [required]
      },
      {
        property: 'taxStatus',
        disabled: () => false,
        options: flags.showNewTaxManagement
          ? Object.entries(taxStatusMap).map(([value, label]) => ({
              value,
              label
            }))
          : taxStatusFormDropdownOptions[customerDetails.taxStatus].map(
              ({ value, label }) => ({
                value,
                label
              })
            )
      },
      {
        property: 'customerAliases'
      },
      {
        property: 'showTaxRegistration'
      },
      {
        property: 'taxCountry',
        options: Object.entries(countriesAlpha2).map(([value, label]) => ({
          value,
          label
        }))
      },
      {
        property: 'taxState',
        options: taxStateOptions,
        validation: taxStateOptions.length > 0 ? [required] : []
      },
      {
        property: 'taxIdentifier'
      },
      {
        property: 'parentId',
        options: customerOptions,
        validation: [skip]
      },
      {
        property: 'contacts',
        validation: [skip],
        options: []
      }
    ],
    onChange: newData => {
      setCustomer(newData)
    },
    onValidationStateChange: setIsValid
  })

  const processedFields = useMemo(() => {
    return {
      ...fields,
      'address.state': {
        ...fields['address.state'],
        visible: statesForCountry(fields['address.country'].value).length > 0
      },
      taxState: {
        ...fields['taxState'],
        visible: statesForCountry(fields['taxCountry'].value).length > 0
      },
      customerAliases: {
        ...fields['customerAliases'],
        value: fields.customerAliases.value ?? [],
        onAddNew: () =>
          fields.customerAliases.onChange([
            ...(fields.customerAliases.value ?? []),
            ''
          ]),
        onRemove: (index: number) => {
          const aliasesBefore =
            index !== 0
              ? fields.customerAliases.value?.slice(0, index) ?? []
              : []
          const aliasesAfter =
            fields.customerAliases.value?.slice(index + 1) ?? []
          fields.customerAliases.onChange([...aliasesBefore, ...aliasesAfter])
        }
      },
      parentId: {
        ...fields['parentId'],
        visible: !customerDetails?.organizations.some(
          org => org.owner?.id === customerDetails.id
        )
      },
      contacts: {
        ...fields['contacts'],
        options: customerDetails?.contacts.map(contact => ({
          value: contact.id,
          label: [undefined, ''].includes(contact.name)
            ? contact.email
            : contact.name ?? '',
          secondaryLabel: contact.name ? contact.email : undefined
        }))
      },
      showTaxRegistration: {
        ...fields.showTaxRegistration,
        visible: !taxRegistration,
        showTaxRegistrationFields: fields.showTaxRegistration.value !== false
      }
    }
  }, [fields, customerDetails, taxRegistration])

  const canUpdate = useMemo(() => {
    return customer !== undefined && isValid
  }, [customer, isValid])

  const handleSubmit = useCallback(async () => {
    if (!canUpdate || !customer || !isValid) {
      return
    }

    const countryStates = statesForCountry(customer.address.country)

    if (
      countryStates.length > 0 &&
      (!fields['address.state'].value ||
        !countryStates.includes(fields['address.state'].value))
    ) {
      fields['address.state'].onChange(undefined as never)
      setIsValid(false)
      setIsSaving(false)
      return
    }

    return await editCustomer(customerDetails.id, {
      ...customer,
      address: {
        ...customer.address,
        state:
          fields['address.state'].value &&
          countryStates.includes(fields['address.state'].value)
            ? fields['address.state'].value
            : undefined
      },
      contacts: customer.contacts.map(contact => {
        return toContactBody(contact)
      })
    })
  }, [canUpdate, customer, isValid, editCustomer, customerDetails.id])

  return {
    fieldsConfig: processedFields,
    handleSubmit,
    canUpdate,
    isSaving,
    setIsSaving,
    contacts: customerDetails.contacts,
    refetchCustomer
  }
}

export function contactFromEmail(
  email: string,
  billingPreference: CustomerContactBillingPreference = 'STANDARD'
) {
  return {
    name: emailToName(email),
    email,
    billingPreference,
    // These will be stripped out before sending to the API (by toContactBody).
    // However we need the props in the interim for type compatibility with the form API.
    id: 'new',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
}

function toContactBody({
  name,
  email,
  billingPreference
}: CustomerContact): ContactBody {
  return { name: name ?? emailToName(email), email, billingPreference }
}

// It would typically make sense for somewhere using this to be co-located with this component,
// but we're going to backfill this value into contacts where `name` is missing, so this is a temp solution.
export function emailToName(email: string) {
  return startCase(email.split('@')[0].replaceAll('.', ' '))
}
