import { useAggregatedCreditNote } from 'CreditNotes/hooks/useAggregatedCreditNote'
import { useClientCopy } from 'CreditNotes/hooks/useClientCopy'
import { AggregatedCreditNote, LineItem } from 'CreditNotes/types'
import { recalculateCreditNote } from 'CreditNotes/utils/calculation'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSaveAggregatedCreditNote } from './useSaveAggregatedCreditNote'
import { useDebouncedCallback } from 'use-debounce'
import { isEqual, sortBy, unionBy, unionWith } from 'lodash'
import { usePrevious } from 'lib/usePrevious'

const PERSIST_DEBOUNCE = 1000

export const useCreditNoteEditing = (creditNoteId: string) => {
  const { creditNote: serverCreditNote } = useAggregatedCreditNote(creditNoteId)
  const [serverRequestTime, setServerRequestTime] = useState(0)
  const [shouldSave, setShouldSave] = useState(false)

  const [clientCreditNote, setClientCreditNote] = useClientCopy(
    serverCreditNote,
    serverRequestTime
  )

  const creditNote = useMaintainUnselectedLineItems(clientCreditNote)
  const saveCreditNote = useSaveAggregatedCreditNote(creditNoteId)

  useEffect(() => {
    if (!shouldSave || !serverCreditNote || !creditNote) {
      return
    }

    saveCreditNote(serverCreditNote, creditNote)
    setServerRequestTime(Date.now())
    setShouldSave(false)
  }, [shouldSave, serverCreditNote, creditNote, saveCreditNote])

  const scheduleSave = useCallback(() => {
    setShouldSave(true)
  }, [])
  const debouncedScheduleSave = useDebouncedCallback(
    scheduleSave,
    PERSIST_DEBOUNCE
  )

  const updateCreditNote = useCallback(
    (newCreditNote: AggregatedCreditNote) => {
      setClientCreditNote(recalculateCreditNote(newCreditNote))
      debouncedScheduleSave()
    },
    [setClientCreditNote, debouncedScheduleSave]
  )

  const updateLineItems = useCallback(
    (lineItemsToUpdate: LineItem[]) => {
      if (!creditNote) {
        return
      }

      updateCreditNote({
        ...creditNote,
        lineItemGroups: creditNote.lineItemGroups.map(lineItemGroup => {
          if (
            !lineItemsToUpdate.find(item => item.groupId === lineItemGroup.id)
          ) {
            return lineItemGroup
          }
          return {
            ...lineItemGroup,
            lineItems: lineItemGroup.lineItems.map(lineItem => {
              return (
                lineItemsToUpdate.find(item => item.id === lineItem.id) ??
                lineItem
              )
            })
          }
        })
      })
    },
    [creditNote, updateCreditNote]
  )

  const updateCreditNoteDetails = useCallback(
    ({
      billingPeriodStart,
      billingPeriodEnd
    }: {
      billingPeriodStart: AggregatedCreditNote['billingPeriodStart']
      billingPeriodEnd: AggregatedCreditNote['billingPeriodEnd']
    }) => {
      if (!creditNote) {
        return
      }

      updateCreditNote({
        ...creditNote,
        billingPeriodStart,
        billingPeriodEnd
      })
    },
    [creditNote, updateCreditNote]
  )

  return { creditNote, updateLineItems, updateCreditNoteDetails }
}

// For the purposes of our first iteration,
// The server has no concept of whether a line item is included or not
// i.e. when a line item is unselected, we'll delete it from the server
//
// To be able to show the unselected items even after they're deleted from the server,
// we need to keep the initial server response
const useMaintainUnselectedLineItems = (
  creditNote: AggregatedCreditNote | null
) => {
  const flattenedLineItems = useMemo(
    () => creditNote?.lineItemGroups.flatMap(group => group.lineItems) ?? [],
    [creditNote?.lineItemGroups]
  )

  const [allLineItems, setAllLineItems] = useState(flattenedLineItems)
  const previousFlattenedLineItems = usePrevious(flattenedLineItems)

  useEffect(() => {
    if (isEqual(flattenedLineItems, previousFlattenedLineItems)) {
      return
    }

    // allLineItems takes precedence to maintain order
    const union = unionWith(allLineItems, flattenedLineItems, lineItemsMatch)

    setAllLineItems(
      // Now we swap out with the new item if it exists
      union.map(item => {
        const matching = flattenedLineItems.find(i => lineItemsMatch(i, item))
        return matching ?? item
      })
    )
  }, [flattenedLineItems, allLineItems, previousFlattenedLineItems])

  return useMemo(() => {
    if (!creditNote) {
      return null
    }
    return maintainUnselectedLineItems(creditNote, allLineItems)
  }, [creditNote, allLineItems])
}

function maintainUnselectedLineItems(
  creditNote: AggregatedCreditNote,
  allLineItems: LineItem[]
): AggregatedCreditNote {
  return {
    ...creditNote,
    lineItemGroups: creditNote.lineItemGroups.map(group => {
      return {
        ...group,
        lineItems: allLineItems
          .filter(item => item.groupId === group.id)
          .map(storedLineItem => {
            const currentLineItem = group.lineItems.find(item =>
              lineItemsMatch(item, storedLineItem)
            )
            if (currentLineItem?.deleted || storedLineItem.deleted) {
              return null
            }
            if (currentLineItem) {
              return {
                ...currentLineItem,
                selected: currentLineItem.selected ?? true
              }
            }

            return { ...storedLineItem, selected: false }
          })
          .filter(Boolean) as LineItem[]
      }
    })
  }
}

function lineItemsMatch(a: LineItem, b: LineItem) {
  if (a.id === b.id) {
    return true
  }

  return a.title === b.title && a.rate === b.rate
}
