import { Flex, Box, Text } from '@chakra-ui/react'
import { Card } from '@sequencehq/core-components'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { Address } from '@sequencehq/api/utils/commonModels'
import { formatAddressForInvoice } from '@sequencehq/invoice-content'
import {
  GreyGrey60,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Link } from 'react-router-dom'
import { format } from '@sequencehq/utils/dates'
import { SummaryRow } from './SummaryRow'
import { CreditNoteBillingPeriod } from './CreditNoteBillingPeriod'
import { AggregatedCreditNote } from 'CreditNotes/types'

interface Props {
  customerId: string
  customerEmails: string[]
  customerAddress: Address
  creditNoteNumber?: string
  creditNoteIssueDate?: string
  currency: string
  customerTaxId?: string
  invoiceNumber?: string
  invoiceId?: string
  billingPeriodStart: AggregatedCreditNote['billingPeriodStart']
  billingPeriodEnd: AggregatedCreditNote['billingPeriodEnd']
  updateCreditNoteDetails: ({
    billingPeriodStart,
    billingPeriodEnd
  }: {
    billingPeriodStart: AggregatedCreditNote['billingPeriodStart']
    billingPeriodEnd: AggregatedCreditNote['billingPeriodEnd']
  }) => void
  status: AggregatedCreditNote['status']
}

export const CreditNoteDetailsSummaryCard = ({
  customerId,
  creditNoteIssueDate,
  creditNoteNumber,
  currency,
  customerEmails,
  customerAddress,
  customerTaxId,
  invoiceNumber,
  invoiceId,
  billingPeriodStart,
  billingPeriodEnd,
  updateCreditNoteDetails,
  status
}: Props) => {
  return (
    <Card px={4} py={3} variant="v2">
      <Flex gap={4} role="group">
        <Flex width="50%">
          <Flex
            alignContent="center"
            alignItems="center"
            width="140px"
            height="28px"
          >
            <Text {...Lato13Regular} color={GreyGrey60}>
              Credited to
            </Text>
          </Flex>
          <Flex flexDirection="column" gap={1} {...Lato13Regular}>
            <Box mx={-2}>
              <CustomerPreviewCardPill
                customerId={customerId}
                variant="ghost"
              />
            </Box>
            <Text>{customerEmails[0]}</Text>
            <Flex flexDirection="column" gap={1}>
              {formatAddressForInvoice(customerAddress).map(
                (textValue, index) => (
                  <Text
                    {...Lato13Regular}
                    color={GreyGrey80}
                    key={`${index}-${textValue}`}
                    style={{
                      overflowWrap: 'anywhere'
                    }}
                  >
                    {textValue}
                  </Text>
                )
              )}
            </Flex>
            {customerTaxId && <Text>Tax ID: {customerTaxId}</Text>}
          </Flex>
        </Flex>

        <Flex flexDirection="column" width="50%">
          <SummaryRow
            label="Credit note number"
            value={
              <Text
                {...Lato13Regular}
                color={creditNoteNumber ? GreyGrey90 : GreyGrey60}
              >
                {creditNoteNumber || 'Pending'}
              </Text>
            }
          />
          {!!invoiceId && (
            <SummaryRow
              label="Related to"
              value={
                <Link to={`/invoices/${invoiceId}`}>
                  <Text {...Lato13Bold} color={IndigoIndigo50}>
                    {invoiceNumber ?? 'view invoice'}
                  </Text>
                </Link>
              }
            />
          )}
          <CreditNoteBillingPeriod
            updateCreditNoteDetails={updateCreditNoteDetails}
            billingPeriodStart={billingPeriodStart}
            billingPeriodEnd={billingPeriodEnd}
            status={status}
          />
          <SummaryRow
            label="Issue date"
            value={
              <Text {...Lato13Regular}>
                {creditNoteIssueDate
                  ? format(new Date(creditNoteIssueDate), 'd MMM yyyy')
                  : '-'}
              </Text>
            }
          />
          <SummaryRow
            label="Currency"
            value={<Text {...Lato13Regular}>{currency}</Text>}
          />
          <SummaryRow
            label="Tax status"
            value={
              <Text
                {...Lato13Regular}
                color={customerTaxId ? GreyGrey90 : GreyGrey60}
              >
                {customerTaxId ? 'Taxed' : 'Untaxed'}
              </Text>
            }
          />
        </Flex>
      </Flex>
    </Card>
  )
}
