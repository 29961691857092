import { Flex } from '@chakra-ui/react'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { useMagicTableToolbarContext } from '@sequencehq/tables'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UNMAPPED_ALIAS_OPTION_VALUE } from './UsageEventsMagicTable'

type ActiveUsageEventsFilter = 'all' | 'unmapped'

export const UsageEventsSegmentedFilters = () => {
  const [activeFilter, setActiveFilter] =
    useState<ActiveUsageEventsFilter>('all')
  const [searchParams] = useSearchParams()
  const unmappedAliasesOnlyParams = searchParams.get('unmappedAliasesOnly')

  const ctx = useMagicTableToolbarContext()

  useEffect(() => {
    if (!unmappedAliasesOnlyParams) {
      setActiveFilter('all')
    } else if (unmappedAliasesOnlyParams === 'true') {
      setActiveFilter('unmapped')
    }
  }, [searchParams, unmappedAliasesOnlyParams])

  const handleFilterChange = useMemo(() => {
    return (value: string) => {
      switch (value) {
        case 'all':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'customerAlias',
            values: []
          })
          break
        case 'unmapped':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'customerAlias',
            values: [UNMAPPED_ALIAS_OPTION_VALUE]
          })
          break
        default:
          break
      }
    }
  }, [])

  const disabled = useMemo(
    () => !ctx.queries.canChangeFilters(),
    [ctx.queries.canChangeFilters]
  )

  return (
    <Flex alignItems="start">
      <RadioButtonGroup
        onChange={handleFilterChange}
        initialValue={activeFilter}
        value={activeFilter}
        options={[
          {
            value: 'all',
            label: 'All',
            disabled
          },
          {
            value: 'unmapped',
            label: 'Unmapped',
            disabled
          }
        ]}
      />
    </Flex>
  )
}
