import { Box } from '@chakra-ui/react'
import { GreyWhite, IndigoIndigo50 } from '@sequencehq/design-tokens'
import {
  MagicTableCell,
  MagicTable,
  useMagicTableQuery
} from '@sequencehq/tables'

import { useSelectedCurrency } from 'RevenueRecognition/view/utils/useSelectedCurrency'
import { format, formatDateRange } from '@sequencehq/utils/dates'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client,
  type Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'

type AccountingPeriod = Dashboardv99990101CommonModels.Revenue.AccountingPeriod
type AccountingPeriodsParams = Dashboardv99990101Api.GetAccountingPeriods.Params

export const JournalReportsTable = () => {
  const { currency } = useSelectedCurrency()

  const { query } = useMagicTableQuery<
    AccountingPeriod,
    AccountingPeriodsParams
  >(dashboardv99990101Client.getAccountingPeriods)

  return (
    <Box backgroundColor={GreyWhite} height="100%">
      <CurrentUserId>
        {userId => (
          <MagicTable<{
            model: AccountingPeriod
            controlFilters: undefined
          }>
            entityNamePlural="accounting periods"
            sequenceUserId={userId}
            infiniteQuery={query}
            rowPath={accountingPeriod => {
              return `/revenue-recognition/journal-reports/report-detail?startDate=${accountingPeriod.accountingPeriod.start}&endDate=${accountingPeriod.accountingPeriod.endInclusive}&currency=${currency}`
            }}
            columns={[
              {
                id: 'label',
                accessorFn: ({ accountingPeriod }) => ({
                  dates: accountingPeriod,
                  label: format(new Date(accountingPeriod.start), 'MMMM yyyy')
                }),
                header: 'Journal report',
                cell: cellValue => {
                  const { label, dates } = cellValue.getValue<{
                    label: string
                    dates: {
                      start: string
                      endInclusive: string
                    }
                  }>()
                  return (
                    <MagicTableCell
                      textColor={IndigoIndigo50}
                      text={label}
                      data-testid={`revrec.journalReport.${dates.start}`}
                    />
                  )
                }
              },
              {
                id: 'period',
                accessorFn: ({ accountingPeriod }) =>
                  formatDateRange({
                    from: new Date(accountingPeriod.start),
                    to: new Date(accountingPeriod.endInclusive)
                  }),
                header: 'Period',
                cell: cellValue => {
                  const formattedDate = cellValue.getValue<string>()
                  return <MagicTableCell text={formattedDate} />
                }
              }
            ]}
          />
        )}
      </CurrentUserId>
    </Box>
  )
}
