import { decimalFromPercentage, percentageFromDecimal } from '@sequencehq/utils'
import {
  type LineItemEditorLineItem,
  type LineItemEditorLineItemGroup
} from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/domainManagement/useLineItemEditor.ts'
import { match } from 'ts-pattern'
import {
  type ApiLineItem,
  type ApiLineItemGroup
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'

export const createAdapterData = (
  lineItemFromApi: ApiLineItem
): LineItemEditorLineItem => {
  return {
    id: lineItemFromApi.id,
    title: lineItemFromApi.title,
    description: lineItemFromApi.description,
    quantity: lineItemFromApi.quantity,
    rate: formatRateFromApi(lineItemFromApi.rate, lineItemFromApi.rateDisplay),
    rateType:
      lineItemFromApi.rateDisplay === 'ABSOLUTE' ? 'FIXED' : 'PERCENTAGE',
    itemType: parseFloat(lineItemFromApi.rate) < 0 ? 'DISCOUNT' : 'PRODUCT',
    taxRate: percentageFromDecimal(lineItemFromApi.taxRate),
    priceId: lineItemFromApi.priceId,
    externalIds: lineItemFromApi.externalIds.reduce(
      (acc, externalId) => ({
        ...acc,
        [externalId.key]: externalId.value
      }),
      {}
    ),
    taxCategoryId: lineItemFromApi.taxCategory?.id
  }
}

const formatRateFromApi = (rate: string, rateDisplay: string): string => {
  const rateValue = parseFloat(rate)
  const isPositiveRate = rateValue > 0

  return match(rateDisplay)
    .with('ABSOLUTE', () => {
      if (isPositiveRate) {
        return rate
      } else {
        return (rateValue * -1).toString()
      }
    })
    .with('PERCENTAGE', () => {
      if (isPositiveRate) {
        return percentageFromDecimal(rate)
      } else {
        return (parseFloat(percentageFromDecimal(rate)) * -1).toString()
      }
    })
    .otherwise(() => rate)
}

const formatRateToApi = (
  rate: string,
  rateType: string,
  itemType: string
): string =>
  match(itemType)
    .with('DISCOUNT', () =>
      match(rateType)
        .with('PERCENTAGE', () =>
          (-1 * parseFloat(decimalFromPercentage(rate))).toString()
        )
        .with('FIXED', () => (-1 * parseFloat(rate)).toString())
        .otherwise(() => rate)
    )
    .with('PRODUCT', () =>
      match(rateType)
        .with('PERCENTAGE', () => decimalFromPercentage(rate))
        .with('FIXED', () => rate)
        .otherwise(() => rate)
    )
    .otherwise(() => rate)

export const createApiData = (
  lineItemFromAdapter: LineItemEditorLineItem
): ApiLineItem => {
  return {
    id: lineItemFromAdapter.id,
    title: lineItemFromAdapter.title,
    description: lineItemFromAdapter.description,
    quantity: lineItemFromAdapter.quantity.toString(),
    rate: formatRateToApi(
      lineItemFromAdapter.rate,
      lineItemFromAdapter.rateType,
      lineItemFromAdapter.itemType
    ),
    rateDisplay:
      lineItemFromAdapter.rateType === 'FIXED' ? 'ABSOLUTE' : 'PERCENTAGE',
    taxRate: decimalFromPercentage(lineItemFromAdapter.taxRate.toString()),
    invoiceId: '1',
    index: 0,
    grossTotal: '0',
    netTotal: '0',
    totalTax: '0',
    externalIds: Object.entries(lineItemFromAdapter.externalIds).map(
      ([key, code]) => ({
        key: key,
        value: code
      })
    ) as ApiLineItem['externalIds']
  }
}

const createAdapterLineItemGroup = (
  lineItemGroup: ApiLineItemGroup
): LineItemEditorLineItemGroup => {
  return {
    id: lineItemGroup.id,
    description: lineItemGroup.title ?? lineItemGroup.description,
    lineItems: [],
    taxCategoryId: lineItemGroup.taxCategory?.id
  }
}

export const lineItemEditorAdapter = {
  in: {
    lineItem: createAdapterData,
    lineItemGroup: createAdapterLineItemGroup
  },
  out: createApiData
}
