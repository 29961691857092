import { isAfter } from '@sequencehq/utils/dist/dates'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from 'Cube/domain/cube.domain.types'
import { useMemo } from 'react'

export const usePhaseDetails = (props: { phaseId: string }) => {
  const cubeContext = useCubeContext()

  const resolvedPhase = cubeContext.queries.resolvedPhases[props.phaseId]
  const availableFeaturesForPhase =
    cubeContext.queries.availableFeatures.phases[resolvedPhase.id].phase
  const isOnlyPhase =
    Object.values(cubeContext.queries.rawData.data.phases).length === 1
  const isFirstPhase =
    cubeContext.queries.orderedPhases[0]?.id === props.phaseId

  const isLastPhase = useMemo(() => {
    if (isOnlyPhase) {
      return true
    }

    const sortedPhases = cubeContext.queries.orderedPhases

    return sortedPhases[sortedPhases.length - 1].id === props.phaseId
  }, [cubeContext.queries.orderedPhases, isOnlyPhase, props.phaseId])

  const previousPhaseEndDate = useMemo(() => {
    const currentPhaseIndex = cubeContext.queries.orderedPhases.findIndex(
      op => op.id === op.id
    )
    if (currentPhaseIndex < 1) {
      return
    }

    const previousPhase =
      cubeContext.queries.orderedPhases[currentPhaseIndex - 1]

    return previousPhase.dates.absolute.end
  }, [cubeContext])

  const phaseComplete = useMemo(() => {
    if (!resolvedPhase.dates.absolute.end) {
      return false
    }

    return (
      [CubeStatus.ScheduleCancelled, CubeStatus.ScheduleActive].includes(
        cubeContext.queries.rawData.data.common.status
      ) && isAfter(new Date(), resolvedPhase.dates.absolute.end)
    )
  }, [resolvedPhase, cubeContext.queries.rawData.data.common.status])

  return {
    resolvedPhase,
    availableFeaturesForPhase,
    isOnlyPhase,
    isFirstPhase,
    isLastPhase,
    previousPhaseEndDate,
    phaseComplete
  }
}
