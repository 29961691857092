import { CheckIcon } from '@heroicons/react/16/solid'
import { Button } from '@sequencehq/core-components'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CompleteMilestonePhaseModal } from './CompleteMilestonePhaseModal'
import { durationsToDateAdapter } from '@sequencehq/utils/dist/dates'
import { usePhaseDetails } from '../usePhaseDetails'
import invariant from 'tiny-invariant'

const useCompleteMilestone = (props: {
  phaseId: string
}) => {
  const ctx = useCubeContext()
  const { availableFeaturesForPhase, resolvedPhase } = usePhaseDetails({
    phaseId: props.phaseId
  })
  const show = availableFeaturesForPhase.completeMilestone.available.visible

  const onComplete = (completionDate: Date) => {
    invariant(
      resolvedPhase.dates.absolute.start,
      'Phase must have a start date to be marked as complete'
    )

    ctx.mutators.updateData({
      phases: {
        [resolvedPhase.id]: {
          duration: durationsToDateAdapter.toDuration(
            resolvedPhase.dates.absolute.start
          )(completionDate)
        }
      }
    })
  }

  return {
    show,
    onComplete,
    minimumCompletionDate: resolvedPhase.dates.absolute.start
  }
}

export const CompleteMilestonePhase = (props: {
  phaseId: string
}) => {
  const { show, minimumCompletionDate, onComplete } = useCompleteMilestone({
    phaseId: props.phaseId
  })

  if (!show || !minimumCompletionDate) {
    return null
  }

  return (
    <CompleteMilestonePhaseModal
      onComplete={onComplete}
      minDate={minimumCompletionDate}
    >
      <Button
        data-testid="phase.completeMilestone"
        variant="secondary"
        leadingIcon={<CheckIcon height="16px" width="16px" />}
      >
        Mark as complete
      </Button>
    </CompleteMilestonePhaseModal>
  )
}
