import { Box, Flex, Grid } from '@chakra-ui/react'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import { Button, Dialog, Modal } from '@sequencehq/core-components'
import { FC } from 'react'
import { useUnlinkCustomerModal } from './useUnlinkCustomerModal'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import Spinner from 'components/Loading/Spinner'

type UnlinkCustomerModalProps = {
  onUnlink?: () => void
  onClose?: () => void
  customerId: string
  service: IntegrationServices
}

const UnlinkCustomerModalUI: FC<UnlinkCustomerModalProps> = props => {
  const hook = useUnlinkCustomerModal({
    onConfirm: props.onUnlink,
    onCancel: props.onClose,
    customerId: props.customerId,
    service: props.service
  })

  return (
    <>
      <Dialog.Header showCloseButton>
        Unlink {hook.customerName} from {hook.serviceName}
      </Dialog.Header>
      <Dialog.Content>
        <Box
          color={GreyGrey80}
          {...Lato13Regular}
          flex={1}
          data-testid="modal.unlinkCustomer"
        >
          This will unlink the customer from {hook.serviceName} immediately. You
          can re-link the customer again if required.
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button variant="secondary" style={{ width: '100%' }}>
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={() => {
              void hook.submit.onClick()
            }}
            disabled={hook.submit.disabled}
          >
            {hook.submit.unlinking ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Unlinking...
              </Flex>
            ) : (
              'Unlink'
            )}
          </Button>
        </Grid>
      </Modal.Footer>
    </>
  )
}

export const UnlinkCustomerModal: FC<UnlinkCustomerModalProps> = props => {
  return (
    <Dialog unmountOnClose>
      <UnlinkCustomerModalUI {...props} />
    </Dialog>
  )
}
