import { format, isBefore } from '@sequencehq/utils/dates'

/**
 * Format the end date of a schedule, accounting for open ended
 * and milestone based schedules.
 * @param endDate
 * @returns
 */
export const formatScheduleEndDate = (endDate: string) => {
  if (!endDate) {
    return 'Open ended'
  }

  if (isBefore(new Date('2100-01-01'), endDate)) {
    return 'Milestone based'
  }

  return format(endDate, 'd MMM yyyy')
}
