import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'

export const getMilestoneBillingAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const isAQuote = Boolean(quoteStatusAdapter.out(prevState.data.common.status))

  /**
   * We don't Milestone billing in quotes. Whilst Cube itself can support
   * the concept, we can't persist the milestone billing approach yet.
   *
   * This is because we need to introduce some kind of token to represent
   * milestone billing. We can get away without for a schedule by doing some
   * unholy faffery related to 100 year durations, but we can't even do
   * that level of faffery for quotes as they use durations (which is the
   * better representation of phase length anyway!).
   *
   * When we fix up to API to allow for some kind of token, we can allow
   * quotes to access the feature.
   */
  if (isAQuote) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {
            status: prevState.data.common.status
          }
        }
      ]
    }
  }

  if (!prevState.configuration.features.useMilestoneBilling) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.FeatureDisabled,
          metadata: {
            status: prevState.data.common.status
          }
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
