import {
  Box,
  Flex,
  Image,
  UnorderedList,
  ListItem,
  Text
} from '@chakra-ui/react'
import { GreyGrey90, Lato13Regular } from '@sequencehq/design-tokens'
import coverImage from './zapierCover.png'

export const ZapierIntegrationDetail = () => {
  return (
    <Flex flexDirection="column" gap="20px">
      <Image
        src={coverImage}
        width="100%"
        borderRadius="8px"
        draggable={false}
      />
      <Text {...Lato13Regular} color={GreyGrey90}>
        Seamlessly connect Sequencehq.com with Zapier to automate notifications
        and workflows for key events. Keep your team informed and in control by
        triggering real-time updates when:
      </Text>
      <Box {...Lato13Regular} color={GreyGrey90} lineHeight="18px">
        <UnorderedList spacing={2}>
          <ListItem>
            Quotes are (re)published or signed by all recipients
          </ListItem>
          <ListItem>
            Company or bank details are updated so no accidents slip through
          </ListItem>
          <ListItem>New customers are added</ListItem>
          <ListItem>Billing schedules are completed</ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  )
}
