import { Flex } from '@chakra-ui/react'
import { RadioButtonGroup } from '@sequencehq/core-components'
import { useMagicTableToolbarContext } from '@sequencehq/tables'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ActiveInvoiceFilter = 'all' | 'draft' | 'upcoming' | 'outstanding' | 'paid'

export const InvoicesSegmentedFilters = () => {
  const [activeFilter, setActiveFilter] = useState<ActiveInvoiceFilter>('all')
  const [searchParams] = useSearchParams()

  const ctx = useMagicTableToolbarContext()

  useEffect(() => {
    const sortBy = searchParams.get('sortBy')
    const invoiceStatus = searchParams.get('invoiceStatus')
    const invoicePaymentStatus = searchParams.get('invoicePaymentStatus')

    if (
      sortBy === 'accountingDate_desc' &&
      !invoiceStatus &&
      !invoicePaymentStatus
    ) {
      setActiveFilter('all')
    } else if (invoiceStatus === 'DRAFT') {
      setActiveFilter('draft')
    } else if (invoiceStatus === 'IN_PROGRESS') {
      setActiveFilter('upcoming')
    } else if (invoiceStatus === 'SENT' && invoicePaymentStatus === 'UNPAID') {
      setActiveFilter('outstanding')
    } else if (invoicePaymentStatus === 'PAID') {
      setActiveFilter('paid')
    } else {
      setActiveFilter('all')
    }
  }, [searchParams])

  const handleFilterChange = useCallback(
    (value: string) => {
      switch (value) {
        case 'all':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoiceStatus',
            values: []
          })
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoicePaymentStatus',
            values: []
          })
          break
        case 'draft':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoiceStatus',
            values: ['DRAFT']
          })
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoicePaymentStatus',
            values: []
          })
          break
        case 'upcoming':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoiceStatus',
            values: ['IN_PROGRESS']
          })
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoicePaymentStatus',
            values: []
          })
          break
        case 'outstanding':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoiceStatus',
            values: ['SENT']
          })
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoicePaymentStatus',
            values: ['UNPAID']
          })
          break
        case 'paid':
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoicePaymentStatus',
            values: ['PAID']
          })
          ctx.functions.bulkSetMultiSelectOptions({
            paramName: 'invoiceStatus',
            values: []
          })
          break
        default:
          break
      }
    },
    [ctx.functions]
  )

  const disabled = useMemo(
    () => !ctx.queries.canChangeFilters(),
    [ctx.queries.canChangeFilters]
  )

  return (
    <Flex alignItems="start">
      <RadioButtonGroup
        onChange={handleFilterChange}
        initialValue={activeFilter}
        value={activeFilter}
        options={[
          { value: 'all', label: 'All', disabled },
          { value: 'draft', label: 'Draft', disabled },
          { value: 'upcoming', label: 'Upcoming', disabled },
          { value: 'outstanding', label: 'Outstanding', disabled },
          { value: 'paid', label: 'Paid', disabled }
        ]}
      />
    </Flex>
  )
}
