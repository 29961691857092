import { Link } from '@chakra-ui/react'
import { PricingModel } from 'common/drawers/PricingEditor/domain'
import { SelectField } from '@sequencehq/core-components'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PRICING_MODEL_OPTIONS } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

const usePricingModelField = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  return {
    value:
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .pricingModel,
    options: PRICING_MODEL_OPTIONS,
    onChange: (newPricingModel: PricingModel) => {
      pricingEditorContext.mutators.common.updatePricingModel(newPricingModel)
    },
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    description: PRICING_MODEL_OPTIONS.find(
      option =>
        option.value ===
        pricingEditorContext.queries.rawData.data.pricingEditorData.common
          .pricingModel
    )?.description
  }
}

/**
 * A helper field for managing the pricing model in a form. This is the only
 * way we currently expect to change the pricing model, and it uses
 * a specific action exposed for that purpose.
 * @param props
 * @returns
 */
export const PricingModelField = () => {
  const { value, onChange, options, disabled, description } =
    usePricingModelField()

  return (
    <SelectField
      label="Pricing model"
      data-testid="common.pricingModel"
      value={value}
      onChange={newPricingModel => onChange(newPricingModel as PricingModel)}
      options={options}
      isDisabled={disabled}
      placeholder="Select pricing model"
      description={
        description ? (
          <>
            {description}{' '}
            <Link
              href="https://docs.sequencehq.com/plans-products/pricing-models"
              isExternal
              color="indigo.50"
            >
              Learn more
            </Link>
          </>
        ) : null
      }
    />
  )
}
