import { PricingEditorRoot } from 'common/drawers/PricingEditor/view/pricingEditor.root'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductContext } from 'modules/Products/hooks/useProductContext'
import { EditorMode as PriceEditorMode } from 'modules/common/drawers/PricingEditor/domain'
import { ListPrice } from 'Products/types'
import { useNotifications } from 'lib/hooks/useNotifications'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

export const useConnector = () => {
  const params = useParams<{
    productId: string
    listPriceId: string
  }>()
  const navigate = useNavigate()
  const { displayNotification } = useNotifications()
  const productContext = useProductContext()

  const initialPriceEditorMode = useMemo(() => {
    if (!params.listPriceId || params.listPriceId.match(NEW_PRICE_PATTERN)) {
      return PriceEditorMode.CREATE
    }

    return PriceEditorMode.EDIT
  }, [params.listPriceId])

  const onSave = useCallback(
    (newData: { price: ListPrice }) => {
      productContext.functions.updateListPriceState(newData.price)
      navigate('..')
      displayNotification('List price created', {
        type: 'success'
      })
    },
    [productContext.functions, displayNotification, navigate]
  )

  const onCancel = useCallback(() => {
    navigate('..')
  }, [navigate])

  return {
    initialPriceEditorMode,
    onSave,
    onCancel
  }
}

export const ListPriceEditorRouteWrapper = () => {
  const context = useConnector()

  return (
    <PricingEditorRoot
      variant="list"
      initialMode={context.initialPriceEditorMode}
      onSave={context.onSave}
      onCancel={context.onCancel}
    />
  )
}
