import { useMemo, useState } from 'react'
import {
  InputSelectControl,
  RadioButton,
  SimpleModalUI,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import {
  BorderRadius6,
  GreyGrey10,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName.ts'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useQuery } from '@sequencehq/api/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { IntegrationServices } from '@sequencehq/api/utils/commonEnums'
import invariant from 'tiny-invariant'

// As far as creating and linking customers to a service,
// we want to only allow doing so for services that the endpoint supports
const SUPPORTED_SERVICES = ['Xero', 'QuickBooks_Online'] as const
const isCustomerLinkingSupported = (service: IntegrationServices) => {
  return SUPPORTED_SERVICES.includes(
    service as (typeof SUPPORTED_SERVICES)[number]
  )
}

type FormData = {
  linkToAccount: string
  createNew: boolean
}

interface Props {
  onConfirm?: () => void
  customerId: string
  service: IntegrationServices
}

export const LinkOrCreateCustomerModal = withUnmountOnModalClose(
  ({ onConfirm, customerId, service: _service }: Props) => {
    invariant(
      isCustomerLinkingSupported(_service),
      `Linking customers is not supported for ${_service}`
    )
    // In order for typescript to be fine with us only passing a supported subset of services
    // we cast the type right below the invariant that is a type guard
    const service = _service as (typeof SUPPORTED_SERVICES)[number]

    const serviceName = getIntegrationName(service)

    const [isLinking, setIsLinking] = useState(false)
    const { displayNotification } = useNotifications()

    const customersOnExternalServiceResponse = useQuery(
      dashboard20240730Client.getIntegrationCustomersService,
      service,
      {
        select: res => res?.items ?? []
      }
    )

    const accountLinkingOptions = customersOnExternalServiceResponse.data
      ?.filter(acc => !acc.isLinked)
      .map(acc => ({
        label: acc.companyName,
        value: acc.id
      }))

    const { fields, queries } = useForm<FormData>({
      value: {
        linkToAccount: '',
        createNew: false
      },
      fieldConfiguration: [
        {
          property: 'createNew'
        },
        {
          property: 'linkToAccount',
          disabled: ({ formData }) => formData.createNew,
          validation: [required],
          options: accountLinkingOptions
        }
      ]
    })

    const customerResponse = useQuery(
      dashboardv99990101Client.getCustomer,
      {
        id: customerId
      },
      {
        enabled: !isLinking,
        select: res => ({
          /**
           * Return this so we can use it as part of the PUT, but select the
           * useful bits otherwise.
           */
          fullCustomer: res,
          name: res?.legalName ?? '',
          externalId:
            res?.integrationIds.find(int => int.service === service)?.id ?? null
        })
      }
    )

    /**
     * Link the customer to an account on the third party service.
     */
    const linkToExistingServiceCustomer = async (): Promise<boolean> => {
      const existingCustomer = customerResponse.data?.fullCustomer
      if (!existingCustomer) {
        return false
      }

      const res = await dashboardv99990101Client.putCustomer({
        id: customerId,
        body: {
          ...existingCustomer,
          contacts: existingCustomer.contacts.map(contact => ({
            email: contact.email,
            name: contact.name ?? '',
            billingPreference: contact.billingPreference
          })),
          integrationIds: [
            ...(existingCustomer?.integrationIds ?? []).filter(
              int => int.service !== service
            ),
            {
              service,
              id: fields.linkToAccount.value
            }
          ]
        }
      })

      if (res.error) {
        return false
      }

      return true
    }

    const pushCustomerToService = async () => {
      const res =
        await dashboard20240730Client.postPushCustomerToExternalService({
          customerId,
          service
        })

      if (res.error) {
        return false
      }

      return true
    }

    const externalAccountDetails = useMemo(() => {
      const externalAccount = customersOnExternalServiceResponse.data?.find(
        account => account.id === fields.linkToAccount.value
      )

      if (!externalAccount) {
        return null
      }

      return {
        name: externalAccount?.companyName ?? '',
        externalUrl: externalAccount?.externalUrl ?? ''
      }
    }, [customersOnExternalServiceResponse.data, fields.linkToAccount.value])

    const handleSubmit = async () => {
      setIsLinking(true)

      const success = await (queries.formData.createNew
        ? pushCustomerToService()
        : linkToExistingServiceCustomer())

      if (!success) {
        displayNotification(`Could not link customer to ${serviceName}`, {
          type: 'error'
        })
        setIsLinking(false)
        return
      }

      displayNotification(`Customer linked to ${serviceName}`, {
        type: 'success'
      })

      setIsLinking(false)
      onConfirm?.()
    }

    return (
      <SimpleModalUI
        title={`Link or create ${serviceName} customer`}
        cancelButtonText="Cancel"
        submitButtonText="Create link"
        submitDisabled={!queries.isValid || isLinking}
        onSubmit={handleSubmit}
        contentProps={{
          minHeight: '0px'
        }}
      >
        <Flex
          color={GreyGrey80}
          {...Lato13Regular}
          flexDirection="column"
          gap="16px"
        >
          <RadioButton
            name="createNew"
            data-testid="createNew.existing"
            label={`Select existing customer in ${serviceName}`}
            isChecked={!fields.createNew.value}
            onChange={() => {
              fields.createNew.onChange(false)
            }}
          />
          <InputSelectControl
            {...fields.linkToAccount}
            data-testid="linkToAccount"
            placeholder={`Select ${serviceName} customer`}
            disabled={fields.linkToAccount.disabled}
          />
          {externalAccountDetails && (
            <Grid
              gridTemplateColumns="1fr auto"
              gridColumnGap="8px"
              backgroundColor={GreyGrey10}
              borderRadius={BorderRadius6}
              padding="12px"
            >
              <GridItem {...Lato13Bold} color={GreyGrey90}>
                {externalAccountDetails.name}
              </GridItem>
              <GridItem {...Lato13Bold} color={IndigoIndigo50}>
                <a
                  href={externalAccountDetails.externalUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in {serviceName}
                </a>
              </GridItem>
            </Grid>
          )}
          <RadioButton
            name="createNew"
            data-testid="createNew.new"
            label={`Create new customer in ${serviceName}`}
            isChecked={fields.createNew.value}
            onChange={() => {
              fields.createNew.onChange(true)
            }}
          />
        </Flex>
      </SimpleModalUI>
    )
  }
)
