import { SelectField } from '@sequencehq/core-components'
import { toBillingFrequencyLabel, toPriceSummary } from '@sequencehq/utils'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { internalPriceToCommonPrice } from 'common/drawers/PricingEditor/communication/external/v1/adapters/pricingEditor.adapters'
import { useMemo } from 'react'

const useListPriceField = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const availableCurrencies =
    pricingEditorContext.queries.rawData.configuration.availableCurrencies

  const availableListPriceOptions = useMemo(() => {
    if (
      pricingEditorContext.queries.rawData.configuration.priceType != 'variant'
    ) {
      return []
    }

    const existingListPrices = Object.values(
      pricingEditorContext.queries.rawData.data.listPrices
    )

    return existingListPrices
      .filter(listPrice => {
        /**
         * If we have a specific currency set, then restrict the list prices
         * to the available currency.
         */
        if (pricingEditorContext.queries.rawData.configuration.currency) {
          return (
            listPrice.common.currency ===
            pricingEditorContext.queries.rawData.configuration.currency
          )
        }

        /**
         * Otherwise, fall back to the list prices that are available
         * for the currencies enabled for the editor.
         */
        return availableCurrencies.includes(listPrice.common.currency)
      })
      .map(listPrice => ({
        value: listPrice.id,
        label: `${toPriceSummary(
          internalPriceToCommonPrice(listPrice)
        )} / ${toBillingFrequencyLabel(listPrice.modelSpecific.billingFrequency)}`
      }))
  }, [
    pricingEditorContext.queries.rawData.configuration.priceType,
    pricingEditorContext.queries.rawData.data.listPrices,
    availableCurrencies,
    pricingEditorContext.queries.rawData.configuration.currency
  ])

  return {
    value:
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .listPriceId,
    options: availableListPriceOptions,
    onChange: (newListPriceId: string) => {
      pricingEditorContext.mutators.common.updateListPrice(newListPriceId)
    },
    disabled:
      !pricingEditorContext.queries.availableFeatures.variantPrice
        .canChangeListPrice.available.enabled,
    show: availableListPriceOptions.length > 1
  }
}
/**
 * A helper field for managing the list price in a form. The `useCommonFieldConfig`
 * hook should be used and layered into the form using this field. This is something
 * we will be able to improve at some point with a fancy form solution.
 * @param props
 * @returns
 */

export const ListPriceField = () => {
  const { value, onChange, options, disabled, show } = useListPriceField()

  if (!show) {
    return null
  }

  return (
    <SelectField
      label="List price"
      data-testid="common.listPriceId"
      value={value ?? ''}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      placeholder="Select list price"
    />
  )
}
