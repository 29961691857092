import StartBillingForm from 'components/BillingSchedules/components/modals/StartBilling/StartBillingForm'
import { StartBillingFormProps } from 'components/BillingSchedules/components/modals/StartBilling/types'
import { createFormModal } from 'components/Form/createFormModal'
import { Toast } from '@sequencehq/core-components'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

/**
 * Translate the useMutation into a format that will work with the hook argument
 * (yeah, that argument is gross, but I want to draw some kind of line with the
 * refactor for getting rid of the rtk-query/core-models schedule stuff!).
 * @returns
 */
const useActivateBillingSchedule = () => {
  const mutation = useMutation(
    dashboard20240730Client.putBillingScheduleActivate
  )

  return [
    mutation.mutate,
    {
      isSuccess: mutation.isSuccess,
      error: mutation.error,
      isLoading: mutation.isPending,
      data: {
        value: () => mutation.data
      }
    }
  ]
}

export const StartBillingModal = createFormModal<any, StartBillingFormProps>({
  //@ts-expect-error - it'll do, we need to delete this createFormModal thing eventually
  FormComponent: StartBillingForm,
  //@ts-expect-error - it'll do, we need to delete this createFormModal thing eventually
  useMutation: useActivateBillingSchedule,
  modalTitle: 'Start schedule',
  successMessage: 'Schedule started',
  submitTitle: 'Start schedule',
  formId: 'startScheduleForm',
  successToast: () => (
    <Toast title="Schedule started" type="success" isClosable={true}></Toast>
  ),
  errorToast: (
    <Toast
      title="Could not start schedule"
      type="error"
      isClosable={true}
    ></Toast>
  ),
  variant: 'dialogue'
})
