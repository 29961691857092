import { ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Icon, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/16/solid'
import { Lato14Bold } from '@sequencehq/design-tokens'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'

export const useShowAdditionalFields = () => {
  const pricingEditorContext = usePricingEditorDomainContext()

  const updateShowAdditionalFields = (newState: boolean) => {
    pricingEditorContext.mutators.common.updateEditor({
      showAdditionalFields: newState
    })
  }

  return {
    showAdditionalFields:
      pricingEditorContext.queries.rawData.editor.showAdditionalFields,
    updateShowAdditionalFields
  }
}

export const ShowAdditionalFieldsToggle = () => {
  const { showAdditionalFields, updateShowAdditionalFields } =
    useShowAdditionalFields()

  return (
    <Box
      display="flex"
      gap="4px"
      alignItems="center"
      marginBottom="16px"
      cursor="pointer"
      userSelect="none"
      data-testid="priceEditor.showAdditionalFields"
      onClick={() => updateShowAdditionalFields(!showAdditionalFields)}
    >
      <Text {...Lato14Bold} color="#5F5CFF">
        Show additional fields
      </Text>
      {showAdditionalFields ? (
        <Icon
          data-testid="priceEditor.showAdditionalFields.hide"
          as={ChevronUpIcon}
          color="#5F5CFF"
          w="20px"
          h="20px"
        />
      ) : (
        <Icon
          data-testid="priceEditor.showAdditionalFields.show"
          as={ChevronDownIcon}
          color="#5F5CFF"
          w="20px"
          h="20px"
        />
      )}
    </Box>
  )
}
