import { Flex, Text } from '@chakra-ui/react'
import { GreyGrey80, Lato14Bold } from '@sequencehq/design-tokens'
import TaxRatesTable from 'Settings/view/taxRatesSettings/components/TaxRatesTable'
import { PlusIcon } from '@heroicons/react/16/solid'
import { Button } from '@sequencehq/core-components'

import TaxRatesModal from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import { useCreateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import { useUpdateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useUpdateTaxRate'
import type { TaxRate } from 'Settings/domain/taxRates.types'
import { CountriesAlpha2 } from '@sequencehq/api/dist/utils/commonEnums'

interface Props {
  taxRates: TaxRate[]
  isLoadingTaxRates: boolean
}

const TaxRatesSection = ({ taxRates, isLoadingTaxRates }: Props) => {
  const createTaxRate = useCreateTaxRate()

  const updateTaxRate = useUpdateTaxRate()

  const countriesWithTaxRates = taxRates
    ?.filter(taxRate => taxRate.taxCategoryId)
    .reduce(
      (acc, taxRate) => ({
        ...acc,
        [taxRate.country]: [...(acc[taxRate.country] || []), taxRate].sort(
          (a, b) => {
            return (
              a?.taxCategoryName?.localeCompare(b?.taxCategoryName || '') || -1
            )
          }
        )
      }),
      {} as Record<CountriesAlpha2, TaxRate[]>
    )

  return (
    <Flex flexDirection="column" width="100%" gap={2}>
      <Flex justifyContent="space-between" align="center">
        <Text {...Lato14Bold} color={GreyGrey80}>
          Tax rates
        </Text>
        <TaxRatesModal
          onSubmit={async values => {
            void (await createTaxRate(values))
          }}
          countriesWithTaxRates={countriesWithTaxRates}
          trigger={
            <Button
              variant="ghost"
              leadingIcon={
                <PlusIcon width="16px" height="16px" color="inherit" />
              }
            >
              New tax rate
            </Button>
          }
        />
      </Flex>

      <TaxRatesTable
        taxRates={taxRates}
        isLoading={isLoadingTaxRates}
        onCreateNewTaxRate={createTaxRate}
        onUpdateTaxRate={updateTaxRate}
      />
    </Flex>
  )
}

export default TaxRatesSection
