import { useState } from 'react'
import {
  InputSelectControlField,
  SimpleModalUI,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'

interface Props {
  onConfirm?: () => void
  productId: string
}

export const LinkQuickbooksItemModal = withUnmountOnModalClose(
  ({ onConfirm, productId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const { displayNotification } = useNotifications()

    // TODO: API call once account retrieval is done
    const quickbooksAccounts: { name: string; id: string }[] = []

    const quickbooksAccountsOptions = quickbooksAccounts.map(account => ({
      label: account.name,
      value: account.id
    }))

    const { fields, queries } = useForm<{ quickbooksAccountId: string }>({
      value: {
        quickbooksAccountId: ''
      },
      fieldConfiguration: [
        {
          property: 'quickbooksAccountId',
          validation: [required]
        }
      ]
    })

    const linkProductToQuickbooksAccount = async () => {
      // TODO: Implement backend call to link product to QuickBooks account

      const result = await Promise.resolve(productId)

      return result
    }

    const handleSubmit = async () => {
      setIsCreating(true)

      const success = await linkProductToQuickbooksAccount()

      if (!success) {
        displayNotification(`Could not create item in QuickBooks`, {
          type: 'error'
        })
        setIsCreating(false)
        return
      }

      displayNotification(`Product created in QuickBooks`, {
        type: 'success'
      })

      setIsCreating(false)
      onConfirm?.()
    }

    return (
      <SimpleModalUI
        title={`Create item in QuickBooks`}
        cancelButtonText="Cancel"
        submitButtonText="Create item"
        submitDisabled={!queries.isValid || isCreating}
        onSubmit={handleSubmit}
        contentProps={{
          minHeight: '0px'
        }}
      >
        <Flex
          color={GreyGrey80}
          {...Lato13Regular}
          flexDirection="column"
          gap="16px"
        >
          <InputSelectControlField
            {...fields.quickbooksAccountId}
            label="Account code"
            data-testid="linkToAccount"
            placeholder="Select an account code..."
            options={quickbooksAccountsOptions}
            styles={{
              wrapper: { marginBottom: '0' }
            }}
          />
        </Flex>
      </SimpleModalUI>
    )
  }
)
