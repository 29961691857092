import { ProductModel } from '@sequencehq/core-models'
import { ListPrice, Phase } from 'modules/Cube/domain/cube.domain.types'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { toPriceSummary, toBillingFrequencyLabel } from '@sequencehq/utils'
import {
  CreateMenuItem,
  Option,
  OptionGroup
} from '@sequencehq/core-components'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'

type UseSearchForProduct = (props: {
  phaseId: Phase['id']
  onSelection?: (entityId: ProductModel['id']) => void
}) => {
  productOptions: Option[]
  productOptionsWithListPrices: OptionGroup[]
  onSelection: (entityId: ProductModel['id']) => void
  onAddNew: () => void
  showAllProductsAddedStateWhenEmpty: boolean
}

const generateListPriceLabel = (listPrice: ListPrice) => {
  return `${toPriceSummary(listPrice)} / ${toBillingFrequencyLabel(
    listPrice.billingFrequency
  )}`
}

export const useSearchForProduct: UseSearchForProduct = props => {
  const navigate = useNavigate()
  const cubeContext = useCubeContext()

  /**
   * We don't want to include existing products in the search, since it leads
   * to strange behaviour given that we're looking to 'edit' the product, like
   * jumping directly into the edit mode for the current price.
   */
  const existingProductsInPhase = useMemo(() => {
    return Object.values(
      cubeContext.queries.resolvedPhases[props.phaseId]?.prices
    ).map(productPrice => productPrice.productId)
  }, [cubeContext, props.phaseId])

  const productOptions = useMemo(() => {
    const allProducts = Object.values(cubeContext.queries.rawData.data.products)

    if (!allProducts.length) {
      return []
    }

    return allProducts
      .filter(product => !existingProductsInPhase.includes(product.id))
      .map(product => ({
        label: product.name,
        value: product.id
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  }, [cubeContext.queries.rawData.data.products, existingProductsInPhase])

  const productOptionsWithListPrices = useMemo(() => {
    const allProducts = Object.values(cubeContext.queries.rawData.data.products)

    if (!allProducts.length) {
      return []
    }

    return allProducts
      .map(product => {
        const listPrices =
          cubeContext.queries.availableListPrices[product.id] ?? []

        const listPriceOptions = listPrices.map(listPrice => ({
          id: listPrice.id,
          label: generateListPriceLabel(listPrice),
          value: listPrice.id
        }))

        return {
          id: product.id,
          key: product.id,
          label: product.name,
          value: product.id,
          options: listPriceOptions,
          emptyState: (
            <CreateMenuItem
              data-testid={`select.${product.id}.newPrice`}
              onClick={() => {
                navigate(`./price-editor/${props.phaseId}/${product.id}`)
              }}
              color={IndigoIndigo50}
            >
              New price variant
            </CreateMenuItem>
          )
        }
      })
      .filter(product => !existingProductsInPhase.includes(product.id))
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  }, [
    cubeContext.queries.rawData.data.products,
    cubeContext.queries.availableListPrices,
    existingProductsInPhase,
    navigate,
    props.phaseId
  ])

  // While we're switching from free-for-all variants to list prices, this function will either be passed a product ID or a list price ID,
  // so the arg needs to be generically named to account for both cases in the interim.
  const onSelection = useCallback(
    (entityId: ProductModel['id']) => {
      props.onSelection?.(entityId)
      const productId = Object.values(
        cubeContext.queries.rawData.data.listPrices
      )
        .flatMap(listPrices => listPrices)
        .find(({ id }) => id === entityId)?.productId
      return navigate(
        `./price-editor/${props.phaseId}/${productId}/${entityId}`
      )
    },
    [
      cubeContext.queries.rawData.data.listPrices,
      navigate,
      props.onSelection,
      props.phaseId
    ]
  )

  /**
   * Mooch on over to the new product route in the products module. This will cause the user
   * to leave Cube, but in the future we should seek to better tutorialize and restrict the user
   * so that items like products/list prices are set up before starting a schedule.
   *
   * Then, allow for saving drafts of schedules with no products added!
   */
  const onAddNew = useCallback(() => {
    return navigate(`/products/new`)
  }, [navigate])

  return {
    productOptions,
    productOptionsWithListPrices,
    showAllProductsAddedStateWhenEmpty: existingProductsInPhase.length > 0,
    onSelection,
    onAddNew
  }
}
