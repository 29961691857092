import { Box, Flex } from '@chakra-ui/react'
import Spinner from 'components/Loading/Spinner'
import {
  IntegrationServices,
  useApiQueryClient,
  useMutation,
  useQuery
} from '@sequencehq/api/dist/utils'
import { Button, Skeleton } from '@sequencehq/core-components'
import {
  GreyGrey10,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { getIntegrationName } from 'Integrations/utils/getIntegrationName'
import { ExistingEntityLink } from '../LinkEntityWidget/ExistingEntityLink'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { UnlinkBillingScheduleModal } from 'Integrations/integrationsConfig/common/LinkEntities/LinkedBillingScheduleWidget/UnlinkBillingScheduleModal.tsx'

interface LinkedBillingScheduleWidgetProps {
  billingScheduleId: string
  service: IntegrationServices
}

const useLinkedBillingScheduleWidget = (props: {
  service: IntegrationServices
  billingScheduleId: string
}) => {
  const serviceName = getIntegrationName(props.service)
  const { displayNotification } = useNotifications()
  const apiQueryClient = useApiQueryClient()

  const billingScheduleQuery = useQuery(
    dashboardv99990101Client.getBillingSchedule,
    { id: props.billingScheduleId },
    {
      select: billingSchedule => {
        if (!billingSchedule) {
          return null
        }

        const externalBillingSchedule = billingSchedule.integrationIds.find(
          ls => ls.service === props.service
        )

        return {
          customerId: billingSchedule.customerId,
          statusAllowsLink: Boolean(billingSchedule.customerId),
          status: billingSchedule.status,
          linkedBillingSchedule: externalBillingSchedule
            ? {
                id: externalBillingSchedule.id,
                url: externalBillingSchedule.url
              }
            : null
        }
      }
    }
  )

  const customerQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    { id: billingScheduleQuery.data?.customerId ?? '' },
    {
      enabled: Boolean(billingScheduleQuery.data?.customerId),
      select: customer => {
        if (!customer) {
          return null
        }

        const customerLink = customer.integrationIds.find(
          int => int.service === props.service
        )

        return customerLink
      }
    }
  )

  const linkingMutation = useMutation(
    dashboardv99990101Client.postPushBillingScheduleToExternalService
  )
  const linkBillingSchedule = () => {
    linkingMutation.mutate(
      {
        billingScheduleId: props.billingScheduleId,
        service: props.service
      },
      {
        onError: () => {
          displayNotification(
            `Failed to sync billing schedule to ${serviceName}`,
            {
              type: 'error'
            }
          )
        },
        onSuccess: () => {
          displayNotification(`Billing schedule synced to ${serviceName}`, {
            type: 'success'
          })
          /**
           * The external URL doesn't appear immediately, so wait a little while
           * and then bust the cache to ensure we load in the updated data.
           */
          setTimeout(() => {
            /**
             * Maybe nice to make this a simple lil' util at some stage.
             */
            dashboardv99990101Client.postPushBillingScheduleToExternalService.invalidateQueries?.forEach(
              qk => {
                apiQueryClient.invalidateQueries({
                  queryKey: Array.isArray(qk) ? qk : [qk]
                })
              }
            )
          }, 2000)
        }
      }
    )
  }

  return {
    loading: billingScheduleQuery.isPending,
    serviceName,
    billingScheduleStatusAllowsLink:
      billingScheduleQuery.data?.statusAllowsLink,
    linkedBillingSchedule: billingScheduleQuery.data?.linkedBillingSchedule,
    link: {
      disabled:
        billingScheduleQuery.data?.status !== 'ACTIVE' ||
        !customerQuery.data ||
        linkingMutation.isPending,
      onClick: linkBillingSchedule,
      linking: linkingMutation.isPending
    }
  }
}

const NotReadyToLink = () => (
  <Flex
    flexDirection="column"
    gap="4px"
    padding="12px"
    backgroundColor={GreyGrey10}
    borderRadius="8px"
  >
    <Box {...Lato13Bold} color={GreyGrey80}>
      Billing schedule
    </Box>
    <Box
      {...Lato13Regular}
      color={GreyGrey60}
      data-testid="linkBillingScheduleWidget.willSyncWhenFinal"
    >
      This billing schedule will be synced once it's active.
    </Box>
  </Flex>
)

export const LinkedBillingScheduleWidget = (
  props: LinkedBillingScheduleWidgetProps
) => {
  const hook = useLinkedBillingScheduleWidget(props)

  if (hook.loading) {
    return <Skeleton height="104px" width="100%" />
  }

  if (!hook.billingScheduleStatusAllowsLink) {
    return <NotReadyToLink />
  }

  if (hook.linkedBillingSchedule) {
    return (
      <ExistingEntityLink
        data-testid={`linkBillingScheduleWidget.${props.service}.linked`}
        externalEntityLabel="Billing schedule"
        serviceName={hook.serviceName}
        unlinkModal={
          <UnlinkBillingScheduleModal
            service={props.service}
            billingScheduleId={props.billingScheduleId}
          />
        }
        linkedEntity={{
          label: hook.linkedBillingSchedule.id,
          href: hook.linkedBillingSchedule.url,
          canUnlink: true,
          isPending: false
        }}
      />
    )
  }

  return (
    <Flex
      flexDirection="column"
      gap="4px"
      padding="12px"
      backgroundColor={GreyGrey10}
      borderRadius="8px"
      data-testid={`linkBillingScheduleWidget.${props.service}`}
    >
      <Box {...Lato13Bold} color={GreyGrey80}>
        Billing schedule
      </Box>
      <Box {...Lato13Regular} color={GreyGrey60} marginBottom="4px">
        This billing schedule has not yet been synced to {hook.serviceName}.
      </Box>
      <Button
        variant="secondary"
        onClick={hook.link.onClick}
        disabled={hook.link.disabled}
        data-testid={`linkBillingScheduleWidget.${props.service}.syncBillingSchedule`}
      >
        {hook.link.linking ? (
          <Flex gap="8px">
            <Spinner height="16px" width="16px" color={GreyGrey60} />
            Syncing...
          </Flex>
        ) : (
          'Sync billing schedule'
        )}
      </Button>
    </Flex>
  )
}
