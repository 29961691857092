import { Flex, Grid, Text } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  GreyGrey80,
  Lato13Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { ReactNode } from 'react'

export const ReadOnlyDetails = (props: { children: ReactNode }) => (
  <Flex
    flexDirection="column"
    gap="8px"
    data-testid="priceEditor.readOnlyDetails"
  >
    <Text {...Lato13Bold} color={GreyGrey80}>
      Details
    </Text>
    <Grid
      gridTemplateColumns="150px 1fr"
      columnGap="28px"
      rowGap="12px"
      borderRadius="6px"
      padding="12px 8px"
      backgroundColor={GreyGrey10}
      color={GreyGrey70}
      {...Lato13Regular}
    >
      {props.children}
    </Grid>
  </Flex>
)
