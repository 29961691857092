import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  SchedulePreviewArguments,
  SchedulePreviewResponse
} from 'modules/Cube/domain/cube.domain.types'
import { useCallback } from 'react'

export type UseLoadBillingSchedulePreview = () => (
  args: DashboardApi20240730.PostBillingScheduleBillingPreview.BillingPreviewBody
) => Promise<
  DashboardApi20240730.PostBillingScheduleBillingPreview.BillingPreview['occurrences']
>

export const useLoadSchedulePreview = () => {
  const load = useCallback(
    async (
      args: SchedulePreviewArguments
    ): Promise<SchedulePreviewResponse> => {
      const response =
        await dashboard20240730Client.postBillingScheduleBillingPreview({
          body: args
        })

      return response.data?.occurrences ?? []
    },
    []
  )

  return load
}
