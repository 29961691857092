import { useCallback, useState } from 'react'
import { useTaxCategoryValidation } from './useTaxCategoryValidation'
import { useCreateTaxRate } from 'Settings/view/taxRatesSettings/hooks/useCreateTaxRate'
import { TaxRate } from 'Settings/domain/taxRates.types'
import type {
  CountriesAlpha2,
  State
} from '@sequencehq/api/dist/utils/commonEnums'
import {
  Dashboardv99990101Api,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { ApiLineItemGroupTaxCategory } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import { useQuery } from '@sequencehq/api/dist/utils'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { match } from 'ts-pattern'
import * as Sentry from '@sentry/react'

interface Props {
  customerCountry: CountriesAlpha2
  customerState?: State
  customerTaxStatus: Dashboardv99990101CommonModels.Customers.CustomerTaxStatus
  taxCategoryId: string
}

export type AvalaraTaxValidationModalState = {
  active: boolean
  onClose: () => void
}

export type SequenceTaxValidationModalState =
  | {
      active: false
      onConfirm: () => void
      onClose: () => void
    }
  | {
      active: true
      onConfirm: (values: any) => Promise<void>
      onClose: () => void
      data: {
        countryTaxRates: Record<CountriesAlpha2, TaxRate[]>
        customerCountry: CountriesAlpha2
        taxCategories: Array<ApiLineItemGroupTaxCategory>
        customerState?: State
      }
    }

type UseTaxModals = (props: Props) => {
  functions: {
    onConfirmSubmit: (saveFunction: () => void) => () => Promise<void>
  }
  modals: {
    avalara: AvalaraTaxValidationModalState
    sequence: SequenceTaxValidationModalState
  }
}

export const useTaxModals: UseTaxModals = ({
  customerCountry,
  customerState,
  customerTaxStatus,
  taxCategoryId
}) => {
  const [avalaraValidationModalState, setAvalaraValidationModalState] =
    useState<AvalaraTaxValidationModalState>({
      active: false,
      onClose: () => {}
    })
  const [sequenceTaxValidationModalState, setSequenceTaxValidationModalState] =
    useState<SequenceTaxValidationModalState>({
      active: false,
      onConfirm: async () => {},
      onClose: () => {}
    })
  const { data: selectedTaxCategory } = useQuery(
    dashboard20240730Client.getTaxCategory,
    { id: taxCategoryId },
    { enabled: Boolean(taxCategoryId) }
  )
  const taxCategories: Array<Dashboardv99990101Api.GetTaxCategories.TaxCategory> =
    selectedTaxCategory ? [selectedTaxCategory] : []
  const {
    valid: taxCategoryValid,
    provider,
    taxRatesForCountry
  } = useTaxCategoryValidation({
    taxCategoryId,
    customerCountry,
    customerTaxStatus
  })
  const createTaxRate = useCreateTaxRate({ invalidateQueries: false })

  const onConfirmSubmit = useCallback(
    (saveFunction: () => void) => async () => {
      if (taxCategoryValid) {
        setAvalaraValidationModalState({
          active: false,
          onClose: () => {}
        })
        setSequenceTaxValidationModalState({
          active: false,
          onConfirm: () => {},
          onClose: () => {}
        })
        saveFunction()
        return Promise.resolve()
      }

      let resolveSave: () => void
      const validationComplete = new Promise<void>(res => (resolveSave = res))

      match(provider)
        .with('AVALARA', () => {
          setAvalaraValidationModalState({
            active: true,
            onClose: () => {
              setAvalaraValidationModalState({
                active: false,
                onClose: () => {}
              })
            }
          })
        })
        .with('SEQUENCE', () => {
          setSequenceTaxValidationModalState({
            active: true,
            data: {
              taxCategories: taxCategories,
              countryTaxRates: {
                [customerCountry]: taxRatesForCountry
              } as Record<CountriesAlpha2, TaxRate[]>,
              customerCountry
            },
            onConfirm: async (values: any) => {
              await createTaxRate(values)

              setSequenceTaxValidationModalState({
                active: false,
                onConfirm: () => {},
                onClose: () => {}
              })

              try {
                saveFunction()
                return resolveSave()
              } catch (e) {
                Sentry.captureException(e)
              }
            },
            onClose: () => {
              setSequenceTaxValidationModalState({
                active: false,
                onConfirm: async () => {},
                onClose: () => {}
              })
            }
          })
        })
        .exhaustive()

      return await validationComplete
    },
    [
      taxCategoryValid,
      provider,
      taxCategories,
      taxRatesForCountry,
      createTaxRate
    ]
  )

  return {
    functions: {
      onConfirmSubmit
    },
    modals: {
      avalara: avalaraValidationModalState,
      sequence: sequenceTaxValidationModalState
    }
  }
}
