import { Flex, Icon } from '@chakra-ui/react'
import {
  IndigoIndigo50,
  Lato13Bold,
  Lato14Regular
} from '@sequencehq/design-tokens'
import { SimpleTableInput } from '@sequencehq/core-components'
import { FC } from 'react'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { PriceEditorTier } from 'common/drawers/PricingEditor/domain'
import { ValidationError } from '@sequencehq/utils'
import { useSeatBasedTierInput } from './useSeatBasedTierInput'
import { PlusIcon } from '@heroicons/react/16/solid'

type SeatBasedTierInputProps = {
  onChange: (value: PriceEditorTier[]) => void
  value: PriceEditorTier[]
  isDisabled: boolean
  currency: Currency
  validationErrors: ValidationError[]
}

export const SeatBasedTierInput: FC<SeatBasedTierInputProps> = props => {
  const { fieldConfig, addNewTier } = useSeatBasedTierInput(props)

  return (
    <>
      <SimpleTableInput {...fieldConfig} data-testid="seat.tiers" />
      <Flex justifyContent="center" marginTop="8px">
        <Flex
          {...Lato13Bold}
          width="fit-content"
          color={IndigoIndigo50}
          onClick={() => addNewTier()}
          _hover={{ opacity: 0.7 }}
          cursor="pointer"
          padding="4px 0"
          alignItems="center"
          data-testid="seat.tiers.addTier"
        >
          <Icon height="16px" width="16px" as={PlusIcon} marginRight="4px" />{' '}
          Add another tier
        </Flex>
      </Flex>
    </>
  )
}
