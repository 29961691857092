import { Box } from '@chakra-ui/react'
import {
  CheckboxGroupField,
  HorizontalSelectorField,
  PriceField
} from '@sequencehq/core-components'
import { useMinimumEditorForm } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/content/MinimumEditorForm/useMinimumEditorForm'
import { MinimumScopeTarget } from 'modules/Cube/view/common/drawers/minimumEditor/drawer/minimumEditor.types'

export const MinimumEditorForm = () => {
  const formHook = useMinimumEditorForm()

  return (
    <Box padding="24px">
      <PriceField
        label="Minimum amount"
        {...formHook.fieldConfig.value}
        currency={formHook.currency}
        data-testid="minimumEditor.amount"
      />
      <HorizontalSelectorField
        isDisabled={formHook.fieldConfig['scope.target'].disabled}
        label="Apply across"
        {...formHook.fieldConfig['scope.target']}
        onChange={newValue =>
          formHook.fieldConfig['scope.target'].onChange(
            newValue as MinimumScopeTarget
          )
        }
        data-testid="minimumEditor.scope"
      />
      <Box data-testid="minimumEditor.scope.prices">
        {!formHook.fieldConfig['scope.priceIds'].hidden && (
          <CheckboxGroupField {...formHook.fieldConfig['scope.priceIds']} />
        )}
      </Box>
    </Box>
  )
}
