import { useInvoiceEditorContext } from 'InvoiceEditor/hooks/useInvoiceEditorContext'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type {
  LineItem as InvoiceContentLineItem,
  LineItemGroup as InvoiceContentLineItemGroup
} from '@sequencehq/invoice-content'
import { formatTotal } from 'InvoiceEditor/domainManagement/invoiceEditorAdapter'
import { toPercentage } from '@sequencehq/formatters'
import {
  type Currency,
  type UsageDataResponseModel,
  usageBasedPricingTypes
} from '@sequencehq/core-models'
import { percentageFromDecimal } from '@sequencehq/utils'
import type {
  ApiCreateLineItemGroup,
  ApiLineItem,
  ApiLineItemGroup,
  ApiLineItemGroupTaxCategory,
  InvoiceEditorSubAccountUsageBreakdown,
  LineItemSubAccountUsageBreakdown
} from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import type { Price } from 'InvoiceEditor/components/LineItems/drawer/LineItemEditor/hooks/useLoadPrices'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { formatDateRange } from '@sequencehq/utils/dist/dates'
import { CountriesAlpha2 } from '@sequencehq/api/dist/utils'
import { State } from '@sequencehq/api/utils'
import { Dashboardv99990101Api } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import {
  AvalaraTaxValidationModalState,
  SequenceTaxValidationModalState,
  useTaxModals
} from './useTaxModals'

export const NEW_LINE_ITEM_GROUP_PATTERN = 'new-line-item-group::'

export type LineItemViewLineItem = InvoiceContentLineItem
export type LineItemViewLineItemGroup = InvoiceContentLineItemGroup

type UseLineItems = () => {
  data: {
    groups: LineItemViewLineItemGroup[]
    customer: {
      state?: State
    }
  }
  lineItemGroupEditor: {
    mode: 'CREATE' | 'EDIT' | 'VIEW'
  }
  setLineItemGroupEditorMode: (mode: 'CREATE' | 'EDIT' | 'VIEW') => void
  lineItemGroupFieldsConfig: {
    description?: {
      value: string
      onChange: (value: string) => void
      isValid: boolean
    }
  }
  subAccountUsageBreakdown: InvoiceEditorSubAccountUsageBreakdown[]
  currentLineItemGroup: LineItemViewLineItemGroup | null
  canEditLineItems: boolean
  canUseTaxCategories: boolean
  onAddLineItemGroup: () => void
  onAddLineItem: (lineItemGroupId: string) => void
  onEditLineItemGroup: (id: string) => void
  onCancelGroupChange: () => void
  updateLineItemGroupFields: (
    updatedFields: Partial<LineItemViewLineItemGroup>
  ) => void
  onUpdateLineItemGroup: () => void
  onEditLineItem: (lineItemGroupId: string, lineItemId: string) => void
  onDeleteLineItemGroup: (id: string) => void
  onDeleteLineItem: (lineItemGroupId: string, lineItemId: string) => void
  onCreateLineItemGroup: () => void
  showSubAccountUsage: boolean
  onConfirmSubmit: (saveFunction: () => void) => () => Promise<void>
  modals: {
    avalara: AvalaraTaxValidationModalState
    sequence: SequenceTaxValidationModalState
  }
}

const apiLineItemToLineItemViewLineItem =
  (currency: Currency, lineItemGroupId: string) =>
  (lineItem: ApiLineItem): LineItemViewLineItem => {
    return {
      title: lineItem.title,
      description: lineItem.description,
      quantity:
        lineItem.rateDisplay === 'PERCENTAGE'
          ? formatTotal(currency, lineItem.quantity)
          : lineItem.quantity,
      rate:
        lineItem.rateDisplay === 'PERCENTAGE'
          ? toPercentage(percentageFromDecimal(lineItem.rate))
          : formatTotal(currency, lineItem.rate, 10),
      price: formatTotal(currency, lineItem.netTotal),
      rateType:
        lineItem.rateDisplay === 'ABSOLUTE'
          ? ('FIXED' as LineItemViewLineItem['rateType'])
          : ('PERCENTAGE' as LineItemViewLineItem['rateType']),
      itemType:
        parseFloat(lineItem.rate) < 0
          ? ('DISCOUNT' as LineItemViewLineItem['itemType'])
          : ('PRODUCT' as LineItemViewLineItem['itemType']),
      taxRate: 0,
      groupId: lineItemGroupId,
      id: lineItem.id,
      taxCategoryId: lineItem.taxCategory?.id
    }
  }

const createUngroupedLineItemViewData = (
  currency: Currency,
  lineItems: ApiLineItem[]
): LineItemViewLineItemGroup[] =>
  lineItems
    .filter(lineItem => !lineItem.groupId)
    .map(lineItem => ({
      id: `ungrouped:${lineItem.id}`,
      description: lineItem.title,
      total: formatTotal(currency, lineItem.netTotal),
      totalTax: formatTotal(currency, lineItem.totalTax),
      grossTotal: formatTotal(currency, lineItem.grossTotal),
      lineItems: [
        apiLineItemToLineItemViewLineItem(
          currency,
          `ungrouped:${lineItem.id}`
        )(lineItem)
      ],
      servicePeriodLabel: !lineItem.servicePeriod
        ? undefined
        : formatDateRange({
            from: new Date(lineItem.servicePeriod.startDate),
            to: new Date(lineItem.servicePeriod.endDate)
          }),
      containsUsage: false
    }))

const apiLineItemGroupsToLineItemViewLineItemGroups = (
  currency: Currency,
  lineItems: ApiLineItem[],
  lineItemGroups: ApiLineItemGroup[],
  usageData: (UsageDataResponseModel & { lineItemGroupId: string })[],
  subAccountUsageBreakdown: Record<
    ApiLineItemGroup['id'],
    LineItemSubAccountUsageBreakdown[]
  >,
  prices: Record<Price['id'], Price>
): LineItemViewLineItemGroup[] =>
  lineItemGroups.map(lineItemGroup => {
    const lineItemsForThisGroup = lineItems.filter(
      lineItem => lineItem.groupId === lineItemGroup.id
    )

    /**
     * All of the line items within a group should share the same service start and end date.
     * If they don't, we won't show any service period.
     */

    const servicePeriodStarts = new Set(
      lineItemsForThisGroup
        .filter(lineItem => lineItem.servicePeriod?.startDate)
        .map(lineItem => lineItem.servicePeriod?.startDate)
    )
    const servicePeriodEnds = new Set(
      lineItemsForThisGroup
        .filter(lineItem => lineItem.servicePeriod?.endDate)
        .map(lineItem => lineItem.servicePeriod?.endDate)
    )

    const servicePeriodStart =
      servicePeriodStarts.size === 1 ? [...servicePeriodStarts][0] : undefined
    const servicePeriodEnd =
      servicePeriodEnds.size === 1 ? [...servicePeriodEnds][0] : undefined

    const usageForThisGroup = usageData.find(
      ({ lineItemGroupId }) => lineItemGroupId === lineItemGroup.id
    )

    const pricesForThisGroup = lineItemsForThisGroup
      .filter(lineItem => Boolean(lineItem.priceId))
      .map(lineItem => prices[lineItem.priceId ?? ''])
      .filter(Boolean)

    return {
      id: lineItemGroup.id,
      description: lineItemGroup.title,
      total: formatTotal(currency, lineItemGroup.netTotal),
      totalTax: formatTotal(currency, lineItemGroup.totalTax),
      grossTotal: formatTotal(currency, lineItemGroup.grossTotal),
      items: lineItemsForThisGroup.map(
        apiLineItemToLineItemViewLineItem(currency, lineItemGroup.id)
      ),
      lineItems: lineItemsForThisGroup.map(
        apiLineItemToLineItemViewLineItem(currency, lineItemGroup.id)
      ),
      servicePeriodLabel:
        !servicePeriodStart || !servicePeriodEnd
          ? ''
          : formatDateRange({
              from: new Date(servicePeriodStart),
              to: new Date(servicePeriodEnd)
            }),
      usageData: usageForThisGroup,
      subAccountUsageBreakdown:
        subAccountUsageBreakdown[lineItemGroup.id] ?? [],
      containsUsage: pricesForThisGroup.some(
        price =>
          'structure' in price &&
          usageBasedPricingTypes.includes(price.structure.pricingType)
      ),
      taxCategoryId: lineItemGroup.taxCategory?.id,
      taxCategory: lineItemGroup.taxCategory
    }
  })

const adapter = {
  in: {
    lineItemGroups: (
      apiLineItems: ApiLineItem[],
      apiGroups: ApiLineItemGroup[],
      apiUsage: (UsageDataResponseModel & { lineItemGroupId: string })[],
      currency: Currency,
      subAccountUsageBreakdown: Record<
        ApiLineItemGroup['id'],
        LineItemSubAccountUsageBreakdown[]
      >,
      prices: Record<Price['id'], Price>
    ): LineItemViewLineItemGroup[] => {
      const ungroupedLineItems = createUngroupedLineItemViewData(
        currency,
        apiLineItems
      )
      const groupedLineItems = apiLineItemGroupsToLineItemViewLineItemGroups(
        currency,
        apiLineItems,
        apiGroups,
        apiUsage,
        subAccountUsageBreakdown,
        prices
      )

      return [...ungroupedLineItems, ...groupedLineItems]
    }
  },
  out: {
    lineItemGroup: (
      adapterLineItemGroup: LineItemViewLineItemGroup,
      existingGroup?: ApiLineItemGroup
    ): ApiCreateLineItemGroup & ApiLineItemGroup => {
      return {
        id: adapterLineItemGroup.id,
        title: adapterLineItemGroup.description ?? '',
        grossTotal: existingGroup?.grossTotal ?? '0',
        netTotal: existingGroup?.netTotal ?? '0',
        totalTax: existingGroup?.totalTax ?? '0',
        invoiceId: existingGroup?.invoiceId ?? '',
        index: existingGroup?.index ?? 0,
        taxCategoryId: adapterLineItemGroup.taxCategoryId
      }
    }
  }
}

export const useLineItems: UseLineItems = () => {
  const invoiceEditorContext = useInvoiceEditorContext()
  const { data: contextData, derived } = invoiceEditorContext
  const navigate = useNavigate()
  const flags = useFlags()

  const [lineItemGroupEditorState, setLineItemGroupEditorState] = useState<{
    mode: 'CREATE' | 'EDIT' | 'VIEW'
  }>({ mode: 'VIEW' })
  const [selectedLineItemGroup, setSelectedLineItemGroup] =
    useState<LineItemViewLineItemGroup | null>(null)
  const { functions, modals } = useTaxModals({
    customerCountry: contextData.customer.address?.country ?? 'GB',
    customerState: contextData.customer.address?.state,
    customerTaxStatus: contextData.invoice.customerTaxStatus ?? 'TAXED',
    taxCategoryId: selectedLineItemGroup?.taxCategoryId ?? ''
  })

  const data = useMemo(() => {
    return {
      groups: adapter.in.lineItemGroups(
        Object.values(contextData.lineItems),
        Object.values(contextData.lineItemGroups),
        contextData.lineItemGroupUsage,
        contextData.invoice.currency,
        derived.queries.subAccountUsageBreakdownByLineItemGroup,
        contextData.prices
      ),
      currency: contextData.invoice.currency ?? 'GBP',
      customer: {
        state: contextData.customer.address?.state
      }
    }
  }, [contextData, derived])

  const onAddLineItemGroup = useCallback(() => {
    if (!selectedLineItemGroup) {
      return
    }

    const newLineItemGroup = adapter.out.lineItemGroup(selectedLineItemGroup)

    const updatedLineItemGroups = {
      ...invoiceEditorContext.data.lineItemGroups,
      [selectedLineItemGroup.id]: newLineItemGroup
    }

    invoiceEditorContext.functions.updateData({
      lineItemGroups: updatedLineItemGroups
    })

    invoiceEditorContext.functions.createLineItemGroup(
      selectedLineItemGroup.id,
      {
        ...invoiceEditorContext.data,
        lineItemGroups: updatedLineItemGroups
      }
    )
    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [
    invoiceEditorContext.data,
    invoiceEditorContext.functions,
    selectedLineItemGroup
  ])

  const onUpdateLineItemGroup = useCallback(() => {
    if (!selectedLineItemGroup) {
      return
    }

    const updatedGroup = {
      ...invoiceEditorContext.data.lineItemGroups[selectedLineItemGroup.id],
      title: selectedLineItemGroup.description,
      taxCategoryId: selectedLineItemGroup.taxCategoryId
    }

    const updatedGroups = {
      ...invoiceEditorContext.data.lineItemGroups,
      [selectedLineItemGroup.id]: updatedGroup
    }

    invoiceEditorContext.functions.updateData({
      lineItemGroups: updatedGroups
    })

    invoiceEditorContext.functions.updateLineItemGroup(
      selectedLineItemGroup.id,
      {
        ...invoiceEditorContext.data,
        lineItemGroups: updatedGroups
      }
    )

    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [
    invoiceEditorContext.data,
    invoiceEditorContext.functions,
    selectedLineItemGroup
  ])

  const onCreateLineItemGroup = () => {
    const newLineItemGroup = {
      id: `${NEW_LINE_ITEM_GROUP_PATTERN}${crypto.randomUUID()}`,
      description: '',
      total: '',
      totalTax: '',
      grossTotal: '',
      lineItems: [],
      taxCategoryId: undefined
    }

    setSelectedLineItemGroup(newLineItemGroup)

    setLineItemGroupEditorState({ mode: 'CREATE' })
  }

  const onDeleteLineItemGroup = useCallback(
    (id: string) => {
      const { [id]: deletedGroup, ...remainingGroups } =
        invoiceEditorContext.data.lineItemGroups
      // TODO also any line items??
      const updatedReducerData = {
        lineItemGroups: remainingGroups
      }

      invoiceEditorContext.functions.updateData(updatedReducerData)

      invoiceEditorContext.functions.deleteLineItemGroup(id, {
        ...invoiceEditorContext.data,
        ...updatedReducerData
      })
    },
    [invoiceEditorContext.data, invoiceEditorContext.functions]
  )

  const onDeleteLineItem = useCallback(
    (lineItemGroupId: string, lineItemId: string) => {
      const { [lineItemId]: deletedLineItem, ...remainingLineItems } =
        invoiceEditorContext.data.lineItems

      invoiceEditorContext.functions.updateData({
        lineItems: remainingLineItems
      })

      invoiceEditorContext.functions.deleteLineItem(
        lineItemGroupId,
        lineItemId,
        {
          ...invoiceEditorContext.data,
          lineItems: remainingLineItems
        }
      )
    },
    [invoiceEditorContext.data, invoiceEditorContext.functions]
  )

  const onEditLineItemGroup = (id: string) => {
    if (flags.showNewTaxManagement) {
      setLineItemGroupEditorState({ mode: 'EDIT' })
    }
    setSelectedLineItemGroup(data.groups.find(group => group.id === id) ?? null)
  }

  const updateLineItemGroupFields = useCallback(
    (updatedFields: Partial<LineItemViewLineItemGroup>) => {
      setSelectedLineItemGroup(prev => {
        return <LineItemViewLineItemGroup>{
          ...prev,
          ...updatedFields
        }
      })
    },
    []
  )

  const lineItemGroupFieldsConfig = useMemo(() => {
    if (!selectedLineItemGroup) {
      return {}
    }

    return {
      description: {
        value: selectedLineItemGroup?.description ?? '',
        onChange: (value: string) =>
          updateLineItemGroupFields({ description: value }),
        isValid: selectedLineItemGroup?.description?.trim().length > 0
      }
    }
  }, [selectedLineItemGroup, updateLineItemGroupFields])

  const onCancelGroupChange = useCallback(() => {
    setSelectedLineItemGroup(null)
    setLineItemGroupEditorState({ mode: 'VIEW' })
  }, [])

  const onAddLineItem = useCallback(
    (lineItemGroupId: string) =>
      navigate(
        `/invoices/${invoiceEditorContext.data.invoice.id}/${lineItemGroupId}/line-items/new`
      ),
    [invoiceEditorContext.data.invoice.id, navigate]
  )

  const onEditLineItem = useCallback(
    (lineItemGroupId: string, lineItemId: string) =>
      navigate(
        `/invoices/${invoiceEditorContext.data.invoice.id}/${lineItemGroupId}/line-items/${lineItemId}`
      ),
    [invoiceEditorContext.data.invoice.id, navigate]
  )

  const subAccountUsageBreakdown =
    invoiceEditorContext.data.subAccountUsageBreakdown ?? []

  return {
    data,
    lineItemGroupFieldsConfig,
    lineItemGroupEditor: lineItemGroupEditorState,
    setLineItemGroupEditorMode: (mode: 'CREATE' | 'EDIT' | 'VIEW') =>
      setLineItemGroupEditorState({
        mode
      }),
    currentLineItemGroup: selectedLineItemGroup,
    canEditLineItems: derived.queries.availableFeatures.canEditLineItems,
    canUseTaxCategories: derived.queries.availableFeatures.canUseTaxCategories,
    onEditLineItemGroup,
    onAddLineItemGroup,
    onCancelGroupChange,
    updateLineItemGroupFields,
    editingLineItemGroupId: selectedLineItemGroup?.id,
    onDeleteLineItemGroup,
    onUpdateLineItemGroup,
    onCreateLineItemGroup,
    onAddLineItem,
    onEditLineItem,
    onDeleteLineItem,
    subAccountUsageBreakdown,
    showSubAccountUsage:
      invoiceEditorContext.derived.queries.availableFeatures
        .canViewSubAccountUsage,
    onConfirmSubmit: functions.onConfirmSubmit,
    modals
  }
}
