import { format, formatDuration } from '@sequencehq/utils/dist/dates'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'

export const usePhaseDuration = (props: { phaseId: string }) => {
  const cubeContext = useCubeContext()

  const resolvedPhase = cubeContext.queries.resolvedPhases[props.phaseId]

  if (resolvedPhase.dates.duration === 'OPEN_ENDED') {
    return {
      differenceFormatted: 'Forever',
      startDateFormatted: '',
      endDateFormatted: '',
      endDateDescription: 'Phase continues forever'
    }
  }

  if (resolvedPhase.dates.duration === 'MILESTONE') {
    return {
      differenceFormatted: 'Milestone based',
      startDateFormatted: resolvedPhase.dates.absolute.start
        ? format(resolvedPhase.dates.absolute.start, 'd LLLL yyyy')
        : '',
      endDateFormatted: 'Milestone based',
      endDateDescription: ''
    }
  }

  const endDateFormatted = resolvedPhase.dates.absolute.end
    ? format(resolvedPhase.dates.absolute.end, 'd LLLL yyyy')
    : ''

  return {
    differenceFormatted: formatDuration(
      {
        ...resolvedPhase.dates.duration,
        days:
          resolvedPhase.dates.duration.days === -1
            ? 0
            : resolvedPhase.dates.duration.days
      },
      {
        format: ['years', 'months', 'days']
      }
    ),
    startDateFormatted: resolvedPhase.dates.absolute.start
      ? format(resolvedPhase.dates.absolute.start, 'd LLLL yyyy')
      : '',
    endDateFormatted,
    endDateDescription: endDateFormatted
      ? `Phase ends on ${endDateFormatted}`
      : ''
  }
}
