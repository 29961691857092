import { Box, Checkbox, Link, Text } from '@chakra-ui/react'
import {
  IndigoIndigo50,
  Lato14Bold,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { BillingType } from 'common/drawers/PricingEditor/domain'

export const BillingTypeField = (props: {
  'data-testid': string
  value: BillingType
  isDisabled: boolean
  onChange: (newValue: BillingType) => void
}) => (
  <Checkbox
    isChecked={props.value === 'IN_ADVANCE'}
    isDisabled={props.isDisabled}
    onChange={() => {
      const newValue =
        props.value === 'IN_ADVANCE' ? 'IN_ARREARS' : 'IN_ADVANCE'
      props.onChange(newValue as BillingType)
    }}
    placeItems="start"
  >
    <Box data-testid={props['data-testid']}>
      <Text {...Lato14Bold}>Bill in advance</Text>
      <Text {...Lato14ExtendedRegular} color="inherit">
        Choose in advance to charge customers upfront. By default customers are
        charged in arrears.{' '}
        <Link
          href="https://docs.sequencehq.com"
          isExternal
          color={IndigoIndigo50}
        >
          Learn more
        </Link>
      </Text>
    </Box>
  </Checkbox>
)
