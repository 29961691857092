import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { PhaseRecurrencePreference } from 'Cube/domain/cube.domain.types'

export const usePhaseActions = (props: { phaseId: string }) => {
  const cubeContext = useCubeContext()
  const deletePhase = () => {
    cubeContext.mutators.updateData({
      common: {
        phaseIds: cubeContext.queries.rawData.data.common.phaseIds.filter(
          phaseId => phaseId !== props.phaseId
        )
      }
    })

    cubeContext.mutators.deleteData({
      type: 'phases',
      ids: [props.phaseId]
    })
  }

  const updatePhaseRecurrencePreference = (
    recurrencePreference: PhaseRecurrencePreference
  ) => {
    cubeContext.mutators.updateData({
      phases: {
        [props.phaseId]: {
          recurrencePreference
        }
      }
    })
  }

  return {
    deletePhase: {
      handler: deletePhase,
      available:
        Object.values(cubeContext.queries.rawData.data.phases).length > 1
    },
    phaseRecurrencePreference: {
      handler: updatePhaseRecurrencePreference,
      value:
        cubeContext.queries.resolvedPhases[props.phaseId].recurrencePreference,
      available:
        cubeContext.queries.availableFeatures.phases[props.phaseId].phase
          .editPhaseRecurrence.available.enabled,
      visible:
        cubeContext.queries.availableFeatures.phases[props.phaseId].phase
          .editPhaseRecurrence.available.visible
    }
  }
}
