import { FC, Fragment, useEffect, useState } from 'react'
import { Flex, Text, IconButton, Link, Box } from '@chakra-ui/react'
import { CopyIcon, CheckIcon } from '@chakra-ui/icons'
import * as Sentry from '@sentry/react'
import { GcpLogsIcon, SentryIcon } from '@sequencehq/core-components'

interface ServerErrorProps {
  message: string | undefined
  sequenceRequestId?: string | null
}

const getGcpProject = (domain: string) => {
  switch (domain) {
    case 'localhost:3001':
    case 'local.seqhq.io:3001':
    case 'dev.seqhq.io':
    case 'test.seqhq.io':
    case 'stable.seqhq.io':
      return 'non-production-339214'

    case 'sandbox.sequencehq.com':
      return 'sandbox-348617'

    case 'eu.sequencehq.com':
      return 'production-eu-345914'
  }
}

export const ServerError: FC<ServerErrorProps> = ({
  sequenceRequestId,
  message
}) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000)
    }
  }, [copied])

  if (!sequenceRequestId && !message) {
    return <Fragment />
  }

  const project = getGcpProject(window.location.host)

  return (
    <Flex mt={1} fontSize="14px" lineHeight="24px" flexDirection="column">
      {message ? <Text pr={2}>{message}</Text> : null}
      {sequenceRequestId ? (
        <Flex>
          <Text pr={2}>Req</Text>
          <Flex
            width="fit-content"
            borderRadius="4px"
            backgroundColor="rgb(0,0,0, 0.2)"
            pl={2}
          >
            <Text pr={2}>{sequenceRequestId}</Text>

            <IconButton
              onClick={() => {
                navigator.clipboard
                  .writeText(sequenceRequestId)
                  .then(() => setCopied(true))
                  .catch(e => Sentry.captureException(e))
              }}
              borderBottomLeftRadius="0"
              borderBottomRightRadius="4px"
              borderTopLeftRadius="0"
              borderTopRightRadius="4px"
              _hover={{
                backgroundColor: 'rgb(0,0,0, 0.2)'
              }}
              colorScheme="white"
              aria-label="Copy request id"
              width="24px"
              minWidth="24px"
              height="24px"
              icon={copied ? <CheckIcon /> : <CopyIcon />}
            />
          </Flex>
          {project ? (
            <>
              <Box width={2} />
              <IconButton
                as={Link}
                target="_blank"
                rel="noreferrer"
                href={`https://console.cloud.google.com/logs/query;query=%22${sequenceRequestId}%22;timeRange=PT5M;?referrer=search&project=${project}`}
                borderRadius="4px"
                _hover={{
                  backgroundColor: 'rgb(0,0,0, 0.2)'
                }}
                colorScheme="white"
                aria-label="Copy request id"
                width="24px"
                minWidth="24px"
                height="24px"
                icon={<GcpLogsIcon />}
              />
              <Box width={2} />
              <IconButton
                as={Link}
                target="_blank"
                rel="noreferrer"
                href={`https://sentry.io/organizations/sequence-3d/issues/?project=6553290&query=Sequence-Request-Id%3A${sequenceRequestId}&statsPeriod=1h`}
                borderRadius="4px"
                _hover={{
                  backgroundColor: 'rgb(0,0,0, 0.2)'
                }}
                colorScheme="white"
                aria-label="Copy request id"
                width="24px"
                minWidth="24px"
                height="24px"
                icon={<SentryIcon />}
              />
            </>
          ) : null}
        </Flex>
      ) : null}
    </Flex>
  )
}
