import { FC, memo } from 'react'
import { Flex } from '@chakra-ui/react'
import Page from 'components/Page'
import { Outlet, useNavigate } from 'react-router-dom'
import { UsageMetricProvider } from 'components/CreateUsageMetric'
import {
  MagicTableCell,
  MagicTableCellAggregationType,
  MagicTableCellEmpty,
  useMagicTableInfiniteQuery,
  MagicTable
} from '@sequencehq/tables'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { Badge } from '@sequencehq/core-components'
import { UsageMetricModel } from '@sequencehq/core-models'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { UsageMetricsView } from 'components/UsageMetrics/types'
import { usePersistMagicTableQueryParams } from '@sequencehq/tables'

const UsageMetrics: FC = memo(() => {
  const navigate = useNavigate()

  const { persistMagicTableQueryParams } = usePersistMagicTableQueryParams()

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getUsageMetrics
  )

  return (
    <>
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title="Metrics"
            addNewConfig={{
              buttonText: 'New usage metric',
              href: '/usage-metrics/new'
            }}
            paddingBottom={0}
          >
            <CurrentUserId>
              {userId => (
                <MagicTable<UsageMetricsView>
                  entityNamePlural="usage metrics"
                  infiniteQuery={infiniteQuery}
                  sequenceUserId={userId}
                  rowPath={row => `/usage-metrics/${row.id}`}
                  columns={[
                    {
                      id: 'id',
                      header: 'Metric ID',
                      accessorKey: 'id',
                      cell: row => (
                        <MagicTableCell
                          text={row.getValue<UsageMetricModel['id']>()}
                          showCopyButton={true}
                        />
                      )
                    },
                    {
                      id: 'name',
                      header: 'Name',
                      accessorKey: 'name',
                      cell: row => (
                        <MagicTableCell
                          text={row.getValue<UsageMetricModel['name']>()}
                        />
                      )
                    },
                    {
                      id: 'eventType',
                      header: 'Event type',
                      accessorKey: 'eventType',
                      cell: value => (
                        <MagicTableCellEmpty>
                          <Badge sentiment="monospaced" textTransform="none">
                            {value.getValue<UsageMetricModel['eventType']>()}
                          </Badge>
                        </MagicTableCellEmpty>
                      )
                    },
                    {
                      id: 'aggregationType',
                      header: 'Aggregation type',
                      accessorKey: 'aggregationType',
                      cell: row => (
                        <MagicTableCellAggregationType
                          aggregationType={row.getValue<
                            UsageMetricModel['aggregationType']
                          >()}
                        />
                      )
                    },
                    {
                      id: 'createdAt',
                      header: 'Created',
                      accessorKey: 'createdAt',
                      cell: row => (
                        <MagicTableCell
                          text={dateTimeWithFormat(
                            row.getValue<UsageMetricModel['createdAt']>(),
                            'd MMM yyyy'
                          )}
                        />
                      )
                    }
                  ]}
                  emptyState={{
                    imageSrc: '/img/empty/gauge.png',
                    learnMoreText:
                      'Create metrics to track usage events in real time and unlock usage-based billing',
                    learnMoreHref:
                      'https://docs.sequencehq.com/metrics-events/examples',
                    buttonNew: {
                      buttonText: 'New usage metric',
                      onClick: () =>
                        navigate(
                          persistMagicTableQueryParams('/usage-metrics/new')
                        )
                    }
                  }}
                />
              )}
            </CurrentUserId>
          </Page>
        </Flex>
      </Flex>
      <UsageMetricProvider>
        <Outlet />
      </UsageMetricProvider>
    </>
  )
})

export default UsageMetrics
