import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { GreyGrey20, Lato14Bold } from '@sequencehq/design-tokens'
import {
  HorizontalSelectorField,
  SelectField,
  SelectFieldWithActions,
  TextField
} from '@sequencehq/core-components'
import { useGraduatedPriceForm } from './useGraduatedPriceForm'
import { ReadOnlyDetails } from '../common/ReadOnlyDetails'
import { PricingModelField } from '../common/PricingModelField'
import { UsageMetricParameterFields } from '../common/UsageMetrics/UsageMetricParameterFields'
import { FixedTierTableInput } from '../common/inputs/FixedTierTableInput/FixedTierTableInput'
import { PercentageTierTableInput } from '../common/inputs/PercentageTierTableInput/PercentageTierTableInput'
import {
  BillingFrequency,
  UsageCalculationMode,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import UsageCalculationPeriodDropdown from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown'
import { usageCalculationModeDescriptions } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  ShowAdditionalFieldsToggle,
  useShowAdditionalFields
} from '../common/ShowAdditionalFieldsToggle'
import { ListPriceField } from '../common/ListPriceField'
import { UsageMetricField } from '../common/UsageMetrics/UsageMetricField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const GraduatedPriceVariantForm = () => {
  const { fieldsConfig, currency, priceDetails } = useGraduatedPriceForm()
  const { showAdditionalFields } = useShowAdditionalFields()

  return (
    <>
      <ListPriceField />

      <Box marginBottom="16px">
        <ReadOnlyDetails>
          {priceDetails.map(({ label, value }) => (
            <ReadOnlyDetails.Item key={label} label={label} value={value} />
          ))}
        </ReadOnlyDetails>
      </Box>

      <TextField
        label="Display name (appears on invoice)"
        data-testid="graduated.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="16px">
        <HorizontalSelectorField
          label="Type"
          data-testid="graduated.usageTierType"
          value={fieldsConfig.usageTierType.value}
          options={fieldsConfig.usageTierType.options}
          onChange={newValue =>
            fieldsConfig.usageTierType.onChange(newValue as UsageTierType)
          }
          validationErrors={fieldsConfig.usageTierType.validationErrors}
          isDisabled={fieldsConfig.usageTierType.disabled}
        />

        <Box
          paddingTop="8px"
          marginBottom="0px"
          paddingBottom="0px"
          width="100%"
        >
          <Flex gap={4} alignItems="center" marginBottom="8px">
            <Box {...Lato14Bold}>Tiers</Box>
            {!fieldsConfig.currency.hidden && (
              <SelectField
                data-testid="graduated.currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="90px"
                height="24px"
                backgroundColor={GreyGrey20}
                styles={{
                  wrapper: {
                    marginBottom: 0
                  }
                }}
              />
            )}
          </Flex>
          {fieldsConfig.usageTierType.value === 'FIXED' ? (
            <FixedTierTableInput
              value={fieldsConfig.tiers.value}
              onChange={fieldsConfig.tiers.onChange}
              isDisabled={fieldsConfig.tiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.tiers.validationErrors}
            />
          ) : (
            <PercentageTierTableInput
              value={fieldsConfig.percentageTiers.value}
              onChange={fieldsConfig.percentageTiers.onChange}
              isDisabled={fieldsConfig.percentageTiers.disabled}
              currency={currency}
              validationErrors={fieldsConfig.percentageTiers.validationErrors}
            />
          )}
        </Box>
      </Box>

      <ShowAdditionalFieldsToggle />
      <Box
        width="100%"
        marginBottom="16px"
        visibility={showAdditionalFields ? 'visible' : 'hidden'}
      >
        <PricingModelField />

        <BillingFrequencyField
          data-testid="graduated.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <UsageMetricField
          data-testid="graduated.usageMetric"
          validationErrors={fieldsConfig.usageMetricId.validationErrors}
          value={fieldsConfig.usageMetricId.value}
          onChange={fieldsConfig.usageMetricId.onChange}
          isDisabled={fieldsConfig.usageMetricId.disabled}
        />

        <UsageMetricParameterFields
          fieldsConfig={fieldsConfig}
          usageMetricId={fieldsConfig.usageMetricId.value}
        ></UsageMetricParameterFields>

        <UsageCalculationPeriodDropdown
          options={fieldsConfig.usageCalculationPeriod.options}
          value={fieldsConfig.usageCalculationPeriod.value}
          isDisabled={fieldsConfig.usageCalculationPeriod.disabled}
          onChange={fieldsConfig.usageCalculationPeriod.onChange}
          validationErrors={
            fieldsConfig.usageCalculationPeriod.validationErrors
          }
          data-testid="graduated.usageCalculationPeriod"
        />
        <ExternalLedgerForm />
      </Box>
    </>
  )
}
