import { PricingEditorUsageMetricRouteComponent } from './view/drawers/PricingEditorUsageMetricRouteComponent'
import { PricingEditorSeatMetricRouteComponent } from './view/drawers/PricingEditorSeatMetricRouteComponent'

export const pricingEditorChildRoutes = [
  {
    path: 'metrics/new',
    element: <PricingEditorUsageMetricRouteComponent />
  },
  {
    path: 'seats/new',
    element: <PricingEditorSeatMetricRouteComponent />
  }
]
