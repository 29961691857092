import { FormFields, useForm } from '@sequencehq/utils'
import { greaterThan, required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'
import { useUsageMetricParameterFieldConfig } from '../common/UsageMetrics/useUsageMetricParameterFieldConfig'
import { useUsageMetrics } from '../../../communication/external/useUsageMetrics'
import { useFlags } from 'launchdarkly-react-client-sdk'

type CommonFields =
  PricingEditorReducerState['data']['pricingEditorData']['common']
type PackagedPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['PACKAGED']

type PackagedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> & {
  currency: FormFields<CommonFields>['currency'] & {
    hidden: boolean
  }
} & FormFields<PackagedPriceFields>

type UsePackagedPriceForm = () => {
  fieldsConfig: PackagedPriceFormFieldConfig
  currency: Currency
  priceDetails: { label: string; value: string }[]
}

export const usePackagedPriceForm: UsePackagedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const flags = useFlags()

  const commonFields = useCommonFieldConfig<
    PackagedPriceFields & CommonFields
  >()
  const parameterFields = useUsageMetricParameterFieldConfig<
    PackagedPriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.PACKAGED
      .usageMetricId,
    () => false
  )
  /**
   * Allow for the on demand billing frequency if the flag is enabled, or the value has already been set (i.e. the flag is now disabled,
   * but a price was set using it). However, when the flag is disabled this will act as trap door state, and will cause the value to
   * disappear from selection once changed.
   */
  const onDemandFrequencyAvailable =
    pricingEditorContext.queries.rawData.data.pricingEditorData.PACKAGED
      .billingFrequency === 'ON_DEMAND' || flags.useOnDemandBillingFrequency

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.PACKAGED
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'usageMetricId',
        validation: [required]
      },
      {
        property: 'billingFrequency',
        options: [
          ...defaultAvailableFrequenciesOptions,
          ...(onDemandFrequencyAvailable
            ? [
                {
                  value: 'ON_DEMAND',
                  label: 'On demand'
                }
              ]
            : [])
        ],
        validation: [required]
      },
      {
        property: 'packageSize',
        validation: [required, greaterThan(0, "Package size can't be zero")]
      },
      {
        property: 'pricePerPackage',
        validation: [
          required,
          greaterThan(0, "Price per package can't be zero")
        ]
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const { name, currency, ...rest } = newData

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name,
          currency
        },
        PACKAGED: rest
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      }
    }
  }, [fields, pricingEditorContext.queries.rawData.configuration.currency])

  const { usageMetrics } = useUsageMetrics()
  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        taxCategoryName:
          pricingEditorContext.queries.rawData.data.product.taxCategoryName,
        pricingModel: 'PACKAGED',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          usageMetrics.find(
            metric => metric.id === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      }
    ],
    [
      usageMetrics,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.usageMetricId,
      pricingEditorContext.queries.rawData.data.product.taxCategoryName
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    priceDetails
  }
}
