import { useCallback } from 'react'
import invariant from 'tiny-invariant'
import {
  type DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'

export type Data = DashboardApi20240730.GetBillingSchedule.BillingSchedule

type UseCallArchiveBillingSchedule = () => (
  billingScheduleId: string
) => Promise<Data>

export const useCallArchiveBillingSchedule: UseCallArchiveBillingSchedule =
  () => {
    return useCallback(async (billingScheduleId: string) => {
      const response = await dashboard20240730Client.putBillingScheduleArchive({
        id: billingScheduleId
      })
      const archived = response.data

      invariant(archived, 'Billing Schedule not found')

      return archived
    }, [])
  }
