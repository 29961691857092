import { Box, Grid } from '@chakra-ui/react'
import { Button, Dialog, Modal } from '@sequencehq/core-components'
import { FC } from 'react'
import { useUnlinkSalesforceOpportunityModal } from './useUnlinkSalesforceOpportunityModal'
import { GreyGrey80, Lato13Regular } from '@sequencehq/design-tokens'

type Props = {
  onUnlink: () => void
}

const UnlinkSalesforceOpportunityModalUI: FC<Props> = props => {
  const hook = useUnlinkSalesforceOpportunityModal({
    onConfirm: props.onUnlink
  })

  return (
    <>
      <Dialog.Header showCloseButton>
        Unlink this quote from Salesforce
      </Dialog.Header>
      <Dialog.Content>
        <Box color={GreyGrey80} {...Lato13Regular} flex={1}>
          This will unlink the customer from Salesforce immediately. You can
          re-link the customer again if required.
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button variant="secondary" style={{ width: '100%' }}>
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={() => {
              void hook.submit.onClick()
            }}
          >
            Unlink
          </Button>
        </Grid>
      </Modal.Footer>
    </>
  )
}

export const UnlinkSalesforceOpportunityModal: FC<Props> = props => {
  return (
    <Dialog unmountOnClose>
      <UnlinkSalesforceOpportunityModalUI {...props} />
    </Dialog>
  )
}
