import { FormFields, useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { PricingEditorReducerState } from 'common/drawers/PricingEditor/domain'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import {
  CommonFields,
  useCommonFieldConfig
} from '../common/useCommonFieldConfig'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useUsageMetricParameterFieldConfig } from '../common/UsageMetrics/useUsageMetricParameterFieldConfig'
import {
  percentageTiersValidator,
  tiersValidator
} from 'common/drawers/PricingEditor/utils/validators/tiers.validators'
import usageCalculationPeriodMapper from 'common/drawers/PricingEditor/utils/usageCalculationPeriodMapper.ts'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import {
  availableUsageCalculationModes,
  availableUsageCalculationPeriodsByBillingFrequency,
  availableUsageTierTypes
} from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants.ts'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'
import { useUsageMetrics } from '../../../communication/external/useUsageMetrics'

type GraduatedPriceFields = Omit<
  PricingEditorReducerState['data']['pricingEditorData']['GRADUATED'],
  'usageCalculationPeriod'
> & {
  usageCalculationPeriod: UsageCalculationPeriodDropdownValue
}

type GraduatedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> & {
  currency: FormFields<CommonFields>['currency'] & {
    hidden: boolean
  }
} & FormFields<GraduatedPriceFields>

type UseGraduatedPriceForm = () => {
  fieldsConfig: GraduatedPriceFormFieldConfig
  currency: Currency
  priceDetails: Array<{ value: string; label: string }>
}

export const useGraduatedPriceForm: UseGraduatedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const flags = useFlags()

  const commonFields = useCommonFieldConfig<
    GraduatedPriceFields & CommonFields
  >()
  const parameterFields = useUsageMetricParameterFieldConfig<
    GraduatedPriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED
      .usageMetricId,
    () => false
  )

  /**
   * Allow for the on demand billing frequency if the flag is enabled, or the value has already been set (i.e. the flag is now disabled,
   * but a price was set using it). However, when the flag is disabled this will act as trap door state, and will cause the value to
   * disappear from selection once changed.
   */
  const onDemandFrequencyAvailable =
    pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED
      .billingFrequency === 'ON_DEMAND' || flags.useOnDemandBillingFrequency

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED,
      usageCalculationPeriod:
        usageCalculationPeriodMapper.fromApiPricePropertiesToUsageCalculationPeriodDropdown(
          {
            usageCalculationMode:
              pricingEditorContext.queries.rawData.data.pricingEditorData
                .GRADUATED.usageCalculationMode,
            usageCalculationPeriod:
              pricingEditorContext.queries.rawData.data.pricingEditorData
                .GRADUATED.usageCalculationPeriod
          },
          pricingEditorContext.queries.rawData.data.pricingEditorData.GRADUATED
            .billingFrequency
        )
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'billingFrequency',
        options: [
          ...defaultAvailableFrequenciesOptions,
          ...(onDemandFrequencyAvailable
            ? [
                {
                  value: 'ON_DEMAND',
                  label: 'On demand'
                }
              ]
            : [])
        ],
        validation: [required]
      },
      {
        property: 'usageMetricId',
        validation: [required]
      },
      {
        property: 'tiers',
        disabled: ctx => ctx.formData.usageTierType !== 'FIXED',
        validation: [required, tiersValidator]
      },
      {
        property: 'percentageTiers',
        disabled: ctx => ctx.formData.usageTierType !== 'PERCENTAGE',
        validation: [required, percentageTiersValidator]
      },
      {
        property: 'usageTierType',
        validation: [required],
        options: availableUsageTierTypes
      },
      {
        property: 'usageCalculationMode',
        validation: [],
        options: availableUsageCalculationModes
      },
      {
        property: 'usageCalculationPeriod',
        validation: [],
        options:
          availableUsageCalculationPeriodsByBillingFrequency[
            pricingEditorContext.queries.rawData.data.pricingEditorData
              .GRADUATED.billingFrequency
          ]
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const mappedCalculationStructure =
        usageCalculationPeriodMapper.fromUsageCalculationPeriodDropdownToApiPriceProperties(
          newData.usageCalculationPeriod,
          newData.billingFrequency
        )

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name: newData.name,
          currency: newData.currency
        },
        GRADUATED: {
          billingFrequency: newData.billingFrequency,
          usageMetricId: newData.usageMetricId,
          tiers: newData.tiers,
          percentageTiers: newData.percentageTiers,
          usageTierType: newData.usageTierType,
          usageCalculationMode: mappedCalculationStructure.usageCalculationMode,
          usageCalculationPeriod:
            mappedCalculationStructure.usageCalculationPeriod,
          parameters: newData.parameters
        }
      })
    }
  })

  const enhancedFields = useMemo(
    () => ({
      ...fields,
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        )
      },
      usageCalculationPeriod: {
        ...fields.usageCalculationPeriod,
        /**
         * Add the (default) label to the default option, which varies based on the selected
         * billing frequency. On demand has a default of cumulative, and everything else
         * has a default of billing period.
         */
        options: fields.usageCalculationPeriod.options.map(opt => {
          if (
            (opt.value === 'CUMULATIVE_USAGE' &&
              queries.formData.billingFrequency === 'ON_DEMAND') ||
            opt.value === 'BILLING_PERIOD'
          ) {
            return {
              ...opt,
              label: `${opt.label} (default)`
            }
          }

          return opt
        })
      }
    }),
    [fields, pricingEditorContext.queries.rawData.configuration.currency]
  )

  const { usageMetrics } = useUsageMetrics()
  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        taxCategoryName:
          pricingEditorContext.queries.rawData.data.product.taxCategoryName,
        pricingModel: 'GRADUATED',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          usageMetrics.find(
            metric => metric.id === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      },
      {
        label: 'Type',
        value:
          fields.usageTierType.options.find(
            opt => opt.value === queries.formData.usageTierType
          )?.label ?? 'Not selected'
      },
      {
        label: 'Usage calculation period',
        value: fields.usageCalculationMode.options.find(
                opt => opt.value === queries.formData.usageCalculationMode
              )?.label ?? 'Not selected'
      }
    ],
    [
      fields.usageCalculationMode.options,
      fields.usageCalculationPeriod.options,
      usageMetrics,
      fields.usageTierType.options,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.usageCalculationMode,
      queries.formData.usageCalculationPeriod,
      queries.formData.usageMetricId,
      queries.formData.usageTierType,
      pricingEditorContext.queries.rawData.data.product.taxCategoryName
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency:
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .currency,
    priceDetails
  }
}
