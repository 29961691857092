import { Grid, GridItem } from '@chakra-ui/react'
import { CustomerHeader } from './components/CustomerHeader'
import { CustomerContextProvider } from './hooks/useCustomerContext'
import { CustomerContactsRoot } from 'CustomerContacts/index'
import { Outlet, useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { CustomerOverviewInspector } from './CustomerOverviewInspector'

export const CustomerPageLayout = () => {
  const { customerId } = useParams<{ customerId: string }>()

  invariant(customerId, 'customerId is required')

  return (
    <CustomerContextProvider customerId={customerId}>
      <CustomerContactsRoot>
        <Grid
          flexDirection="column"
          gridTemplateColumns={'1fr auto'}
          gridTemplateRows={'auto 1fr'}
          gridTemplateAreas={`"header header" "content inspector"`}
          overflow="hidden"
          height="100vh"
          width="100%"
        >
          <GridItem gridArea="header">
            <CustomerHeader />
          </GridItem>
          <GridItem
            gridArea="content"
            overflow="auto"
            height="100%"
            width="100%"
          >
            <Outlet />
          </GridItem>
          <GridItem>
            <CustomerOverviewInspector customerId={customerId} />
          </GridItem>
        </Grid>
      </CustomerContactsRoot>
    </CustomerContextProvider>
  )
}
