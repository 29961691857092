import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useMemo } from 'react'

export const useBillingScheduleInspector = () => {
  const ctx = useCubeContext()
  const { updateData } = ctx.mutators

  const stripe = useMemo(() => {
    return {
      available:
        ctx.queries.availableFeatures.schedule.stripePayment.available.enabled,
      data: {
        acceptPaymentsViaStripe: {
          value: ctx.queries.rawData.data.schedule.stripePayment,
          disabled:
            !ctx.queries.availableFeatures.schedule.stripePayment.available
              .enabled
        },
        autoChargeViaStripe: {
          value: ctx.queries.rawData.data.schedule.stripeAutoCharge,
          disabled:
            !ctx.queries.availableFeatures.schedule.stripeAutoCharge.available
              .enabled
        }
      },
      onChange: (newData: {
        acceptPaymentsViaStripe?: {
          value: boolean
          disabled?: boolean
        }
        autoChargeViaStripe?: { value: boolean; disabled?: boolean }
      }) => {
        if (
          newData.acceptPaymentsViaStripe !== undefined &&
          newData.acceptPaymentsViaStripe.value !==
            ctx.queries.rawData.data.schedule.stripePayment
        ) {
          updateData({
            schedule: {
              stripePayment: newData.acceptPaymentsViaStripe?.value
            }
          })
        }

        if (
          newData.autoChargeViaStripe !== undefined &&
          newData.autoChargeViaStripe.value !==
            ctx.queries.rawData.data.schedule.stripeAutoCharge
        ) {
          updateData({
            schedule: {
              stripeAutoCharge: newData.autoChargeViaStripe?.value
            }
          })
        }
      }
    }
  }, [
    ctx.queries.availableFeatures.schedule.stripePayment.available,
    ctx.queries.availableFeatures.schedule.stripeAutoCharge.available,
    ctx.queries.rawData.data.schedule.stripePayment,
    ctx.queries.rawData.data.schedule.stripeAutoCharge,
    updateData
  ])

  return {
    stripe,
    customerId: ctx.queries.rawData.data.common.customerId,
    billingScheduleId: ctx.queries.rawData.data.common.id
  }
}
