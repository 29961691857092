import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { Inspector } from '@sequencehq/core-components'
import { GreyGrey70, GreyWhite } from '@sequencehq/design-tokens'
import { useQuoteEditorSidebar } from '../../QuoteEditorSidebar/useQuoteEditorSidebar'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SidebarSection } from '../../QuoteEditorSidebar/widgets/SidebarSection'
import { PublishedStatus } from '../../QuoteEditorSidebar/PublishedStatus'
import { SidebarFields } from '../../QuoteEditorSidebar/widgets/SidebarFields'
import { Signers } from '../../QuoteEditorSidebar/widgets/Signers'
import { Flex } from '@chakra-ui/react'
import { ContractValue } from '../../QuoteEditorSidebar/widgets/ContractValue'

const ItemContent = () => {
  {
    const hook = useQuoteEditorSidebar()
    const flags = useFlags()

    return (
      <Flex
        backgroundColor={GreyWhite}
        height="100%"
        flexDirection="column"
        width="100%"
        overflow="hidden"
        gap="16px"
      >
        <SidebarSection title="Status" showBorder>
          <PublishedStatus />
        </SidebarSection>

        <SidebarSection title="Contract value" showBorder>
          <ContractValue />
        </SidebarSection>

        <SidebarSection
          title="Details"
          showBorder={flags.useSigners && hook.features.isSignersVisible}
        >
          <SidebarFields hook={hook} />
        </SidebarSection>

        {flags.useSigners && hook.features.isSignersVisible && (
          <SidebarSection title="Signers">
            <Signers />
          </SidebarSection>
        )}
      </Flex>
    )
  }
}

export const QuoteInformationInspectorItem: React.FC = () => {
  return (
    <Inspector.IconItem
      tooltip="Quote information"
      content={
        <Inspector.Content>
          <ItemContent />
        </Inspector.Content>
      }
      icon={
        <InformationCircleIcon height="16px" width="16px" color={GreyGrey70} />
      }
    />
  )
}
