import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { BillingScheduleModel } from '@sequencehq/core-models'
import {
  GreyGrey60,
  GreyGrey80,
  Lato14ExtendedRegular
} from '@sequencehq/design-tokens'
import { MutationFormProps } from 'components/Form/types'
import Spinner from 'components/Loading/Spinner'
import {
  UpdateAndSendInvoiceStatusFormProps,
  UpdateInvoiceStatusFormMutation
} from 'components/UpdateInvoiceStatus/types'
import { useGetMerchantForSequenceAccountQuery } from 'features/api'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getEnvironment, isSandboxEnv } from 'lib/environment/environment'
import { noReturn } from 'lib/noReturn'
import { FC, useState } from 'react'
import { Form } from 'react-final-form'

const FinalizeAndSendInvoiceForm: FC<
  MutationFormProps<UpdateInvoiceStatusFormMutation> &
    UpdateAndSendInvoiceStatusFormProps
> = ({
  id,
  customerName,
  customerEmails,
  billingScheduleId,
  submitForm,
  formId
}) => {
  const flags = useFlags()
  const enableAutoIssueInvoices = flags?.enableAutoIssueInvoices

  const [autoIssue, setAutoIssue] = useState<boolean>(false)
  const billingScheduleQuery = useQuery(
    dashboard20240730Client.getBillingSchedule,
    {
      id: billingScheduleId ?? ''
    }
  )
  const updateBillingScheduleMutation = useMutation(
    dashboard20240730Client.putBillingSchedule
  )

  const { data: merchant, isFetching: isFetchingMerchant } =
    useGetMerchantForSequenceAccountQuery()
  const merchantValue = merchant?.value()

  if (isFetchingMerchant || !merchantValue) {
    return <Spinner></Spinner>
  }

  const showAutoInvoiceCheckbox =
    enableAutoIssueInvoices &&
    billingScheduleQuery.data &&
    !billingScheduleQuery.data.autoIssueInvoices &&
    merchantValue

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={async () => {
        const promises: Promise<any>[] = [
          submitForm({
            id
          })
        ]

        if (autoIssue && billingScheduleQuery.data) {
          promises.push(
            updateBillingScheduleMutation.mutateAsync({
              id: billingScheduleQuery.data.id,
              body: {
                ...billingScheduleQuery.data,
                autoIssueInvoices: true
              }
            })
          )
        }

        await Promise.all(promises)
      }}
      render={({ handleSubmit }) => {
        return (
          <Flex
            as="form"
            flexDirection="column"
            onSubmit={noReturn(handleSubmit)}
            id={formId}
          >
            {`Email this invoice to ${customerName} (${customerEmails.join(
              ', '
            )}). After sending you can no longer edit the invoice. To make further changes, use the Credit Note feature.`}

            {showAutoInvoiceCheckbox && (
              <Box pt="40px">
                <Checkbox
                  isChecked={autoIssue}
                  width="100%"
                  onChange={e => setAutoIssue(e.target.checked)}
                >
                  <Text color={GreyGrey80} {...Lato14ExtendedRegular}>
                    Auto-send future invoices created by this Billing Schedule
                  </Text>
                </Checkbox>
              </Box>
            )}
            {isSandboxEnv() && (
              <Box pt={5} color={GreyGrey60}>
                Note: No end-customer emails will be sent in {getEnvironment()}.
              </Box>
            )}
          </Flex>
        )
      }}
    />
  )
}

export default FinalizeAndSendInvoiceForm
