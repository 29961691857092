import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import { Badge } from '@sequencehq/core-components'
import { toBillingScheduleStatusBadgeProps } from '@sequencehq/core-models'
import {
  GreyGrey30,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { Link, useNavigate } from 'react-router-dom'
import { useFetchBillingSchedules } from 'Customer/hooks/useFetchBillingSchedules.ts'
import { useCustomerContext } from 'Customer/hooks/useCustomerContext'
import { format } from '@sequencehq/utils/dates'
import { formatScheduleEndDate } from 'Cube/index'

export const BillingScheduleTable = () => {
  const navigate = useNavigate()
  const { customer } = useCustomerContext()
  const { billingSchedules } = useFetchBillingSchedules({
    customerId: customer.id
  })

  if (!billingSchedules) {
    return (
      <Center height="200px" width="100%" mt={4}>
        <Spinner />
      </Center>
    )
  }

  const isArchived = Boolean(customer.archivedAt)

  return (
    <Box p={4} pt={0}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text {...Lato14Bold}>Schedules</Text>
        <Button
          {...Lato13Bold}
          variant="component-library-ghost"
          leftIcon={
            <PlusIcon
              color={isArchived ? undefined : '#5F5CFF'}
              height={16}
              width={16}
            />
          }
          {...(!isArchived && {
            as: Link,
            to: `/billing-schedules/new?customerId=${customer.id}`
          })}
          isDisabled={isArchived}
        >
          Create schedule
        </Button>
      </Flex>
      <TableContainer
        borderRadius="lg"
        overflow="hidden"
        border={`1px solid ${GreyGrey30}`}
        mt={2}
        width="100%"
        boxShadow="0px 0px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)"
      >
        <Table variant="v2" width="100%">
          <Thead>
            <Tr>
              <Th width={120}>Schedule</Th>
              <Th>Products</Th>
              <Th width={100}>Status</Th>
              <Th width={100}>Start Date</Th>
              <Th width={120}>End Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {billingSchedules.items.length > 0 ? (
              billingSchedules.items.map(schedule => {
                return (
                  <Tr
                    key={schedule.id}
                    onClick={() =>
                      navigate(`/billing-schedules/${schedule.id}`)
                    }
                  >
                    <Td>
                      <Text
                        {...Lato13Bold}
                        as={Link}
                        to={`/billing-schedules/${schedule.id}`}
                        color="#5F5CFF"
                        onClick={e => e.stopPropagation()}
                      >
                        View schedule
                      </Text>
                    </Td>
                    <Td isTruncated>
                      {schedule.prices.map(price => price.name).join(', ')}
                    </Td>
                    <Td>
                      <Badge
                        {...toBillingScheduleStatusBadgeProps({
                          status: schedule.status
                        })}
                      />
                    </Td>
                    <Td>{format(schedule.startDate, 'd MMM yyyy')}</Td>
                    <Td>{formatScheduleEndDate(schedule.endDate)}</Td>
                  </Tr>
                )
              })
            ) : (
              <Tr
                sx={{
                  cursor: 'auto!important',
                  _hover: {
                    bgColor: 'inherit!important'
                  }
                }}
              >
                <Td colSpan={5}>
                  <EmptyContent />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const EmptyContent = () => {
  return (
    <Box width="400px" margin="12px auto" textAlign="center">
      <Text {...Lato13Bold} color="gray.90">
        No billing schedules to display
      </Text>
      <Text {...Lato13Regular} color="gray.80">
        You have not created a billing schedule for this customer yet.
      </Text>
    </Box>
  )
}
