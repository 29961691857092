import { developerLog } from '@sequencehq/utils'
import {
  PricingEditorActions,
  PricingEditorReducerState,
  PostActionStage
} from './pricingEditor.domain.types'
import { match } from 'ts-pattern'
import * as actions from './actionHandlers'
import * as postActionStages from './postActionStages'
import { compose } from 'lodash/fp'
import { Reducer } from 'react'
import { INITIAL_PRICING_EDITOR_STATE } from './pricingEditor.constants'

const handleAction =
  (prevState: PricingEditorReducerState) => (action: PricingEditorActions) => {
    return match(action)
      .with({ type: 'load' }, matchedAction =>
        actions.load(prevState)(matchedAction)
      )
      .with({ type: 'updateConfiguration' }, matchedAction =>
        actions.updateConfiguration(prevState)(matchedAction)
      )
      .with({ type: 'updateEditor' }, matchedAction =>
        actions.updateEditor(prevState)(matchedAction)
      )
      .with({ type: 'updatePricingEditorData' }, matchedAction =>
        actions.updatePricingEditorData(prevState)(matchedAction)
      )
      .with({ type: 'updateProduct' }, matchedAction =>
        actions.updateProduct(prevState)(matchedAction)
      )
      .with({ type: 'updatePricingModel' }, matchedAction =>
        actions.updatePricingModel(prevState)(matchedAction)
      )
      .with({ type: 'updateListPrice' }, matchedAction =>
        actions.updateListPrice(prevState)(matchedAction)
      )
      .exhaustive()
  }

const logStage =
  (stageName: string): PostActionStage =>
  () =>
  prevState => {
    developerLog(
      `[PricingEditor] After post action stage: %c${stageName}`,
      'color: lime;',
      {
        state: prevState
      }
    )
    return prevState
  }

const handlePostActionStages: PostActionStage = ctx => prevState => {
  /**
   * Since this is a compose, the actual order is bottom to top!
   */
  return compose(
    logStage('Queries')(ctx),
    postActionStages.queries(ctx)
  )(prevState)
}

export const pricingEditorReducer: Reducer<
  PricingEditorReducerState,
  PricingEditorActions
> = (prevState = INITIAL_PRICING_EDITOR_STATE, action) => {
  developerLog(
    `%c[PricingEditor]======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    {
      initialState: prevState,
      action
    }
  )

  const postActionState = handleAction(prevState)(action)

  developerLog(
    `%c[PricingEditor]======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    {
      postActionState
    }
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  developerLog(
    `%c[PricingEditor]======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  return finalState
}
