import { FormFields, useForm, ValidatorWithMetadata } from '@sequencehq/utils'
import {
  LinearPriceType,
  PricingEditorReducerState
} from 'common/drawers/PricingEditor/domain'
import {
  CommonFields,
  useCommonFieldConfig
} from '../common/useCommonFieldConfig'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { useMemo } from 'react'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { useNavigate } from 'react-router-dom'
import { useUsageMetricParameterFieldConfig } from '../common/UsageMetrics/useUsageMetricParameterFieldConfig'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'
import { currencyToName } from '@sequencehq/core-models'
import { useUsageMetrics } from '../../../communication/external/useUsageMetrics'
import { useFlags } from 'launchdarkly-react-client-sdk'

type LinearPriceTypeObject = {
  label: string
  value: LinearPriceType
}

type LinearPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['LINEAR']

type LinearPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel' | 'currency'
> & {
  currency: FormFields<CommonFields>['currency'] & {
    hidden: boolean
  }
} & FormFields<LinearPriceFields>

type UseLinearPriceForm = () => {
  fieldsConfig: LinearPriceFormFieldConfig
  currency: Currency
  formData: LinearPriceFields & CommonFields
  priceDetails: { label: string; value: string }[]
}

const availableLinearPriceTypes: LinearPriceTypeObject[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const validateOptionalNotNegative: ValidatorWithMetadata<
  string,
  Record<string, unknown>,
  Record<string, unknown>
> = value => {
  return value && greaterThanEqualTo(0)(value)
    ? [
        {
          message: 'Cannot be negative',
          metadata: {}
        }
      ]
    : []
}

export const minMaxPriceValidator: ValidatorWithMetadata<
  string,
  {
    minPrice: string
  },
  {
    minPrice: string
  }
> = (maxPrice, additionalData) => {
  return additionalData.minPrice &&
    maxPrice &&
    greaterThan(+additionalData.minPrice)(maxPrice)
    ? [
        {
          message: 'Max. must be greater than min.',
          metadata: {
            minPrice: additionalData.minPrice
          }
        }
      ]
    : []
}

export const useLinearPriceForm: UseLinearPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const flags = useFlags()
  const { usageMetrics } = useUsageMetrics()
  const commonFields = useCommonFieldConfig<LinearPriceFields & CommonFields>()
  const parameterFields = useUsageMetricParameterFieldConfig<
    LinearPriceFields & CommonFields
  >(
    pricingEditorContext.queries.rawData.data.pricingEditorData.LINEAR
      .usageMetricId,
    () => false
  )

  /**
   * Allow for the on demand billing frequency if the flag is enabled, or the value has already been set (i.e. the flag is now disabled,
   * but a price was set using it). However, when the flag is disabled this will act as trap door state, and will cause the value to
   * disappear from selection once changed.
   */
  const onDemandFrequencyAvailable =
    pricingEditorContext.queries.rawData.data.pricingEditorData.LINEAR
      .billingFrequency === 'ON_DEMAND' || flags.useOnDemandBillingFrequency

  const { fields, queries } = useForm({
    value: {
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.common,
      ...pricingEditorContext.queries.rawData.data.pricingEditorData.LINEAR
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'usageMetricId',
        validation: [required]
      },
      {
        property: 'billingFrequency',
        options: [
          ...defaultAvailableFrequenciesOptions,
          ...(onDemandFrequencyAvailable
            ? [
                {
                  value: 'ON_DEMAND',
                  label: 'On demand'
                }
              ]
            : [])
        ],
        validation: [required]
      },
      {
        property: 'price',
        disabled: ctx => ctx.formData.linearPriceType === 'PERCENTAGE',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'minPrice',
        disabled: ctx => ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative]
      },
      {
        property: 'maxPrice',
        disabled: ctx => ctx.formData.linearPriceType === 'FIXED',
        validation: [validateOptionalNotNegative, minMaxPriceValidator]
      },
      {
        property: 'percentage',
        disabled: ctx => ctx.formData.linearPriceType === 'FIXED',
        validation: [required, greaterThan(0, "Price can't be zero")]
      },
      {
        property: 'linearPriceType',
        options: availableLinearPriceTypes,
        validation: [required]
      },
      ...parameterFields.fields
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      const { name, currency, ...rest } = newData

      pricingEditorContext.mutators.common.updatePricingEditorData({
        common: {
          name,
          currency
        },
        LINEAR: rest
      })
    }
  })

  const enhancedFields = useMemo(() => {
    return {
      ...fields,
      currency: {
        ...fields.currency,
        hidden: Boolean(
          pricingEditorContext.queries.rawData.configuration.currency
        ),
        options:
          queries.formData.linearPriceType === 'PERCENTAGE'
            ? fields.currency.options.map(opt => ({
                value: opt.value,
                label: `${opt.value} - ${currencyToName[opt.value]}`
              }))
            : fields.currency.options
      }
    }
  }, [
    queries.formData.linearPriceType,
    fields,
    pricingEditorContext.queries.rawData.configuration.currency
  ])

  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        taxCategoryName:
          pricingEditorContext.queries.rawData.data.product.taxCategoryName,
        pricingModel: 'LINEAR',
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Usage metric',
        value:
          usageMetrics.find(
            metric => metric.id === queries.formData.usageMetricId
          )?.label ?? 'Not selected'
      }
    ],
    [
      usageMetrics,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.usageMetricId,
      pricingEditorContext.queries.rawData.data.product.taxCategoryName
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    formData: queries.formData,
    priceDetails
  }
}
