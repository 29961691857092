import { Box, Flex, Grid, Spinner } from '@chakra-ui/react'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  Button,
  Dialog,
  Modal,
  ModalContextProvider,
  useModalContext
} from '@sequencehq/core-components'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useState } from 'react'

const useArchiveQuoteModal = (props: {
  quoteId: string
  onSuccess?: () => Promise<void>
}) => {
  const modal = useModalContext()
  /**
   * We maintain this state separately to allow for any follow
   * up async work done in the `onSuccess` function to stall
   * the 'submitting' state of this modal. If we just references
   * the mutation, the submitting UI state would end too soon.
   */
  const [archiving, setArchiving] = useState(false)
  const { displayNotification } = useNotifications()
  const quoteRes = useQuery(
    dashboard20240730Client.getQuote,
    { id: props.quoteId },
    {
      select: data => {
        if (!data) {
          return null
        }
        return {
          quoteNumber: data.quoteNumber,
          status: data.status
        }
      }
    }
  )

  const archiveQuoteMutator = useMutation(
    dashboard20240730Client.postQuoteArchive
  )

  return {
    loading: quoteRes.isPending,
    quoteNumber: quoteRes.data?.quoteNumber,
    showSignatureWarning: quoteRes.data?.status == 'READY_TO_SIGN',
    archive: {
      archiving,
      onClick: async () => {
        setArchiving(true)
        const res = await archiveQuoteMutator.mutateAsync({
          id: props.quoteId
        })

        if (!res) {
          displayNotification('Could not archive quote', { type: 'error' })
          setArchiving(false)
          return
        }

        if (props.onSuccess) {
          await props.onSuccess()
        }

        setArchiving(false)
        modal.setIsOpen(false)
        displayNotification(`Quote ${quoteRes.data?.quoteNumber} archived`, {
          type: 'success'
        })
      }
    }
  }
}

const ArchiveQuoteModalUI = (props: {
  quoteId: string
  onClose?: () => void
  onSuccess?: () => Promise<void>
}) => {
  const hook = useArchiveQuoteModal({
    quoteId: props.quoteId,
    onSuccess: props.onSuccess
  })

  return (
    <>
      <Dialog.Header showCloseButton>Archive quote</Dialog.Header>
      <Dialog.Content>
        <Box color={GreyGrey80} {...Lato13Regular} flex={1}>
          {hook.showSignatureWarning ? (
            <Box data-testid="archiveQuoteModal.signatureWarning">
              This will archive the quote immediately. Any signature requests
              will be cancelled. This action cannot be&nbsp;undone.
            </Box>
          ) : (
            <Box>
              This will archive the quote immediately. This action cannot
              be&nbsp;undone.
            </Box>
          )}
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                data-testid="archiveQuoteModal.cancel"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={() => {
              void hook.archive.onClick()
            }}
            disabled={hook.archive.archiving}
            data-testid="archiveQuoteModal.submit"
          >
            {hook.archive.archiving ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Archiving...
              </Flex>
            ) : (
              'Archive'
            )}
          </Button>
        </Grid>
      </Modal.Footer>
    </>
  )
}

/**
 * Displays a modal which will allows for archiving the quote.
 * @param props
 * @returns
 */
export const ArchiveQuoteModal = (props: {
  quoteId: string
  children?: ReactNode
  isOpen?: boolean
  onClose?: () => void
  onSuccess?: () => Promise<void>
}) => {
  return (
    <ModalContextProvider isOpen={props.isOpen}>
      {props.children && <Dialog.Trigger>{props.children}</Dialog.Trigger>}
      <Dialog data-testid="archiveQuoteModal" unmountOnClose>
        <ArchiveQuoteModalUI {...props} />
      </Dialog>
    </ModalContextProvider>
  )
}
