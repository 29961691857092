import { pick } from 'lodash/fp'
import {
  ActionHandler,
  Price,
  PricingEditorReducerState,
  PricingModel,
  StandardPrice,
  UpdatePricingModelAction
} from '../pricingEditor.domain.types'
import deepmerge from 'deepmerge'

/**
 * When we change a pricing model, we want to carry across some data.
 * This is because some fields, like the tiers, are particularly
 * onorous to re-enter. If someone is 15 tiers deep into a VOLUME
 * price and realises they want GRADUATED, it leads to the BIG SAD.
 *
 * This 'transition' logic, therefore, defines which data points
 * can transition from one pricing model to another, on a change.
 */
type PropertyTransition<
  SourceModel extends Price = Price,
  TargetModel extends Price = Price
> =
  | string
  | {
      target: string
      value: (
        model: SourceModel['modelSpecific']
      ) => Partial<TargetModel['modelSpecific']>
    }

const transitionStandardBillingFrequency: PropertyTransition<StandardPrice> = {
  target: 'billingFrequency',
  value: source =>
    source.billingFrequency === 'ONE_TIME'
      ? { billingFrequency: 'MONTHLY' }
      : {}
}

const availableTransitions: Partial<
  Record<PricingModel, Partial<Record<PricingModel, PropertyTransition[]>>>
> = {
  VOLUME: {
    GRADUATED: [
      'tiers',
      'percentageTiers',
      'usageTierType',
      'billingFrequency',
      'usageMetricId',
      'parameters'
    ],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters']
  },
  GRADUATED: {
    VOLUME: [
      'tiers',
      'percentageTiers',
      'usageTierType',
      'billingFrequency',
      'usageMetricId',
      'parameters'
    ],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters']
  },
  LINEAR: {
    VOLUME: ['billingFrequency', 'usageMetricId', 'parameters'],
    GRADUATED: ['usageMetricId', 'billingFrequency', 'parameters'],
    PACKAGED: ['usageMetricId', 'billingFrequency', 'parameters']
  },
  PACKAGED: {
    VOLUME: ['billingFrequency', 'usageMetricId', 'parameters'],
    GRADUATED: ['usageMetricId', 'billingFrequency', 'parameters'],
    LINEAR: ['usageMetricId', 'billingFrequency', 'parameters']
  },
  STANDARD: {
    VOLUME: [transitionStandardBillingFrequency] as PropertyTransition[],
    GRADUATED: [transitionStandardBillingFrequency] as PropertyTransition[],
    LINEAR: [transitionStandardBillingFrequency] as PropertyTransition[]
  },
  SEAT_BASED_LINEAR: {
    SEAT_BASED_GRADUATED: [
      'seatMetricId',
      'minimumSeats',
      'billingFrequency',
      'overagesBillingFrequency',
      'prorationStrategy',
      'billingType'
    ]
  },
  SEAT_BASED_GRADUATED: {
    SEAT_BASED_LINEAR: [
      'seatMetricId',
      'minimumSeats',
      'billingFrequency',
      'overagesBillingFrequency',
      'prorationStrategy',
      'billingType'
    ]
  }
}

export const applyPricingModelTransition = (args: {
  prevPricingEditorData: PricingEditorReducerState['data']['pricingEditorData']
  oldPricingModel: PricingModel
  newPricingModel: PricingModel
}) => {
  const propertiesToTransition =
    availableTransitions[args.oldPricingModel]?.[args.newPricingModel] ?? []

  return {
    [args.newPricingModel]: {
      ...args.prevPricingEditorData[args.newPricingModel],
      ...pick(propertiesToTransition.filter(prop => typeof prop === 'string'))(
        args.prevPricingEditorData[args.oldPricingModel]
      ),
      ...propertiesToTransition.reduce((acc, transition) => {
        if (typeof transition === 'string') {
          return acc
        }
        return {
          ...acc,
          ...transition.value(args.prevPricingEditorData[args.oldPricingModel])
        }
      }, {})
    }
  }
}

export const updatePricingModel: ActionHandler<UpdatePricingModelAction> =
  prevState => action => {
    return deepmerge(
      prevState,
      {
        /**
         * Reset to an invalid state, and allow the forms to update appropriately on mount.
         * We don't reset the state for the external ledgers, as this is managed independently
         * of the pricing model.
         */
        editor: {
          valid: false
        },
        data: {
          pricingEditorData: {
            common: {
              pricingModel: action.payload
            },
            ...applyPricingModelTransition({
              prevPricingEditorData: prevState.data.pricingEditorData,
              oldPricingModel:
                prevState.data.pricingEditorData.common.pricingModel,
              newPricingModel: action.payload
            })
          }
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    )
  }
