import { TextField } from '@sequencehq/core-components'
import { FormFieldConfig, FormFields } from '@sequencehq/utils'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import { UsageParameterDataModel } from './useUsageMetricParameterFieldConfig'
import { useUsageMetrics } from '../../../../communication/external/useUsageMetrics'

export const UsageMetricParameterFields = <
  DataModel extends UsageParameterDataModel
>({
  fieldsConfig,
  usageMetricId
}: {
  fieldsConfig: FormFields<DataModel>
  usageMetricId: string | undefined
}) => {
  const { usageMetrics } = useUsageMetrics()
  const usageMetric = usageMetricId
    ? usageMetrics.find(metric => metric.id === usageMetricId)
    : undefined

  return usageMetric ? (
    <>
      {usageMetric.parameters.map(parameter => {
        //@ts-expect-error dynamic forms are the worst for typing! But we know that all of the
        //field configurations here are string values. At some point we can look to extend the typing
        //of useForm to try and handle dynamic properties on the object.
        const fieldConfig = fieldsConfig[
          `parameters.${parameter.id}`
        ] as FormFieldConfig<{ parameterValue: string }, 'parameterValue'>

        return (
          <TextField
            key={parameter.id}
            label={parameter.name}
            data-testid={`parameters.${parameter.id}`}
            value={fieldConfig.value}
            onChange={value => fieldConfig.onChange(value)}
            validationErrors={fieldConfig.validationErrors}
            isDisabled={fieldConfig.disabled}
            infoPopover={{
              body: parameter.description
            }}
          />
        )
      })}
    </>
  ) : (
    <></>
  )
}
