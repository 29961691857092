import { Box, Flex } from '@chakra-ui/react'
import { FC } from 'react'
import { Lato14Regular, RedRed60, GreyGrey30 } from '@sequencehq/design-tokens'
import { Phase as PhaseType } from 'modules/Cube/domain/cube.domain.types'
import { usePhase } from 'Cube/view/common/widgets/Pricing/Phase/usePhase'
import { SearchForProduct } from 'Cube/view/common/widgets/Pricing/Phase/SearchForProduct/SearchForProduct'
import { PhaseHeader } from 'Cube/view/common/widgets/Pricing/Phase/PhaseHeader'
import { PhaseDurationSelection } from 'Cube/view/common/widgets/Pricing/Phase/PhaseDurationSelection'
import { PhaseActions } from 'Cube/view/common/widgets/Pricing/Phase/PhaseActions'
import { PhaseAlignmentWarning } from 'Cube/view/common/widgets/Pricing/Phase/PhaseAlignmentWarning'
import { PricingTableExpanded } from './PricingTable/PricingTableExpanded'
import { PricingTableCompact } from './PricingTable/PricingTableCompact'

export const Phase: FC<{
  phaseId: PhaseType['id']
  expanded: boolean
  onExpand: (expanded: boolean) => void
  focusDurationOnLoad?: boolean
  wideLayout?: boolean
}> = props => {
  const phasesHook = usePhase({
    phaseId: props.phaseId,
    expanded: props.expanded,
    onExpand: props.onExpand,
    focusDurationOnLoad: props.focusDurationOnLoad
  })

  return (
    <Box
      padding="0 16px"
      _notFirst={{
        borderTop: `1px solid ${GreyGrey30}`
      }}
      data-testid={`phase.section`}
      data-phaseId={props.phaseId}
    >
      <PhaseHeader
        phaseId={props.phaseId}
        showAlignDatesWarning={phasesHook.features.alignDates.visible}
        expanded={phasesHook.expanded}
        onExpand={(newState: boolean) => phasesHook.updateExpanded(newState)}
      />
      {phasesHook.expanded && (
        <Box paddingBottom="16px">
          {phasesHook.features.alignDates.visible && (
            <PhaseAlignmentWarning phasesHook={phasesHook} />
          )}
          <PhaseDurationSelection
            phaseId={props.phaseId}
            durationInputRef={phasesHook.refs.durationInput}
          />
          {props.wideLayout ? (
            <PricingTableExpanded phaseId={props.phaseId} />
          ) : (
            <PricingTableCompact phaseId={props.phaseId} />
          )}
          {phasesHook.showProductSearch && (
            <Box marginBottom="16px">
              <SearchForProduct
                onSelection={() => phasesHook.setShowAddProductSearch(false)}
                phaseId={props.phaseId}
                onBlur={() => phasesHook.setShowAddProductSearch(false)}
              />
            </Box>
          )}
          {Boolean(phasesHook.validationErrors.length) && (
            <Flex
              marginBottom="16px"
              color={RedRed60}
              {...Lato14Regular}
              justifyContent="center"
            >
              {phasesHook.validationErrors[0].message}
            </Flex>
          )}
          <PhaseActions phasesHook={phasesHook} />
        </Box>
      )}
    </Box>
  )
}
