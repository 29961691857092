import {
  Button,
  Card,
  Flex,
  IconButton,
  MenuButton,
  Text,
  Tooltip
} from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey50,
  GreyGrey60,
  GreyGrey70,
  Lato13Bold,
  RedRed60
} from '@sequencehq/design-tokens'
import { useLineItems } from 'InvoiceEditor/components/LineItems/hooks/useLineItems'
import PlusCircleIcon from '@heroicons/react/16/solid/PlusCircleIcon'
import PlusIcon from '@heroicons/react/16/solid/PlusIcon'
import {
  TextInput,
  KebabMenu,
  ModalContextProvider
} from '@sequencehq/core-components'
import { LineItemsEmptyState } from 'InvoiceEditor/components/LineItems/LineItemsEmptyState'
import EllipsisHorizontalIcon from '@heroicons/react/24/outline/EllipsisHorizontalIcon'
import {
  LineItemGroupCard,
  SubAccountUsageBreakdownCard
} from '@sequencehq/invoice-content'
import { useState } from 'react'
import LineItemGroupEditorDrawer from 'InvoiceEditor/components/drawers/LineItemGroupEditorDrawer.tsx'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { AvalaraBlocker } from 'common/components/AvalaraBlocker/AvalaraBlocker'
import { TaxRatesModalUI } from 'Settings/view/taxRatesSettings/modals/TaxRatesModal'
import type { CountriesAlpha2 } from '@sequencehq/api/commonEnums'
import { TaxRate } from 'Settings/domain/taxRates.types.ts'

type EditLineItemGroupProps = {
  description: string
  onCancel: () => void
  onSave: () => void
  onChange: (description: string) => void
  isValid: boolean
}

const EditLineItemGroup = (props: EditLineItemGroupProps) => {
  return (
    <Flex gap="16px" marginBottom="8px">
      <TextInput
        data-testid="lineItemGroupEditor.description"
        value={props.description}
        placeholder="Group item name"
        onChange={props.onChange}
        autoHighlightText={true}
        px="12px"
        py="6px"
        height="32px"
      />

      <Flex gap="8px">
        <Button
          variant="secondary2"
          onClick={props.onCancel}
          py="8px"
          px="12px"
          height="32px"
        >
          Cancel
        </Button>
        <Button
          variant="primary2"
          onClick={props.onSave}
          py="8px"
          px="12px"
          height="32px"
          isDisabled={!props.isValid}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

export const LineItems = () => {
  const {
    data,
    lineItemGroupEditor,
    setLineItemGroupEditorMode,
    lineItemGroupFieldsConfig,
    currentLineItemGroup,
    canEditLineItems,
    canUseTaxCategories,
    onEditLineItemGroup,
    updateLineItemGroupFields,
    onAddLineItemGroup,
    onUpdateLineItemGroup,
    onCancelGroupChange,
    onAddLineItem,
    onEditLineItem,
    onDeleteLineItemGroup,
    onDeleteLineItem,
    onCreateLineItemGroup,
    subAccountUsageBreakdown,
    showSubAccountUsage,
    onConfirmSubmit,
    modals
  } = useLineItems()

  const flags = useFlags()

  const [kebabMenuOpenGroupId, setKebabMenuOpenGroupId] = useState<
    string | undefined
  >()

  const [isLineItemGroupDrawerOpen, setLineItemGroupDrawerOpen] =
    useState(false)

  const canUseNewTaxManagement =
    flags.showNewTaxManagement && canUseTaxCategories

  return (
    <>
      <Flex direction="column" gap="24px" width="100%">
        {data.groups.map(group => (
          <LineItemGroupCard
            data-testid={`lineItemGroup.card-${group.id}`}
            key={group.id}
            group={group}
            groupHeader={
              !canUseNewTaxManagement &&
              currentLineItemGroup?.id === group.id ? (
                <EditLineItemGroup
                  description={
                    lineItemGroupFieldsConfig.description?.value ?? ''
                  }
                  onCancel={onCancelGroupChange}
                  onSave={onUpdateLineItemGroup}
                  onChange={
                    lineItemGroupFieldsConfig.description?.onChange ??
                    (() => {})
                  }
                  isValid={Boolean(
                    lineItemGroupFieldsConfig.description?.isValid
                  )}
                />
              ) : undefined
            }
            isDisabled={lineItemGroupEditor.mode === 'CREATE'}
            lineItemGroupEditControls={groupClassName =>
              canEditLineItems ? (
                <Flex
                  gap="2px"
                  sx={
                    kebabMenuOpenGroupId === group.id
                      ? { display: 'flex' }
                      : {
                          display: group.lineItems.length > 0 ? 'none' : 'flex',
                          [`.${groupClassName}:hover &`]: {
                            display: 'flex'
                          }
                        }
                  }
                >
                  <Tooltip hasArrow label="Add item" placement="top">
                    <IconButton
                      data-testid={`lineItemGroup.card-${group.id}.addLineItem`}
                      aria-label="menu"
                      borderRadius="6px"
                      minWidth="32px"
                      width="32px"
                      height="32px"
                      variant="ghost2"
                      icon={
                        <PlusIcon color="black" height="18px" width="18px" />
                      }
                      onClick={() => onAddLineItem(group.id)}
                    />
                  </Tooltip>

                  <KebabMenu
                    onOpen={() => setKebabMenuOpenGroupId(group.id)}
                    onClose={() => setKebabMenuOpenGroupId(undefined)}
                    buttonComponent={
                      <MenuButton
                        data-testid={`lineItemGroup.card-${group.id}.groupMenu`}
                        as={IconButton}
                        borderRadius="6px"
                        minWidth="32px"
                        width="32px"
                        height="32px"
                        variant="ghost2"
                        icon={
                          <EllipsisHorizontalIcon
                            color={GreyGrey70}
                            width="16px"
                          />
                        }
                        onClick={e => e.stopPropagation()}
                      />
                    }
                    menuItems={[
                      {
                        status: 'LIVE',
                        label: 'Edit',
                        action: () => {
                          onEditLineItemGroup(group.id)
                          setLineItemGroupDrawerOpen(true)
                        }
                      },
                      {
                        status: 'LIVE',
                        label: 'Add item',
                        action: () => onAddLineItem(group.id)
                      },
                      {
                        status: 'LIVE',
                        label: 'Delete item group',
                        action: () => onDeleteLineItemGroup(group.id),
                        styles: { color: RedRed60 }
                      }
                    ]}
                  />
                </Flex>
              ) : null
            }
            lineItemEditControls={
              canEditLineItems
                ? (_, lineItemId) => (
                    <KebabMenu
                      buttonComponent={
                        <MenuButton
                          data-testid={`lineItemGroup.card-${group.id}.lineItem-${lineItemId}.menu`}
                          as={IconButton}
                          borderRadius="6px"
                          minWidth="32px"
                          width="32px"
                          height="32px"
                          variant="ghost2"
                          icon={
                            <EllipsisHorizontalIcon
                              color={GreyGrey70}
                              width="16px"
                            />
                          }
                          onClick={e => e.stopPropagation()}
                        />
                      }
                      menuItems={[
                        {
                          status: 'LIVE',
                          label: 'Edit',
                          action: () => onEditLineItem(group.id, lineItemId)
                        },
                        {
                          status: 'LIVE',
                          label: 'Delete',
                          action: () => onDeleteLineItem(group.id, lineItemId),
                          styles: { color: RedRed60 }
                        }
                      ]}
                    />
                  )
                : null
            }
            emptyState={
              canEditLineItems ? (
                <LineItemsEmptyState
                  onLineItemAdd={() => onAddLineItem(group.id)}
                />
              ) : null
            }
          />
        ))}

        {canUseNewTaxManagement &&
          ['CREATE', 'EDIT'].includes(lineItemGroupEditor.mode) && (
            <LineItemGroupEditorDrawer
              isOpen={isLineItemGroupDrawerOpen}
              onClose={() => {
                setLineItemGroupDrawerOpen(false)
                setLineItemGroupEditorMode('VIEW')
              }}
              isEditing={lineItemGroupEditor.mode === 'EDIT'}
              lineItemGroup={currentLineItemGroup}
              updateLineItemGroupFields={updateLineItemGroupFields}
              onEdit={
                flags.showNewTaxManagement
                  ? onConfirmSubmit(onUpdateLineItemGroup)
                  : () => new Promise(onUpdateLineItemGroup)
              }
              onCreate={
                flags.showNewTaxManagement
                  ? onConfirmSubmit(onAddLineItemGroup)
                  : () => new Promise(onAddLineItemGroup)
              }
              onCancel={onCancelGroupChange}
            />
          )}

        {lineItemGroupEditor.mode === 'CREATE' && !canUseNewTaxManagement && (
          <Card padding="24px">
            <Flex gap="16px" justify="space-between">
              <TextInput
                autoFocus
                data-testid="invoiceEditor.lineItemGroup.name"
                placeholder="Group item name"
                onChange={newValue =>
                  updateLineItemGroupFields({ description: newValue })
                }
              />
              <Flex gap="8px">
                <Button variant="secondary2" onClick={onCancelGroupChange}>
                  Cancel
                </Button>
                <Button
                  data-testid="invoiceEditor.lineItemGroup.save"
                  variant="primary2"
                  onClick={onAddLineItemGroup}
                  isDisabled={!lineItemGroupFieldsConfig.description?.isValid}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </Card>
        )}

        {canEditLineItems && (
          <Button
            data-testid="invoiceEditor.addLineItemGroup"
            variant="unstyled"
            isDisabled={lineItemGroupEditor.mode !== 'VIEW'}
            onClick={() => {
              setLineItemGroupDrawerOpen(true)

              onCreateLineItemGroup()
            }}
            leftIcon={
              <PlusCircleIcon height="16px" width="16px" color="inherit" />
            }
            iconSpacing="4px"
            display="flex"
            width="100%"
            borderRadius="8px"
            background="transparent"
            color={GreyGrey60}
            border={`${GreyGrey50} 1px dashed`}
            _hover={{ color: GreyGrey70, backgroundColor: GreyGrey20 }}
            pointerEvents={
              lineItemGroupEditor.mode !== 'VIEW' ? 'none' : undefined
            }
          >
            <Text {...Lato13Bold} color="inherit">
              Add line item group
            </Text>
          </Button>
        )}

        {showSubAccountUsage && (
          <SubAccountUsageBreakdownCard
            usageBreakdown={subAccountUsageBreakdown}
          />
        )}
      </Flex>

      {modals.avalara.active && (
        <AvalaraBlocker
          confirmTitleText="Update line item group"
          onClose={modals.avalara.onClose}
        >
          This line item group's tax category needs to be mapped to an Avalara
          tax code.
        </AvalaraBlocker>
      )}

      {modals.sequence.active && (
        <ModalContextProvider isOpen>
          <TaxRatesModalUI
            onSubmit={modals.sequence.onConfirm}
            countriesWithTaxRates={modals.sequence.data.countryTaxRates}
            taxCategories={modals.sequence.data.taxCategories}
            preselectedCountry={modals.sequence.data.customerCountry}
            preselectedState={data.customer.state}
            options={{
              closeOnCancel: false,
              closeOnSubmit: false,
              submitText: 'Save tax rates',
              onCancel: modals.sequence.onClose,
              onClose: modals.sequence.onClose
            }}
          />
        </ModalContextProvider>
      )}
    </>
  )
}
