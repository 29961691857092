import { Currency, PriceModel, toPriceSummary } from '@sequencehq/core-models'
import {
  ApplyDiscountTo,
  DiscountEditorDomainInterface,
  DiscountEditorMode,
  DiscountEditorValidationError
} from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { useDiscountEditorContext } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/useDiscountEditorContext'
import { sortBy } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { toBillingFrequencyLabel } from '@sequencehq/utils'

type TargetDataStructure = DiscountEditorDomainInterface['data']['discount']
type FieldConfig<
  Property extends keyof TargetDataStructure = keyof TargetDataStructure
> = {
  disabled: boolean
  validationErrors: DiscountEditorValidationError[]
  value: TargetDataStructure[Property]
  onChange: (newValue: TargetDataStructure[Property]) => void
}

type ApplyToPrice = {
  id: PriceModel['id']
  label: string
  price: string
  selected: boolean
  disabled: boolean
  toggleSelection: () => void
}
type ApplyToField = FieldConfig<'applyTo'> & {
  options: { value: ApplyDiscountTo; label: string; disabled?: boolean }[]
} & {
  prices: ApplyToPrice[]
}
type UseDiscountEditorForm = () => {
  fieldsConfig: {
    name: FieldConfig<'name'>
    discountCalculationType: FieldConfig<'discountCalculationType'> & {
      options: {
        value: TargetDataStructure['discountCalculationType']
        label: string
      }[]
    }
    nominalValue: FieldConfig<'nominalValue'> & {
      hidden: boolean
      currency: Currency
    }
    percentageValue: FieldConfig<'percentageValue'> & { hidden: boolean }
    applyTo: ApplyToField
  }
}

const discountCalculationTypeOptions: {
  value: TargetDataStructure['discountCalculationType']
  label: string
}[] = [
  {
    value: 'PERCENTAGE',
    label: 'Percentage'
  },
  {
    value: 'NOMINAL',
    label: 'Fixed'
  }
]

export const useDiscountEditorForm: UseDiscountEditorForm = () => {
  const discountEditorContext = useDiscountEditorContext()

  const updateValue = useCallback(
    <Property extends keyof TargetDataStructure>(property: Property) =>
      (newValue: TargetDataStructure[Property]) => {
        discountEditorContext.functions.updateDiscount({
          [property]: newValue
        })
      },
    [discountEditorContext.functions]
  )

  const selectedBillingFrequency = useMemo(() => {
    const allSelectedPrices = discountEditorContext.data.discount.priceIds
      .map(priceId => discountEditorContext.data.prices[priceId])
      .filter(Boolean)

    return (
      allSelectedPrices.find(price => price.billingFrequency !== 'ONE_TIME')
        ?.billingFrequency ?? null
    )
  }, [
    discountEditorContext.data.discount.priceIds,
    discountEditorContext.data.prices
  ])

  const fieldsConfig = useMemo(() => {
    const discount = discountEditorContext.data.discount
    return {
      name: {
        value: discount.name,
        onChange: updateValue('name'),
        validationErrors: discountEditorContext.queries.validation.filter(
          error => error.fieldName === 'name'
        ),
        disabled: discountEditorContext.editor.mode === DiscountEditorMode.VIEW
      },
      discountCalculationType: {
        value: discount.discountCalculationType,
        onChange: updateValue('discountCalculationType'),
        validationErrors: discountEditorContext.queries.validation.filter(
          error => error.fieldName === 'discountCalculationType'
        ),
        disabled: discountEditorContext.editor.mode === DiscountEditorMode.VIEW,
        options: discountCalculationTypeOptions
      },
      nominalValue: {
        hidden: discount.discountCalculationType === 'PERCENTAGE',
        value: discount.nominalValue,
        onChange: updateValue('nominalValue'),
        validationErrors: discountEditorContext.queries.validation.filter(
          error => error.fieldName === 'nominalValue'
        ),
        currency: discount.currency,
        disabled: discountEditorContext.editor.mode === DiscountEditorMode.VIEW
      },
      percentageValue: {
        hidden: discount.discountCalculationType === 'NOMINAL',
        value: discount.percentageValue,
        onChange: updateValue('percentageValue'),
        validationErrors: discountEditorContext.queries.validation.filter(
          error => error.fieldName === 'percentageValue'
        ),
        disabled: discountEditorContext.editor.mode === DiscountEditorMode.VIEW
      },
      applyTo: {
        value: discount.applyTo,
        onChange: (newValue: ApplyDiscountTo) => {
          updateValue('applyTo')(newValue)
        },
        validationErrors: discountEditorContext.queries.validation.filter(
          error => error.fieldName === 'applyTo'
        ),
        disabled: discountEditorContext.editor.mode === DiscountEditorMode.VIEW,
        options: [
          {
            value: 'ALL',
            label: 'All products',
            disabled: !discountEditorContext.configuration.applyToAllAvailable,
            disabledReason: !discountEditorContext.configuration
              .applyToAllAvailable
              ? 'A discount can only be applied to prices with the same frequency'
              : ''
          },
          {
            value: 'SPECIFIC',
            label: 'Specific products',
            disabled: !Object.values(discountEditorContext.data.prices).length
          }
        ] as { value: ApplyDiscountTo; label: string; disabled?: boolean }[],
        prices: sortBy<ApplyToPrice>('label')(
          Object.values(discountEditorContext.data.prices).map(price => {
            const priceFrequencyDisabled =
              selectedBillingFrequency &&
              ![selectedBillingFrequency, 'ONE_TIME'].includes(
                price.billingFrequency
              )

            return {
              id: price.id,
              label: price.name,
              price: `${toPriceSummary(
                price
              )} / ${toBillingFrequencyLabel(price.billingFrequency)}`,
              selected: discountEditorContext.data.discount.priceIds.includes(
                price.id
              ),
              disabled:
                /**
                 * We only allow for the selection of one billing frequency for discounts
                 */
                priceFrequencyDisabled ||
                !discountEditorContext.configuration.availablePrices.includes(
                  price.id
                ),
              toggleSelection: () => {
                discountEditorContext.functions.updateDiscount({
                  priceIds:
                    discountEditorContext.data.discount.priceIds.includes(
                      price.id
                    )
                      ? discountEditorContext.data.discount.priceIds.filter(
                          id => id !== price.id
                        )
                      : [
                          ...discountEditorContext.data.discount.priceIds,
                          price.id
                        ]
                })
              }
            }
          })
        )
      }
    }
  }, [
    discountEditorContext.data,
    discountEditorContext.editor.mode,
    discountEditorContext.queries.validation,
    discountEditorContext.configuration.availablePrices,
    discountEditorContext.configuration.applyToAllAvailable,
    discountEditorContext.functions,
    selectedBillingFrequency,
    updateValue
  ])

  return {
    fieldsConfig
  }
}
