import { developerLog } from '@sequencehq/utils'
import {
  EditorMode,
  PricingEditorDomainInput,
  PricingEditorDomainOutput,
  PricingEditorPortErrors,
  PricingEditorPortImplementation
} from 'common/drawers/PricingEditor/domain/pricingEditor.domain.types'
import { useCallback, useMemo } from 'react'
import * as adapters from '../../adapters'
import { loadCommonData } from '../common.api.v1/loadCommonData'
import {
  BASE_INITIAL_LIST_PRICE,
  INITIAL_PRICING_EDITOR_STATE
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import * as mutators from './entitySaving'
import deepmerge from 'deepmerge'

type UseListPricePorts = (props: {
  listPriceId: string
  productId: string
  initialMode: EditorMode
}) => PricingEditorPortImplementation

export const loadAllData = async (args: {
  configuration: Partial<PricingEditorDomainInput['configuration']>
  initialData: Partial<PricingEditorDomainInput['initialData']>
  listPriceId?: string
  productId: string
}): Promise<{
  data: PricingEditorDomainInput | null
  error: PricingEditorPortErrors | null
}> => {
  developerLog(`%c[PricingEditor] Loading list price`, 'color: pink;', {
    props: { id: args.listPriceId }
  })
  const { accountSettings, xeroIntegration, listPrices, product } =
    await loadCommonData({ productId: args.productId })

  const errorResponse = [accountSettings, xeroIntegration, product].some(
    response => Boolean(response.error)
  )

  if (
    errorResponse ||
    !accountSettings.data ||
    !product.data ||
    !xeroIntegration.data
  ) {
    return {
      data: null,
      error: PricingEditorPortErrors.Other
    }
  }

  const listPrice = listPrices.find(({ id }) => id === args.listPriceId)
  const selectedPrice = listPrice ?? {
    ...BASE_INITIAL_LIST_PRICE,
    productId: product.data.id,
    currency: accountSettings.data.enabledCurrencies[0]
  }
  const res = {
    xeroIntegration: xeroIntegration.data,
    price: selectedPrice,
    product: product.data
  }

  const inputData = adapters.dataAdapter.in.list(res)

  return {
    data: {
      data: inputData.domainInputData,
      configuration: deepmerge(args.configuration, {
        availableCurrencies: accountSettings.data.enabledCurrencies
      }),
      initialData: deepmerge(args.initialData, inputData.initialData, {
        arrayMerge: (_, source: unknown[]) => source
      })
    },
    error: null
  }
}

export const useListPricePorts: UseListPricePorts = props => {
  const loadCore = useCallback(() => {
    return loadAllData({
      configuration: {
        ...INITIAL_PRICING_EDITOR_STATE['configuration'],
        mode: props.initialMode,
        priceType: 'list'
      },
      initialData: {
        mode: props.initialMode
      },
      listPriceId: props.listPriceId,
      productId: props.productId
    })
  }, [props.initialMode, props.listPriceId, props.productId])

  const createProduct = mutators.useCreateProduct()

  /**
   * This could do with a tidy, as needing to create two entities in one function,
   * with the associated surfacing of error reasons, is a bit branch-y.
   */
  const saveListPrice = useCallback(
    async (data: PricingEditorDomainOutput) => {
      developerLog(`%c[PricingEditor] Saving list price`, 'color: pink;', {
        state: data
      })

      const saveData = adapters.dataAdapter.out.list(data)

      if (saveData.product) {
        const productResult = await createProduct(saveData.product)

        if (!productResult.success) {
          return {
            success: false,
            error: PricingEditorPortErrors.ErrorOnProductSave,
            data: { price: null }
          }
        }

        if (productResult.success && productResult.data) {
          const listPriceResult = await mutators.createListPrice({
            ...saveData.price,
            productId: productResult.data.id
          })

          if (!listPriceResult.success) {
            return {
              success: false,
              error:
                PricingEditorPortErrors.ErrorOnListPriceSaveButProductCreated,
              data: { product: productResult.data, price: null }
            }
          }

          return {
            success: true,
            error: null,
            data: {
              product: productResult.data,
              price: listPriceResult.data
            }
          }
        }
      }

      const listPriceResult = await ('id' in saveData.price
        ? mutators.updateListPrice(saveData.price)
        : mutators.createListPrice(saveData.price))

      return {
        success: listPriceResult.success,
        error: null,
        data: {
          price: listPriceResult.data
        }
      }
    },
    [createProduct]
  )

  const portImplementation: PricingEditorPortImplementation = useMemo(() => {
    return {
      in: {
        load: loadCore
      },
      out: {
        save: saveListPrice
      }
    }
  }, [loadCore, saveListPrice])

  return portImplementation
}
