import { IntegrationService } from '@sequencehq/core-models'
import { AmazonRedshiftIcon } from '@sequencehq/core-components'
import { GoogleBigQueryIcon } from '@sequencehq/core-components'
import { GoogleSheetsIcon } from '@sequencehq/core-components'
import { HubspotIcon } from '@sequencehq/core-components'
import { NetsuiteIcon } from '@sequencehq/core-components'
import { QuickbooksIcon } from '@sequencehq/core-components'
import { SalesforceIcon } from '@sequencehq/core-components'
import { SlackIcon } from '@sequencehq/core-components'
import { SnowflakeIcon } from '@sequencehq/core-components'
import { StripeIcon } from '@sequencehq/core-components'
import { XeroIcon } from '@sequencehq/core-components'
import { ReactElement } from 'react'
import { AvalaraIcon } from '@sequencehq/core-components'

export function getLogo(
  integrationService: IntegrationService,
  size = 32
): ReactElement | null {
  const props = { width: size, height: size }

  switch (integrationService) {
    case 'Stripe':
      return <StripeIcon {...props} />
    case 'Xero':
      return <XeroIcon {...props}></XeroIcon>
    case 'QuickBooks_Online':
      return <QuickbooksIcon {...props}></QuickbooksIcon>
    case 'NetSuite':
      return <NetsuiteIcon {...props}></NetsuiteIcon>
    case 'Salesforce':
      return <SalesforceIcon {...props}></SalesforceIcon>
    case 'HubSpot':
      return <HubspotIcon {...props}></HubspotIcon>
    case 'Slack':
      return <SlackIcon {...props}></SlackIcon>
    case 'Google_BigQuery':
      return <GoogleBigQueryIcon {...props}></GoogleBigQueryIcon>
    case 'Google_Sheets':
      return <GoogleSheetsIcon {...props}></GoogleSheetsIcon>
    case 'Snowflake':
      return <SnowflakeIcon {...props}></SnowflakeIcon>
    case 'Amazon_Redshift':
      return <AmazonRedshiftIcon {...props}></AmazonRedshiftIcon>
    case 'Avalara':
      return <AvalaraIcon {...props}></AvalaraIcon>
    default:
      console.error(
        "Integration service doesn't have a logo",
        integrationService
      )
      return null
  }
}

export function addPaddingToIntegrationLogo(
  integrationService: IntegrationService
): boolean {
  switch (integrationService) {
    case 'Stripe':
    case 'Salesforce':
    case 'Amazon_Redshift':
      return true
    default:
      return false
  }
}
