import { EditorBreadcrumb } from './EditorBreadcrumb/EditorBreadcrumb'
import { StandardPriceForm } from '../../editors/StandardPrice/StandardPriceForm'
import { useListPriceLayout } from './useListPriceLayout'
import { Button, Modal, Skeleton } from '@sequencehq/core-components'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { match } from 'ts-pattern'
import { EditorMode } from 'common/drawers/PricingEditor/domain'
import { GraduatedPriceForm } from '../../editors/GraduatedPrice/GraduatedPriceForm'
import { LinearPriceForm } from '../../editors/LinearPrice/LinearPriceForm'
import { VolumePriceForm } from '../../editors/VolumePrice/VolumePriceForm'
import { PackagedPriceForm } from '../../editors/PackagedPrice/PackagedPriceForm'
import { SeatBasedPriceForm } from '../../editors/SeatBasedPrice/SeatBasedPriceForm'
import { AddProductForm } from './ProductForm/ProductForm'
import { ReviewForm } from './ReviewForm/ReviewForm'

export const ListPriceLayout = (props: {
  loading: boolean
  initialMode: EditorMode
  onCancel?: () => void
}) => {
  const {
    cancelText,
    editorMode,
    pricingModel,
    showBreadcrumbs,
    onBack,
    submitAction,
    title,
    onCancel
  } = useListPriceLayout({
    initialMode: props.initialMode,
    onCancel: props.onCancel
  })

  return (
    <Modal variant="drawer" onClose={onCancel} data-testid="listPrice.modal">
      <Modal.Header showCloseButton onClose={onCancel} withBorder>
        {title}
      </Modal.Header>
      <Modal.Content padding={0}>
        <Grid
          templateAreas={`
                "breadcrumb"
                "content"
              `}
          templateRows={'48px 1fr'}
          width="100%"
          height="100%"
        >
          {props.loading ? (
            <GridItem gridArea="breadcrumb" padding="24px">
              <Skeleton height="40px" width="100%" />
            </GridItem>
          ) : (
            showBreadcrumbs && (
              <GridItem gridArea="breadcrumb">
                <EditorBreadcrumb />
              </GridItem>
            )
          )}
          <GridItem gridArea="content" overflow="auto">
            <Box
              width="100%"
              minWidth="400px"
              data-testid="priceEditor.listPriceForm"
              padding="24px"
            >
              {props.loading ? (
                <Flex
                  direction="column"
                  gap="20px"
                  width="100%"
                  marginTop="12px"
                >
                  <Skeleton height="40px" width="100%" />
                  <Skeleton height="200px" width="100%" />
                  <Skeleton height="40px" width="100%" />
                  <Skeleton height="40px" width="100%" />
                  <Skeleton height="40px" width="100%" />
                </Flex>
              ) : (
                match(editorMode)
                  .with(EditorMode.ADD_PRODUCT, () => <AddProductForm />)
                  .with(EditorMode.CREATE, EditorMode.EDIT, () => {
                    return match(pricingModel)
                      .with('STANDARD', () => <StandardPriceForm />)
                      .with('GRADUATED', () => <GraduatedPriceForm />)
                      .with('LINEAR', () => <LinearPriceForm />)
                      .with('VOLUME', () => <VolumePriceForm />)
                      .with('PACKAGED', () => <PackagedPriceForm />)
                      .with('SEAT_BASED_GRADUATED', 'SEAT_BASED_LINEAR', () => (
                        <SeatBasedPriceForm />
                      ))
                      .otherwise(() => 'form')
                  })
                  .with(EditorMode.REVIEW, () => <ReviewForm />)
                  .exhaustive()
              )}
            </Box>
          </GridItem>
        </Grid>
      </Modal.Content>
      <Modal.Footer withBorder>
        <Box flex={1}></Box>
        <Button
          data-testid="listPrice.modal.cancel"
          variant="secondary"
          onClick={onBack}
        >
          {cancelText}
        </Button>
        <Button
          data-testid="listPrice.modal.submit"
          variant="primary"
          onClick={submitAction.action}
          disabled={submitAction.disabled}
        >
          {submitAction.text}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
