import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/16/solid'
import Page from 'components/Page'
import QuotesMagicTable from 'modules/Quotes/QuotesMagicTable'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, memo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Quotes: FC = memo(() => {
  const flags = useFlags()
  const navigate = useNavigate()

  useEffect(() => {
    if (!flags?.showQuoteBuilder) {
      navigate('/home')
    }
  })

  const buttonProps: ButtonProps = {
    variant: 'component-library-primary',
    leftIcon: <PlusIcon width="16px" height="16px" color="inherit" />,
    iconSpacing: '4px'
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Quotes"
          paddingBottom={0}
          menuButtons={
            <Button {...buttonProps} onClick={() => navigate('/quotes/new')}>
              New quote
            </Button>
          }
        >
          <QuotesMagicTable />
        </Page>
      </Flex>
    </Flex>
  )
})

export default Quotes
