import {
  Box,
  Center,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useToast,
  UseToastOptions
} from '@chakra-ui/react'
import CurrencyPoundIcon from '@heroicons/react/16/solid/CurrencyPoundIcon'
import ViewColumnsIcon from '@heroicons/react/16/solid/ViewColumnsIcon'
import CalendarIcon from '@heroicons/react/16/solid/CalendarIcon'
import { Badge, KebabMenu } from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoicePaymentStatusBadgeProps,
  toInvoiceStatusBadgeProps,
  toMoney
} from '@sequencehq/core-models'
import { borderColor } from '@sequencehq/core-theme'
import { formatISODate, localDateWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTableCellInvoiceStatus,
  MagicTableFilterConfig,
  useMagicTableInfiniteQuery,
  useLinkMagicTableWithSearchParams
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { InvoiceBulkActions } from 'components/InvoiceBulkActions'
import { useInvoicePdfDownload } from 'InvoiceEditor/components/Header/useInvoicePdfDownload'
import {
  dueAfterFilterOptions,
  dueBeforeFilterOptions,
  invoiceAfterFilterOptions,
  invoiceBeforeFilterOptions,
  paymentStatusFilterOptions,
  sentFilterOptions,
  statusFilterOptions
} from './filters'
import { menuItemBuilders } from 'components/Invoices/menuItemBuilders'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { InvoicesViewSettingsHeader } from 'components/Invoices/InvoicesViewSettingsHeader'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import { useEnabledCurrencies } from 'components/CurrencySettings/useCurrencies'
import { FC, useCallback, useMemo } from 'react'
import { InvoicesSegmentedFilters } from 'components/Invoices/InvoicesSegmentedFilters'
import { addPaddingToIntegrationLogo, getLogo } from 'lib/integrations/logos'
import { formatDateRange, formatDistanceToNow } from '@sequencehq/utils/dates'
import {
  dashboardv20240509Client,
  Dashboardv20240509Api,
  WithPagination
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { InvoicesFilters, InvoicesPropFilters } from '../types'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { BulkActionsInFlight } from 'modules/BulkActions/BulkActionsInFlight'
import { useDispatch } from 'features/store'
import { openOverlay } from 'features/overlay'
import RectangleStackIcon from '@heroicons/react/24/solid/RectangleStackIcon'
import { ActiveFilter } from '@sequencehq/tables'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice
type InvoicesView = {
  model: Invoice
  controlFilters: InvoicesFilters
  propFilters: InvoicesPropFilters
}

type Props = {
  onSaveFilters?: (activeFilters: ActiveFilter[]) => void
  showSegmentedFilters?: boolean
  forcedActiveFilters?: ActiveFilter[]
}

export const InvoicesMagicTable: FC<Props> = ({
  onSaveFilters,
  showSegmentedFilters = false,
  forcedActiveFilters = []
}) => {
  const flags = useFlags()
  const toast = useToast()
  const dispatch = useDispatch()
  const { downloadPdf } = useInvoicePdfDownload()

  const enabledCurrencyRes = useEnabledCurrencies()

  const currencyFilterOptions = useMemo(() => {
    if (enabledCurrencyRes.status === 'SUCCESS') {
      return enabledCurrencyRes.enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      }))
    }
    return []
  }, [enabledCurrencyRes])

  const successToast = (options: UseToastOptions) => {
    if (options.id) {
      toast(options)
    }
  }

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  const filtersConfig: MagicTableFilterConfig<InvoicesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoiceStatusBadgeProps({
            status: status as InvoiceStatus
          })}
        />
      ),
      label: 'Status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoicePaymentStatus',
      options: paymentStatusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoicePaymentStatusBadgeProps({
            status: status as InvoicePaymentStatus
          })}
        />
      ),
      label: 'Payment status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceCurrency',
      label: 'Currency',
      icon: CurrencyPoundIcon,
      options: currencyFilterOptions,
      format: currency => currency
    },
    {
      type: 'date',
      paramName: 'due',
      paramNameBefore: 'dueBefore',
      paramNameAfter: 'dueAfter',
      optionsBefore: dueBeforeFilterOptions,
      optionsAfter: dueAfterFilterOptions,
      format: (_, label) => label,
      label: 'Due',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'sent',
      paramNameBefore: 'sentBefore',
      paramNameAfter: 'sentAfter',
      optionsBefore: sentFilterOptions,
      optionsAfter: sentFilterOptions,
      format: (_, label) => label,
      label: 'Sent',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'invoice',
      paramNameBefore: 'invoiceBefore',
      paramNameAfter: 'invoiceAfter',
      optionsBefore: invoiceBeforeFilterOptions,
      optionsAfter: invoiceAfterFilterOptions,
      format: (_, label) => label,
      label: 'Invoice Date',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'toggle',
      paramName: 'excludeZeroQuantity',
      label: 'Hide zero-usage invoices'
    }
  ]

  const filters = filtersConfig.map(filter => ({
    ...filter,
    disabled: !!forcedActiveFilters.find(
      forcedFilter => forcedFilter.paramName === filter.paramName
    )
  }))

  const {
    activeFilters: searchActiveFilters,
    onChangeActiveFilters: onChangeSearchActiveFilters,
    sortBy,
    onChangeSortBy
  } = useLinkMagicTableWithSearchParams(filters)

  const activeFilters = [...forcedActiveFilters, ...searchActiveFilters]
  const onChangeActiveFilters = useCallback(
    (newActiveFilters: ActiveFilter[]) => {
      onChangeSearchActiveFilters(
        newActiveFilters.filter(
          filter =>
            !forcedActiveFilters.find(
              forcedFilter => forcedFilter.paramName === filter.paramName
            )
        )
      )
    },
    [onChangeSearchActiveFilters, forcedActiveFilters]
  )

  const { infiniteQuery } = useMagicTableInfiniteQuery<
    WithPagination<{ items: Invoice[] }>
  >(dashboardv20240509Client.getInvoices, filters, activeFilters, sortBy)

  if (enabledCurrencyRes.status !== 'SUCCESS') {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  const additionalToolbarComponentsV3 = showSegmentedFilters
    ? {
        additionalToolbarComponentsV3: {
          start: <InvoicesSegmentedFilters />
        }
      }
    : {}

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<InvoicesView>
            activeFilters={activeFilters}
            onChangeActiveFilters={onChangeActiveFilters}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
            entityNamePlural="invoices"
            entityIcon={RectangleStackIcon}
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            rowPath={(row: InvoicesView['model']) => `/invoices/${row.id}`}
            columns={[
              {
                id: 'grossTotal',
                header: 'Amount',
                accessorFn: row => ({
                  currency: row.currency,
                  value: row.grossTotal
                }),
                cell: value => (
                  <MagicTableCell
                    text={toMoney(
                      value.getValue<{
                        currency: Invoice['currency']
                        value: Invoice['grossTotal']
                      }>()
                    )}
                    textAlign={value.column.columnDef?.meta?.textAlign}
                  />
                ),
                enableSorting: true,
                meta: { textAlign: 'right' }
              },
              {
                id: 'currency',
                header: 'Currency',
                accessorKey: 'currency',
                cell: value => {
                  const currency = value.getValue<Invoice['currency']>()

                  return <MagicTableCell text={currency} />
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  invoiceStatus: row.status,
                  paymentStatus: row.paymentStatus
                }),
                cell: value => {
                  const { invoiceStatus, paymentStatus } = value.getValue<{
                    invoiceStatus: Invoice['status']
                    paymentStatus: Invoice['paymentStatus']
                  }>()

                  return (
                    <MagicTableCellInvoiceStatus
                      invoiceStatus={
                        invoiceStatus === 'VOID' ? 'VOIDED' : invoiceStatus
                      }
                      paymentStatus={paymentStatus}
                    />
                  )
                }
              },
              {
                id: 'invoiceNumber',
                header: 'Invoice #',
                accessorKey: 'invoiceNumber',
                cell: value => {
                  const invoiceNumber =
                    value.getValue<Invoice['invoiceNumber']>()

                  if (!invoiceNumber) {
                    return null
                  }

                  return <MagicTableCell text={invoiceNumber} />
                }
              },
              {
                id: 'customerLegalCompanyName',
                header: 'Customer',
                accessorFn: row => ({
                  customerId: row.customerId
                }),
                cell: value => {
                  const { customerId } = value.getValue<{
                    customerId: Invoice['customerId']
                  }>()

                  return (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      pl="6px"
                    >
                      <CustomerPreviewCardPill
                        customerId={customerId}
                        variant="ghost"
                      />
                    </Flex>
                  )
                },
                enableSorting: true
              },
              {
                id: 'billingPeriod',
                header: 'Billing Period',
                accessorFn: row => ({
                  billingPeriod: row.billingPeriod
                }),
                cell: value => {
                  const { billingPeriod } = value.getValue<{
                    billingPeriod: Invoice['billingPeriod']
                  }>()

                  return (
                    <MagicTableCell
                      text={
                        billingPeriod
                          ? formatDateRange({
                              from: new Date(billingPeriod.start),
                              to: new Date(billingPeriod.endInclusive)
                            })
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'issueDate',
                header: 'Sent',
                accessorKey: 'issueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['issueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'dueDate',
                header: 'Due',
                accessorKey: 'dueDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['dueDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                )
              },
              {
                id: 'accountingDate',
                header: 'Date',
                accessorKey: 'accountingDate',
                cell: value => (
                  <MagicTableCell
                    text={
                      localDateWithFormat(
                        value.getValue<Invoice['accountingDate']>(),
                        'd MMM yyyy'
                      ) || ''
                    }
                  />
                ),
                enableSorting: true
              },
              {
                id: 'lastRecalculated',
                header: 'Last Recalculated',
                accessorKey: 'calculatedAt',
                cell: row => {
                  const calculatedAt = row.getValue<Invoice['calculatedAt']>()

                  return (
                    <MagicTableCell
                      text={
                        calculatedAt
                          ? formatDistanceToNow(new Date(calculatedAt), {
                              addSuffix: true
                            })
                          : ''
                      }
                    />
                  )
                }
              },
              {
                id: 'linkedServices',
                header: 'Links',
                accessorKey: 'linkedServices',
                cell: row => {
                  const linkedServices =
                    row.getValue<Invoice['linkedServices']>()

                  if (!linkedServices.length) {
                    return null
                  }

                  return (
                    <MagicTableCellEmpty>
                      <Flex gap="4px" overflow="hidden">
                        {linkedServices.map(linkedService => (
                          <Tooltip
                            key={linkedService.externalService}
                            label={linkedService.externalService.replace(
                              /_/g,
                              ''
                            )}
                          >
                            <Center
                              boxSize="24px"
                              bg={borderColor}
                              borderRadius="24px"
                            >
                              {getLogo(
                                linkedService.externalService,
                                addPaddingToIntegrationLogo(
                                  linkedService.externalService
                                )
                                  ? 18
                                  : undefined
                              )}
                            </Center>
                          </Tooltip>
                        ))}
                      </Flex>
                    </MagicTableCellEmpty>
                  )
                }
              }
            ]}
            kebabMenu={(model: Invoice, props) => (
              <KebabMenu
                isLazy
                menuItems={menuItemBuilders(successToast, downloadPdf)
                  .map(builder => builder(model))
                  .filter(builder => builder.status === 'LIVE')}
                flags={flags}
                {...props}
              />
            )}
            filters={filters}
            emptyState={{
              imageSrc: '/img/empty/invoices.png',
              learnMoreText:
                'Auto-invoice customers with billing schedules or create a one-off invoice',
              learnMoreHref:
                'https://docs.sequencehq.com/invoicing/invoicing-customers',
              buttonNew: {
                buttonText: 'New invoice',
                onClick: () => {
                  dispatch(
                    openOverlay({
                      content: 'createInvoiceModal',
                      data: {
                        customer: undefined
                      }
                    })
                  )
                }
              }
            }}
            bulkActions={(
              selectedRows: Invoice[],
              clearSelectedRows: () => void
            ) => {
              const bulkActionBar = (
                <InvoiceBulkActions
                  selectedRows={selectedRows}
                  clearSelectedRows={clearSelectedRows}
                  popoverOffset={[0, 0]}
                >
                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  />
                </InvoiceBulkActions>
              )

              if (!flags.useBulkActionsV2) {
                return bulkActionBar
              }
              return <BulkActionsInFlight fallback={bulkActionBar} />
            }}
            viewSettingsHeader={<InvoicesViewSettingsHeader />}
            onSaveFilters={() => onSaveFilters?.(activeFilters)}
            {...additionalToolbarComponentsV3}
          />
        </>
      )}
    </CurrentUserId>
  )
}
