import { match } from 'ts-pattern'
import { useListPricePorts } from '../communication/external/v1/ports/list.api.v1/useListPricePorts'
import { useVariantPricePorts } from '../communication/external/v1/ports/variant.api.v1/useVariantPricePorts'
import {
  EditorMode,
  PricingEditorDomainInterface,
  PricingEditorPortErrors,
  usePricingEditorDomain
} from '../domain'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NEW_PRODUCT_PREFIX } from '../domain/pricingEditor.constants'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { VariantPrice } from '../communication/external/v1/ports'

enum LoadingStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  RELOADING = 'RELOADING',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

type CommonProps = {
  initialMode: EditorMode
  currency?: Currency
  variantPriceId?: string
  onSave?: (data: { price: any }) => void
}

type UsePricingEditorRoot = (
  props:
    | ({
        variant: 'variant'
        disableDefaultPricePorts?: boolean
        existingPrice?: VariantPrice
      } & CommonProps)
    | ({ variant: 'list' } & CommonProps)
) => {
  domain: PricingEditorDomainInterface
  error: PricingEditorPortErrors | null
  ready: boolean
}

export const usePricingEditorRoot: UsePricingEditorRoot = props => {
  const [error, setError] = useState<PricingEditorPortErrors | null>(null)
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.UNINITIALIZED
  )
  const params = useParams<{
    productId: string
    listPriceId: string
    variantPriceId: string
  }>()

  const listPricePortImplementation = useListPricePorts({
    listPriceId: params.listPriceId ?? '',
    productId:
      params.productId ?? `${NEW_PRODUCT_PREFIX}${crypto.randomUUID()}`,
    initialMode: props.initialMode
  })

  const variantPricePortImplementation = useVariantPricePorts({
    productId: params.productId ?? '',
    listPriceId: params.listPriceId ?? '',
    variantPriceId: params.variantPriceId ?? props.variantPriceId ?? '',
    existingPrice: 'existingPrice' in props ? props.existingPrice : undefined,
    disableDefaultPricePorts:
      'disableDefaultPricePorts' in props
        ? props.disableDefaultPricePorts ?? false
        : false,
    currency: props.currency,
    initialMode: props.initialMode
  })

  const portImplementation = useMemo(() => {
    return match(props.variant)
      .with('list', () => {
        return listPricePortImplementation
      })
      .with('variant', () => {
        return variantPricePortImplementation
      })
      .exhaustive()
  }, [
    props.variant,
    listPricePortImplementation,
    variantPricePortImplementation
  ])

  const domain = usePricingEditorDomain({
    ports: portImplementation,
    afterSave: props.onSave
  })

  useEffect(() => {
    if (
      loadingStatus === LoadingStatus.LOADING ||
      loadingStatus === LoadingStatus.RELOADING ||
      loadingStatus === LoadingStatus.ERROR ||
      domain.queries.rawData.editor.loaded
    ) {
      return
    }

    setLoadingStatus(
      loadingStatus === LoadingStatus.READY
        ? LoadingStatus.RELOADING
        : LoadingStatus.LOADING
    )
    void domain.mutators.external.in.load().then(res => {
      if (res.error) {
        setLoadingStatus(LoadingStatus.ERROR)
        setError(res.error)
        return
      }

      setLoadingStatus(LoadingStatus.READY)
      setError(null)

      if (props.variant === 'list') {
        const baseSteps = [
          {
            name: 'Configure list price',
            mode: EditorMode.CREATE
          },
          {
            name: 'Review',
            mode: EditorMode.REVIEW
          }
        ]

        if (props.initialMode === EditorMode.ADD_PRODUCT) {
          domain.mutators.common.updateEditor({
            steps: [
              {
                name: 'Product information',
                mode: EditorMode.ADD_PRODUCT
              },
              ...baseSteps
            ]
          })
          return
        }

        domain.mutators.common.updateEditor({
          steps: baseSteps
        })
      }
    })
  }, [
    domain.mutators.external.in,
    loadingStatus,
    domain.queries.rawData.editor.loaded,
    domain.mutators.common,
    props.variant,
    props.initialMode
  ])

  return {
    domain,
    error,
    ready: domain.queries.rawData.editor.loaded
  }
}
