import { Box, Link, Text } from '@chakra-ui/react'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import { TextInputField } from '@sequencehq/forms'
import { required } from '@sequencehq/validation'
import { Blocker } from 'components/Form'
import { TextareaField } from 'components/FormFields'
import { DrawerForm } from 'components/Modal'
import arrayMutators from 'final-form-arrays'
import { handleFormResponse } from 'lib/formValidation'
import { FC } from 'react'
import { Form } from 'react-final-form'
import { useCreateSeatMetric } from 'modules/Seats/SeatMetric/hooks/useCreateSeatMetric'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type CreateSeatTypeFormProps = {
  onSuccess: (seatType: DashboardApi20240730.GetSeatMetrics.SeatMetric) => void
  handleCancel: () => void
}

export const CreateSeatTypeForm: FC<CreateSeatTypeFormProps> = ({
  onSuccess,
  handleCancel
}) => {
  const createSeatMetric = useCreateSeatMetric()

  return (
    <Form<DashboardApi20240730.PostSeatMetrics.PostSeatMetricBody>
      keepDirtyOnReinitialize
      destroyOnUnregister
      mutators={{
        ...arrayMutators
      }}
      onSubmit={async (values, form) => {
        const seatMetric = await createSeatMetric({
          ...values
        })

        if (seatMetric) {
          onSuccess(seatMetric)
        }

        return handleFormResponse(seatMetric, form.getRegisteredFields())
      }}
      render={formProps => (
        <DrawerForm
          {...formProps}
          title="Create seat metric"
          submitLabel="Create seat metric"
          handleCancel={handleCancel}
        >
          <Blocker
            name="seatType"
            dirty={formProps.dirty}
            submitting={formProps.submitting}
            modalTitle="Cancel seat metric editing"
            message="Progress you made so far will not be saved."
            submitTitle="Yes, Cancel"
            cancelTitle="Keep editing"
          />

          <Text textStyle="formHelperText">
            Seat metrics reflect the types of seats customers are billed for
            (e.g. admin seats or viewer seats).
          </Text>

          <Box height={4} />

          <TextInputField
            fieldName="label"
            fieldLabel="Name"
            placeholder="Responder"
            validate={required}
            data-testid="seatMetric.name"
          />

          <Box height={4} />

          <TextareaField
            fieldName="description"
            fieldLabel="Description"
            placeholder="Track usage for support seats"
            data-testid="seatMetric.description"
          />

          <Box height={4} />

          <TextInputField
            fieldName="seatType"
            fieldLabel="Seat type"
            placeholder="support_seat"
            validate={required}
            data-testid="seatMetric.seatType"
          />

          <Text textStyle="formHelperText" mt={2}>
            Use the seat type to submit seat events and update the balance for a
            customer.{' '}
            <Link
              color={IndigoIndigo50}
              href="https://docs.sequencehq.com/getting-started/overview"
              target="_blank"
              rel="noreferrer noopener"
            >
              Learn more
            </Link>
            .
          </Text>
        </DrawerForm>
      )}
    />
  )
}
