import { useCallback } from 'react'

import { pdf } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'

import { baseApi } from 'features/api/baseApi'
import { useFetchDataForLocalPdfCreditNote } from 'components/CreditNotes/useFetchDataForLocalPdfCreditNote'
import { CreditNoteModel } from '@sequencehq/core-models'
import { ReactPdfCreditNote } from '@sequencehq/invoice-content'
import { isProductionEnv } from 'lib/environment/environment.ts'

export const useGeneratePDF = () => {
  const [getCreditNoteDownload] =
    baseApi.endpoints.getCreditNoteDownload.useLazyQuerySubscription()

  const { fetchData } = useFetchDataForLocalPdfCreditNote()

  const generateLocalPDF = useCallback(
    async ({
      id: creditNoteId
    }: Pick<CreditNoteModel, 'id' | 'creditNoteNumber'>) => {
      try {
        const pdfCreditNoteData = await fetchData({ creditNoteId })

        return await pdf(
          <ReactPdfCreditNote
            creditNote={pdfCreditNoteData.creditNote}
            lineItems={pdfCreditNoteData.lineItems}
            lineItemGroups={pdfCreditNoteData.lineItemGroups}
            merchantDetails={pdfCreditNoteData.merchantDetails}
            showNonProdWatermark={!isProductionEnv()}
            isCustomerPortalEnabled={
              pdfCreditNoteData.merchant.customerPortalEnabled
            }
            isRenderedInBrowser={true}
          />
        ).toBlob()
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [fetchData]
  )

  const generateRemotePDF = useCallback(
    async ({
      id: creditNoteId
    }: Pick<CreditNoteModel, 'id' | 'creditNoteNumber'>) => {
      try {
        const fulfilled = await getCreditNoteDownload({
          id: creditNoteId
        }).unwrap()

        return new Blob([fulfilled], { type: 'application/pdf' })
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    [getCreditNoteDownload]
  )

  return {
    generateRemotePDF,
    generateLocalPDF
  }
}
