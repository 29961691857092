import { Box, Flex, Icon, useOutsideClick } from '@chakra-ui/react'
import {
  GreyGrey20,
  GreyGrey60,
  GreyGrey80,
  Lato13Bold,
  Lato16Bold
} from '@sequencehq/design-tokens'
import ArrowLongRightIcon from '@heroicons/react/16/solid/ArrowLongRightIcon'
import { usePhaseDuration } from './usePhaseDuration'
import { usePhaseName } from './usePhaseName'
import { Button, TextInput } from '@sequencehq/core-components'
import { useLayoutEffect, useRef, useState } from 'react'

const NameInput = (props: {
  initialValue: string
  onSubmit: (value: string) => void
  onCancel: () => void
}) => {
  const [name, setName] = useState<string>(props.initialValue)
  const inputRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<HTMLInputElement>(null)

  useOutsideClick({
    ref: wrapperRef,
    handler: () => props.onSubmit(name)
  })

  /**
   * Focus the input on mount
   */
  useLayoutEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Flex
      gap="8px"
      width="100%"
      onClick={e => {
        e.stopPropagation()
      }}
      ref={wrapperRef}
    >
      <TextInput
        ref={inputRef}
        height="32px"
        value={name}
        onChange={newValue => setName(newValue)}
        width="100%"
        placeholder="Phase name"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            props.onSubmit(name)
          }
        }}
        data-testid="phase.name.input"
      />
      <Button
        variant="secondary"
        onClick={() => {
          props.onCancel()
        }}
        data-testid="phase.name.cancel"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          props.onSubmit(name)
        }}
        data-testid="phase.name.submit"
      >
        Save
      </Button>
    </Flex>
  )
}

export const PhaseTitle = (props: {
  phaseId: string
  isEditing: boolean
  onEditStart: () => void
  onEditComplete: () => void
}) => {
  const phaseDuration = usePhaseDuration({ phaseId: props.phaseId })
  const phaseName = usePhaseName({ phaseId: props.phaseId })

  if (props.isEditing) {
    return (
      <NameInput
        initialValue={phaseName.name}
        onSubmit={newValue => {
          phaseName.updateName(newValue)
          props.onEditComplete()
        }}
        onCancel={() => props.onEditComplete()}
      />
    )
  }

  if (phaseName.name) {
    return (
      <Flex alignItems="center" width="fit-content" gap="4px">
        <Box
          {...Lato16Bold}
          color={GreyGrey80}
          padding="8px 4px"
          {...(phaseName.available
            ? {
                _hover: {
                  background: GreyGrey20,
                  borderRadius: '4px',
                  cursor: 'text'
                },
                onClick: e => {
                  e.stopPropagation()
                  props.onEditStart()
                }
              }
            : {})}
          data-testid="phase.name"
        >
          {phaseName.name}
        </Box>
        {phaseDuration.startDateFormatted && phaseDuration.endDateFormatted ? (
          <Flex
            gap={phaseName.name ? '4px' : '8px'}
            alignItems="center"
            paddingTop="2px"
          >
            <Box {...Lato13Bold} color={GreyGrey60}>
              {phaseDuration.startDateFormatted}
            </Box>

            <Icon
              as={ArrowLongRightIcon}
              height="16px"
              width="16px"
              position="relative"
              color={GreyGrey60}
              top="1px"
            />

            <Box {...Lato13Bold} color={GreyGrey60}>
              {phaseDuration.endDateFormatted}
            </Box>
          </Flex>
        ) : (
          <Box {...Lato13Bold} color={GreyGrey60} paddingTop="2px">
            {phaseDuration.differenceFormatted}
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      alignItems="center"
      gap="8px"
      padding="8px 4px"
      data-testid="phase.name"
      {...(phaseName.available
        ? {
            _hover: {
              background: GreyGrey20,
              borderRadius: '4px',
              cursor: 'text'
            },
            onClick: e => {
              e.stopPropagation()
              props.onEditStart()
            }
          }
        : {})}
      width="fit-content"
    >
      {phaseDuration.startDateFormatted && phaseDuration.endDateFormatted ? (
        <Flex gap="4px" alignItems="center">
          <Box {...Lato16Bold} color={GreyGrey80}>
            {phaseDuration.startDateFormatted}
          </Box>

          <Icon
            as={ArrowLongRightIcon}
            height="16px"
            width="16px"
            position="relative"
            color={GreyGrey80}
            top="1px"
          />

          <Box {...Lato16Bold} color={GreyGrey80}>
            {phaseDuration.endDateFormatted}
          </Box>
        </Flex>
      ) : (
        <Box {...Lato16Bold} color={GreyGrey80}>
          {phaseDuration.differenceFormatted}
        </Box>
      )}
    </Flex>
  )
}
