import { useMemo } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useLoadAllItemsQuery } from '@sequencehq/api/dist/utils'

export const useSeatMetrics = () => {
  const getSeatMetricsQuery = useLoadAllItemsQuery(
    dashboard20240730Client.getSeatMetrics
  )

  const seatMetrics = useMemo(() => {
    if (getSeatMetricsQuery.data) {
      return getSeatMetricsQuery.data.pages.flatMap(page => page.items)
    }
  }, [getSeatMetricsQuery.data])

  return {
    metrics: seatMetrics,
    loading: getSeatMetricsQuery.isPending,
    error: getSeatMetricsQuery.isError
  }
}
