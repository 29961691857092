import { createContext, useContext } from 'react'
import type { InvoiceEditorContext } from '../domainManagement/invoiceEditor.types'
import { INITIAL_DATA } from '../domainManagement/invoiceEditor.constants'

const InvoiceEditorContext = createContext<InvoiceEditorContext>({
  data: INITIAL_DATA,
  derived: {
    warnings: [],
    queries: {
      availableFeatures: {
        canCreateCreditNote: false,
        canDownloadInvoice: false,
        canPreviewInvoice: false,
        canDraftInvoice: false,
        canEditCustomer: false,
        canEditInvoice: false,
        canEditLineItems: false,
        canFinaliseAndSendInvoice: false,
        canFinaliseInvoice: false,
        finaliseInvoiceDisabled: false,
        canRecalculateInvoice: false,
        canSendInvoice: false,
        canSendPaymentReminder: false,
        canSendTestInvoice: false,
        canSyncCustomerToIntegrations: false,
        canSyncInvoiceToIntegrations: false,
        canUpdatePaymentCollectionMethod: false,
        canUpdatePaymentStatus: false,
        canViewInCustomerPortal: false,
        canVoidInvoice: false,
        canEditMemo: false,
        canEditPurchaseOrderNumber: false,
        canEditReference: false,
        canEditDueDate: false,
        canViewSubAccountUsage: false,
        canUseTaxCategories: false,
        canEditInvoiceDate: false,
        canEditBillingPeriod: false
      },
      subAccountUsageBreakdownByLineItemGroup: {}
    },
    validation: {
      update: []
    }
  },
  functions: {
    addCollection: () => {},
    createCreditNote: () => {},
    createLineItem: () => {},
    createLineItemGroup: () => {},
    deleteLineItem: () => {},
    deleteLineItemGroup: () => {},
    downloadInvoice: () => {},
    finaliseAndSendInvoice: () => {},
    finaliseInvoice: () => {},
    linkCustomerToIntegration: () => {},
    recalculateInvoice: () => {},
    saveInvoice: () => {},
    sendInvoice: () => {},
    sendPaymentReminder: () => {},
    sendTestInvoice: () => {},
    showEditCustomerForm: () => {},
    showPaymentDetailsDrawer: () => {},
    syncInvoiceToIntegration: () => {},
    showInvoicePdfPreviewDrawer: () => {},
    updateCustomer: () => {},
    updateData: () => {},
    updateLineItem: () => {},
    updateLineItemGroup: () => {},
    updatePaymentStatus: () => {},
    voidInvoice: () => {},
    convertInvoiceToDraft: () => {},
    updateMemo: () => {},
    updateDueDate: () => {},
    updatePurchaseOrderNumber: () => {},
    updateReference: () => {},
    updateInvoiceDate: () => {},
    updateBillingPeriod: () => {}
  }
})

export const useInvoiceEditorContext = () => useContext(InvoiceEditorContext)
export const InvoiceEditorProvider = InvoiceEditorContext.Provider
