import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { PricingWidget } from 'Cube/view/common/widgets/Pricing/PhasesWidget'

const PhasesBlock = createReactBlockSpec(
  {
    type: 'phases',
    propSchema: {},
    content: 'none'
  },
  {
    render: () => <PricingWidget />
  }
)
const { table, ...blockSpecs } = defaultBlockSpecs

export const blocknoteSchema = BlockNoteSchema.create({
  blockSpecs: {
    ...blockSpecs,
    phases: PhasesBlock
  }
})
