import { Flex, Text } from '@chakra-ui/react'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import { SeatBalanceModel, SeatTypeBalanceModel } from '@sequencehq/core-models'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTable,
  MagicTableCell,
  useMagicTableInfiniteQuery,
  MagicTableFilterConfig,
  useLinkMagicTableWithSearchParams
} from '@sequencehq/tables'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import {
  SeatBalanceFilters,
  SeatBalancesView
} from 'modules/Seats/SeatBalances/types'
import { useGetCustomersByAliasByAliasQuery } from 'features/api'
import { ExtractQueryParams } from 'lib/types'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCustomerNamesByAlias } from 'components/UsageEvents/useCustomerNamesByAlias.tsx'
import Spinner from 'components/Loading'
import { UsersIcon as UsersIcon24 } from '@heroicons/react/24/solid'

export const SeatBalancesMagicTable = () => {
  const {
    isLoading: isLoadingAliases,
    aliases,
    customersWithAliases
  } = useCustomerNamesByAlias()

  const filters: MagicTableFilterConfig<SeatBalanceFilters>[] = [
    {
      type: 'multiSelectAliased',
      paramName: 'customerName',
      paramNameAliasOf: 'customerAliases',
      options: (customersWithAliases ?? []).map(customer => ({
        label: customer.customerName,
        value: customer.aliases.join(',')
      })),
      format: value => (
        <Text>
          {
            (customersWithAliases ?? []).find(
              customer => customer.aliases.join(',') === value
            )?.customerName
          }
        </Text>
      ),
      label: 'Customer',
      icon: IdentificationIcon
    }
  ]

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatBalances,
    filters,
    activeFilters,
    sortBy
  )

  if (isLoadingAliases || !customersWithAliases || !aliases) {
    return <Spinner></Spinner>
  }

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatBalancesView>
          activeFilters={activeFilters}
          onChangeActiveFilters={onChangeActiveFilters}
          sortBy={sortBy}
          onChangeSortBy={onChangeSortBy}
          entityNamePlural="seat balances"
          entityIcon={UsersIcon24}
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          getSubRows={row => {
            return (
              row?.balances?.map(balance => {
                return {
                  ...balance,
                  id: '',
                  sequenceAccountId: '',
                  legalName: '',
                  customerAliases: [],
                  balances: []
                }
              }) ?? []
            )
          }}
          columns={[
            {
              id: 'customerAliases',
              accessorKey: 'customerAliases',
              header: 'Customer',
              cell: value => {
                return (
                  <MagicTableAutoLoader<
                    ExtractQueryParams<
                      typeof useGetCustomersByAliasByAliasQuery
                    >,
                    { customerId?: string }
                  >
                    queryParams={{
                      alias:
                        value.getValue<SeatBalanceModel['customerAliases']>()[0]
                    }}
                    useQuery={useGetCustomersByAliasByAliasQuery}
                    extract={customer => ({
                      customerId: customer?.id
                    })}
                  >
                    {({ customerId }) => {
                      if (customerId) {
                        return (
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            height="40px"
                            pl="6px"
                          >
                            <CustomerPreviewCardPill
                              customerId={customerId}
                              variant="ghost"
                            />
                          </Flex>
                        )
                      }

                      return (
                        <MagicTableCell text={value.row.original.legalName} />
                      )
                    }}
                  </MagicTableAutoLoader>
                )
              }
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat metric',
              cell: value => {
                const isSubRow = value.row.depth > 0
                const totalSubRows = value.row.subRows.length
                const hasSubRows = totalSubRows > 0

                if (!hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                    />
                  )
                }

                if (hasSubRows && !isSubRow) {
                  return (
                    <MagicTableCell
                      text={`${totalSubRows} seat ${
                        totalSubRows > 1 ? 'metrics' : 'metric'
                      }`}
                      textColor={GreyGrey60}
                    />
                  )
                }
                return (
                  <MagicTableCell
                    text={value.getValue<SeatTypeBalanceModel['seatType']>()}
                  />
                )
              }
            },
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Balance',
              cell: value => (
                <MagicTableCell
                  text={String(value.getValue<SeatBalanceModel['total']>())}
                />
              )
            },
            {
              id: 'changeTimestamp',
              accessorKey: 'changeTimestamp',
              header: 'Last Updated (UTC)',
              cell: value => {
                const changeTimestamp =
                  value.getValue<SeatBalanceModel['changeTimestamp']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      changeTimestamp,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          emptyState={{
            imageSrc: '/img/empty/gauge.png',
            learnMoreText:
              'Seat balances reflect the latest number of seats in use for a customer',
            learnMoreHref: 'https://docs.sequencehq.com/seats/seat-balances'
          }}
          filters={filters}
        />
      )}
    </CurrentUserId>
  )
}
