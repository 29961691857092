import { Inspector } from '@sequencehq/core-components'
import { QuickBooksInspectorItem } from 'modules/Integrations'
import { LinkQuickbooksProductWidget } from 'Integrations/integrationsConfig/quickBooks/InspectorItem/QuickBooksInspectorItem'

interface Props {
  productId: string
}

export const ProductInspector = ({ productId }: Props) => {
  return (
    <Inspector>
      <Inspector.ItemGroup>
        <QuickBooksInspectorItem>
          <LinkQuickbooksProductWidget productId={productId} />
        </QuickBooksInspectorItem>
      </Inspector.ItemGroup>
    </Inspector>
  )
}
