import { Flex, Grid, Skeleton, Text } from '@chakra-ui/react'
import { Badge } from '@sequencehq/core-components'
import {
  PriceModel,
  PricingStructureModel,
  toBillingFrequencyLabel,
  toBillingType,
  toMoney
} from '@sequencehq/core-models'
import {
  GreyGrey100,
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { useGetUsageMetricsIdQuery } from 'features/api'
import { capitalize, kebabCase } from 'lodash'
import ClipboardIcon from '@heroicons/react/16/solid/ClipboardIcon'
import { Link } from 'react-router-dom'
import { pricingTypeLabel } from 'modules/Products/formatters'
import {
  enforceMinimumPrecision,
  priceModelAdapter,
  toPriceSummary
} from '@sequencehq/utils'
import { ListPrice, Product } from 'modules/Products/types'
import usageCalculationPeriodMapper from 'common/drawers/PricingEditor/utils/usageCalculationPeriodMapper.ts'
import { usageCalculationPeriodLabels } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/GraduatedPriceForm.constants.ts'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { compose, replace } from 'lodash/fp'
import { useQuery } from '@sequencehq/api/dist/utils'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { NEW_PRICE_PATTERN } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'

const deriveIsPricePercentage = (
  structure?: PricingStructureModel | ListPrice['structure']
): boolean => {
  if (!structure) {
    return false
  }

  if (structure.pricingType === 'VOLUME') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'GRADUATED') {
    return structure.tiers[0].isPricePercentage
  }

  if (structure.pricingType === 'LINEAR') {
    return structure.isPricePercentage
  }

  return false
}

const PRICING_TYPES_WITH_PERCENTAGE = ['LINEAR', 'GRADUATED', 'VOLUME']

export const ProductPriceMeta = ({
  price,
  product
}: {
  price: PriceModel | ListPrice
  product?: Product
}) => {
  const flags = useFlags()

  const { data: taxCategoriesData } = useQuery(
    dashboardv99990101Client.getTaxCategories
  )

  const taxCategoryForProduct = taxCategoriesData?.items?.find(
    taxCategory => taxCategory.id === product?.taxCategoryId
  )

  const handleCopy = () => {
    void navigator.clipboard.writeText(price.id)
  }
  const transformedPrice =
    'integrationIds' in price ? price : priceModelAdapter.out(price)

  return (
    <Flex flexDirection="column">
      <Text {...Lato14Bold} color={GreyGrey100}>
        Details
      </Text>
      <Grid
        templateColumns={`${product?.revenueRecognitionMethod ? '176px' : '160px'} 1fr`}
        gap={3}
        mt={4}
        color={GreyGrey70}
      >
        {product && (
          <>
            <Text {...Lato13Regular}>Product name</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {product.name}
            </Text>

            {product.label && (
              <>
                <Text {...Lato13Regular}>Product description</Text>
                <Text {...Lato13Regular} color={GreyGrey90}>
                  {product.label}
                </Text>
              </>
            )}

            {product.revenueRecognitionMethod && (
              <>
                <Text {...Lato13Regular}>Revenue recognition method</Text>
                <Text
                  {...Lato13Regular}
                  color={GreyGrey90}
                  data-testid="pricingEditor.review.revenueRecognitionMethod"
                >
                  {compose(
                    replace('_', ' '),
                    capitalize
                  )(product.revenueRecognitionMethod)}
                </Text>
              </>
            )}

            {taxCategoryForProduct && (
              <>
                <Text {...Lato13Regular}>Tax category</Text>
                <Text
                  {...Lato13Regular}
                  color={GreyGrey90}
                  data-testid="pricingEditor.review.taxCategoryName"
                >
                  {taxCategoryForProduct.name}
                </Text>
              </>
            )}
          </>
        )}
        <Text {...Lato13Regular}>Pricing model</Text>
        <Text {...Lato13Regular} color={GreyGrey90}>
          {capitalize(
            kebabCase(pricingTypeLabel(price.structure.pricingType))
          ) + ' pricing'}
        </Text>
        <Text {...Lato13Regular}>Billing frequency</Text>
        <Text {...Lato13Regular} color={GreyGrey90}>
          {toBillingFrequencyLabel(price.billingFrequency)} /{' '}
          {toBillingType(price.billingType)}
        </Text>

        {price.structure.pricingType !== 'ONE_TIME' &&
          price.structure.pricingType !== 'FIXED' &&
          price.structure.pricingType !== 'SEAT_BASED' && (
            <PriceUsageMetric usageMetricId={price.structure.usageMetricId} />
          )}

        {PRICING_TYPES_WITH_PERCENTAGE.includes(
          price.structure.pricingType
        ) && (
          <>
            <Text {...Lato13Regular}>Type</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {deriveIsPricePercentage(price.structure)
                ? 'Percentage'
                : 'Fixed'}
            </Text>
          </>
        )}

        <GraduatedPricingFields price={price} />

        {price.structure.pricingType === 'LINEAR' ? (
          <LinearPricingFields price={price} />
        ) : price.structure.pricingType === 'PACKAGE' ? (
          <PackagePricingFields price={price} />
        ) : (
          <>
            <Text {...Lato13Regular}>Price</Text>
            <Text {...Lato13Regular} color={GreyGrey90}>
              {toPriceSummary(transformedPrice)}
            </Text>
          </>
        )}

        {!price.id.match(NEW_PRICE_PATTERN) && (
          <>
            <Text {...Lato13Regular}>Price ID</Text>
            <Flex gap={2}>
              <Badge
                sentiment="neutral"
                containerStyle={{
                  height: '16px',
                  padding: 1
                }}
              >
                {price.id}
              </Badge>
              <ClipboardIcon
                width="14px"
                color="gray.60"
                onClick={handleCopy}
                cursor="pointer"
              />
            </Flex>
          </>
        )}
      </Grid>
    </Flex>
  )
}

const PriceUsageMetric = ({ usageMetricId }: { usageMetricId: string }) => {
  const { data: usageMetric, isLoading } = useGetUsageMetricsIdQuery({
    id: usageMetricId
  })

  const usageMetricValue = usageMetric?.value()

  if (isLoading || !usageMetricValue) {
    return (
      <>
        <Text {...Lato13Regular}>Usage metric</Text>
        <Skeleton w="100px" />
      </>
    )
  }

  const linkToUsageMetric = `/usage-metrics/${usageMetricValue.id}`

  return (
    <>
      <Text {...Lato13Regular}>Usage metric</Text>
      <Link to={linkToUsageMetric}>
        <Text {...Lato13Bold} color={IndigoIndigo50}>
          {usageMetricValue.name}
        </Text>
      </Link>
    </>
  )
}

const LinearPricingFields = ({ price }: { price: PriceModel | ListPrice }) => {
  const { structure, currency } = price

  if (structure.pricingType !== 'LINEAR') {
    return null
  }

  return (
    <>
      {structure.isPricePercentage ? (
        <>
          <Text {...Lato13Regular}>Percentage</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {enforceMinimumPrecision(2)(structure.pricePerUnit)}%
          </Text>
        </>
      ) : (
        <>
          <Text {...Lato13Regular}>Price per unit</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.pricePerUnit })}
          </Text>
        </>
      )}
      {structure.minPrice && (
        <>
          <Text {...Lato13Regular}>Min. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.minPrice })}
          </Text>
        </>
      )}
      {structure.maxPrice && (
        <>
          <Text {...Lato13Regular}>Max. Price</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.maxPrice })}
          </Text>
        </>
      )}
    </>
  )
}

const PackagePricingFields = ({ price }: { price: PriceModel | ListPrice }) => {
  const { structure, currency } = price

  if (structure.pricingType !== 'PACKAGE') {
    return null
  }

  return (
    <>
      {structure.pricePerPackage && (
        <>
          <Text {...Lato13Regular}>Price per package</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {toMoney({ currency, value: structure.pricePerPackage })}
          </Text>
        </>
      )}
      {structure.packageSize && (
        <>
          <Text {...Lato13Regular}>Package size</Text>
          <Text {...Lato13Regular} color={GreyGrey90}>
            {structure.packageSize}
          </Text>
        </>
      )}
    </>
  )
}

const GraduatedPricingFields = ({
  price
}: {
  price: ListPrice | PriceModel
}) => {
  const { structure } = price

  if (structure.pricingType !== 'GRADUATED') {
    return null
  }

  const usageCalculationPeriodValue =
    usageCalculationPeriodMapper.fromApiPricePropertiesToUsageCalculationPeriodDropdown(
      {
        usageCalculationPeriod:
          'usageCalculationPeriod' in price
            ? price.usageCalculationPeriod
            : undefined,
        usageCalculationMode: structure.usageCalculationMode
      },
      price.billingFrequency
    )

  return (
    <>
      <Text {...Lato13Regular}>Usage calculation period</Text>
      <Text {...Lato13Regular} color={GreyGrey90}>
        {usageCalculationPeriodLabels[usageCalculationPeriodValue]}
      </Text>
    </>
  )
}
