import { dequal } from 'dequal'
import { pick } from 'lodash/fp'
import { Discount, ResolvedPhase } from 'modules/Cube/domain/cube.domain.types'

const checkableProperties = pick(['amount', 'discountCalculationType'])

export const getInvalidDiscountProration = ({
  previousPhase,
  phase
}: {
  previousPhase?: ResolvedPhase
  phase: Omit<ResolvedPhase, 'analysis'>
}): Discount[] => {
  const previousPhaseAnalysis = previousPhase?.analysis
  /**
   * If the phase is not misaligned, or is the last phase, then we can safely assume all is well
   * for this phase.
   */
  if (!previousPhaseAnalysis || previousPhaseAnalysis.alignedWithPeriod.end) {
    return []
  }

  const productDiscountsInPreviousPhase = previousPhase.prices.map(price => ({
    productId: price.productId,
    discount: previousPhase.discounts.find(
      discount =>
        discount.applyToAllPrices || discount.priceIds.includes(price.id)
    )
  }))

  if (!productDiscountsInPreviousPhase.length) {
    return []
  }

  const productDiscountsInThisPhase = phase.prices
    /**
     * We ignore one time prices from this check, as they do not prorate
     * in any way, so the discount only applies to them once.
     */
    .filter(price => price.billingFrequency !== 'ONE_TIME')
    .map(price => ({
      productId: price.productId,
      discount: phase.discounts.find(
        discount =>
          discount.applyToAllPrices || discount.priceIds.includes(price.id)
      )
    }))

  const changedDiscounts = productDiscountsInThisPhase.filter(
    currentProductDiscount => {
      const productDiscountInPreviousPhase =
        productDiscountsInPreviousPhase.find(
          previousProductDiscount =>
            previousProductDiscount.productId ===
            currentProductDiscount.productId
        )

      if (!productDiscountInPreviousPhase) {
        return false
      }

      return (
        !productDiscountInPreviousPhase?.discount ||
        !currentProductDiscount.discount ||
        !dequal(
          checkableProperties(currentProductDiscount.discount),
          checkableProperties(productDiscountInPreviousPhase.discount)
        )
      )
    }
  )

  const changedIncompatibleDiscounts = changedDiscounts.filter(
    productDiscount =>
      productDiscount.discount?.discountCalculationType === 'PERCENTAGE'
  )

  return changedIncompatibleDiscounts
    .map(({ discount }) => discount)
    .filter((discount): discount is Discount => Boolean(discount))
}
