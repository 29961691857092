import { Box, Link } from '@chakra-ui/react'
import { SelectField } from '@sequencehq/core-components'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import { BillingFrequency } from 'common/drawers/PricingEditor/domain'

/**
 * The standard field for billing frequency. It still expects
 * to receive options, as each pricing model can have different
 * options for this field. However, it provides the standard label
 * and description for convenience.
 *
 * Maybe in a future refactor, the billing frequency options
 * _can_ be consolidated here. But to keep things but being too
 * heavy a refactor, this will just prevent the need to copy
 * the new description a whole bunch of times.
 * @param props
 * @returns
 */
export const BillingFrequencyField = (props: {
  value: BillingFrequency
  options: Array<{ value: BillingFrequency; label: string }>
  onChange: (newValue: BillingFrequency) => void
  isDisabled: boolean
  validationErrors: { message: string }[]
  'data-testid': string
}) => {
  return (
    <SelectField
      data-testid={props['data-testid']}
      label="Billing frequency"
      description={
        props.value === 'ON_DEMAND' ? (
          <Box>
            Creates an invoice the next day when a usage event is submitted.{' '}
            <Link
              href="https://docs.sequencehq.com/billing/on-demand-billing"
              isExternal
              color={IndigoIndigo50}
            >
              Learn more
            </Link>
          </Box>
        ) : null
      }
      placeholder="Select billing frequency"
      value={props.value}
      options={props.options}
      onChange={newValue => props.onChange(newValue as BillingFrequency)}
      validationErrors={props.validationErrors}
      isDisabled={props.isDisabled}
    />
  )
}
