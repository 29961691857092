import {
  ModalContextProvider,
  SimpleDialogUI
} from '@sequencehq/core-components'
import { FC } from 'react'
import { useSteppedConfirmStartModal } from './useSteppedConfirmStartModal'
import Spinner from 'components/Loading/Spinner'
import { SteppedModal } from 'common/components/SteppedModal/SteppedModal'
import { BillingScheduleContextData } from 'Cube/domain'
import { AvalaraBlocker } from 'common/components/AvalaraBlocker/AvalaraBlocker'
import { match } from 'ts-pattern'

type SteppedConfirmStartModalProps = {
  onClose: () => void
  onConfirm: () => Promise<void>
  submitting: boolean
  billingScheduleQueries: BillingScheduleContextData['queries']
}

export const SteppedConfirmStartModal: FC<
  SteppedConfirmStartModalProps
> = props => {
  const { loading, validation, showAvalaraBlocker } =
    useSteppedConfirmStartModal({
      onClose: props.onClose,
      billingScheduleQueries: props.billingScheduleQueries
    })

  if (loading || validation === null) {
    return (
      <ModalContextProvider isOpen>
        <SimpleDialogUI title="Start schedule">
          <Spinner />
        </SimpleDialogUI>
      </ModalContextProvider>
    )
  }

  return match(showAvalaraBlocker)
    .with(true, () => (
      <AvalaraBlocker confirmTitleText="Start schedule" onClose={props.onClose}>
        This schedule includes products with tax categories that have not yet
        been mapped to an Avalara tax code.
      </AvalaraBlocker>
    ))
    .with(false, () => (
      <SteppedModal
        canSkip={false}
        onClose={props.onClose}
        onFinalConfirm={props.onConfirm}
        submitting={props.submitting}
        validation={validation}
        validationSummaryText="Some details are missing before you can start invoicing this customer. You can also add this later."
        confirmationSummaryText="The schedule will start generating invoices based on the defined prices and billing dates."
        confirmTitleText="Start schedule"
        confirmActionText="Start schedule"
      />
    ))
    .exhaustive()
}
