import { useStandardPriceForm } from './useStandardPriceForm'
import { TextField, SelectField, PriceField } from '@sequencehq/core-components'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { PricingModelField } from '../common/PricingModelField'
import { Box, InputGroup, Text } from '@chakra-ui/react'
import { BillingFrequency } from 'common/drawers/PricingEditor/domain'
import { GreyGrey90 } from '@sequencehq/design-tokens'
import { ExternalLedgerForm } from '../common/ExternalLedgerForm/ExternalLedgerForm'
import { BillingTypeField } from '../common/BillingTypeField'
import { BillingFrequencyField } from '../common/BillingFrequencyField'

export const StandardPriceForm = () => {
  const { fieldsConfig } = useStandardPriceForm()

  return (
    <>
      <Text
        color={GreyGrey90}
        fontWeight="bold"
        fontSize="l"
        textColor="##1D2939"
        mb="6"
      >
        Pricing Information
      </Text>
      <PricingModelField />

      <TextField
        label="Display name (appears on invoice)"
        data-testid="standard.name"
        value={fieldsConfig.name.value}
        onChange={fieldsConfig.name.onChange}
        validationErrors={fieldsConfig.name.validationErrors}
        isDisabled={fieldsConfig.name.disabled}
        placeholder="Enter a display name"
      />

      <Box paddingBottom="8px">
        <BillingFrequencyField
          data-testid="standard.billingFrequency"
          value={fieldsConfig.billingFrequency.value}
          options={fieldsConfig.billingFrequency.options}
          onChange={fieldsConfig.billingFrequency.onChange}
          validationErrors={fieldsConfig.billingFrequency.validationErrors}
          isDisabled={fieldsConfig.billingFrequency.disabled}
        />

        <BillingTypeField
          value={fieldsConfig.billingType.value}
          onChange={newValue => {
            fieldsConfig.billingType.onChange(newValue)
          }}
          isDisabled={fieldsConfig.billingType.disabled}
          data-testid="standard.billingType"
        />

        <Box mt={2} />

        <InputGroup display="flex" alignItems="center">
          <PriceField
            label="Price"
            data-testid="standard.price"
            value={fieldsConfig.price.value}
            onChange={newValue => fieldsConfig.price.onChange(newValue)}
            validationErrors={fieldsConfig.price.validationErrors}
            currency={fieldsConfig.currency.value}
            isDisabled={fieldsConfig.price.disabled}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          {!fieldsConfig.currency.hidden && (
            <Box mt={6}>
              <SelectField
                data-testid="standard.currency"
                value={fieldsConfig.currency.value}
                onChange={newCurrency =>
                  fieldsConfig.currency.onChange(newCurrency as Currency)
                }
                options={fieldsConfig.currency.options}
                validationErrors={fieldsConfig.currency.validationErrors}
                isDisabled={fieldsConfig.currency.disabled}
                placeholder="Select currency"
                width="150px"
                inputStyle={{
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
              />
            </Box>
          )}
        </InputGroup>
      </Box>

      <ExternalLedgerForm />
    </>
  )
}
