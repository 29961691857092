import { Popover, PopoverAnchor, PopoverBody, PopoverContent, Portal, useDisclosure, useToast } from '@chakra-ui/react'
import { InvoicePaymentStatus, toInvoicePaymentStatusBadgeProps } from '@sequencehq/core-models'
import { isErrorResponse, isSuccessResponse } from 'components/Form'
import { InvoiceBulkActionBar, Toast } from '@sequencehq/core-components'
import {
  usePostInvoicesFinalizeAndSendMutation,
  usePostInvoicesFinalizeMutation,
  usePostInvoicesRecalculateMutation,
  usePostInvoicesSendMutation,
  usePostInvoicesVoidMutation,
  usePutInvoicesPaymentStatusMutation
} from 'features/api'
import { FC, useEffect, useState } from 'react'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { ShadowL } from '@sequencehq/design-tokens'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNavigate } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ConfirmMergeInvoicesModal } from 'InvoiceEditor/components/modals/ConfirmMergeInvoicesModal.tsx'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice

export type InvoiceBulkActionsProps = {
  children: React.ReactNode
  selectedRows: Invoice[]
  clearSelectedRows: () => void
  popoverOffset?: [number, number]
}

type Action =
  | 'finalize'
  | 'send'
  | 'void'
  | 'merge'
  | 'markAs'
  | 'finalizeAndSend'
  | 'recalculate'

type Modals = {
  confirmMergeInvoices: {
    invoiceIds: string[]
  }
}

export const InvoiceBulkActions: FC<InvoiceBulkActionsProps> = ({
  children,
  selectedRows,
  clearSelectedRows,
  popoverOffset
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [finalizeInvoices] = usePostInvoicesFinalizeMutation()
  const [sendInvoices] = usePostInvoicesSendMutation()
  const [recalculateInvoices] = usePostInvoicesRecalculateMutation()
  const [voidInvoices] = usePostInvoicesVoidMutation()
  const mergeInvoices = useMutation(dashboard20240730Client.postMergeInvoices)
  const [updatePaymentStatus] = usePutInvoicesPaymentStatusMutation()
  const [finalizeAndSendInvoices] = usePostInvoicesFinalizeAndSendMutation()
  const toast = useToast()
  const navigate = useNavigate()
  const [modals, setModals] = useState<Modals>({ confirmMergeInvoices: { invoiceIds: [] } })

  //['finalize', 'send', 'send', 'markAs']

  // if selected row is draft - posible actions are finalize and send
  // if selected row is final - posible actions are send
  // if selected row is sent - possible actions are mark as

  // filter out actions that are not possible

  // if action is finalize filter selected rows to only include draft
  // if action is send filter selected rows to only include draft and final

  // if selectedRows is empty, close the popover if it's open

  const filterSelectedRows = (action: Action) => {
    switch (action) {
      case 'finalize':
        return selectedRows.filter(row => row.status === 'DRAFT')

      case 'recalculate':
        return selectedRows.filter(
          row =>
            ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
            !!row.billingScheduleId
        )

      case 'send':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )

      case 'markAs':
        return selectedRows.filter(row => row.status === 'SENT')

      case 'void':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )

      case 'merge':
        return (selectedRows.filter(row => row.status !== 'DRAFT').length === 0
          && new Set(selectedRows.map(row => row.customerId)).size === 1
          && new Set(selectedRows.map(row => row.currency)).size === 1
          && selectedRows.length > 1) ? selectedRows : []
    }

    return selectedRows
  }

  const getSelectedRowsIds = (selected: Invoice[]) => {
    return selected.map(row => row.id)
  }

  useEffect(() => {
    if (selectedRows.length > 0 && !isOpen) {
      onToggle()
    }
    if (selectedRows.length === 0 && isOpen) {
      onClose()
    }
  }, [selectedRows, isOpen, onToggle, onClose])

  const finalizeSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await finalizeInvoices({
      finaliseMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      onClose()
      clearSelectedRows()
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Finalizing started`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to finalize invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const recalculateSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await recalculateInvoices({
      recalculateMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      onClose()
      clearSelectedRows()
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Recalculation started`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to recalculate invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const finalizeAndSendSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await finalizeAndSendInvoices({
      finaliseAndSendMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Finalizing and sending started`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to finalise and send invoices`}
            isClosable={true}
          />
        )
      })
    }
    onClose()
    return invoiceUpdateRes
  }

  const sendSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await sendInvoices({
      sendMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast type="success" title={`Sending started`} isClosable={true}/>
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to send invoices`}
            isClosable={true}
          />
        )
      })
    }
    return invoiceUpdateRes
  }

  const voidSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await voidInvoices({
      voidMultipleInvoicesEndpointRequestModel: {
        invoiceIds: filteredIds
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      clearSelectedRows()
      onClose()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast type="success" title={`Voiding started`} isClosable={true}/>
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to void invoices`}
            isClosable={true}
          />
        )
      })
    }

    return invoiceUpdateRes
  }

  const confirmMergeSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    setModals(current => ({ ...current, confirmMergeInvoices: { invoiceIds: filteredIds } }))
  }

  const mergeSelectedInvoices = async (invoiceIds: string[]) => {

    try {
      const response = await mergeInvoices.mutateAsync({
        invoiceIds: invoiceIds
      })

      invariant(response, 'Failed to merge invoices')

      toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast type="success" title={`Invoices merged`} isClosable={true}/>
        )
      })

      navigate(`/invoices/${response.id}`)
    } catch (e) {
      const error = e as Error

      toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to merge invoices`}
            isClosable={true}
          />
        )
      })
    }
  }

  const markAsSelectedInvoices = async (
    status: 'UNPAID' | 'PARTIALLY_PAID' | 'PAID' | 'UNCOLLECTIBLE',
    filtered: Invoice[]
  ) => {
    const filteredIds: string[] = getSelectedRowsIds(filtered)

    const invoiceUpdateRes = await updatePaymentStatus({
      updateMultipleInvoicesPaymentStatusEndpointRequestModel: {
        invoiceIds: filteredIds,
        paymentStatus: status
      }
    })

    if (isSuccessResponse(invoiceUpdateRes)) {
      clearSelectedRows()
      onClose()

      const humanReadableStatus = toInvoicePaymentStatusBadgeProps({
        status
      }).children.toLowerCase()

      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="success"
            title={`Started marking invoices as ${humanReadableStatus}`}
            isClosable={true}
          />
        )
      })
    }

    if (isErrorResponse(invoiceUpdateRes)) {
      return toast({
        position: 'bottom',
        isClosable: true,
        render: () => (
          <Toast
            type="error"
            title={`Unable to update invoice payment statuses`}
            isClosable={true}
          />
        )
      })
    }
    return invoiceUpdateRes
  }

  const handleSubmitRows = (action: Action) => {
    const filteredSelectedRows = filterSelectedRows(action)

    if (filteredSelectedRows.length > 0) {
      switch (action) {
        case 'finalize':
          return finalizeSelectedInvoices(filteredSelectedRows)

        case 'recalculate':
          return recalculateSelectedInvoices(filteredSelectedRows)

        case 'send': {
          const draftSelectedRows = filteredSelectedRows.filter(
            row => row.status === 'DRAFT'
          )

          const sendSelectedRows = filteredSelectedRows.filter(
            row => row.status !== 'DRAFT'
          )

          if (draftSelectedRows) {
            return Promise.all([
              finalizeAndSendSelectedInvoices(draftSelectedRows),
              sendSelectedInvoices(sendSelectedRows)
            ])
          }
          return sendSelectedInvoices(filteredSelectedRows)
        }

        case 'void':
          return voidSelectedInvoices(filteredSelectedRows)

        case 'merge':
          return confirmMergeSelectedInvoices(filteredSelectedRows)
      }
    }
    return
  }

  const handleMarkAsRows = (status: InvoicePaymentStatus) => {
    const filtered = filterSelectedRows('markAs')

    if (getSelectedRowsIds(selectedRows).length > 0) {
      return markAsSelectedInvoices(status, filtered)
    }
    return
  }

  const handleCloseBulkActions = () => {
    clearSelectedRows()
    onClose()
  }

  const sortActions = () => {
    const actions = []
    if (selectedRows.some(row => row.status === 'DRAFT')) {
      actions.push('finalize', 'send', 'void')
    }
    if (
      selectedRows.some(
        row =>
          ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
          !!row.billingScheduleId
      )
    ) {
      actions.push('recalculate')
    }
    if (selectedRows.some(row => row.status === 'FINAL')) {
      if (actions.includes('finalize')) {
        actions.filter(action => action !== 'finalize')
        actions.push('send', 'void')
      }
      actions.push('send', 'void')
    }
    if (selectedRows.some(row => row.status === 'SENT')) {
      actions.push('markAs')
    }

    const uniqueArray = [...new Set(actions)]
    return uniqueArray
  }

  const possibleActions = sortActions()

  const countFor = (action: Action) => {
    const filteredCount = filterSelectedRows(action).length

    if (filteredCount === selectedRows.length) {
      return undefined
    }

    return filteredCount
  }

  return (
    <>
      {modals.confirmMergeInvoices.invoiceIds.length > 0 && (
        <ConfirmMergeInvoicesModal
          onClose={() => setModals(current => ({
            ...current,
            confirmMergeInvoices: { invoiceIds: [] }
          }))}
          onConfirm={() => mergeSelectedInvoices(modals.confirmMergeInvoices.invoiceIds)}
          count={modals.confirmMergeInvoices.invoiceIds.length}
        />
      )}
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="top"
        closeOnBlur={false}
        offset={popoverOffset}
      >
        <PopoverAnchor>{children}</PopoverAnchor>
        <Portal>
          <PopoverContent
            width="100%"
            py={1}
            px={2}
            borderRadius="8px"
            boxShadow={ShadowL}
          >
            <PopoverBody width="100%" height="100%" p="0">
              <InvoiceBulkActionBar
                count={selectedRows.length}
                onDismiss={handleCloseBulkActions}
              >
                {(possibleActions.includes('finalize') ||
                  possibleActions.includes('send') ||
                  possibleActions.includes('recalculate')) && (
                  <>
                    <InvoiceBulkActionBar.RecalculateButton
                      count={countFor('recalculate')}
                      onClick={() => void handleSubmitRows('recalculate')}
                    />
                    <InvoiceBulkActionBar.FinalizeButton
                      count={countFor('finalize')}
                      onClick={() => void handleSubmitRows('finalize')}
                    />
                    <InvoiceBulkActionBar.SendButton
                      count={countFor('send')}
                      onClick={() => void handleSubmitRows('send')}
                    />
                  </>
                )}

                {possibleActions.includes('markAs') && (
                  <InvoiceBulkActionBar.MarkAsDropdown
                    count={countFor('markAs')}
                    onSelect={status => handleMarkAsRows(status)}
                  />
                )}

                <InvoiceBulkActionBar.KebabMenu
                  items={[
                    <InvoiceBulkActionBar.KebabMenu.Merge
                      key="merge"
                      count={countFor('merge')}
                      onClick={() => void handleSubmitRows('merge')}
                    />,
                    <InvoiceBulkActionBar.KebabMenu.Void
                      key="void"
                      count={countFor('void')}
                      onClick={() => void handleSubmitRows('void')}
                    />
                  ]}
                />
              </InvoiceBulkActionBar>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}
