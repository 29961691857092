import {
  BillingFrequency,
  GraduatedPrice,
  UsageCalculationMode,
  UsageTierType
} from 'common/drawers/PricingEditor/domain'
import { UsageCalculationPeriodDropdownValue } from 'common/drawers/PricingEditor/view/editors/GraduatedPrice/UsageCalculationPeriodDropdown/UsageCalculationPeriodDropdown.tsx'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants.ts'

export const standardPriceFrequencies: {
  value: BillingFrequency
  label: string
}[] = [
  {
    value: 'ONE_TIME',
    label: 'Once'
  },
  ...defaultAvailableFrequenciesOptions
]

export const usageCalculationModeDescriptions: Record<
  UsageCalculationMode,
  string
> = {
  CUMULATIVE:
    'Usage is counted from the billing start date and accumulates with each billing period.',
  BILLING_PERIOD: 'Charges are based on usage in each billing period.',
  PERIODIC: 'Charges are based on usage in each billing period.'
}

export const availableUsageTierTypes: {
  label: string
  value: UsageTierType
}[] = [
  {
    label: 'Fixed',
    value: 'FIXED'
  },
  {
    label: 'Percentage',
    value: 'PERCENTAGE'
  }
]

export const availableUsageCalculationModes: {
  label: string
  value: UsageCalculationMode
}[] = [
  {
    label: 'Billing period calculation',
    value: 'BILLING_PERIOD'
  },
  {
    label: 'Cumulative calculation',
    value: 'CUMULATIVE'
  }
]

export const usageCalculationPeriodLabels: Record<
  UsageCalculationPeriodDropdownValue,
  string
> = {
  BILLING_PERIOD: 'Billing period',
  CUMULATIVE_USAGE: 'Cumulative usage',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: 'Semi-annual',
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly'
} as const

const usageCalculationPeriodOptions = {
  billingPeriod: {
    label: usageCalculationPeriodLabels.BILLING_PERIOD,
    value: 'BILLING_PERIOD'
  },
  monthly: {
    label: usageCalculationPeriodLabels.MONTHLY,
    value: 'MONTHLY'
  },
  cumulativeUsage: {
    label: usageCalculationPeriodLabels.CUMULATIVE_USAGE,
    value: 'CUMULATIVE_USAGE'
  },
  quarterly: {
    label: usageCalculationPeriodLabels.QUARTERLY,
    value: 'QUARTERLY'
  },
  halfYearly: {
    label: usageCalculationPeriodLabels.HALF_YEARLY,
    value: 'HALF_YEARLY'
  },
  annual: {
    label: usageCalculationPeriodLabels.ANNUAL,
    value: 'ANNUAL'
  }
} as const

export const availableUsageCalculationPeriodsByBillingFrequency: Record<
  GraduatedPrice['modelSpecific']['billingFrequency'],
  {
    label: string
    value: UsageCalculationPeriodDropdownValue
  }[]
> = {
  ONE_TIME: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage
  ],
  MONTHLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.quarterly,
    usageCalculationPeriodOptions.halfYearly,
    usageCalculationPeriodOptions.annual
  ],
  QUARTERLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.halfYearly,
    usageCalculationPeriodOptions.annual
  ],
  HALF_YEARLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.annual
  ],
  YEARLY: [
    usageCalculationPeriodOptions.billingPeriod,
    usageCalculationPeriodOptions.cumulativeUsage
  ],
  ON_DEMAND: [
    usageCalculationPeriodOptions.cumulativeUsage,
    usageCalculationPeriodOptions.monthly,
    usageCalculationPeriodOptions.quarterly,
    usageCalculationPeriodOptions.annual
  ]
}
