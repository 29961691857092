import { useCreateBlockNote } from '@blocknote/react'
import { useCallback, useEffect, useMemo } from 'react'
import { presentationToBlockNoteAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/presentationToBlocknote.adapter'
import { useCubeContext } from 'modules/Cube/communication/internal/cube.domain.context'
import { blocknoteSchema } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/blocknoteSchema'
import { useDebouncedCallback } from 'use-debounce'
import {
  hasPresentationChanged,
  mergeBlockNotePresentationIntoFullPresentation
} from './utils/presentation'
import { uploadFile } from './uploadFile'
import { useFlags } from 'launchdarkly-react-client-sdk'

type useContentEditor = () => {
  features: {
    contentEditor: {
      editor: typeof blocknoteSchema.BlockNoteEditor
      onChange: () => void
      onKeyDownCapture: (event: React.KeyboardEvent<HTMLDivElement>) => void
      disabled: boolean
      addPhaseBlock: {
        enabled: boolean
      }
    }
  }
}

export const useContentEditor: useContentEditor = () => {
  const cubeContext = useCubeContext()
  const flags = useFlags()
  const blockNoteEditor = useCreateBlockNote(
    {
      schema: blocknoteSchema,
      initialContent: presentationToBlockNoteAdapter.in(
        cubeContext.queries.rawData.data.presentation
      ),
      uploadFile: flags.useImageUploads ? uploadFile : undefined
    },
    [cubeContext.queries.rawData.editor.lastLoadedAt]
  )

  const syncPresentationToDomain = useDebouncedCallback(() => {
    const blockNotePresentation = presentationToBlockNoteAdapter.out({
      cubeQueries: cubeContext.queries
    })(blockNoteEditor.document)

    /**
     * Don't push changes to presentation if the editor is in an uneditable state anyway
     */
    if (!cubeContext.queries.availableFeatures.quote.edit.available.enabled) {
      return
    }

    const newPresentation = mergeBlockNotePresentationIntoFullPresentation(
      blockNotePresentation,
      cubeContext.queries.rawData.data.presentation
    )

    if (
      !hasPresentationChanged(
        newPresentation,
        cubeContext.queries.rawData.data.presentation
      )
    ) {
      return
    }

    cubeContext.mutators.updateData({
      presentation: newPresentation
    })
  }, 500)

  const onKeyDownCapture = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      /**
       * Prevents the default Blocknote behaviour where indentation is added when pressing the tab key
       * Source: https://github.com/TypeCellOS/BlockNote/issues/666
       */
      if (event.key === 'Tab') {
        event.preventDefault()
        return
      }
    },
    []
  )

  const addPhaseBlockEnabled: boolean = useMemo(() => {
    return !cubeContext.queries.rawData.data.presentation.blocks.find(
      block => block.blockType === 'phases'
    )
  }, [cubeContext.queries.rawData.data.presentation])

  const features = useMemo(() => {
    return {
      contentEditor: {
        editor: blockNoteEditor,
        disabled:
          !cubeContext.queries.availableFeatures.quote.edit.available.enabled,
        onChange: syncPresentationToDomain,
        onKeyDownCapture,
        addPhaseBlock: {
          enabled: addPhaseBlockEnabled
        }
      }
    }
  }, [
    blockNoteEditor,
    cubeContext.queries.availableFeatures.quote.edit.available.enabled,
    syncPresentationToDomain,
    onKeyDownCapture,
    addPhaseBlockEnabled
  ])

  useEffect(() => {
    syncPresentationToDomain()
  }, [blockNoteEditor.document, syncPresentationToDomain])

  useEffect(() => {
    /**
     * If we just reinitialized, cancel any queued debounced updates, since
     * the fresh data is now the ground truth. Ordering matters here - it
     * must be placed after the useEffect which calls the syncPresentationToDomain
     * function, otherwise a sneaky extra call will happen after the cancel.
     */
    syncPresentationToDomain.cancel()
  }, [
    cubeContext.queries.rawData.editor.lastLoadedAt,
    syncPresentationToDomain
  ])

  return {
    features
  }
}
